/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import * as Sentry from '@sentry/react'
import { Wasm as WasmIntegration } from '@sentry/wasm'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

if (window.__env === 'production') {
  Sentry.init({
    dsn:
      'https://93f5f63cb2514405a700053731ef67a6@o518563.ingest.sentry.io/5627822',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new WasmIntegration(),
      new ExtraErrorDataIntegration(),
      new CaptureConsoleIntegration(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.5,
    sampleRate: 0.5,
    environment: `${window.__appName}-${window.__env}`,
    attachStacktrace: true,
    autoSessionTracking: true,
  })
}

require('@rails/ujs').start()
require('jquery')
require('turbolinks').start()
require('chartkick')
require('chart.js')
require('lib/initBurgerMenu')
require('channels')
require('popper.js')
require('bootstrap')

import moment from 'moment-timezone'
import $ from 'jquery'

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

moment.locale('nb')

// Add Turbolinks to the global namespace
window.Turbolinks = Turbolinks
// Remove previous (native) events, and add Turbolinks'
ReactRailsUJS.detectEvents()
// (Optional) Clean up global namespace
delete window.Turbolinks

window.jQuery = $
window.$ = $
