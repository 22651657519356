import React from 'react'
import PropTypes from 'prop-types'
import DigimedSuggestion from './DigimedSuggestion'

const InterestNotification = ({
  availableServiceProviders,
  lowCoverageZone,
  address,
  digimedVideoIllustrationPath,
}) => {
  const headerText = () => {
    if (availableServiceProviders) {
      return 'Alle timer opptatt nå'
    }
    return 'Hjemmebesøk: Kommer snart'
  }

  const subHeaderText = () => {
    if (availableServiceProviders && !lowCoverageZone) {
      return 'Alle tilgjengelige timer i ditt område er dessverre opptatte. I mellomtiden kan du allikevel få hjelp av våre erfarne Hjemmeleger via nettet.'
    }

    if (lowCoverageZone && availableServiceProviders) {
      return 'Alle tilgjengelige timer i ditt område er dessverre opptatte. I mellomtiden kan du allikevel få hjelp av våre erfarne Hjemmeleger via nettet.'
    }
    return 'Det finnes dessverre ingen behandlere i ditt område enda. Vi jobber hardt med å utvide til hele landet. I mellomtiden kan du allikevel få hjelp av våre erfarne Hjemmeleger via nettet.'
  }

  return (
    <div className="interest-notification__content mt-3">
      <div className="interest-notification__text-wrapper">
        <h2 className="interest-notification__title h4">
          {headerText()}
        </h2>

        <p className="interest-notification__sub-header">
          {subHeaderText()}
        </p>

        <DigimedSuggestion
          heading="Snakk med lege nå!"
          imageUrl={digimedVideoIllustrationPath}
        />
      </div>
    </div>
  )

  InterestNotification.propTypes = {
    availableServiceProviders: PropTypes.bool.isRequired,
    lowCoverageZone: PropTypes.bool.isRequired,
    address: PropTypes.objectOf(PropTypes.string),
    digimedVideoIllustrationPath: PropTypes.string.isRequired,
  }
}

export default InterestNotification
