import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class PasswordInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    name: PropTypes.string,
  }

  validate = (text) => {
    const patt = /^(?=.*[a-z])(?=.*\d).{8,}$/
    return patt.test(text)
  }

  wrapperClass = () =>
    this.props.isValid
      ? 'form-group booking-form__valid-icon'
      : 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'

  errorClass() {
    return this.props.showError && !this.props.isValid ? 'error' : ''
  }

  render() {
    const { ...prevProps } = this.props

    const placeholder =
      this.props.placeholder !== undefined
        ? this.props.placeholder
        : 'Passord'

    const props = {
      ...prevProps,
      validate: this.validate,
      name: 'user[password]',
      type: 'password',
      placeholder,
    }

    return (
      <div>
        <p className="text-muted mt-0 pt-0">
          Bruk minst 8 tegn i passordet inkludert ett nummer og ett
          spesialtegn
        </p>
        <div className={this.wrapperClass()}>
          <div
            className={`form-control booking-form__icon-wrapper ${
              this.props.disabled ? 'disabled' : null
            } ${this.errorClass()}`}
          >
            <label className="sr-only">{props.placeholder}</label>
            <ValidatedInput {...props} />
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordInput
