import React from 'react'
import PropTypes from 'prop-types'

import TextInput from './TextInput'
import FieldWrapper, { propTypes } from './FieldWrapper'

const Email = (props) => {
  const inputProps = _.pick(props, Object.keys(propTypes))

  return (
    <FieldWrapper {...props}>
      <label htmlFor={props.name} className={`label {}`}>
        {props.label}
      </label>
      <TextInput {...inputProps} />
    </FieldWrapper>
  )
}

Email.defaultProps = {
  name: 'email',
  label: 'E-postadresse',
  placeholder: '',
  autoComplete: 'email',
  cssScope: '',
  filter: (value) => value.replace(' ', ''),
  validate: (value) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value),
}

Email.propTypes = {
  onChangeText: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  initialValue: PropTypes.string,
  showError: PropTypes.bool,
  name: PropTypes.string,
  cssScope: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  onRef: PropTypes.func,
}

export default Email
