import React, { useState } from 'react'
import Switch from './Switch'
import PropTypes from 'prop-types'
import { put } from '../lib/backend-client'

const SpSettingsNotificationToggle = (props) => {
  const [enabled, setEnabled] = useState(props.enabled)

  const handleToggle = () => {
    const value = !enabled
    setEnabled(value)
    put(props.path, {
      id: props.settingId,
      service_provider_setting: { value: value },
    })
  }

  return (
    <>
      <Switch isOn={enabled} onChange={handleToggle} />
    </>
  )
}

SpSettingsNotificationToggle.propTypes = {
  path: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  settingId: PropTypes.number.isRequired,
}

export default SpSettingsNotificationToggle
