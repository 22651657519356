import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { get } from '../lib/backend-client'
import parse from '../lib/event-descriptions'

const EventLog = (props) => {
  const [events, setEvents] = useState([])
  const [loaded, setLoaded] = useState(false)

  const fetchEvents = async (e) => {
    e.preventDefault()
    const response = await get(props.path)
    if (response.errors)
      return alert('Error: ' + response.errors.join(', '))

    setEvents(response.events)
    setLoaded(true)
  }

  const d = (date) => moment(date).format('DD.MM.YY HH:mm:ss')

  return (
    <div className="event-log my-3">
      {!loaded && (
        <button
          className="btn btn-outline-primary"
          onClick={fetchEvents}
        >
          Vis
        </button>
      )}
      {loaded && events.length == 0 && <p>ingen hendelser</p>}
      {events.length > 0 && (
        <div className="row my-2">
          <div className="col-3">Tidspunkt</div>
          <div className="col-9">Beskrivelse</div>
        </div>
      )}
      {events &&
        events.map((event, i) => (
          <div
            key={event.id}
            className={`row my-1 small-text ${
              i % 2 ? 'even' : 'odd'
            }`}
          >
            <div className="col-3">{d(event.created_at)}</div>
            <div className="col-9">{parse(event)}</div>
          </div>
        ))}
    </div>
  )
}

EventLog.propTypes = {
  path: PropTypes.string.isRequired,
}

export default EventLog
