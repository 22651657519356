import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from './forms/fields/PhoneInput'
import NameInput from './forms/fields/NameInput'
import TelLink from './TelLink'
import { patch } from '../lib/backend-client'

const ContactPersonInlineEdit = ({
  path,
  name,
  phone_number,
  service_provider,
}) => {
  const [editable, setEditable] = useState(false)
  const [contact, setContact] = useState({ name, phone_number })
  const [error, setError] = useState(null)

  const changeHandler = (value, valid, fieldName) => {
    const contactDupe = { ...contact }
    contactDupe[fieldName] = value
    setContact(contactDupe)
  }

  const edit = (e) => {
    e.preventDefault()
    setEditable(true)
  }

  const save = async (e) => {
    e.preventDefault()
    const response = await patch(path, {
      contact_person: { ...contact },
    })

    if (response.success) {
      setEditable(false)
      setError(null)
    } else {
      setError(response.err.message)
    }
  }

  const close = (e) => {
    e.preventDefault()
    setEditable(false)
  }

  return (
    <div className="row contact-person-inline-edit hlform">
      <div className="col-sm-6">
        {!editable && <p>{contact.name}</p>}
        {editable && (
          <NameInput
            name="name"
            value={contact.name || ''}
            onChange={changeHandler}
            highlightErrors={false}
            autocomplete="off"
            required={true}
          />
        )}
      </div>
      <div className="col-sm-6 text-sm-right ml-0 pl-sm-0">
        {!editable && (
          <p>
            <TelLink phoneNumber={contact.phone_number} />
          </p>
        )}
        {editable && (
          <PhoneInput
            name="phone_number"
            value={contact.phone_number || ''}
            onChange={changeHandler}
            highlightErrors={false}
            autocomplete="off"
            isRequired
          />
        )}
      </div>
      <div className="col-12">
        {!editable && (
          <a href="#" onClick={edit}>
            {name || phone_number ? 'Rediger' : 'Legg til'}{' '}
            kontaktperson
          </a>
        )}
        {editable && (
          <>
            <a href="#" onClick={save} className="btn btn-secondary">
              Lagre
            </a>
            <a href="#" onClick={close} className="ml-2">
              Avbryt
            </a>
            {error && (
              <span className="text-danger ml-2">{error}</span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ContactPersonInlineEdit.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string,
  phone_number: PropTypes.string,
  service_provider: PropTypes.object.isRequired,
}

export default ContactPersonInlineEdit
