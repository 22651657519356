import gql from '../../api/graphql'

export const cancelConsultation = async (consultationId) => {
  const response = await gql(
    `mutation {
        cancelConsultation(
         id: ${consultationId}
        ) {
          id
        }
      }`,
  )

  return response.data?.data.cancelConsultation
}
