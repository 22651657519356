import React from 'react'
import PropTypes from 'prop-types'

const GoogleMapsLink = ({ destination, text }) => (
  <a
    href={`https://www.google.com/maps/dir/?api=1&destination=${destination}&country=Norway`}
    target="_blank"
    rel="noreferrer"
  >
    {text || destination}
  </a>
)

GoogleMapsLink.propTypes = {
  destination: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default GoogleMapsLink
