import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BookingExpiredModal from './BookingExpiredModal.jsx'
import consumer from '../channels/consumer'

const IDLE = 'idle'
const RUNNING = 'running'
const EXPIRED = 'expired'
const HIJACKED = 'hijacked'

const DURATION = 5 // minutes
const MAX_RETRIES = 3 // times DURATION minutes

const CHANGE_EVENT = 'keyup'
const IS_TEXT_KEY = (keyCode) =>
  (keyCode > 47 && keyCode < 58) || // number keys
  keyCode == 32 || // spacebar & return key(s) (if you want to allow carriage returns)
  (keyCode > 64 && keyCode < 91) || // letter keys
  (keyCode > 95 && keyCode < 112) // numpad keys

const log = function () {
  // var args = Array.from(arguments)
  // console.log.apply(console, args)
}

let connected = false
class TimeLimitCountdown extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    runningIcon: PropTypes.string.isRequired,
    idleIcon: PropTypes.string.isRequired,
    expiredIcon: PropTypes.string.isRequired,
    reservePath: PropTypes.string.isRequired,
    releasePath: PropTypes.string.isRequired,
    restartPath: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state =
      this.readPersistedState(props.booking.reserved) ||
      this.getInitialState()

    window.addEventListener('unload', this.socketRelease)
    document.addEventListener(
      'safe-unload-pending',
      this.overrideUnloadRelease,
    )
    log('initial state', this.state)
  }

  getInitialState(retries = 0) {
    const endDate = new Date()
    endDate.setMinutes(endDate.getMinutes() + DURATION)
    const countdownState = retries >= MAX_RETRIES ? EXPIRED : IDLE
    return { min: DURATION, sec: 0, countdownState, endDate, retries }
  }

  handleFormActivity = (e) => {
    if (
      IS_TEXT_KEY(e.keyCode) &&
      this.state.countdownState === IDLE
    ) {
      document.removeEventListener(
        CHANGE_EVENT,
        this.handleFormActivity,
      )
      this.reserve()
    }
  }

  overrideUnloadRelease = () => {
    log('removed unload listener')
    window.removeEventListener('unload', this.socketRelease)
  }

  componentDidMount() {
    log('mounted in', this.state.countdownState, 'state')

    document.addEventListener('submitPayment', this.stop)

    if (this.state.countdownState === IDLE) {
      document.addEventListener(CHANGE_EVENT, this.handleFormActivity)
    }

    if (this.state.countdownState === RUNNING) this.start()

    this.socket = consumer.subscriptions.create(
      'AvailabilityChannel',
      {
        connected: () => {
          connected = true
        },
        disconnected: () => {
          connected = false
        },
        release: function (booking_id) {
          if (connected) {
            this.perform('expire_booking', { booking_id })
          }
        },
        received: (booking) => {
          const {
            service_provider_id,
            begins_at,
            id,
          } = this.props.booking

          const sameSlot =
            booking.service_provider_id === service_provider_id &&
            booking.begins_at === begins_at

          const sameUser = booking.id === id
          log(
            'sameSlot: ' + sameSlot,
            'sameUser: ' + sameUser,
            booking,
          )

          if (sameSlot) {
            if (sameUser) {
              this.setState({ reserved: booking.reserved })
              if (booking.reserved) {
                log('attempting to start')
                this.start()
              } else {
                this.stop()
              }
            } else {
              this.hijack()
            }
          }
        },
      },
    )
  }

  componentWillUnmount() {
    document.removeEventListener(
      CHANGE_EVENT,
      this.handleFormActivity,
    )
    document.removeEventListener('submitPayment', this.stop)
    this.release()
  }

  start() {
    log('start')
    if (this.interval) {
      console.warn('already running')
      return
    }
    if (this.state.retries >= MAX_RETRIES) {
      console.warn('too many retries')
      this.release()
      this.expire()
      return
    }

    this.interval = setInterval(this.tick, 1000)

    this.setAndPersistState({
      countdownState: RUNNING,
    })

    log('starting, retries: ' + this.state.retries)
  }

  tick = () => {
    const time = this.calculateCountdown()
    if (
      time.min + time.sec > 0 &&
      this.state.countdownState === RUNNING
    ) {
      this.setAndPersistState(time)
    } else {
      this.stopAndRelease()
    }
  }

  stopAndRelease = () => {
    this.stop()
    this.release()
  }

  stop = () => {
    if (this.state.countdownState === RUNNING) {
      log('stopping')
      this.interval = clearInterval(this.interval)
      if (this.state.retries >= MAX_RETRIES) {
        this.expire()
      } else {
        this.setAndPersistState(
          this.getInitialState(this.state.retries + 1),
        )
        document.addEventListener(
          CHANGE_EVENT,
          this.handleFormActivity,
        )
      }
    }
  }

  reserve() {
    log('reserving')
    $.ajax({
      type: 'POST',
      url: this.props.reservePath,
      async: false,
    }).done((response) => {
      log(response)
      if (!response.success) {
        this.hijack()
      }
    })
  }

  socketRelease = () => {
    if (this.socket.consumer.subscriptions.subscriptions.length) {
      log('releasing booking')
      this.socket.release(this.props.booking.id)
    }
    this.destroyPersistedState()
  }

  release() {
    log('releasing')
    $.ajax({
      type: 'POST',
      url: this.props.releasePath,
      async: false,
    })
  }

  expire() {
    log('expiring')
    this.setAndPersistState({ countdownState: EXPIRED })
  }

  hijack() {
    log('hijacking')
    this.setAndPersistState({ countdownState: HIJACKED })
    document.removeEventListener(
      CHANGE_EVENT,
      this.handleFormActivity,
    )
  }

  calculateCountdown() {
    let diff =
      (Date.parse(new Date(this.state.endDate)) -
        Date.parse(new Date())) /
      1000

    // clear countdown when date is reached
    if (diff <= 0) return { min: 0, sec: 0 }

    const timeLeft = {
      min: 0,
      sec: 0,
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60)
      diff -= timeLeft.min * 60
    }
    timeLeft.sec = diff

    return timeLeft
  }

  setAndPersistState(state) {
    this.setState(state, () => {
      window.localStorage.setItem(
        this.persistKey(),
        JSON.stringify(this.state),
      )
    })
  }

  readPersistedState(dbReserved) {
    const state = JSON.parse(
      window.localStorage.getItem(this.persistKey()),
    )
    if (state && !dbReserved)
      state.countdownState =
        state.EXPIRED || state.HIJACKED ? state.countdownState : IDLE
    log('read', this.persistKey(), state)
    return state
  }

  destroyPersistedState() {
    window.localStorage.removeItem(this.persistKey())
  }

  persistKey = () => `HL-countdown-${this.props.booking.id}`

  renderCountdown() {
    return (
      <div>
        <img src={this.props.runningIcon} className="emoji" />
        Vi holder av timen din i{' '}
        <span className="time-limit-countdown__highlight">
          {this.state.min} min{' '}
        </span>
        og{' '}
        <span className="time-limit-countdown__highlight">
          {' '}
          {this.state.sec} sek
        </span>
      </div>
    )
  }

  renderCountdownState() {
    switch (this.state.countdownState) {
      case RUNNING:
        return this.renderCountdown()
        break
      case IDLE:
        return (
          <div>
            <img src={this.props.idleIcon} className="emoji" />
            Begynn å fylle ut skjemaet for å holde av timen!
          </div>
        )
        break
      case EXPIRED:
        return (
          <div>
            <img src={this.props.expiredIcon} className="emoji" />
            Tid utløpt. Fullfør bestillingen nå for å beholde timen!
          </div>
        )
        break
      case HIJACKED:
        return (
          <div>
            <img src={this.props.idleIcon} className="emoji" />
            Timen er ikke lenger tilgjengelig.
          </div>
        )
        break
      default:
        console.error(
          'Invalid countdownState: ' + this.state.countdownState,
        )
        return null
    }
  }

  render() {
    return (
      <>
        <BookingExpiredModal
          restartPath={this.props.restartPath}
          isOpen={this.state.countdownState == HIJACKED}
        />
        {this.renderCountdownState()}
      </>
    )
  }
}

export default TimeLimitCountdown
