import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ValidatedInput from '../inputs/ValidatedInput'

class CcNumberInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
  }

  filterInput(input) {
    const text = input.replace(/[^0-9.]+/g, '').substring(0, 16)

    let outputString = ''
    _.map(text, function (digit, index) {
      if (
        (text.length > 4 && index == 3) ||
        (text.length > 8 && index == 7) ||
        (text.length > 12 && index == 11)
      ) {
        outputString += digit + ' '
      } else {
        outputString += digit
      }
    })

    return outputString
  }

  validate(text) {
    const patt = /^\d{4} \d{4} \d{4} \d{4}$/
    return patt.test(text)
  }

  render() {
    const safeProps = _.omit(this.props, 'isValid', 'style')

    const childProps = {
      ...safeProps,
      validate: this.validate,
      filter: this.filterInput,
      placeholder: 'Ditt kortnummer',
      inputMode: 'numeric',
    }

    return (
      <div className="form-group" style={style}>
        <label className="payment-form__label">Kortnummer</label>
        <br />
        <ValidatedInput {...childProps} />
      </div>
    )
  }
}

export default CcNumberInput
