import React, { useState } from 'react'
import PropTypes from 'prop-types'

const EMPTY_CONTACT_PERSON = {
  id: '',
  name: '',
  phone_number: '',
}

const contactPerson = (props) => {
  const [contactPerson, setContactPerson] = useState(
    props.contact_person || { ...EMPTY_CONTACT_PERSON },
  )
  const [
    isAddingOrUpdatingContact,
    setIsAddingOrUpdatingContact,
  ] = useState(props.contact_person ? true : false)

  const [isDeletingContact, setIsDeletingContact] = useState(false)

  const changeHandler = (e) => {
    const propName = e.target.getAttribute('data-propname')
    const value = e.target.value
    updateContactPerson(propName, value)
  }

  const addContactPerson = (e) => {
    e.preventDefault()
    setIsAddingOrUpdatingContact(true)
  }

  const deleteHandler = (e) => {
    e.preventDefault()
    if (contactPerson['id'] !== '') {
      if (
        confirm(
          'Kontakt vil bli fjernet fra bestillingen. Er du sikker?',
        )
      ) {
        updateContactPerson('_destroy', true)
        setIsDeletingContact(true)
      }
    } else {
      setIsAddingOrUpdatingContact(false)
    }
  }

  const updateContactPerson = (propName, value) => {
    const newContactPerson = { ...contactPerson }
    newContactPerson[propName] = value
    setContactPerson(newContactPerson)
  }

  {
    /* disabled the possibility of adding a contact person for admin */
  }
  // const renderAddContactPerson = () => (
  //   <div className="AddContact">
  //     <p className="mb-4">
  //       <a
  //         href="#"
  //         onClick={addContactPerson}
  //         className="btn btn-block btn-primary"
  //       >
  //         Legg til Kontakt
  //       </a>
  //     </p>
  //   </div>
  // )

  const renderMinimumFields = () => (
    <>
      <input
        className="form-control string optional"
        data-propname="id"
        data-contact-person_id={contactPerson.id}
        onChange={changeHandler}
        value={contactPerson.id}
        type="hidden"
        name={`${props.parentModel}[contact_person_attributes][id]`}
        id={`contact_person_attributes__id`}
      />
      <div className="col-12">
        <a
          href="#"
          className="mb-2 text-right remove-patient"
          style={{
            display: props.allowRemove ? 'none' : 'inherit',
          }}
          onClick={deleteHandler}
          data-propname="_destroy"
          data-contact_person_id={contactPerson.id}
        >
          Slett kontakt fra bestillingen
        </a>
      </div>
      <div className="col-sm-6">
        <div className="form-group string optional">
          <label
            className="form-control-label string optional"
            htmlFor={`contact_person_attributes__name`}
          >
            Kontakt Fullt navn
          </label>
          <input
            className="form-control string optional"
            type="text"
            data-propname="name"
            data-contact-person_id={contactPerson.id}
            onChange={changeHandler}
            value={contactPerson.name}
            name={`${props.parentModel}[contact_person_attributes][name]`}
            id={`contact_person_attributes__name`}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group tel optional">
          <label
            className="form-control-label tel optional"
            htmlFor={`contact_person_attributes__phone_number`}
          >
            Kontakt Telefonnummer
          </label>

          <input
            className="form-control string tel optional"
            placeholder="Med landskode f.eks. +47"
            data-propname="phone_number"
            data-contact-person_id={contactPerson.id}
            onChange={changeHandler}
            value={contactPerson.phone_number}
            type="tel"
            name={`${props.parentModel}[contact_person_attributes][phone_number]`}
            id={`contact_person_attributes__phone_number`}
          />
        </div>
      </div>
    </>
  )

  const renderDeleteField = () => (
    <input
      data-propname="_destroy"
      data-contact-person_id={contactPerson.id}
      value={contactPerson._destroy ? '' : contactPerson.id}
      type="hidden"
      name={`${props.parentModel}[contact_person_id]`}
      id={`contact_person_attributes___destroy`}
    />
  )

  return (
    <>
      {/* disabled the possibility of adding a contact person for admin */}
      {/* {!props.contact_person &&
        !isAddingOrUpdatingContact &&
        renderAddContactPerson()}
      {(props.contact_person || isAddingOrUpdatingContact) && ( */}

      {props.contact_person && (
        <div
          className="row py-2 mb-1"
          id={contactPerson.id}
          data-contact-person_id={contactPerson.id}
          style={{
            backgroundColor: '#f7f7f9',
            display: contactPerson['_destroy'] ? 'none' : 'flex',
          }}
        >
          {!isDeletingContact && renderMinimumFields()}
          {contactPerson['id'] !== null && renderDeleteField()}
        </div>
      )}
    </>
  )
}

contactPerson.propTypes = {
  parentModel: PropTypes.string.isRequired,
  allowRemove: PropTypes.bool,
  contact_person: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    phone_number_old: PropTypes.string,
  }),
}

export default contactPerson
