import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadGoogle from '../lib/loadGoogle'

class BookingOverviewMap extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    class: PropTypes.string,
    bookings: PropTypes.string.isRequired,
    marker: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
  }

  placeMarkers(callback) {
    const bookings = JSON.parse(this.props.bookings)
    const bounds = new google.maps.LatLngBounds()

    const icon = {
      url: this.props.marker,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1,
      size: new google.maps.Size(64, 42),
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(64 / 2, 18),
      anchor: new google.maps.Point(64 / 2, 42),
    }

    for (const booking of bookings) {
      const { lat, lng } = booking
      const time = new Date(booking.begins_at)
      const hours = time.getHours()
      const minutes =
        (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()

      const marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        map: this.map,
        icon: icon,
        label: {
          text: `${hours}:${minutes}`,
          color: 'white',
          fontSize: '18px',
          fontFamily: '"Fabriga Regular", Courier,Monospace',
        },
      })

      if (marker.getVisible()) {
        bounds.extend(marker.getPosition())
      }
    }

    this.map.fitBounds(bounds)
    callback(bookings)
  }

  componentDidMount = async () => {
    if (!window.google) {
      await loadGoogle(this.props.googleMapsApiKey)
    }

    this.map = new google.maps.Map(this.mapContainer, {
      streetViewControl: false,
      disableDefaultUI: true,
      fullscreenControl: true,
      gestureHandling: 'cooperative',
    })

    this.placeMarkers((bookings) => {
      setTimeout(() => {
        const zoomLvl = this.map.getZoom()
        if (zoomLvl > 16 || bookings.length < 2) {
          this.map.setZoom(15)
        }
      }, 1000)
    })
  }

  render() {
    return (
      <div>
        <div
          ref={(ref) => {
            this.mapContainer = ref
          }}
          style={{ height: '250px' }}
          className={this.props.class}
        />
      </div>
    )
  }
}

export default BookingOverviewMap
