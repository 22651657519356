import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Patient from './Patient'

const EMPTY_PATIENT = {
  name: 'Pasient uten navn',
  phone_number: '',
  email: '',
  national_id: '',
}

const PatientList = (props) => {
  const defaultPatients = props.patients.length
    ? props.patients
    : [{ ...EMPTY_PATIENT }]

  const initialPatients = defaultPatients.map((patient) => ({
    ...patient,
    national_id: patient.id ? props.national_ids[patient.id] : '',
  }))

  const [patients, setPatients] = useState(initialPatients)
  const [open, setOpen] = useState(!props.clickToShow)

  const addPatient = (e) => {
    e.preventDefault()
    setPatients([...patients, { ...EMPTY_PATIENT }])
  }

  const removePatient = (index) => {
    const patientsClone = [...patients]
    patientsClone.splice(index, 1)
    setPatients(patientsClone)
  }

  const updatePatient = (index, newPatient) => {
    const patientsClone = [...patients]
    patientsClone[index] = newPatient
    setPatients(patientsClone)
  }

  const handleOpenClick = () => {
    setOpen(true)
  }

  const onlyPatientIsEmptyPatient = () =>
    patients.length === 1 && patients[0] === EMPTY_PATIENT

  const renderPatientList = () => {
    if (open && !onlyPatientIsEmptyPatient()) {
      return patients.map((patient, i) => (
        <Patient
          key={i}
          index={i}
          onChange={updatePatient}
          onRemove={removePatient}
          parentModel={props.parent_model}
          allowRemove={i == 0 && patients.length == 1}
          renderOptionalFields={props.parent_model === 'booking'}
          patient={patient}
        ></Patient>
      ))
    }
    return null
  }

  return (
    <div className="patient-list">
      {renderPatientList()}
      {open && props.allowAddPatients && (
        <button
          className="mb-4 btn btn-block btn-secondary"
          onClick={addPatient}
        >
          Ny pasient
        </button>
      )}
      {!open && props.allowAddPatients && (
        <button
          className="mb-4 btn btn-block btn-secondary"
          onClick={handleOpenClick}
        >
          Legg til pasienter
        </button>
      )}
    </div>
  )
}

PatientList.defaultProps = {
  patients: [{ ...EMPTY_PATIENT }],
  allowAddPatients: true,
  clickToShow: false,
}

PatientList.propTypes = {
  parent_model: PropTypes.string.isRequired,
  national_ids: PropTypes.object,
  patients: PropTypes.array,
  allowAddPatients: PropTypes.bool,
  clickToShow: PropTypes.bool,
}

export default PatientList
