//https://codesandbox.io/s/github/reduxjs/redux-fundamentals-example-app/tree/checkpoint-9-createSlice/?from-embed=&file=/src/api/client.js:129-986

const BASE_URL = '/api/v1'
export async function client(
  endPoint,
  { params, body, ...customConfig } = {},
) {
  const qs = new URLSearchParams(params)
  let url = BASE_URL + endPoint
  if (params) url += '?' + qs
  const headers = { 'Content-Type': 'application/json' }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await fetch(url, config)
    data = await response.json()
    if (response.ok) {
      return data
    }
  } catch (err) {
    return Promise.reject(err)
  }
}

client.get = function (endPoint, params, customConfig = {}) {
  return client(endPoint, { params, ...customConfig, method: 'GET' })
}

client.post = function (endPoint, body, customConfig = {}) {
  return client(endPoint, { ...customConfig, body })
}
