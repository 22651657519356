import React from 'react'
import PropTypes from 'prop-types'

const ButtonReset = ({ onClick, children }) => (
  <button
    onClick={onClick}
    style={{
      cursor: 'pointer',
      appearance: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
      outline: 'none',
      verticalAlign: 'baseline',
    }}
  >
    {children}
  </button>
)

ButtonReset.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
}

export default ButtonReset
