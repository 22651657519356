function initBurgerMenu() {
  document.addEventListener('turbolinks:load', function () {
    $('.hamburger-button').on('click', function (e) {
      e.preventDefault()
      $('body').toggleClass('menu-is-open')

      window.setTimeout(function () {
        $('#main-menu').toggleClass('animate')
      }, 50)
    })
  })
}

export default initBurgerMenu()
