import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Selector } from './Selector'
import { serviceCenterProducts } from './queries'
import { serviceCenterShape } from '../propShapes'
import StickyConfirmation from '../../StickyConfirmation'
import Button from '../../forms/fields/Button'

const sortProducts = (products) => {
  const sorted = {
    doctor: products.filter((p) => p.slug.match(/doc/)),
    vaccines: products.filter((p) => p.slug.match(/vac/)),
    general: products.filter((p) => !p.slug.match(/vac|doc/)),
  }

  const result = {}
  if (sorted.doctor.length) result.doctor = sorted.doctor
  if (sorted.vaccines.length) result.vaccines = sorted.vaccines
  if (sorted.general.length) result.general = sorted.general
  return result
}

let sortedProducts

const findLowestPrice = (products) =>
  products.reduce(
    (sum, p) => (p.price < sum ? p.price : sum),
    Infinity,
  )

const formatPrice = (price) => Math.ceil(price / 100)
export function ProductSelector({
  serviceCenter,
  onComplete,
  onError,
  t,
}) {
  const [availableProducts, setAvailableProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])

  function onSubmit() {
    onComplete(selectedProducts)
  }

  function handleSelect(ids, category) {
    sortedProducts[category].forEach(
      (p) => (p.selected = ids.includes(p.id)),
    )
    const products = Object.values(sortedProducts)
      .map((cat) => cat.filter((p) => p.selected))
      .flat()

    setSelectedProducts(products)
  }

  useEffect(() => {
    async function fetchAvailableProducts() {
      try {
        const args = serviceCenter
          ? { serviceCenterIds: [serviceCenter?.id] }
          : {}
        const { products } = (
          await serviceCenterProducts(args)
        ).data.serviceCenterProducts
        sortedProducts = sortProducts(products)
        setAvailableProducts(products)
      } catch (error) {
        onError(error)
      }
    }
    fetchAvailableProducts()
  }, [onError, serviceCenter, setAvailableProducts])

  const totalPrice = selectedProducts.reduce((sum, product) => {
    return product.price + sum
  }, 0)

  return (
    <section>
      <h1 className="mb-4">{t('header', s)}</h1>
      {availableProducts.length < 1 ? (
        <div className="d-flex justify-content-center align-items-center">
          <span className="spinner-border text-primary ml-2" />
        </div>
      ) : (
        <>
          {Object.keys(sortedProducts).map((k) => (
            <div key={k}>
              <div data-toggle="collapse" data-target={`#${k}`}>
                <Button
                  className="btn btn-secondary btn-primary btn-big ishadow"
                >
                  {`${t(k, s)} (${t('from')} ${
                    findLowestPrice(sortedProducts[k]) / 100
                  },-)`}
                </Button>
              </div>
              <div id={k} className="collapse">
                <Selector
                  name={k}
                  allowMultiple
                  options={sortedProducts[k].map((product) => {
                    const {
                      id,
                      name,
                      publicName,
                      description,
                      price,
                      slug,
                    } = product
                    const title = `${publicName} (${
                      price ? `${formatPrice(price)},-` : t('free', s)
                    })`
                    return { name, id, title, description, slug }
                  })}
                  onSelect={handleSelect}
                />
              </div>
            </div>
          ))}

          <StickyConfirmation
            onConfirm={onSubmit}
            show={selectedProducts.length > 0}
          >
            <div className="text-center">
              {t('confirm', s)}
              <br />
              {totalPrice > 0 && (
                <span>
                  {`${t('total_price', s)}: ${formatPrice(
                    totalPrice,
                  )},-`}
                </span>
              )}
            </div>
          </StickyConfirmation>
        </>
      )}
    </section>
  )
}

ProductSelector.propTypes = {
  onComplete: PropTypes.func.isRequired,
  serviceCenter: serviceCenterShape,
  onError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    header: 'Hva trenger du hjelp med?',
    confirm: 'Bekreft',
    free: 'gratis',
    total_price: 'Totalt',
    doctor: 'Legetime',
    vaccines: 'Vaksiner',
    general: 'Tester, prøver og behandling',
  },
  en: {
    header: 'What do you need help with?',
    confirm: 'Confirm',
    free: 'free',
    total_price: 'In total',
    doctor: 'Doctor consultation',
    vaccines: 'Vaccines',
    general: 'Other tests and treatments',
  },
  pl: {
    header: 'W czym możemy Ci pomóc?',
    confirm: 'Potwierdź',
    free: 'gratis',
    total_price: 'W sumie',
    doctor: 'Doctor consultation',
    vaccines: 'Vaccines',
    general: 'Other tests and treatments',
  },
}
