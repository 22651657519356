import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Row from './Row'
import VerticalRow from './VerticalRow'
import DayMenu from './DayMenu'
import formatCurrency from '../../lib/formatCurrency'
import _ from 'lodash'

class Day extends Component {
  static propTypes = {
    dayData: PropTypes.object.isRequired,
    avatar: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isSubmitting: PropTypes.array.isRequired,
    cloneDay: PropTypes.func.isRequired,
    perHourProfit: PropTypes.number.isRequired,
    assetRootUrl: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showDayMenu: false,
    }
  }

  dayRows() {
    return _.chunk(this.props.dayData.hours, 4)
  }

  handleChange = (hour) => {
    this.props.onChange({
      date: this.props.dayData.date,
      hours: [hour],
    })
  }

  dayMenuClass() {
    if (this.state.showDayMenu) {
      return 'visible'
    } else {
      return ''
    }
  }

  toggleDayMenu = () => {
    this.setState({ showDayMenu: !this.state.showDayMenu })
  }

  showSpinner() {
    if (this.isSubmitting()) {
      return <span className="spinner" />
    }
  }

  isSubmitting() {
    if (
      _.find(this.props.isSubmitting, {
        date: this.props.dayData.date,
      })
    ) {
      return true
    }

    return false
  }

  clickRow = (row) => {
    const allSelected = _.every(row, (hour) => {
      return (
        hour.status == 'booked' ||
        hour.status == 'selected' ||
        hour.status == 'unavailable'
      )
    })

    const hours = _.filter(row, (hour) => {
      if (allSelected) {
        return hour.status == 'selected'
      } else {
        return hour.status == 'open'
      }
    })

    this.props.onChange({
      date: this.props.dayData.date,
      hours: hours,
    })
  }

  potentialProfit() {
    const selectedSlots = _.filter(
      this.props.dayData.hours,
      (slot) => {
        return slot.status == 'booked' || slot.status == 'selected'
      },
    )

    return selectedSlots.length * this.props.perHourProfit
  }

  renderTodayText() {
    if (moment(this.props.dayData.date).isSame(moment(), 'date')) {
      return <span>I dag &nbsp;&nbsp;</span>
    }

    return ''
  }

  closeDayMenu = () =>
    this.setState({
      showDayMenu: !this.state.showDayMenu,
    })

  render() {
    const horizontalRows = []
    const verticalRows = []

    this.dayRows().map((row, i) => {
      const safeProps = _.omit(
        this.props,
        'dayData',
        'onChange',
        'isSubmitting',
      )

      const childProps = {
        ...safeProps,
        rowData: row,
        day: _.capitalize(
          moment(this.props.dayData.date).format('dddd'),
        ),
        onChange: this.handleChange,
        key: i,
        isSubmitting: this.isSubmitting(),
        handleClickRow: this.clickRow,
      }

      horizontalRows.push(<Row {...childProps} />)
      verticalRows.push(<VerticalRow {...childProps} />)
    })

    return (
      <div className="calendar-entry-day">
        {this.showSpinner()}
        <div className="backoffice__mini-header sub-header">
          <span>
            {this.renderTodayText()}
            <span className="text-normal">
              {_.capitalize(
                moment(this.props.dayData.date).format('dddd Do MMM'),
              )}
            </span>
          </span>

          <span className="button-group--small list-toggle">
            <div
              className={`button-group-item toggle-day-menu ${this.dayMenuClass()}`}
              onClick={this.toggleDayMenu}
            >
              <DayMenu
                showMenu={this.state.showDayMenu}
                date={this.props.dayData.date}
                close={this.closeDayMenu}
                dayData={this.props.dayData}
                onChange={this.props.onChange}
                cloneDay={this.props.cloneDay}
              />
            </div>
          </span>
        </div>

        <div className="calendar-entry-select">
          <div className="multi-select-wrapper">{verticalRows}</div>

          <div className="hour-select-wrapper">{horizontalRows}</div>
        </div>
        {this.props.perHourProfit > 0 && (
          <div className="calendar-entry-select__earnings">
            <span>Potensiell inntjening:</span>
            <span>{formatCurrency(this.potentialProfit())}</span>
          </div>
        )}
      </div>
    )
  }
}

export default Day
