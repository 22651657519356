import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Lightbox } from 'react-modal-image'

import PriceCalcTable from './payment_form/PriceCalcTable'
import TermsModal from './TermsModal'
import RailsForm from './RailsForm'
import DiscountInput from './payment_form/DiscountInput'
import formatCurrency from '../lib/formatCurrency'
import {
  injectStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from 'react-stripe-elements'
import * as Sentry from '@sentry/react'

const errorMessages = {
  card_error: {
    incorrect_number: 'Kortnummer er feil',
    invalid_number: 'Kortnummeret er ikke et gyldig kortnummer',
    invalid_expiry_month: 'Utløpsmåned er feil',
    invalid_expiry_year: 'Utløpsår er feil',
    invalid_cvc: 'CVC er ugyldig',
    expired_card: 'Kortet har utløpt',
    incorrect_cvc: 'CVC er feil',
    amount_too_large: 'Beløpet er for stort',
    amount_too_small: 'Beløpet er for lite',
    incorrect_zip: 'Ugyldig postnummer',
    invalid_card_type:
      'Vi støtter ikke dette kortet. Forsøk et annet kort.',
    balance_insufficient: 'Ikke dekning på kortet',
    card_declined: 'Kortet ble avvist av banken',
    country_unsupported:
      'Kortet et utstedt i et land vi ikke støtter',
    bank_account_declined: 'Kortets konto ble avvist av banken',
    card_decline_rate_limit_exceeded:
      'Kortet ditt har blitt avvist for mange ganger. Kontakt banken eller prøv igjen om 24 timer.',
    missing: 'Det finnes ikke kort på kunden som blir belastet',
    processing_error: 'Noe gikk galt ved behandling av kortdata',
    rate_limit:
      'Vi opplever for tiden stor trafikk, vennligst prøv igjen om litt.',
  },
  invalid_request_error: {
    msg: 'Finner ingen betalingsinformasjon',
  },
}

class PaymentForm extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    stripe: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    baseTwoInOnePrice: PropTypes.number.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    booking: PropTypes.object.isRequired,
    couponCode: PropTypes.string,
    discountAmount: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      cardNumber: '',
      cvc: '',
      expYear: '',
      expMonth: '',
      stripeToken: '',
      couponCode: '',
      discountedPrice: null,
      showTermsModal: false,
      showCouponCheck: false,
      couponError: false,
      cardValid: false,
      expValid: false,
      cvcValid: false,
      lightboxOpen: false,
      skipPayment: false,
      discountAmount: this.props.discountAmount || 0,
    }

    this.requestTimeout = null
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ error: null, isSubmitting: true })
    document.dispatchEvent(new Event('submitPayment'))

    if (this.state.discountedPrice == 0) {
      this.attemptSkipPayment()
      return
    }

    this.props.stripe
      .createPaymentMethod('card', {
        billing_details: { name: this.props.user.name },
      })
      .then((response) => {
        if (response.error) {
          this.setState({
            isSubmitting: false,
            error: response.error.message,
          })
          return
        }

        $.ajax({
          url: '/booking_wizard/payment',
          method: 'POST',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr(
              'content',
            ),
          },
          data: {
            payment_method_id: response.paymentMethod.id,
            booking: {
              coupon_code: this.state.couponCode,
            },
          },
        })
          .done(this.handleStripeResponse)
          .fail((error) => {
            this.setState({
              isSubmitting: false,
              error:
                errorMessages.card_error[error.responseJSON.error] ||
                'Noe gikk galt med betalingen. Prøv på nytt om litt',
            })
          })
      })
  }

  attemptSkipPayment() {
    $.ajax({
      url: this.props.path,
      method: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      data: {
        payment_method_id: 'coupon',
        booking: {
          coupon_code: this.state.couponCode,
        },
      },
    })
      .done((response) => {
        if (response.success && response.redirect_url) {
          window.location = response.redirect_url
        } else {
          this.setState({
            isSubmitting: false,
            error:
              'Noe gikk galt med betalingen. Prøv på nytt om litt',
          })
          Sentry.captureMessage(
            'UnsuccessfulPaymenResponseFromBackend',
            {
              extra: {
                response,
                location:
                  'PaymentForm.attemptSkipPayment:done(read response redirect error)',
              },
            },
          )
        }
      })
      .fail((error) => {
        if (error.responseJSON && error.responseJSON.redirect_url) {
          window.location = error.responseJSON.redirect_url
        } else {
          this.setState({
            isSubmitting: false,
            error:
              'Noe gikk galt med betalingen. Prøv på nytt om litt',
          })
          Sentry.captureMessage(
            'UnsuccessfulPaymenResponseFromBackend',
            {
              extra: {
                response,
                location:
                  'PaymentForm.attemptSkipPayment:catch(read response redirect error)',
              },
            },
          )
        }
      })
  }

  handleStripeResponse = (response) => {
    if (response.error) {
      let error = response.error.message

      if (response.error.type == 'card_error') {
        error =
          errorMessages[response.error.type][response.error.code]
      } else if (response.error.type == 'invalid_request_error') {
        error = errorMessages[response.error.type]['msg']
      } else {
        error =
          'Noe gikk dessverre galt. Vennligst prøv på nytt om litt'
      }

      Sentry.captureMessage(error, {
        extra: {
          ...response,
          location: 'PaymentForm.handleStripeResponse',
        },
      })

      this.setState({ isSubmitting: false, error })
    } else if (response.requires_action) {
      this.handleAction(response)
    } else {
      if (!response.redirect_url) {
        Sentry.captureException('Stripe response no redirect url', {
          extra: response,
        })
      }

      document.dispatchEvent(new Event('safe-unload-pending')) // Let countdown know not to release booking on upcoming unload event
      setTimeout(() => (window.location = response.redirect_url), 10) // Give countdown a chance to catch the event before redirecting
    }
  }

  handleAction = (response) => {
    this.props.stripe
      .handleCardAction(response.payment_intent_client_secret)
      .then((result) => {
        if (result.error) {
          // Show error in payment form
          this.setState({
            isSubmitting: false,
            error: result.error.message,
          })
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          $.ajax({
            url: '/booking_wizard/payment',
            method: 'POST',
            headers: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr(
                'content',
              ),
            },
            data: {
              payment_intent_id: result.paymentIntent.id,
              booking: {
                coupon_code: this.state.couponCode,
              },
            },
          })
            .done((response) => {
              if (!response.redirect_url) {
                Sentry.captureMessage(
                  'Stripe response no redirect url',
                  {
                    extra: response,
                  },
                )
              }

              window.location = response.redirect_url
            })
            .fail((error) => {
              this.setState({
                isSubmitting: false,
                error:
                  errorMessages.card_error[
                    error.responseJSON.error
                  ] ||
                  'Noe gikk galt med betalingen. Prøv på nytt om litt',
              })
              Sentry.captureMessage(
                error.responseJSON || 'Unknown error',
                {
                  extra: { error: error.responseJSON },
                },
              )
            })
        }
      })
  }

  openTermsModal = (e) => {
    e.preventDefault()
    this.setState({ showTermsModal: true })
  }

  renderError() {
    if (!this.state.error) return null

    return (
      <div className="payment-form__error" role="alert">
        {this.state.error}
      </div>
    )
  }

  creditCardClass() {
    if (this.state.formIsValid) {
      return 'form-control booking-form__valid-icon'
    } else {
      return 'form-control booking-form__valid-icon booking-form__valid-icon--invalid'
    }
  }

  showSpinner() {
    if (this.state.isSubmitting) {
      return <span className="btn__loader" />
    }
  }

  bubbleChange = () =>
    document.dispatchEvent(
      new KeyboardEvent('keyup', { keyCode: 49 }),
    )

  stripeElementClassName = () =>
    'StripeElement' +
    (this.state.skipPayment ? ' StripeElement--disabled' : '')

  handleCardNumberChange = (e) => {
    this.bubbleChange()
    if (e.complete) {
      return this.setState({ cardValid: true })
    }

    this.setState({ cardValid: false })
  }

  handleExpiryChange = (e) => {
    this.bubbleChange()
    if (e.complete) {
      return this.setState({ expValid: true })
    }

    this.setState({ expValid: false })
  }

  openCVCInfo = () => this.setState({ lightboxOpen: true })
  closeCVCInfo = () => this.setState({ lightboxOpen: false })

  handleCVCChange = (e) => {
    this.bubbleChange()
    if (e.complete) {
      return this.setState({ cvcValid: true })
    }

    this.setState({ cvcValid: false })
  }

  handlePriceUpdate = (price, coupon, discount) =>
    this.setState({
      discountedPrice: price,
      couponCode: coupon,
      discountAmount: discount,
    })

  closeTermsModal = () => this.setState({ showTermsModal: false })

  render() {
    const price = formatCurrency(this.props.price)
    const discountedPrice = formatCurrency(this.state.discountedPrice)
    const formStyling = {
      base: {
        fontSize: window.innerWidth <= 543 ? '16px' : '18px',
        fontFamily: '"Fabriga Regular", Sans-Serif',
        lineHeight: '28px',
        color: '#381d50',
        '::placeholder': {
          color: 'rgba(56, 29, 80, .5)',
        },
      },
    }

    return (
      <RailsForm
        ref={(ref) => {
          this.form = ref
        }}
        onSubmit={this.handleSubmit}
        action={this.props.path}
        method="POST"
        className="payment-form"
        name="housecall_payment"
      >
        <div className="row">
          <div className="col-12">
            <div className="payment-form__header">
              <span>Betaling</span>
              <span className="payment-form__card-icon amex" />
              <span className="payment-form__card-icon mastercard" />
              <span className="payment-form__card-icon visa" />
            </div>

            <div className="mt-2 form-group payment-form__form-inline clearfix">
              <label className="payment-form__label">
                Kortnummer
              </label>
              <div
                className={`booking-form__valid-icon ${
                  this.state.cardValid
                    ? ''
                    : 'booking-form__valid-icon--invalid'
                }`}
              >
                <CardNumberElement
                  placeholder="Ditt kortnummer"
                  disabled={this.state.skipPayment}
                  className={this.stripeElementClassName()}
                  onChange={this.handleCardNumberChange}
                  style={formStyling}
                />
              </div>
            </div>

            <div className="row mt-2 form-group payment-form__form-inline clearfix">
              <div className="col-7">
                <label className="payment-form__label">
                  Utløpsdato
                </label>
                <div
                  className={`booking-form__valid-icon ${
                    this.state.expValid
                      ? ''
                      : 'booking-form__valid-icon--invalid'
                  }`}
                >
                  <CardExpiryElement
                    style={formStyling}
                    disabled={this.state.skipPayment}
                    className={this.stripeElementClassName()}
                    onChange={this.handleExpiryChange}
                  />
                </div>
              </div>
              <div className="col-5 p-l-0">
                <label className="payment-form__label">
                  CVC &nbsp;
                  <i
                    className="fa fa-info-circle payment-form__info-icon"
                    onClick={this.openCVCInfo}
                  ></i>
                </label>
                <div
                  className={`booking-form__valid-icon ${
                    this.state.cvcValid
                      ? ''
                      : 'booking-form__valid-icon--invalid'
                  }`}
                >
                  <CardCvcElement
                    style={formStyling}
                    disabled={this.state.skipPayment}
                    className={this.stripeElementClassName()}
                    onChange={this.handleCVCChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">{this.renderError()}</div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group payment-form__form-inline clearfix">
              <label className="payment-form__label">
                Rabattkode
              </label>

              <DiscountInput
                updatePrice={this.handlePriceUpdate}
                initialValue={this.props.couponCode}
                zip={this.props.booking.zip}
                type="booking"
              />
            </div>
          </div>
        </div>

        <PriceCalcTable
          price={this.props.price}
          basePrice={this.props.basePrice}
          baseTwoInOnePrice={this.props.baseTwoInOnePrice}
          discountedPrice={this.state.discountedPrice}
          booking={this.props.booking}
          user={this.props.user}
          discountAmount={this.state.discountAmount}
        />

        <div className="row">
          <div className="col-12 text-xs-center">
            <div className="form-group payment-form__form-inline clearfix">
              <input
                type="hidden"
                name="stripe_token"
                value={this.state.stripeToken}
              />

              <button
                type="submit"
                className="btn btn-secondary btn-block payment-form__button"
                disabled={this.state.isSubmitting}
              >
                Betal (
                {this.state.discountedPrice != null
                  ? discountedPrice
                  : price}
                ){this.showSpinner()}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="info-box">
              <div className="info-box__heading">Avbestilling</div>
              <div className="info-box__text payment-form__info-text">
                Reservasjoner kan avbestilles uten omkostninger inntil
                24 timer før besøket. Ved å betale aksepterer du
                &nbsp;
                <a href="#" onClick={this.openTermsModal}>
                  Hjemmelegenes brukervilkår.
                </a>
                <TermsModal
                  isOpen={this.state.showTermsModal}
                  close={this.closeTermsModal}
                />
              </div>
            </div>
          </div>
        </div>
      </RailsForm>
    )
  }
}

export default injectStripe(PaymentForm)
