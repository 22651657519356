import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CopyToClipboard from './CopyToClipboard'
import HLForm from './forms/HLForm'
import NameInput from './forms/fields/NameInput'
import NationalIdInput from './forms/fields/NationalIdInput'
import TextInput from './forms/fields/TextInput'
import Button from './forms/fields/Button'
import Submit from './forms/fields/Submit'
import { v_nationalId, v_name, v_company } from '../lib/validations'

const PatientData = ({
  name,
  national_id,
  company,
  phone_number,
  onSubmit,
  assetRootUrl,
}) => {
  const defaultValid = {
    name: v_name(name),
    national_id: v_nationalId(national_id),
    company: v_company(company),
  }

  const allFieldsValid = (fields) => fields.name && fields.national_id

  const [editMode, setEditMode] = useState(false)
  const [fieldValid, setFieldValid] = useState(defaultValid)
  const [valid, setValid] = useState(allFieldsValid(defaultValid))
  const [patientInfo, setPatientInfo] = useState({
    name: name,
    national_id: national_id,
    company: company,
    phone_number: phone_number,
  })

  const handleSubmit = (e, values) => {
    setEditMode(false)
    onSubmit(values)
  }

  const handleChange = (value, valid, name) => {
    setPatientInfo({ ...patientInfo, [name]: value })
    const fv = { ...fieldValid, [name]: valid }
    setFieldValid(fv)
    setValid(allFieldsValid(fv))
  }

  const handleEditClick = (e) => {
    e.preventDefault()
    setEditMode(true)
  }

  return !editMode ? (
    <>
      <table>
        <tbody>
          <tr>
            <td>Navn:</td>
            <td>
              <CopyToClipboard
                clipboardIconPath={assetRootUrl.clipboardIcon}
                value={patientInfo.name}
              />
            </td>
          </tr>
          <tr>
            <td>Tlf:</td>
            <td>
              <CopyToClipboard
                clipboardIconPath={assetRootUrl.clipboardIcon}
                value={patientInfo.phone_number}
              />
            </td>
          </tr>
          <tr>
            <td>P.nr.:</td>
            <td>
              <CopyToClipboard
                clipboardIconPath={assetRootUrl.clipboardIcon}
                value={patientInfo.national_id}
              />
            </td>
          </tr>
          <tr>
            <td>Bedrift:</td>
            <td>
              <CopyToClipboard
                clipboardIconPath={assetRootUrl.clipboardIcon}
                value={patientInfo.company}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <a href="#" id="edit-patient" onClick={handleEditClick}>
        Rediger pasientinfo
      </a>
    </>
  ) : (
    <>
      <HLForm
        preventSubmit={true}
        name="PersonalData"
        omitCsrf={true}
      >
        <div className="formgroup">
          <NameInput
            value={patientInfo.name}
            required={true}
            onChange={handleChange}
          />
          <NationalIdInput
            value={patientInfo.national_id}
            required={true}
            onChange={handleChange}
          />
          <TextInput
            name="company"
            label="Bedrift"
            value={patientInfo.company}
            onChange={handleChange}
          />
          <div className="text-right">
            <Submit
              onSubmit={handleSubmit}
              disabled={!valid}
              label="Lagre"
              className="m-1"
            ></Submit>
            <Button
              className="btn-info m-1"
              label="Avbryt"
              onClick={() => setEditMode(false)}
            />
          </div>
        </div>
      </HLForm>
    </>
  )
}

PatientData.propTypes = {
  name: PropTypes.string.isRequired,
  national_id: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  company: PropTypes.string,
  assetRootUrl: PropTypes.object.isRequired,
}

export default PatientData
