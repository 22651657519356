import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'

const ReactQuill = React.lazy(() => import('react-quill'))

const RichTextEditor = ({
  value = '',
  name = 'richText',
  onChange,
}) => {
  const [text, setText] = useState('')

  const changeText = (value) => {
    setText(value)
    if (onChange) onChange(value)
  }

  return (
    <Suspense fallback={<span className="btn__loader" />}>
      <ReactQuill
        theme="snow"
        className="formfield ishadow"
        name={name || 'richText'}
        defaultValue={value || ''}
        onChange={changeText}
      />
      <textarea name={name} defaultValue={text} hidden />
    </Suspense>
  )
}

RichTextEditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default RichTextEditor
