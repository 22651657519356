import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TypeaheadSearch from './TypeaheadSearch'
import { DEFAULT_LOCALE, translate } from '../lib/locale/'
import gqlCall from '../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
} from '../api/graphqlExtended'

const OrganizationAndCoupon = ({ organizationProp, couponProp }) => {
  const locale = DEFAULT_LOCALE
  const [coupon, setCoupon] = useState(couponProp?.value)
  const [organization, setOrganization] = useState(
    organizationProp?.value,
  )
  const [error, setError] = useState(null)

  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  const handleOrgSelection = (value, valid, name, data) => {
    setOrganization((prevOrganization) => {
      return { ...prevOrganization, name: data.name, id: data.id }
    })
  }

  const handleCouponSelection = async (value, valid, name, data) => {
    setError(null)
    setCoupon((prevCoupon) => {
      return { ...prevCoupon, code: data.code, id: data.id }
    })
    if (data.id) {
      try {
        const fetchedOrganization = await fetchOrganization(data.id)
        setOrganization((prevOrganization) => {
          return {
            ...prevOrganization,
            name: fetchedOrganization.name,
            id: fetchedOrganization.id,
          }
        })
      } catch (e) {
        setError(e.toString())
      }
    }
  }

  const fetchOrganization = async (couponId) => {
    const options = {
      couponId,
    }
    const response = await gqlCall(
      buildGraphQLQuery({
        searchOptions: options,
        resourceName: 'organization',
        multiple: false,
      }),
    )
    return parseQueryResponse({
      response: response,
      resourceName: 'organization',
      multiple: false,
    })
  }

  const handleCouponDelete = () => {
    setCoupon((prevCoupon) => {
      return { ...prevCoupon, code: null, id: null }
    })
  }

  const handleOrganizationDelete = () => {
    setOrganization((prevOrganization) => {
      return { ...prevOrganization, name: null, id: null }
    })
  }

  return (
    <div className="hlform">
      <TypeaheadSearch
        label={'Rabattkode'}
        isGraphqlType={true}
        listQueryAttributes="id code"
        listAttributes={[{ name: 'code', customClass: 'col-md-6' }]}
        createLink={couponProp?.createLink}
        fieldName={couponProp?.fieldName}
        resourceName="coupons"
        displayedAttributes="code"
        value={{
          id: coupon?.id,
          name: coupon?.code,
        }}
        onSelect={handleCouponSelection}
        onDelete={handleCouponDelete}
      />
      <TypeaheadSearch
        label={t('organization')}
        isGraphqlType={true}
        listAttributes={[
          { name: 'name', customClass: 'col-md-6' },
          {
            name: 'orgNr',
            customClass: 'col-md-6 text-md-right small',
          },
        ]}
        createLink={organizationProp?.createLink}
        fieldName={organizationProp?.fieldName}
        resourceName="organizations"
        value={{
          id: organization?.id,
          name: organization?.name,
        }}
        onSelect={handleOrgSelection}
        disabled={!!coupon.id && !!organization.id}
        onDelete={handleOrganizationDelete}
      />

      {error && <p className="small">{error}</p>}
    </div>
  )
}

OrganizationAndCoupon.propTypes = {
  organizationProp: PropTypes.object.isRequired,
  couponProp: PropTypes.object.isRequired,
}

export default OrganizationAndCoupon
