import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Button from '../forms/fields/Button'
import HLForm from '../forms/HLForm'
import SelectHL from '../forms/fields/SelectHL'
import { translate } from '../../lib/locale/'
import { patch } from '../../lib/backend-client'

const modalStyle = {
  content: {
    width: '80%',
    WebkitOverflowScrolling: 'touch',
  },
}

const textModal = {
  header: {
    regularDelete:
      'Sletter du kartet, slettes også timene som tilhører kartet.',
    mainDelete:
      'Sletter du kartet, slettes også timene som tilhører kartet. Du må velge et nytt hovedkart',
    notMainAnymore: 'Du må velge nytt hovedkart',
    becomeMain:
      'Dette kartet vil bli satt som nytt hovedkart. Tidligere hovedkart vil få kategorien "annet"',
  },
}

const CoverageMapEditorModal = (props) => {
  const [newMainCoverageMap, setNewMainCoverageMap] = useState(
    props.coverageMaps[0],
  )

  const updatePath = useMemo(() => {
    return props.path.replace(/\d+$/, newMainCoverageMap?.id)
  }, [props.path, newMainCoverageMap])

  const t = (prop, source) => translate(`header.${prop}`, source)

  const handleChangeMainMap = (mapId) => {
    setNewMainCoverageMap(
      props.coverageMaps.find((map) => map.id == mapId),
    )
  }

  const handleSubmit = async () => {
    if (shouldSelect()) {
      const data = {
        id: props.serviceProvider.id,
        coverage_map: {
          id: newMainCoverageMap.id,
          category: 'main',
          name: newMainCoverageMap.name,
        },
      }
      await patch(updatePath, JSON.stringify(data), true)
    }
    props.handleSubmitModal()
  }

  const closeModal = () => {
    props.closeModal()
  }

  const shouldSelect = () => {
    return (
      props.typeOfModal == 'notMainAnymore' ||
      props.typeOfModal == 'mainDelete'
    )
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="Velg nytt hovedkart"
      style={modalStyle}
      onRequestClose={closeModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
    >
      <HLForm
        action={updatePath}
        method={'patch'}
        name="new-main-coverage-map"
        preventSubmit={true}
      >
        <h3 className="h6">Er du sikker?</h3>
        <p>{t(props.typeOfModal, textModal)}</p>
        {shouldSelect() && (
          <SelectHL
            label={'Velg nytt hovedkart'}
            name="coverage_map[id]"
            value={props.coverageMaps[0].id}
            onChange={handleChangeMainMap}
            options={props.coverageMaps.map((map) => ({
              value: map.id,
              label: map.name,
            }))}
          />
        )}
        <div className="row">
          <div className="col-6">
            <Button
              label="Avbryt"
              onClick={closeModal}
              className="btn-link mt-4"
            />
          </div>
          <div className="col-6 text-right">
            <Button
              label="Lagre"
              onClick={handleSubmit}
              disabled={props.isValid}
              className="btn-primary"
            />
          </div>
        </div>
      </HLForm>
    </ReactModal>
  )
}

CoverageMapEditorModal.propTypes = {
  path: PropTypes.string.isRequired,
  coverageMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  handleSubmitModal: PropTypes.func,
  typeOfModal: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  serviceProvider: PropTypes.object.isRequired,
}

export default CoverageMapEditorModal
