import loadExternalScript from './loadExternalScript'

async function loadGoogle(key) {
  await loadExternalScript(
    `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places,drawing&callback=Function.prototype`,
  )
  return window.google
}

export default loadGoogle
