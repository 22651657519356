import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import NumericInput from '../forms/fields/NumericInput'
import Button from '../forms/fields/SubmitButton'
import FormProgress from '../forms/FormProgress'
import HLForm from '../forms/HLForm'
import Layout from './shared/Layout'
import { useSessionStorage } from '../../lib/hooks'

const VaccineWizardPickAmount = ({
  steps,
  currentStep,
  redirectTo,
  assetRootUrl,
  params,
}) => {
  const [valid, setValid] = useState(true)
  const handleChange = (val, valid) => {
    setValid(valid)
    setValueInSessionStorage(val)
  }

  const {
    storedValueInSessionStorage,
    setValueInSessionStorage,
  } = useSessionStorage('epax_count', 6)

  const [value, setValue] = useState(storedValueInSessionStorage)

  useEffect(() => {
    setValue(storedValueInSessionStorage)
  }, [storedValueInSessionStorage])

  return (
    <Layout
      heading="Antall vaksiner"
      subheading="Oppgi hvor mange ansatte du tror skal ha vaksine."
    >
      <FormProgress steps={steps} currentStep={currentStep} />
      <p className="info-box">
        Ved over 30 vaksiner ber vi deg{' '}
        <a href="https://hjemmelegene.no/bedrift/kontakt">
          ta kontakt med oss
        </a>
        , så hjelper vi deg. Ved færre enn 5 vaksiner, les mer{' '}
        <a
          href="https://hjemmelegene.no/vaksine-hjemme-eller-pa-jobb"
          target="_blank"
          rel="noopener noreferrer"
        >
          her
        </a>
        .
      </p>
      <HLForm
        className="row"
        action={redirectTo}
        method="put"
        name="vaccine_wizard_amount"
        onSubmit={() => setValue(value)}
      >
        <div className="col pr-0">
          <NumericInput
            min={6}
            max={30}
            label="Antall"
            name="amount"
            onChange={handleChange}
            showValidation={true}
            isRequired={true}
            initialValue={value}
          />
        </div>
        <input type="hidden" name="city" defaultValue={params.city} />
        <input type="hidden" name="zip" defaultValue={params.zip} />
        <input
          type="hidden"
          name="address"
          defaultValue={params.address}
        />
        <div className="col-2 pb-2">
          <Button
            label="Neste"
            disabled={!valid}
            className="btn-primary h-100"
          />
        </div>
      </HLForm>
      <div style={{ marginBottom: '200px' }}></div>
      <img
        className="wizard-images position-absolute d-none d-sm-block"
        src={assetRootUrl.doctorWalkingImage}
        alt="Doctor walking"
      />
    </Layout>
  )
}

VaccineWizardPickAmount.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  params: PropTypes.object,
}

VaccineWizardPickAmount.defaultProps = {
  steps: [],
  currentStep: '',
  redirectTo: '',
}

export default VaccineWizardPickAmount
