import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Button from './forms/fields/Button'

const InfoDisconnectedModal = ({ isOpen, header, content }) => {
  ReactModal.setAppElement('body')

  const modalStyle = {
    content: {
      width: '70%',
      maxWidth: '650px',
      WebkitOverflowScrolling: 'touch',
      border: '#f00',
      background: '#ffc9c9',
      position: 'relative',
    },
  }

  const handleClick = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Info disconnected"
      style={modalStyle}
    >
      <h3 className="ReactModal__heading mb-3">{header}</h3>
      <p>{content}</p>
      <Button
        className="btn-info m-1"
        label="Avbryt"
        onClick={handleClick}
      >
        last siden på nytt
      </Button>
    </ReactModal>
  )
}

InfoDisconnectedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
}

export default InfoDisconnectedModal
