import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Products = (props) => {
  const [productsSelected, setProductsSelected] = useState([])

  const handleChange = () => {
    const productsList = []
    document
      .querySelectorAll(`[data-products_name]`)
      .forEach((element) => {
        productsList.push({
          name: element.dataset[`products_name`],
          selected: element.selected,
        })
      })

    const productsSelected = productsList
      .filter((product) => product.selected === true)
      .map((product) => product.name)

    setProductsSelected(productsSelected)
    props.onProductSelected(productsSelected)
  }

  return (
    <>
      <div className="mb-3">
        <label htmlFor="products">Choose a product:</label>
        <select
          name="products"
          className="form-control"
          multiple={true}
          onChange={handleChange}
          size={props.options.length}
          value={productsSelected}
        >
          {props.options.map((option, i) => (
            <option
              key={`${option}_${i}`}
              value={option}
              data-products_name={option}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

Products.propTypes = {
  options: PropTypes.array.isRequired,
  onProductSelected: PropTypes.func.isRequired,
}

export default Products
