import React from 'react'
import PropTypes from 'prop-types'
import { translate as t } from '../lib/locale'

const StickyConfirmation = ({
  show,
  children,
  onConfirm,
  onCancel,
}) => {
  return (
    <div
      role="button"
      className={`sticky-confirmation ${show ? 'show' : ''}`}
    >
      <div className="btn btn-primary btn-block" onClick={onConfirm}>
        {children}
      </div>
      {onCancel && (
        <button className="cancel" onClick={onCancel}>
          {t('cancel')}
        </button>
      )}
    </div>
  )
}

StickyConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

export default StickyConfirmation
