export default {
  order: 'Bestilling',
  back: 'Tilbake',
  continue: 'Fortsett',
  previous: 'Forrige',
  chosen: 'Valgt',
  from: 'Fra',
  to: 'Til',
  next: 'Neste',
  show: 'Vis',
  confirm: 'Bekreft',
  cancel: 'Avbryt',
  restart: 'Start på nytt',
  startOver: 'Vennligst start på nytt.',
  phoneNumber: 'Telefonnummer',
  thankYou: 'Takk!',
  sendCode: 'Send kode',
  smsCode: 'SMS-kode',
  companyName: 'Navn på arbeidsgiver',
  optional: 'Valgfritt',
  nationalId: 'Personnummer/D-nummer',
  fullName: 'Fullt navn',
  name: 'Navn',
  today: 'I dag',
  tomorrow: 'I morgen',
  atTime: 'kl.',
  person: 'Person',
  persons: 'Personer',
  time: 'Tid',
  location: 'Sted',
  address: 'Adresse',
  numberOf: 'Antall',
  tests: 'tester',
  cancelAppointment: 'Avlys timen',
  organization: 'Organisasjon',
  coupon: 'Kupong',
  priceModel: 'Prismodell',
  effectiveFrom: 'Gyldig fra',
  effectiveTo: 'Gyldig til',
  newDeal: 'Ny bedriftsavtale',
  deal: 'Bedriftsavtale',
  responsible: 'Ansvarlig',
  active: 'Aktiv',
  expired: 'Utløpt',
  product: 'Produkt',
  upcoming: 'Kommende',
  description: 'Beskjed til helsepersonell',
  adminDescription: 'Intern beskrivelse',
  receiptText: 'Kvitteringstekst',
  seeDetails: 'Se Detaljer',
  willBeDeleted: 'vil bli slettet',
  areYouSure: 'Er du sikker',
  patient: 'Pasient',
  patients: 'Pasienter',
  billingReference: 'Fakturareferanse',
  billingDueBy: 'Betalingsfrist',
  billingEmail: 'E-post for faktura',
  paymentType: 'Betalingsmetode',
  paymentTypes: {
    card: 'Kort ved bestilling',
    invoice: 'Etterskuddsfakturering',
  },
  invoicingMethod: 'Faktureringsmetode',
  invoicingMethods: {
    email: 'E-postfaktura',
    letter: 'Brevfaktura',
    ehf: 'EHF',
  },
  priceTiers: 'Prisstige',
  suppress: 'Slett',
  language: 'Språk',
  arrived: 'Ankommet',
  ready: 'Klar',
  email: 'E-post',
  letter: 'Brev',
  ehf: 'EHF',
  products: 'Produkter/tjenester',
  organizations: {
    name: 'Navn på bedrift',
    number: 'Organisasjonsnummer',
  },
  unpaid: 'Ikke betalt',
  paid: 'Betalt',
}
