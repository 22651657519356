import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = (props) => {
  if (props.errorMessage) {
    return <div className="error-message">{props.errorMessage}</div>
  }
  return <div></div>
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}

export default ErrorMessage
