import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from './forms/fields/Button'
import HLForm from './forms/HLForm'
import axios from 'axios'

const UploadFileForm = ({
  recordId,
  recordType,
  fieldName,
  submitCallback,
}) => {
  const [busy, setBusy] = useState(false)

  const submitChanges = async (e) => {
    e.preventDefault()
    setBusy(true)
    const formData = new FormData(document.forms['send_file'])
    axios({
      method: 'post',
      url: '/uploads',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function (response) {
        submitCallback(response.data.thumbnail_url)
      })
      .catch(function (response) {
        console.error(response)
      })
    setBusy(false)
  }

  const cancel = (e) => {
    e.preventDefault()
    submitCallback()
  }

  return (
    <>
      <h4>Velg bilde</h4>

      <HLForm
        action="/uploads"
        method="post"
        name="send_file"
        multipart
      >
        <input value={recordId} type="hidden" name="record_id" />
        <input value={recordType} type="hidden" name="record_type" />
        <input value={fieldName} type="hidden" name="field_name" />

        <div className="row">
          <input
            type="file"
            id="file"
            name="file"
            accept="image/png, image/jpeg, image/jpg"
          ></input>
        </div>

        <div className="row">
          <div className="col-6">
            <Button
              label="Avbryt"
              onClick={cancel}
              className="btn-link mt-4"
            />
          </div>
          <div className="col-6 text-right">
            <Button
              label="Lagre"
              type="submit"
              disabled={busy}
              onClick={submitChanges}
              className="btn-primary"
            />
          </div>
        </div>
      </HLForm>
    </>
  )
}

UploadFileForm.propTypes = {
  recordId: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  submitCallback: PropTypes.func.isRequired,
}

export default UploadFileForm
