import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Label from '../Label'
export function CountryCodeSelector({
  selectedOption,
  onSelect,
  countries,
  disabled,
  label,
  name,
  shadow,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const toggling = () => setIsOpen(!disabled ? !isOpen : false)

  const onOptionClicked = (value) => () => {
    onSelect(value)
    setIsOpen(false)
  }

  return (
    <div
      className={`align-self-end formfield mr-1
        ${shadow ? 'ishadow' : ''}
        ${disabled ? 'disabled' : ''}`}
    >
      <Label label={label} for={name} />
      <div
        name={name}
        onClick={toggling}
        className="select input"
        style={{ minWidth: '120px' }}
      >
        <span className="select-arrows" />
        {selectedOption}
      </div>
      {isOpen && (
        <div
          className={`formfield ${shadow ? 'ishadow' : ''} dropdown`}
        >
          <ul>
            {countries.map((country) => (
              <li
                onClick={onOptionClicked(country.code)}
                key={`${country.code}${country.name}`}
              >
                {country.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

CountryCodeSelector.defaultProps = {
  disabled: false,
  shadow: true,
  label: 'Landskode',
  name: 'country_code',
}

CountryCodeSelector.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  shadow: PropTypes.bool,
}
