import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class Textarea extends Component {
  static defaultProps = {
    minLength: 0,
    maxLength: 1000,
    value: '',
  }

  static propTypes = {
    onChange: PropTypes.func,
    onChangeText: PropTypes.func.isRequired,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    value: PropTypes.string,
    isValid: PropTypes.bool,
    showError: PropTypes.bool,
  }

  constructor(props) {
    super(props)
  }

  errorClass() {
    return this.props.showError && !this.props.isValid ? 'error' : ''
  }

  handleChange = (event) => {
    let text = event.target.value

    if (this.props.maxLength) {
      if (text.length > this.props.maxLength) {
        text = text.slice(0, this.props.maxLength)
      }
    }

    this.setState({ text })
    const valid = text.length >= this.props.minLength

    if (this.props.onChange) this.props.onChange(event)
    this.props.onChangeText(text, valid)
  }

  render() {
    const safeProps = _.omit(
      this.props,
      'onChangeText',
      'value',
      'maxLength',
      'isValid',
      'showError',
    )

    const childProps = {
      ...safeProps,
      onChange: this.handleChange,
      value: this.props.value,
      className: `form-control ${this.errorClass()}`,
    }

    return (
      <div>
        {this.props.maxLength && (
          <small>
            Du har {this.props.maxLength - this.props.value.length} /{' '}
            {this.props.maxLength} tegn igjen.
          </small>
        )}
        <textarea {...childProps} data-private />
      </div>
    )
  }
}

export default Textarea
