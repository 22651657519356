import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SelectHL from '../forms/fields/SelectHL'
import ShieldedSpinner from '../ShieldedSpinner'
import Button from '../forms/fields/Button'
import gqlCall from '../../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
  defaultGraphQLSerializer,
} from '../../api/graphqlExtended'

const SwitchDefaultDeal = ({
  currentDefaultDeal,
  defaultOrganizationDeals,
}) => {
  const [loading, setIsLoading] = useState(true)
  const [selectedDealId, setSelectedDealId] = useState(
    currentDefaultDeal.id,
  )
  const [message, setMessage] = useState('')

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const onSelectDeal = (newDeal) => {
    setSelectedDealId(newDeal)
  }

  const changeDefaultDeal = async () => {
    setIsLoading(true)

    if (
      window.confirm(
        'Are your sure you want to change the default deal? This will change current list prices for all products.',
      )
    ) {
      try {
        const changeDealMutation = buildGraphQLQuery({
          params: `dealId: ${selectedDealId}`,
          resourceName: 'changeDefaultDeal',
          type: 'mutation',
        })

        await gqlCall(changeDealMutation)

        setMessage('Default deal changed')
        setIsLoading(false)
      } catch (error) {
        setMessage(error.message)
        setIsLoading(false)
      }
    }

    setIsLoading(false)
  }

  return (
    <div className="hlform flex-grow-1">
      <ShieldedSpinner show={loading} />
      <SelectHL
        label="Choose the default deal"
        name="select-default-deal"
        value={selectedDealId.toString()}
        onChange={onSelectDeal}
        showValidation={false}
        options={defaultOrganizationDeals.map((deal) => ({
          label: `#${deal.id} - ${deal.admin_description}`,
          value: deal.id,
        }))}
      />
      {message && <p>{message}</p>}
      <Button onClick={changeDefaultDeal}>
        Confirm new default deal
      </Button>
    </div>
  )
}

SwitchDefaultDeal.propTypes = {
  currentDefaultDeal: PropTypes.object.isRequired,
  defaultOrganizationDeals: PropTypes.array.isRequired,
}

export default SwitchDefaultDeal
