import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, translate } from '../../lib/locale'
import PriceModelInfoBox from './PriceModelInfoBox'
import PriceModelTypeaheadListItem from './PriceModelTypeaheadListItem'

const PriceModelTypeaheadList = (props) => {
  const locale = DEFAULT_LOCALE
  const EMPTY_RESOURCE = props.emptyResource

  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  const defaultResources = [{ ...EMPTY_RESOURCE }]

  const [resources, setResources] = useState(defaultResources)
  const [open, setOpen] = useState(!props.clickToShow)

  useEffect(() => {
    if (props.resources?.length) {
      const resourcesClone = props.resources.map((resource) => {
        return { ...resource, expended: false }
      })
      setResources(resourcesClone)
    }
  }, [props.resources])

  const addResource = (e) => {
    e.preventDefault()
    setResources([...resources, { ...EMPTY_RESOURCE }])
  }

  const removeResource = (index) => {
    const resourcesClone = [...resources]
    resourcesClone.splice(index, 1)
    props.OnPriceModelsChange(resourcesClone)
  }

  const handleOpenClick = () => {
    setOpen(true)
  }

  const updateResource = (index, newResource) => {
    const resourcesClone = [...resources]
    resourcesClone[index] = newResource
    props.OnPriceModelsChange(resourcesClone)
  }

  const updateExpend = (id) => {
    const resourcesClone = [...resources]
    const resourceToUpdate = resourcesClone.find(
      (res) => res.id === id,
    )
    resourceToUpdate.expended = !resourceToUpdate.expended
    setResources(resourcesClone)
  }

  const onlyResourceIsEmptyResource = () =>
    resources.length === 1 && resources[0] === EMPTY_RESOURCE

  const renderPriceModelTypeaheadList = () => {
    if (open && !onlyResourceIsEmptyResource()) {
      return resources?.map((resource, i) => (
        <div key={`resource-${i}`}>
          <PriceModelTypeaheadListItem
            key={i}
            index={i}
            priceModel={resource}
            typeaheadProps={props.typeaheadProps}
            onUpdate={updateResource}
            onRemove={removeResource}
            onExpend={updateExpend}
            hideRemove={i == 0 && resources.length == 1}
            expendable={!!resource?.id}
          />
        </div>
      ))
    }
    return null
  }

  return (
    <div className="resource-list">
      {renderPriceModelTypeaheadList()}
      {open && props.allowAddResources && (
        <button
          className="mb-4 btn btn-compact btn-secondary"
          onClick={addResource}
        >
          ✚ {t(props.resourceName)}
        </button>
      )}
      {!open && props.allowAddResources && (
        <button
          className="mb-4 btn btn-compact btn-secondary"
          onClick={handleOpenClick}
        >
          Legg {t(props.resourceName)}
        </button>
      )}
    </div>
  )
}

PriceModelTypeaheadList.defaultProps = {
  allowAddResources: true,
  clickToShow: false,
}

PriceModelTypeaheadList.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resources: PropTypes.array,
  allowAddResources: PropTypes.bool,
  clickToShow: PropTypes.bool,
  emptyResource: PropTypes.object,
  typeaheadProps: PropTypes.object,
  OnPriceModelsChange: PropTypes.func.isRequired,
}

export default PriceModelTypeaheadList
