import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class ZipInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    validateZipExist: PropTypes.func,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
    showError: PropTypes.bool,
    className: PropTypes.string,
    onBlur: PropTypes.func,
  }

  filterInput(input) {
    return input.substring(0, 4).replace(/[^0-9.]+/g, '')
  }

  validate(text) {
    return text.length == 4
  }

  errorClass() {
    if (this.props.showError && !this.props.isValid) {
      return 'error'
    }

    return ''
  }

  wrapperClass = () => {
    if (this.props.isValid) {
      return 'form-group booking-form__valid-icon'
    } else {
      return 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'
    }
  }

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      filter: this.filterInput,
      name: 'user[patient_attributes][zip]',
      placeholder: 'Postnummer',
      pattern: '[0-9]*',
      inputMode: 'numeric',
      onBlur: this.props.onBlur,
    }

    return (
      <div
        className={`${this.wrapperClass()} ${this.props.className}`}
      >
        <div
          className={`booking-form__icon-wrapper ${this.errorClass()}`}
        >
          <label className="sr-only">{props.placeholder}</label>
          <ValidatedInput {...props} />
        </div>
      </div>
    )
  }
}

export default ZipInput
