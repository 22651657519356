import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FirstTimeSlotButton from './FirstTimeSlotButton'
import consumer from '../channels/consumer'
import * as Sentry from '@sentry/react'

class UserDashboardCard extends Component {
  static propTypes = {
    searchPath: PropTypes.string.isRequired,
    fetchPath: PropTypes.string.isRequired,
    selectTimePath: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    cardIcon: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      match: false,
      isLoading: true,
      booking: '',
    }
  }
  // provide the path through props
  selectTimePath = this.props.selectTimePath

  componentDidMount() {
    this.dashboardFirstTimeSlot()
    this.monitorAvailibility()
  }

  componentWillUnmount() {
    this.state.socket.consumer.subscriptions.remove(this)
  }

  dashboardFirstTimeSlot = () => {
    this.setState({
      isLoading: true,
    })
    fetch(this.props.fetchPath)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          booking: data,
          isLoading: false,
        })
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            location:
              'UserDashboard.fetchFirstTimeSlot:catch(read response json error)',
          },
        })
      })
  }

  showZip = () => {
    return this.props.zip ? `(${this.props.zip})` : ''
  }

  monitorAvailibility = () => {
    this.socket = consumer.subscriptions.create(
      'AvailabilityChannel',
      {
        received: (booking) => {
          const service_provider_id = this.state.booking
            .service_provider_id
          const begins_at = this.state.booking.begins_at

          const sameSlot =
            booking.service_provider_id === service_provider_id &&
            booking.begins_at === begins_at

          const moreRecentSlot = booking.begins_at < begins_at

          if (sameSlot) {
            if (booking.reserved) {
              this.dashboardFirstTimeSlot()
            }
          } else if (moreRecentSlot) {
            if (!booking.reserved) {
              this.dashboardFirstTimeSlot()
            }
          }
        },
      },
    )
    this.setState({ socket: this.socket })
  }

  render() {
    return (
      <div className="list-group-item list-group-item__info list-group-item__gaps list-group-item--brand-primary card-left">
        <div className="list-group-item__info--inner list-group-item__info--inner-more-line-height(style='flex: 10')">
          <div className="user-list-group mb-3">
            <h2>Bestill hjemmebesøk</h2>
            {this.state.booking.match === true ? (
              <p>
                Neste ledige time i {this.showZip()} {this.props.city}{' '}
              </p>
            ) : (
              <p>Finn en ledig time der du bor</p>
            )}
          </div>
          <div className="user-card-footer d-flex justify-content-between align-items-end">
            <div className="user-card-footer-link">
              <FirstTimeSlotButton
                searchPath={this.props.searchPath}
                isLoading={this.state.isLoading}
                booking={this.state.booking}
                urlPath={this.selectTimePath}
              />
            </div>
            <img
              src={this.props.cardIcon}
              className="user-card-image"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default UserDashboardCard
