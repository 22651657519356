export const mockUser = {
  name: 'Balle Clorin',
  nationalId: '12345678912',
  phone: '+4745031025',
  email: 'balle@supperaad.no',
}

export const mockAppointment = {
  id: 1,
  date: '2022-02-07',
  hour: '09:30',
  nationalId: '12345678912',
  who: 'meplus',
  serviceCenter: {
    id: 1,
    name: 'Clinic East-1 Økernveien',
    title: 'Hjemmelegene Økern',
    street: 'Økernveien 146',
    zip: '0483',
    city: 'Oslo',
  },
}

export const mockPatients = [
  {
    name: 'Balle Clorin',
    nationalId: '12345678912',
  },
  {
    name: 'Kåre Øksnese',
    nationalId: '12345678913',
  },
]

export const cancelAppointmentResponse = {
  data: {
    cancelAppointment: {
      id: '1',
      deletedAt: '2022-01-29T21:47:49+01:00',
    },
  },
}

export const appointmentAvailabilityCalendarResponse = {
  data: {
    appointmentAvailabilityCalendar: [
      {
        serviceCenter: {
          id: '1',
          name: 'Hjemmelegene Økern',
        },
        availability: {
          '2022-01-29': {
            '08:00': {
              slot: 480,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '08:10': {
              slot: 490,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '08:20': {
              slot: 500,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '08:30': {
              slot: 510,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '08:40': {
              slot: 520,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '08:50': {
              slot: 530,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:00': {
              slot: 540,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:10': {
              slot: 550,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:20': {
              slot: 560,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:30': {
              slot: 570,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:40': {
              slot: 580,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '09:50': {
              slot: 590,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:00': {
              slot: 600,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:10': {
              slot: 610,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:20': {
              slot: 620,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:30': {
              slot: 630,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:40': {
              slot: 640,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '10:50': {
              slot: 650,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:00': {
              slot: 660,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:10': {
              slot: 670,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:20': {
              slot: 680,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:30': {
              slot: 690,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:40': {
              slot: 700,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '11:50': {
              slot: 710,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:00': {
              slot: 720,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:10': {
              slot: 730,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:20': {
              slot: 740,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:30': {
              slot: 750,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:40': {
              slot: 760,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '12:50': {
              slot: 770,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:00': {
              slot: 780,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:10': {
              slot: 790,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:20': {
              slot: 800,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:30': {
              slot: 810,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:40': {
              slot: 820,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '13:50': {
              slot: 830,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:00': {
              slot: 840,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:10': {
              slot: 850,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:20': {
              slot: 860,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:30': {
              slot: 870,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:40': {
              slot: 880,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '14:50': {
              slot: 890,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:00': {
              slot: 900,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:10': {
              slot: 910,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:20': {
              slot: 920,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:30': {
              slot: 930,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:40': {
              slot: 940,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '15:50': {
              slot: 950,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
            '16:00': {
              slot: 960,
              max_capacity: 5,
              available_capacity: 5,
              allocated_capacity: 0,
            },
          },
        },
      },
    ],
  },
}
