import React from 'react'
import PropTypes from 'prop-types'

import { dateFormat, hourFormat } from '../../../lib/dateFormat'

const PickedTimeBox = ({ timeStart, timeEnd, address }) => (
  <div className="info-box info-box--lower-padding my-4">
    <span className="booking-summary__time">
      🗓&nbsp;&nbsp;{dateFormat(timeStart)} til {hourFormat(timeEnd)} {address}
    </span>
  </div>
)

PickedTimeBox.propTypes = {
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  address: PropTypes.string,
}

export default PickedTimeBox
