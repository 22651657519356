import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class AddressInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
    showError: PropTypes.bool,
    placeholder: PropTypes.string,
  }

  validate(text) {
    const patt = /[a-z]{3,}.*[0-9]/i
    return patt.test(text)
  }

  wrapperClass = () => {
    if (this.props.isValid) {
      return 'form-group booking-form__valid-icon'
    } else {
      return 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'
    }
  }

  errorClass() {
    if (this.props.showError && !this.props.isValid) {
      return 'error'
    }

    return ''
  }

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      name: 'user[patient_attributes][address]',
      placeholder: prevProps.placeholder || 'Besøkets gateadresse',
    }

    return (
      <div className={this.wrapperClass()}>
        <div
          className={`form-control booking-form__icon-wrapper ${this.errorClass()}`}
        >
          <label className="sr-only">{props.placeholder}</label>
          <ValidatedInput {...props} />
        </div>
      </div>
    )
  }
}

export default AddressInput
