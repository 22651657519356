import React from 'react'
import PropTypes from 'prop-types'

const TeliaProxyLink = ({
  phoneNumberInitiator,
  phoneNumberReceiver,
}) => {
  if (!phoneNumberReceiver) return null
  const title =
    'Ring opp ' + phoneNumberReceiver + ' fra Hjemmelegene'

  const safePhoneNumberReceiver = phoneNumberReceiver.replace(
    '+',
    '00',
  )
  const safePhoneNumberInitiator = phoneNumberInitiator.replace(
    '+47',
    '',
  )

  const BASE_URL = 'call_through_proxy'

  const buildPath = (initiator, receiver) => {
    return `/${BASE_URL}?phone_number_initiator=${safePhoneNumberInitiator}&phone_number_receiver=${safePhoneNumberReceiver}`
  }

  return (
    <a
      href={buildPath(phoneNumberInitiator, phoneNumberReceiver)}
      title={title}
    >
      {phoneNumberReceiver}
    </a>
  )
}

TeliaProxyLink.propTypes = {
  phoneNumberInitiator: PropTypes.string,
  phoneNumberReceiver: PropTypes.string,
}

export default TeliaProxyLink
