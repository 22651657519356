import React from 'react'
import PropTypes from 'prop-types'

import FormProgress from '../forms/FormProgress'
import SelectTimeView from '../SelectTimeView'
import Layout from './shared/Layout'

const VaccineWizardPickTime = ({
  steps,
  currentStep,
  path,
  availableBookings,
  zip,
  inLowCoverage,
  address,
}) => (
  <Layout
    heading="Når passer det?"
    subheading="Velg når du ønsker at vi skal komme."
  >
    <FormProgress steps={steps} currentStep={currentStep} />
    <div className="info-box">
      Velg om dere ønsker vaksinering på formiddagen eller
      ettermiddagen. Du mottar beskjed om nøyaktig tidspunkt senest 7
      dager før vaksinering.
    </div>
    <SelectTimeView
      availableBookings={availableBookings}
      path={path}
      method="put"
      zip={zip}
      inLowCoverage={inLowCoverage}
      address={address}
      suggestDigimed={false}
    />
  </Layout>
)

VaccineWizardPickTime.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  availableBookings: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  zip: PropTypes.string.isRequired,
  inLowCoverage: PropTypes.bool.isRequired,
}

VaccineWizardPickTime.defaultProps = {
  steps: [],
  currentStep: '',
  path: '',
  address: {},
  availableBookings: {},
}

export default VaccineWizardPickTime
