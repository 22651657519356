import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import CheckboxHL from './forms/fields/CheckboxHL'
import Button from './forms/fields/Button'
import { deleteRecord } from '../lib/backend-client'

const modalStyle = {
  content: {
    width: '80%',
    WebkitOverflowScrolling: 'touch',
  },
}

const DeleteCalendarEntryModal = (props) => {
  const [entriesChecked, setEntriesChecked] = useState([])

  useEffect(() => {
    setEntriesChecked(getEntriesCheckedObject())
  }, [props.calendarEntries])

  const getEntriesCheckedObject = () => {
    return props.calendarEntries.reduce((array, calendarEntry) => {
      const newEntry = {
        ...calendarEntry,
        isChecked: true,
      }
      return [...array, newEntry]
    }, [])
  }

  const toggleCheck = (calendarEntryId) => {
    const copy = [...entriesChecked]
    const indexOfElementToChange = copy.findIndex(
      (entry) => entry.id == calendarEntryId,
    )
    copy[indexOfElementToChange].isChecked = !copy[
      indexOfElementToChange
    ].isChecked
    setEntriesChecked(copy)
  }

  const handleSubmit = async () => {
    let resultDelete = { success: true }
    const calendarEntriesToDeleteIds = entriesChecked.reduce(
      (array, entry) => {
        const newArray = entry.isChecked
          ? [...array, entry.id]
          : array
        return newArray
      },
      [],
    )
    if (calendarEntriesToDeleteIds.length > 0) {
      calendarEntriesToDeleteIds.forEach(async (id) => {
        resultDelete = await deleteRecord(
          `/admin/calendar_entries/${id}`,
        )
      })
    }
    props.confirmModal(resultDelete)
  }

  const handleCancel = () => {
    const selectorOptions = Array.from(
      document.querySelectorAll('#service_provider_ids option'),
    )
    selectorOptions.map((o) => (o.selected = false))
    props.oldServiceProviderIds.forEach(
      (id) =>
        (selectorOptions.find((o) => o.value == id).selected = true),
    )
    props.closeModal()
  }

  ReactModal.setAppElement('body')

  return (
    <>
      {props.calendarEntries && (
        <ReactModal
          isOpen={props.isModalOpen}
          contentLabel="Close calendar for service provider"
          style={modalStyle}
          onRequestClose={handleCancel}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
        >
          <div>
            <h2 className="h5">Fjerne åpen time?</h2>
            <p>
              Kryss av for behandlerne der du ønsker at timeboken skal forbli lukket på tidspunktet for timen:
            </p>
            {entriesChecked &&
              entriesChecked.map((entry, index) => (
                <div key={index}>
                  <CheckboxHL
                    shadow={false}
                    name="check_modal_calendar"
                    value={entry.isChecked}
                    className="mb-3"
                    onChange={() => toggleCheck(entry.id)}
                  >
                    {entry.serviceProviderFullName}
                  </CheckboxHL>
                </div>
              ))}
            <Button
              id="button-cancel-calendar-modal"
              label="Cancel"
              className="btn-danger mr-2"
              onClick={handleCancel}
            ></Button>
            <Button
              id="button-submit-calendar-modal"
              label="Confirm"
              onClick={handleSubmit}
            ></Button>
          </div>
        </ReactModal>
      )}
    </>
  )
}

DeleteCalendarEntryModal.propTypes = {
  oldServiceProviderIds: PropTypes.array,
  calendarEntries: PropTypes.array,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmModal: PropTypes.func,
}

export default DeleteCalendarEntryModal
