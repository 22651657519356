import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import Button from '../forms/fields/Button'
import { serializeForm } from '../../lib/utils'
import TypeaheadSearch from '../TypeaheadSearch'

const modalStyle = {
  content: {
    background: '#fafafa',
    WebkitOverflowScrolling: 'touch',
  },
}

const LineItemPickerModal = (props) => {
  ReactModal.setAppElement('body')

  const [valid, setValid] = useState(null)
  const [lineItemId, setLineItemId] = useState(null)
  const form = useRef()

  const close = () => {
    setValid(false)
    setError(false)
  }

  const handleCbChange = (value, valid, name) => {}

  const validateCB = (value) => value

  const validate = () => {
    const values = serializeForm(form.current)
    let v = true
    for (const key in values) {
      if (!values[key]) v = false
    }
    setValid(v)
    if (v) {
      props.onSubmit(values)
    }
  }

  const closeModal = () => {
    props.onClose()
  }

  const commonProps = {
    validate: validateCB,
    onChange: handleCbChange,
    highlightErrors: valid === false,
    required: true,
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={close}
      style={modalStyle}
      contentLabel="Confirm that you understand"
    >
      <form className="hlform" ref={form}>
        <h4 className="groupheading mt-0">
          Find LineItem by BookingID or OrderID
        </h4>

        <TypeaheadSearch
          label={'BookingID or OrderID'}
          isGraphqlType={true}
          listAttributes={[
            {
              name: (entry) => (
                <div key={`entry-${entry.id}`}>
                  {`LineItem ID ${entry.id} / OrderID ${
                    entry.orderId
                  } / bookings: ${entry.bookingIds.join(',')}`}
                </div>
              ),
            },
          ]}
          createLink={false}
          fieldName="lineItemId"
          resourceName="lineItems"
          displayedAttributes="code"
          value={{
            id: lineItemId,
            name: lineItemId,
          }}
          onSelect={(val) => {
            setLineItemId(val)
          }}
        />
        <br />
        <br />
        <Button id="pickLineItem" onClick={validate}>
          Pick Line Item and close
        </Button>
        <Button id="closeModal" onClick={closeModal}>
          Cancel
        </Button>
      </form>
    </ReactModal>
  )
}

LineItemPickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default LineItemPickerModal
