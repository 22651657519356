import React, { useState } from 'react'
import PropTypes from 'prop-types'

import NameInput from '../forms/fields/NameInput'
import NationalIdInput from '../forms/fields/NationalIdInput'
import EmailInput from '../forms/fields/EmailInput'
import CompanyNameInput from '../forms/fields/CompanyNameInput'
import Checkbox from '../forms/fields/CheckboxHL'
import Submit from '../forms/fields/SubmitButton'
import Button from '../forms/fields/Button'
import HLForm from '../forms/HLForm'

import { updateUser } from './queries'
import {
  v_name,
  v_email,
  v_nationalId,
  v_company,
} from '../../lib/validations'
import { WHO } from './AppointmentWizard'

const UserForm = ({
  who,
  onComplete,
  existingUser,
  hasMultipleCustomers,
  productSlugs,
  hasOrganization,
  reset,
  onError,
  t,
}) => {
  const allFieldsValid = (fields, who) => {
    if (hasMultipleCustomers)
      return fields && fields.name && fields.email

    return fields && fields.name && fields.email
  }

  const [user, setUser] = useState({ ...existingUser })
  const [valid, setValid] = useState(
    allFieldsValid(existingUser, who),
  )
  const [fieldValid, setFieldValid] = useState({
    name: v_name(existingUser?.name),
    email: v_email(existingUser?.email),
    nationalId:
      who === WHO.others || v_nationalId(existingUser?.nationalId),
  })

  const handleSubmit = async (e, values) => {
    const response = await updateUser({
      ...user,
      ...values,
    })
    if (response.netError) return onError(response.errors)
    onComplete({ ...response.data, companyName: user.companyName })
  }

  const validate = (value, valid, name) => {
    const fv = { ...fieldValid, [name]: valid }
    setUser({ ...user, [name]: value })
    setFieldValid(fv)
    setValid(allFieldsValid(fv, who))
  }

  const completeExistingUser = () =>
    v_name(existingUser.name) &&
    v_nationalId(existingUser.nationalId) &&
    v_email(existingUser.email)

  return (
    <section>
      <h1 className="mb-4">
        {who === WHO.me &&
        productSlugs?.some((s) => s.includes('vac'))
          ? t('headingVaccination', s)
          : who === WHO.me
          ? t('headingMe', s)
          : t('headingNotJustMe', s)}
      </h1>
      {who !== WHO.me && (
        <p className="mb-0">
          <p className="mb-0">{t(`notice.${who}`, s)}</p>
        </p>
      )}
      {completeExistingUser() && <p>{t('existingUser', s)}</p>}
      <HLForm
        preventSubmit={true}
        onSubmit={handleSubmit}
        omitCsrf={true}
      >
        <NameInput
          autoFocus={true}
          autoComplete="off"
          label={t('fullName')}
          onChange={validate}
          isRequired={true}
          value={user.name || ''}
        />
        {who !== WHO.others && (
          <NationalIdInput
            showValidation={fieldValid.nationalId}
            autoComplete="off"
            onChange={validate}
            label={t('nationalId')}
            name="nationalId"
            value={user.nationalId || ''}
          />
        )}
        <EmailInput
          disabled={existingUser.email ? true : false}
          autoComplete="off"
          label={`${t('email', s)}`}
          onChange={validate}
          isRequired={true}
          value={user.email || ''}
        />

        <Checkbox
          name="marketingConsent"
          autoComplete="off"
          label={`${t('email', s)}`}
          onChange={validate}
          className="naked"
          showValidation={false}
          value={user.marketingConsent || false}
        >
          {t('emailConsent', s)}
        </Checkbox>
        {productSlugs?.some((s) => s.includes('vac')) &&
        hasOrganization ? (
          <div>{t('employeeConsent', s)}</div>
        ) : null}
        <div className="row">
          <div className="col-6">
            <Button
              label={t('restart')}
              className="btn-link mt-4"
              onClick={reset}
            />
          </div>
          <div className="col-6 text-right">
            <Submit
              disabled={!valid}
              label={t('confirm')}
              className="my-3"
            ></Submit>
          </div>
        </div>
      </HLForm>
    </section>
  )
}

import { userShape } from './propShapes'

UserForm.propTypes = {
  who: PropTypes.string.isRequired,
  existingUser: userShape,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hasMultipleCustomers: PropTypes.bool,
  productSlugs: PropTypes.array,
  hasOrganization: PropTypes.bool,
}

const s = {
  nb: {
    headingMe: 'Hvem er timen til?',
    headingNotJustMe: 'Hvem kan vi kontakte?',
    headingVaccination: 'Hvem skal vaksineres?',
    email: 'E-post',
    company: 'Hvor jobber du?',
    existingUser:
      'Se over informasjonen vi fant om deg før du fortsetter.',
    notice: {
      meplus: 'Informasjon om deg som kontaktperson og pasient.',
      others: 'Vi trenger en kontaktperson for bestillingen.',
    },
    nationalIdExplainer:
      'Vi trenger ditt personnummer siden du også er pasient.',
    emailConsent:
      'Hjemmelegene kan sende meg relevant informasjon og nyheter',
    employeeConsent: `NB: Ved å melde deg på godtar du at Hjemmelegene kan oppgi ditt navn blant de vaksinerte på faktura til din arbeidsgiver. Dette er nødvendig av skattemessige årsaker.`,
  },
  en: {
    headingMe: 'Who is the appointment for?',
    headingNotJustMe: 'Who can we contact?',
    headingVaccination: 'Who is taking the vaccine?',
    email: 'E-mail',
    company: 'Where do you work?',
    existingUser:
      'Check the information we found and correct if something is wrong before continuing.',
    notice: {
      meplus: 'Information about you as contact person and patient.',
      others:
        'NB: Not information about patients! We need a contact person for the appointment. You will enter patient information in the next step.',
    },
    nationalIdExplainer:
      'We need your national ID-number since you are also a patient.',
    emailConsent:
      'Hjemmelegene may send me relevant information and announcements.',
    employeeConsent: `NB: By signing up you agree that Hjemmelegene may state your name among those who have been vaccinated on the invoice to your employer. This is necessary for tax reasons.`,
  },
  pl: {
    headingMe: 'Dla kogo umawiasz wizytę?',
    headingNotJustMe: 'Z kim możemy się skontaktować?',
    headingVaccination: 'Kto będzie szczepiony?',
    email: 'E-mail',
    company: 'Pracodawca',
    existingUser:
      'Sprawdź znalezione przez nas informacje i, jeśli coś się nie zgadza, nanieś poprawki.',
    notice: {
      meplus:
        'Informacje o Tobie jako osobie kontaktowej i pacjencie.',
      others:
        'Nie chodzi o dane pacjentów! Do umówienia wizyty potrzebujemy osoby kontaktowej. Informacje o pacjentach uzupełnisz w następnych krokach.',
    },
    nationalIdExplainer:
      'Ponieważ Ty również jesteś pacjentem potrzebujemy Twojego numeru dowodu lub paszportu',
    emailConsent:
      'Zgadzam się aby Hjemmelegene wysyłało mi dotyczące mnie informacje i ogłoszenia.',
    employeeConsent: `Wyrażam zgodę na poinformowanie pracodawcy o mojej rejestracji`,
  },
}

export default UserForm
