import React from 'react'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../../lib/utils'

const FilteringTab = ({
  filteringObjects,
  onChange,
  isOpen,
  onCheck,
  checked,
  type,
}) => {
  const handleChange = (e) => {
    const selectedFilter = {}
    selectedFilter[e.target.name] = e.target.checked
    onCheck(e, type)
    onChange(selectedFilter, type)
  }

  return (
    <>
      {isOpen && (
        <ul className="li-reset">
          {filteringObjects.map((fo) => (
            <li key={fo} className="tabs-content-checkbox">
              <input
                onChange={handleChange}
                type="checkbox"
                name={fo}
                id={fo}
                checked={checked.includes(fo)}
              />
              <label htmlFor={fo}>{capitalizeFirstLetter(fo)}</label>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

FilteringTab.propTypes = {
  filteringObjects: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
}

export default FilteringTab
