import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

function ready(fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

class RailsForm extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    method: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      csrfToken: '',
      csrfParam: '',
    }
  }

  componentDidMount() {
    ready(() => {
      this.setState({
        csrfToken: $.rails.csrfToken(),
        csrfParam: $.rails.csrfParam(),
      })
    })
  }

  submit = () => {
    this.form.submit()
  }

  render() {
    const { children, method } = this.props
    const safeProps = _.omit(this.props, 'children', 'method')

    return (
      <form
        className="form"
        method="post"
        ref={(ref) => {
          this.form = ref
        }}
        {...safeProps}
      >
        <input
          type="hidden"
          value={this.state.csrfToken}
          name={this.state.csrfParam}
        />
        <input type="hidden" name="_method" value={method} />

        {children}
      </form>
    )
  }
}

export default RailsForm
