import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import gqlCall from '../../../api/graphql'
import { parseQueryResponse } from '../../../api/graphqlExtended'
import { formatCurrencyNoDec } from '../../../lib/formatCurrencyOneDec'

const Price = ({ label, value, quantity, products, onChange }) => {
  const [netPrice, setNetPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const graphqlSearch = async () => {
      setLoading(true)
      const searchOptions = `lineItemAttributes: [
          { v2ProductId: 7, quantity: ${quantity}},
          { v2ProductId: 16, quantity: 1}
        ]`

      const query = `query {
          v2OrderPrice(${searchOptions}) {
            totalNetPrice
            lineItems
          } }`

      try {
        const response = await gqlCall(query)
        const parsedResponse = parseQueryResponse({
          response: response,
          resourceName: 'v2OrderPrice',
          multiple: false,
        })

        const price = formatCurrencyNoDec(
          parsedResponse.totalNetPrice,
          true,
          true,
        )
        setNetPrice(price)
        if (onChange) onChange(price)
      } catch (e) {
        setError(e.toString())
      }
      setLoading(false)
      setError(null)
    }
    graphqlSearch()
  }, [quantity])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  return (
    <div>
      <label style={{ fontWeight: 'bold', color: 'black' }}>
        {label}:
      </label>{' '}
      <span>{netPrice}</span>
    </div>
  )
}

Price.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  onChange: PropTypes.func,
}

export default Price
