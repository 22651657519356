import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

let map

const Map = (props) => {
  const [mapLoaded, setMapLoaded] = useState(false)
  const initMap = () => {
    map = new google.maps.Map(mapContainer.current, {
      zoom: 13,
      mapId: 'a8a3a2d31870ba87',
    })
    map.setCenter({ lat: 59.9139, lng: 10.7522 })
    setMapLoaded(true)
  }

  const {
    markers,
    polygons,
    directionsRenderers,
    directionsRenderersOptimized,
  } = props

  const usePrevious = (elementList) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = elementList
    })
    return ref.current
  }

  const prevMarkers = usePrevious(markers)
  const prevPolygons = usePrevious(polygons)
  const prevDirectionsRenderers = usePrevious(directionsRenderers)
  const prevDirectionsRenderersOptimized = usePrevious(
    directionsRenderersOptimized,
  )

  useEffect(() => {
    initMap()
  }, [])

  useEffect(() => {
    if (prevMarkers !== undefined && prevMarkers !== markers) {
      prevMarkers.forEach((marker) => {
        marker.setMap(null)
      })
    }
  }, [markers])

  useEffect(() => {
    if (prevPolygons !== undefined && prevPolygons !== polygons) {
      prevPolygons.forEach((polygon) => {
        polygon.setMap(null)
      })
    }
  }, [polygons])

  useEffect(() => {
    if (
      prevDirectionsRenderers !== undefined &&
      prevDirectionsRenderers !== directionsRenderers
    ) {
      prevDirectionsRenderers.forEach((directionsRenderer) => {
        directionsRenderer.setMap(null)
      })
    }
  }, [directionsRenderers])

  useEffect(() => {
    if (
      prevDirectionsRenderersOptimized !== undefined &&
      prevDirectionsRenderersOptimized !==
        directionsRenderersOptimized
    ) {
      prevDirectionsRenderersOptimized.forEach(
        (directionsRendererOptimized) => {
          directionsRendererOptimized.setMap(null)
        },
      )
    }
  }, [directionsRenderersOptimized])

  const displayMarkers = () => {
    if (markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      markers.forEach((marker) => {
        marker.setMap(map)

        if (
          marker.position.lat !== null &&
          marker.position.lng !== null &&
          marker.getVisible()
        ) {
          bounds.extend(marker.getPosition())
        }
      })
      map.fitBounds(bounds)
    }
  }

  const displayCoverageMaps = () => {
    if (polygons && polygons.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      polygons.forEach((polygon) => {
        polygon.setMap(map)
        polygon.getPaths().forEach((path) => {
          path.forEach((latlng) => {
            bounds.extend(latlng)
          })
        })
        map.fitBounds(bounds)
      })
    }
  }

  const displayRoutes = () => {
    directionsRenderers.forEach((dir) => {
      dir.setMap(map)
    })
  }

  const displayRoutesOptimized = () => {
    directionsRenderersOptimized.forEach((dir) => {
      dir.setMap(map)
    })
  }

  const mapContainer = useRef()
  if (mapLoaded) {
    displayMarkers()
    displayRoutes()
    displayRoutesOptimized()
    displayCoverageMaps()
  }

  return (
    <div>
      <div ref={mapContainer} style={{ height: '85vh' }} />
    </div>
  )
}

Map.propTypes = {
  markers: PropTypes.array.isRequired,
  polygons: PropTypes.array.isRequired,
  directionsRenderers: PropTypes.array.isRequired,
  directionsRenderersOptimized: PropTypes.array.isRequired,
}

export default Map
