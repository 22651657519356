import React from 'react'
import CircleItem from './CircleItem'
import PropTypes from 'prop-types'

const VaccinationCircles = ({ item, clickOnCercle }) => {
  const hasApplied = item.productAdministeredAt

  const hasSysVAc = item.sysvacRegisteredAt

  const userQuestionnaireStatus = () => {
    const hash = item.options
    if (
      Object.keys(hash).length > 0 &&
      Object.keys(hash).every((key) => !!hash[key])
    ) {
      return 'done'
    } else {
      return 'invalid'
    }
  }

  const sysvacStatus = () => {
    if (hasApplied && !hasSysVAc) {
      return 'invalid'
    } else if (hasApplied && hasSysVAc) {
      return 'done'
    } else {
      return 'notDone'
    }
  }

  const handleClick = (value) => {
    clickOnCercle(value)
  }

  return (
    <>
      <div className="col-sm-1 " onClick={() => handleClick('okToVaccine')}>
        <CircleItem
          id={`${item.patientId}_appointment_item_ok`}
          className="circle-item questionnaire"
          status={userQuestionnaireStatus()}
        />
      </div>
      <div
        className="col-sm-1"
        onClick={() => handleClick('productAdministeredAt')}
      >
        <CircleItem
          className="circle-item applied_at"
          id={`list_item_${item.patientId}_applied_at`}
          status={hasApplied ? 'done' : 'notDone'}
        />
      </div>
      <div
        className="col-sm-1 "
        onClick={() => handleClick('sysvacRegisteredAt')}
      >
        <CircleItem
          id={`list_item_${item.patientId}_sysvac_registered_at`}
          className="circle-item sysvac_registered_at"
          status={sysvacStatus()}
        />
      </div>
    </>
  )
}

VaccinationCircles.propTypes = {
  item: PropTypes.object.isRequired,
  clickOnCercle: PropTypes.func,
}

export default VaccinationCircles
