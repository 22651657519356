import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { serviceCenterShape } from './propShapes'
import { appointmentAvailabilityCalendar } from './queries'
import Button from '../forms/fields/Button'
import _ from 'lodash'

import { MODE } from './AppointmentWizard'

const DropinAppointmentSearch = ({
  serviceCenter,
  patientCount,
  productSlugs,
  onComplete,
  onError,
  setLoading,
  reset,
  t,
}) => {
  const [availableSlotsToday, setAvailableSlotsToday] = useState(true)
  const today = new Date()

  const fetchSlots = async () => {
    setLoading(true, t('searching', s))
    const response = await appointmentAvailabilityCalendar(
      serviceCenter.id,
      today,
      patientCount,
      productSlugs,
    )
    setLoading(false)
    if (response.errors) return onError(response.errors)

    const allSlots = response.data[0].availability
    const date = Object.keys(allSlots)[0]
    const allSlotsToday = allSlots[date]

    if (allSlotsToday && !_.isEmpty(allSlotsToday)) {
      onComplete({ date, hour: MODE.DROP_IN_NOW })
    } else {
      setAvailableSlotsToday(false)
    }
  }

  useEffect(() => {
    fetchSlots()
  }, [])

  return (
    <section>
      {!availableSlotsToday && (
        <>
          <h1 className="mb-4">{t('headingFull', s)}</h1>
          <div className="info-box info-box-danger mb-4">
            <p>
              {t('no_spot_available', s, null, {
                stationTitle: serviceCenter.title,
              })}
            </p>
          </div>
          <Button
            label={t('restart')}
            className="btn-link "
            onClick={reset}
          />
        </>
      )}
    </section>
  )
}

DropinAppointmentSearch.propTypes = {
  serviceCenter: serviceCenterShape.isRequired,
  patientCount: PropTypes.number.isRequired,
  productSlugs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    headingFull: 'Fullbooket i dag',
    no_spot_available:
      'Dessverre har vi ingen flere ledige timer på {{stationTitle}} i dag.',
    searching: 'Søker etter ledige timer',
  },
  en: {
    headingFull: 'Fully booked today',
    no_spot_available:
      'Unfortunately there are no more available time slots at {{stationTitle}} today.',
    searching: 'Searching for available times',
  },
  pl: {
    headingFull:
      'Dziś zarezerwowane są już wszystkie możliwe terminy',
    no_spot_available:
      'Niestety, dziś nie ma już więcej wolnych terminów w {{stationTitle}}.',
    searching: 'Szukanie wolnych terminów',
  },
}

export default DropinAppointmentSearch
