import React from 'react'
import PropTypes from 'prop-types'
import { filterEmpty } from '../../../lib/utils'

const FieldWrapper = (props) => {
  const validationClass = (props) => {
    if (!props.showValidation) return ''
    if (props.valid || !props.isRequired) return 'valid'
    if (props.isRequired) {
      return props.highlightErrors ? 'invalid' : 'required-empty'
    }
    if (!props.valid && !props.empty) {
      return props.highlightErrors ? 'invalid' : ''
    }
    return ''
  }

  const mainWrapper = (
    <div
      className={filterEmpty([
        `formfield ${
          props.className.match(/naked/) || !props.shadow
            ? ''
            : 'ishadow'
        }`,
        props.className,
        props.disabled ? 'disabled' : '',
        validationClass(props),
      ])}
      style={props.style}
    >
      {props.children}
    </div>
  )

  return (
    <>
      {props.attentionMessage && (
        <div>
          <aside role="alert" className="attention-message">
            {props.attentionMessage}
          </aside>
          {mainWrapper}
        </div>
      )}
      {!props.attentionMessage && mainWrapper}
    </>
  )
}

export const propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  valid: PropTypes.bool,
  empty: PropTypes.bool,
  isRequired: PropTypes.bool,
  highlightErrors: PropTypes.bool,
  className: PropTypes.string,
  attentionMessage: PropTypes.string,
  showValidation: PropTypes.bool,
  shadow: PropTypes.bool,
  style: PropTypes.object,
}

FieldWrapper.propTypes = propTypes

FieldWrapper.defaultProps = {
  disabled: false,
  isRequired: false,
  className: '',
  highlightErrors: false,
  attentionMessage: '',
  showValidation: true,
  shadow: true,
  style: {},
}

export default FieldWrapper
