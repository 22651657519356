import * as Sentry from '@sentry/react'
import axios from 'axios'

export const gql = (gqlCommand) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  const payload = {
    query: gqlCommand,
    variables: {},
  }

  return axios({
    method: 'post',
    url: window.location.origin + '/graphql',
    data: payload,
    headers: headers,
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.errors) {
        const error = response.data.errors
          .map((el) => el.message)
          .join(', ')
        throw new Error(error)
      }
      return response
    } else {
      const error = `GraphQL did not respond with status 200. Response: ${JSON.stringify(
        response,
      )}`
      Sentry.captureException(error)
      throw new Error(error)
    }
  })
}

export default gql
