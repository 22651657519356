import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from './forms/fields/PhoneInput'
import OtpInput from './forms/fields/OtpInput'
import Submit from './forms/fields/Submit'

import {
  verifyMobileCarrier,
  validateOTP,
  sendCode,
  createOtpSession,
  verifyOtpSession,
} from '../lib/phone-validation'

const OTPLogin = (props) => {
  const [state, setState] = useState({
    validFormat: false,
    validMobileCarrier: false,
  })

  const handlePhoneChange = (validFormat, phoneNumber) =>
    setState({ validFormat, phoneNumber })

  const handleSubmit = async () => {
    const response = await verifyMobileCarrier(props.phoneNumber)
    if (response.err) {
      alert(
        `${state.phoneNumber} er ikke et gyldig mobiltelefonnummer. Sjekk nummeret og prøv på nytt!`,
      )
      setState({ validMobileCarrier: false })
    } else {
      setState({ validMobileCarrier: true })
      createOtpSession(props.phoneNumber)
    }
  }

  const handleOtpCodeChange = async (valid, code) => {
    if (valid) {
      const response = await verifyOtpSession(state.phoneNumber, code)
      if (response.err) {
        alert('Feil kode. Prøv på nytt')
      } else {
        setState({ validMobileCarrier: true })
        createOtpSession(props.phoneNumber)
      }
    }
  }

  return (
    <fieldset>
      <div className="formrow first">
        <PhoneInput
          className="first col-8"
          name="phone_number"
          value={props.phoneNumber || ''}
          highlightErrors={false}
          onChange={handlePhoneChange}
          isRequired
        />
        <Submit
          disabled={state.validFormat}
          onSubmit={handleSubmit}
          className="last col-4"
        />
      </div>
      <OtpInput
        className="last"
        disabled={!state.validMobileCarrier}
        disabledPlaceholder="Tast inn gyldig mobilnummer først"
        onChange={handleOtpCodeChange}
      />
    </fieldset>
  )
}

OTPLogin.propTypes = {
  phoneNumber: PropTypes.string,
}

export default OTPLogin
