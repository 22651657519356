import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
class ValidatedInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onChangeText: PropTypes.func.isRequired,
    type: PropTypes.string,
    validate: PropTypes.func,
    filter: PropTypes.func,
    onRef: PropTypes.func,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
    initialValue: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      isValid: false,
      value: props.initialValue || '',
      filter: props.filter || ((text) => text),
    }
  }

  componentDidMount() {
    if (this.props.validate)
      this.handleChange({ target: { value: this.state.value } })
    if (this.props.onRef) this.props.onRef(this)
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined)
  }

  handleChange = (event) => {
    const value =
      this.props.type != 'checkbox'
        ? event.target.value
        : event.target.checked

    this.setState({ value: this.state.filter(value) }, () => {
      let valid = false
      if (this.props.validate)
        valid = this.props.validate(this.state.value)
      this.setState({ isValid: valid })
      this.props.onChangeText(this.state.value, valid)
    })

    if (this.props.onChange) this.props.onChange(event)
  }

  replaceValue(value) {
    this.setState({ value: value })
    this.handleChange({ target: { value: value } })
  }

  composeClassName = (prevProps) =>
    [
      prevProps.type == 'checkbox' ? '' : 'form-control',
      prevProps.className ? prevProps.className : '',
      this.props.showError && !this.state.isValid ? 'error' : '',
    ]
      .filter((val) => val.length)
      .join(' ')

  render() {
    const safeProps = _.omit(
      this.props,
      'onChangeText',
      'initialValue',
      'validate',
      'filter',
      'showError',
      'onRef',
      'isValid',
    )

    const childProps = {
      ...safeProps,
      onChange: this.handleChange,
      className: this.composeClassName(safeProps),
      value: this.state.value,
    }
    return <input {...childProps} disabled={this.props.disabled} />
  }
}

export default ValidatedInput
