import React from 'react'
import PropTypes from 'prop-types'

const StaticMap = ({
  lat,
  lng,
  label,
  width = '100%',
  height = '100%',
}) => {
  return (
    <div className="static-map">
      {lat && lng && (
        <iframe
          width={width}
          height={height}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={`https://maps.google.com/maps?q=${lat},${lng}&zoom=3&output=embed`}
        />
      )}
      {(!lat || !lng) && 'No map'}
    </div>
  )
}

StaticMap.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
}

export default StaticMap
