import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { ClinicUserForm } from './ClinicUserForm'
import Button from '../forms/fields/Button'
import moment from 'moment-timezone'
import ClinicContext from './ClinicContext'
import { createUser, fetchPatient } from './queries'

const DROP_IN_NOW = 'DROP_IN_NOW'

const EMPTY_LINE_ITEM = {
  id: 'new',
  quantity: '',
  product: {
    id: 'new',
    name: '',
    price: 0,
  },
}

const EMPTY_ORDER = {
  id: 'new',
  lineItems: [EMPTY_LINE_ITEM],
  payment: { state: 'INCOMPLETE' },
}

export const ClinicCreateAppointmentForm = ({ onComplete }) => {
  const {
    clinic,
    booking,
    hackConsentOptions,
    addError,
  } = useContext(ClinicContext)
  const [user, setUser] = useState({ locale: 'NB', isPatient: true })
  const [isValidUser, setValidUser] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)

  const appointmentTime = {
    date: moment(new Date()).format('YYYY-MM-DD'),
    hour: DROP_IN_NOW,
  }

  const handleUserChange = (value, name) => {
    setUser((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const handleSubmit = async () => {
    setSubmittingForm(true)
    const isUserPatient = user.isPatient

    try {
      const userResponse = await createUser(user)

      const patient = isUserPatient
        ? await fetchPatient(userResponse.patientId)
        : { id: 'new' }

      const appointmentPatient = {
        ...patient,
        id: 'new',
        patientId: patient.id,
        options: hackConsentOptions,
      }

      const unpersistedAppointment = {
        date: appointmentTime.date,
        hour: appointmentTime.hour,
        productSlugs: [],
        user: userResponse,
        appointmentsPatients: [appointmentPatient],
        order: EMPTY_ORDER,
        mode: DROP_IN_NOW,
        ignoreRules: true,
      }

      !!clinic
        ? { ...unpersistedAppointment, serviceCenter: clinic }
        : { ...unpersistedAppointment, booking: booking }

      setSubmittingForm(false)
      onComplete(unpersistedAppointment)
    } catch (error) {
      addError(`Error handling form submission: ${error}`)
    }
  }

  return (
    <section>
      <ClinicUserForm
        user={user}
        setUser={setUser}
        onChange={handleUserChange}
        onValid={setValidUser}
      />
      <div className="my-1">
        <Button
          className="btn-primary btn-block my-2"
          disabled={submittingForm || !isValidUser}
          label={'Next'}
          onClick={handleSubmit}
        />
      </div>
    </section>
  )
}

ClinicCreateAppointmentForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
}
