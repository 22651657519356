// import appLoadingReducer from './features/routeDashboard/state/appLoadedReducers'
import {
  allDataLoadedReducer,
  allDatasetReducer,
} from './features/routeDashboard/state/globalDataReducers'
import { dateSelectedReducer } from './features/filters/state/dateSelectedReducers'
import {
  referralSelectedReducer,
  bookingSelectedReducer,
} from './features/tabs/state/itemSelectedReducers'

import {
  buildRoutesDataReducer,
  buildRoutesOptimizedDataReducer,
  buildCoverageDataReducer,
  coverageSpSelectedReducer,
  routeSpSelectedReducer,
  routeOptimizedSpSelectedReducer,
} from './features/options/state/optionSelectedReducers'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
  coverageSpSelected: coverageSpSelectedReducer,
  routeSpSelected: routeSpSelectedReducer,
  routeOptimizedSpSelected: routeOptimizedSpSelectedReducer,
  routesData: buildRoutesDataReducer,
  routesOptimizedData: buildRoutesOptimizedDataReducer,
  coverageData: buildCoverageDataReducer,
  dateSelected: dateSelectedReducer,
  referralsSelected: referralSelectedReducer,
  bookingsSelected: bookingSelectedReducer,
  allDataLoaded: allDataLoadedReducer,
  dataset: allDatasetReducer,
})

export default allReducers
