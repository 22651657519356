import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GooglePlacesAutocompleteInput from './GooglePlacesAutocompleteInput'
import Button from './forms/fields/Button'

class AddressSearchForm extends Component {
  static propTypes = {
    redirectTo: PropTypes.string,
    products: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    textButton: PropTypes.string,
  }

  static defaultProps = {
    redirectTo: '/booking_wizard/start',
    textButton: 'Vis ledige timer',
  }

  constructor(props) {
    super(props)

    this.state = {
      zip: undefined,
      denySubmit: true,
      address: undefined,
    }
  }

  componentDidMount = async () => {
    this.denySubmit = true
  }

  buildpath() {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete('redirect')
    urlParams.append('zip', this.state.zip)
    this.props.products?.forEach((product) => {
      urlParams.append('products[]', product)
    })
    if (this.state.address.street) {
      urlParams.append(
        'address',
        `${this.state.address.street} ${
          this.state.address.number ? this.state.address.number : ''
        }`,
      )
    }
    if (this.state.address.city) {
      urlParams.append('city', this.state.address.city)
    }

    return `${this.props.redirectTo}?${urlParams.toString()}`
  }

  submit = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!this.state.denySubmit) {
      window.location = this.buildpath()
    }
  }

  getAddress = (value, valid, _name) => {
    this.setState({ denySubmit: true }, () => {
      this.setState({ zip: value.zip, address: value })
      if (valid) this.setState({ denySubmit: false })
    })
  }

  render() {
    return (
      <div className="search-box hlform">
        <GooglePlacesAutocompleteInput
          googleMapsApiKey={this.props.googleMapsApiKey}
          onComplete={this.getAddress}
          isSingleLine={true}
          required={false}
          showValidation={false}
        />
        <Button
          className="search-box__button d-sm-inline-block mt-2"
          onClick={this.submit}
          id="search-address-large-button"
        >
          {this.props.textButton}
        </Button>
      </div>
    )
  }
}

export default AddressSearchForm
