import React from 'react'
import { DEFAULT_LOCALE, translate } from '../../../lib/locale/'

const DealsLayout = ({ children }) => {
  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)
  return (
    <div className="page__content">
      <h1 className="admin-header">{t('deal')}</h1>

      {children}
    </div>
  )
}

export default DealsLayout
