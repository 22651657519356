import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { verifyMobileCarrier } from '../lib/phone-validation'

import TestIdInput from './forms/fields/TestIdInput'
import ReactModal from 'react-modal'
import Button from './Button'

const AddTestModal = (props) => {
  ReactModal.setAppElement('body')

  const [isReadyForInput, setIsReadyForInput] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [testId, setTestId] = useState(null)
  const [submittable, setSubmittable] = useState(false)

  const verifyPhoneNumber = async () => {
    const response = await verifyMobileCarrier(props.phoneNumber)
    if (response.err) {
      setIsReadyForInput(false)
      setErrorMessage(
        `${props.phoneNumber} er ikke et gyldig mobiltelefonnummer. Lukk vinduet, sjekk nummeret og prøv på nytt!`,
      )
    } else {
      setIsReadyForInput(true)
    }
  }

  const closeAndCleanState = () => {
    if (props.onClose) props.onClose()
    setErrorMessage(null)
    setIsReadyForInput(false)
  }

  const getHeading = () => {
    if (isReadyForInput) return 'Oppgi Fürst Test ID'
    if (errorMessage) return 'Feil'
    return 'Et øyeblikk...'
  }

  const submit = async () => {
    setIsReadyForInput(false)
    try {
      const response = await $.post(props.path, { test_id: testId })
      window.location.reload()
    } catch (e) {
      if (e.responseJSON) {
        setErrorMessage(e.responseJSON.errors.join(', '))
      } else {
        setErrorMessage(e.statusText)
      }
      setIsReadyForInput(true)
    }
  }

  const handleTestIdChange = (value, valid) => {
    setTestId(value)
    setSubmittable(valid)
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={closeAndCleanState}
      onAfterOpen={verifyPhoneNumber}
      contentLabel="Add test to patient"
      id="AddTestModal"
    >
      <>
        <div
          className="payment-form__icon ReactModal__exit-button-new"
          onClick={closeAndCleanState}
        />
        <h3 className="ReactModal__heading mb-3">{getHeading()}</h3>
        {isReadyForInput ? (
          <>
            <div className="hlform">
              <TestIdInput
                onChange={handleTestIdChange}
                className="large-input"
              ></TestIdInput>
              {errorMessage && (
                <p className="text-danger">{errorMessage}</p>
              )}
              <Button
                label="Legg til"
                onClick={submit}
                disabled={!submittable}
              ></Button>
            </div>
          </>
        ) : (
          <p className="text-center">
            <span className="spinner-border text-primary" />
          </p>
        )}
      </>
    </ReactModal>
  )
}

AddTestModal.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  bookingId: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddTestModal
