const initialCoverageData = []
const initialRouteData = []
const initialRouteOptimizedData = []
const initialSpSelectedCoverageData = []
const initialSpSelectedRouteData = []
const initialSpSelectedRouteOptimizedData = []

export const buildCoverageDataReducer = (
  state = initialCoverageData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_COVERAGE_TOGGLE':
      const spId = action.payload.id
      const serviceProvidersList = action.payload.serviceProviders
      const serviceProvidersListCheckedIds =
        action.payload.serviceProvidersSelected

      if (serviceProvidersListCheckedIds.find((id) => id === spId)) {
        return [...state].filter((coverage) => coverage.id !== spId)
      } else {
        const sp = serviceProvidersList.find((sp) => sp.id === spId)
        const { id, color, coverage_maps } = sp
        return [...state, { id, color, coverageMaps: coverage_maps }]
      }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter(
        (coverage) => !spIds.includes(coverage.id),
      )
    }
    default:
      return state
  }
}

export const buildRoutesDataReducer = (
  state = initialRouteData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_ROUTE_TOGGLE':
      const spId = action.payload.spId
      const serviceProvidersListCheckedIds =
        action.payload.serviceProvidersSelected

      if (serviceProvidersListCheckedIds.find((id) => id === spId)) {
        return [...state].filter((route) => route.id !== spId)
      } else {
        return [...state, action.payload.route]
      }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter((route) => !spIds.includes(route.id))
    }
    default:
      return state
  }
}

export const buildRoutesOptimizedDataReducer = (
  state = initialRouteOptimizedData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_ROUTE_OPTIMIZED_TOGGLE':
      const spId = action.payload.spId
      const serviceProvidersListCheckedIds =
        action.payload.serviceProvidersSelected

      if (serviceProvidersListCheckedIds.find((id) => id === spId)) {
        return [...state].filter((route) => route.id !== spId)
      } else {
        return [...state, action.payload.route]
      }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter(
        (routeOptimized) => !spIds.includes(routeOptimized.id),
      )
    }
    default:
      return state
  }
}

export const coverageSpSelectedReducer = (
  state = initialSpSelectedCoverageData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_COVERAGE_TOGGLE': {
      const spId = action.payload.id
      if (state.includes(spId)) {
        return [...state].filter((id) => id !== spId)
      } else {
        return [...state, spId]
      }
    }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter((id) => !spIds.includes(id))
    }
    default:
      return state
  }
}

export const routeSpSelectedReducer = (
  state = initialSpSelectedRouteData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_ROUTE_TOGGLE': {
      const spId = action.payload.spId

      if (state.includes(spId)) {
        return [...state].filter((id) => id !== spId)
      } else {
        return [...state, spId]
      }
    }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter((id) => !spIds.includes(id))
    }
    default:
      return state
  }
}

export const routeOptimizedSpSelectedReducer = (
  state = initialSpSelectedRouteOptimizedData,
  action,
) => {
  switch (action.type) {
    case 'OPTION/SERVICE_PROVIDERS_ROUTE_OPTIMIZED_TOGGLE': {
      const spId = action.payload.spId

      if (state.includes(spId)) {
        return [...state].filter((id) => id !== spId)
      } else {
        return [...state, spId]
      }
    }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      const spIds = action.payload
      return [...state].filter((id) => !spIds.includes(id))
    }
    default:
      return state
  }
}
