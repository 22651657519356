import React from 'react'
import PropTypes from 'prop-types'

const MarketingConsent = ({ checked }) => (
  <label className="booking-form__label my-3">
    <div className="booking-form__checkbox-wrapper">
      <input
        type="checkbox"
        name="booking[user_attributes][marketing_consent]"
        defaultChecked={checked}
      />
    </div>
    <div className="booking-form__checkbox-text">
      Hjemmelegene kan kontakte meg på E-post om nyheter, tilbud og
      nyttige råd.
    </div>
  </label>
)

MarketingConsent.propTypes = {
  checked: PropTypes.bool,
}

export default MarketingConsent
