import PropTypes from 'prop-types'

export const idType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
])

export const childrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
])

export const userShape = PropTypes.shape({
  id: idType,
  name: PropTypes.string,
  email: PropTypes.string,
  nationalId: PropTypes.string,
})

export const patientShape = PropTypes.shape({
  id: idType.isRequired,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  nationalId: PropTypes.string,
})

export const lineItemShape = PropTypes.shape({
  id: idType,
  productId: idType,
  product: productShape,
  quantity: PropTypes.number,
})

export const serviceCenterShape = PropTypes.shape({
  id: idType,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  google_place_id: PropTypes.string,
  max_capacity: PropTypes.number,
  maxCapacity: PropTypes.number,
  operation_type: PropTypes.string, // when provided on root component mount
  operationType: PropTypes.string, // when returned from GraphQL
  slug: PropTypes.string,
  wheelchair_accessible: PropTypes.bool,
  directions: PropTypes.string,
  data: PropTypes.object,
  lat: PropTypes.string,
  lng: PropTypes.string,
})

export const appointmentShape = PropTypes.shape({
  id: idType,
  date: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  serviceCenter: serviceCenterShape,
  booking: PropTypes.object,
  appointmentsPatients: PropTypes.arrayOf(patientShape),
  order: orderShape,
})

export const orderShape = PropTypes.shape({
  id: idType,
  orderState: PropTypes.string,
  shippingState: PropTypes.string,
  additionalInfo: PropTypes.string,
  productTermsAcceptedAt: PropTypes.dateTime,
  chargeId: PropTypes.string,
  createdAt: PropTypes.dateTime,
  customerId: idType,
  customerType: PropTypes.string,
  onHold: PropTypes.bool,
  lineItems: PropTypes.arrayOf(lineItemShape),
})

export const productShape = PropTypes.shape({
  id: idType,
  name: PropTypes.string,
  publicName: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  slug: PropTypes.string,
  vatRate: PropTypes.number,
  requireShipping: PropTypes.bool,
  requireHousecall: PropTypes.bool,
  requireDigital: PropTypes.bool,
  createdAt: PropTypes.dateTime,
  updatedAt: PropTypes.dateTime,
  type: PropTypes.string,
  productCategoryId: idType,
  price: PropTypes.number,
})

export const consentFieldsShape = PropTypes.shape({
  slug: PropTypes.string,
  description: PropTypes.string,
})
