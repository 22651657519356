import { fetchAppointmentPatient } from './queries'

export const refreshAppointmentPatientList = async (
  appointmentsChanged,
  currentAppointmentsPatients,
) => {
  if (!appointmentsChanged.length > 0) return []

  const isNewAppointment = !currentAppointmentsPatients
    .map((a) => parseInt(a.appointment.id))
    .includes(parseInt(appointmentsChanged[0].id))

  if (appointmentsChanged.length == 0) return

  const updatedAppointmentsPatients = appointmentsChanged.some(
    (a) => a.appointments_patients,
  )
    ? await Promise.all(
        appointmentsChanged
          .flatMap((appointment) => appointment.appointments_patients)
          .map((appointment_patients) =>
            fetchAppointmentPatient(appointment_patients.id),
          ),
      )
    : []
  const patientsFromPreviousAppointment = currentAppointmentsPatients.filter(
    (ap) => ap.appointment.id == appointmentsChanged[0]?.id,
  )
  const deletedAppointmentPatient = patientsFromPreviousAppointment.filter(
    ({ id: oldId }) =>
      !updatedAppointmentsPatients.some(
        ({ id: newId }) => newId == oldId,
      ),
  )
  const newAppointmentsPatients = isDeletingAppointmentPatient(
    appointmentsChanged[0],
    currentAppointmentsPatients,
  )
    ? currentAppointmentsPatients.filter(
        ({ id: oldId }) =>
          !deletedAppointmentPatient.some(
            ({ id: newId }) => newId == oldId,
          ),
      )
    : overwriteArrayOfObjects(
        currentAppointmentsPatients,
        updatedAppointmentsPatients,
      )
  return [
    newAppointmentsPatients,
    isNewAppointment,
    appointmentsChanged[0],
  ]
}

export const refreshOneAppointmentPatient = async (
  appointmentPatientChanged,
  currentAppointmentsPatients,
) => {
  if (!appointmentPatientChanged.length > 0) return []

  const patientstoUpdate = (
    await Promise.all(
      appointmentPatientChanged.map((appointmentPatient) =>
        fetchAppointmentPatient(appointmentPatient.id),
      ),
    )
  ).map((ap) => {
    return { ...ap, id: ap.id.toString() }
  })
  const newAppointmentsPatients = overwriteArrayOfObjects(
    currentAppointmentsPatients,
    patientstoUpdate,
  )
  return newAppointmentsPatients
}

const isDeletingAppointmentPatient = (
  newAppointement,
  oldAppointmentsPatients,
) => {
  if (
    !oldAppointmentsPatients.some(
      (aP) => aP.appointment.id === newAppointement.id.toString(),
    )
  )
    return false

  if (!newAppointement.appointments_patients) return true

  return (
    newAppointement.appointments_patients.length <
    oldAppointmentsPatients.filter(
      (appoP) => appoP.appointment.id == newAppointement.id,
    ).length
  )
}

const overwriteArrayOfObjects = (arrayToOverwrite, upToDateArray) => {
  const map = new Map()
  arrayToOverwrite.forEach((i) => map.set(i.id, i))
  upToDateArray.forEach((oi) => map.set(oi.id, oi))
  return Array.from(map.values())
}
