import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import HLForm from './forms/HLForm'
import NameInput from './forms/fields/NameInput'
import PhoneInput from './forms/fields/PhoneInput'
import NationalIdInput from './forms/fields/NationalIdInput'
import SubmitButton from './forms/fields/SubmitButton'
import Button from './Button'
import TextButton from './TextButton'
import AddTestModal from './AddTestModal'

const BackofficePatientEditor = (props) => {
  const [addTestModalOpen, setAddTestModalOpen] = useState(false)

  const deleteLabTest = async (id) => {
    try {
      await $.ajax({
        method: 'DELETE',
        url: `${props.labTestPath}/${id}`,
      })
      window.location.reload()
    } catch (e) {
      alert(e.statusText)
    }
  }

  return (
    <HLForm
      preventSubmit={true}
      action={props.patientPath}
      method="patch"
    >
      <div className="formgroup">
        <h2 className="groupheading">Kontaktinformasjon</h2>
        <NameInput
          name="patient[name]"
          value={props.patient.name || ''}
          highlightErrors={false}
          required={true}
        />
        <div className="formrow">
          <PhoneInput
            name="patient[phone_number]"
            value={props.patient.phone_number || ''}
            highlightErrors={false}
            isRequired
          />
          <NationalIdInput
            name="patient[national_id]"
            value={props.patient.national_id || ''}
            highlightErrors={false}
          />
        </div>
      </div>
      <div className="formgroup">
        <div className="formgroup"></div>
        <SubmitButton label="Lagre" />
      </div>
      {props.labTests && (
        <div className="formgroup">
          <h2 className="groupheading">Tester</h2>
          {props.labTests.map((test) => (
            <div key={test.test_id} className="row my-2">
              <div className="col-6">ID: {test.test_id}</div>
              <div className="col-3">
                {moment(test.created_at).format('DD.MM.YY')}
              </div>
              <div className="col-3 text-right">
                <TextButton
                  label="Slett"
                  onClick={() => deleteLabTest(test.test_id)}
                />
              </div>
            </div>
          ))}
          {!props.labTests.length && <p>Ingen tester registrert</p>}
        </div>
      )}
      <div className="formgroup">
        <h2 className="groupheading">Funksjoner</h2>
        <Button
          label="Legg til Test"
          onClick={() => setAddTestModalOpen(true)}
        ></Button>
        <AddTestModal
          isOpen={addTestModalOpen}
          onClose={() => setAddTestModalOpen(false)}
          phoneNumber={props.patient.phone_number}
          bookingId={props.booking.id}
          patientId={props.patient.id}
          path={props.labTestPath}
        ></AddTestModal>
      </div>
    </HLForm>
  )
}

BackofficePatientEditor.propTypes = {
  patientPath: PropTypes.string.isRequired,
  labTestPath: PropTypes.string.isRequired,
  labTests: PropTypes.array,
  booking: PropTypes.object.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    national_id: PropTypes.string,
  }),
}

export default BackofficePatientEditor
