import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { patch } from '../lib/backend-client'

import HLForm from './forms/HLForm'
import SubmitButton from './forms/fields/SubmitButton'
import PatientIndepencenceRow from './PatientIndependenceRow'

const BackofficePatientIndependenceForm = (props) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [submittable, setSubmittable] = useState(false)
  const [patientsValidation, setPatientsValidation] = useState(
    Array(props.booking.patients.length).fill(false),
  )
  const [patients, setPatients] = useState(props.booking.patients)

  useEffect(() => {
    setSubmittable(
      patientsValidation.every((validation) => validation == true),
    )
  }, [patientsValidation])

  const handlePatientChange = (updatedPatient, isPatientValid) => {
    const index = props.booking.patients.findIndex(
      (patient) => patient.id == updatedPatient.id,
    )
    props.booking.patients[index] = updatedPatient
    changePatients(updatedPatient)
    validatePatient(index, isPatientValid)
  }

  const changePatients = (updatedPatient) => {
    const dupePatients = patients
    const newPatients = dupePatients.map((patient) => {
      return patient.id == updatedPatient.id
        ? updatedPatient
        : patient
    })
    setPatients(newPatients)
  }

  const submit = async (event) => {
    const data = JSON.stringify({
      patients_attributes: patients,
    })
    const response = await patch(props.path, data, true)
    if (response.status == 'no_content') {
      window.location.href = props.path
    } else {
      setErrorMessage('Issue while updating patients')
    }
  }

  const validatePatient = (indexPatient, isPatientValid) => {
    const newValidation = [...patientsValidation]
    newValidation[indexPatient] = isPatientValid
    setPatientsValidation(newValidation)
  }

  return (
    <HLForm preventSubmit={true} action={props.path} method="patch">
      {props.booking.patients.map((patient) => (
        <PatientIndepencenceRow
          key={patient.id}
          patient={{
            ...patient,
            contact_person: {
              id: null,
              legal_guardian: false,
            },
          }}
          patients={patients}
          mainContact={props.mainContact}
          onChange={handlePatientChange}
        />
      ))}
      {errorMessage && (
        <p className="text-danger text-center">{errorMessage}</p>
      )}
      <SubmitButton
        className="btn-block"
        label="Fortsett"
        onClick={submit}
        disabled={!submittable}
      />
    </HLForm>
  )
}

BackofficePatientIndependenceForm.propTypes = {
  booking: PropTypes.object.isRequired,
  mainContact: PropTypes.object,
  path: PropTypes.string.isRequired,
}

export default BackofficePatientIndependenceForm
