import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadGoogle from '../lib/loadGoogle'

const AC_OPTIONS = {
  types: ['address'],
  componentRestrictions: {
    country: 'no',
  },
}

function extractAddressComponent(place, type) {
  const component = place.address_components.find((item) =>
    item.types.includes(type),
  )
  if (!component) return ''
  return component.long_name
}

// Map View with Autocomplete
class MapViewWithAC extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    defaultLocation: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired,
    showMarkerInitially: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    defaultLocation: { lat: -34.397, lng: 150.644 },
    zoom: 5,
  }

  constructor(props) {
    super(props)

    this.state = {
      hasResult: false,
    }
  }

  componentDidMount = async () => {
    const google = await loadGoogle(this.props.googleMapsApiKey)

    const { lat, lng } = this.props.defaultLocation
    const location = { lat, lng }

    const map = new google.maps.Map(this.mapContainer, {
      center: location,
      zoom: this.props.zoom,
    })

    const marker = new google.maps.Marker({
      map,
      visible: !!this.props.showMarkerInitially,
      position: { lat, lng },
    })

    const ac = new google.maps.places.Autocomplete(
      this.input,
      AC_OPTIONS,
    )
    ac.addListener('place_changed', () => {
      const place = ac.getPlace()
      this.handleAutocompleteChanged(place)

      if (place.geometry) {
        map.setCenter(place.geometry.location)
        map.setZoom(15)
        marker.set('position', place.geometry.location)
        marker.set('visible', true)
      }

      this.input.blur()
    })
  }

  handleAutocompleteChanged(place) {
    this.setState({ hasResult: true })
    if (place.formatted_address) {
      const formattedAddress = place.formatted_address
      const address = formattedAddress.split(',')[0]
      const city = extractAddressComponent(place, 'postal_town')
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.props.onChange({
        lat,
        lng,
        address,
        city,
        formattedAddress,
      })
    } else {
      const formattedAddress = place.name
      const lat = ''
      const lng = ''
      this.props.onChange({ lat, lng, formattedAddress })
    }
  }

  renderButton() {
    if (this.state.hasResult) {
      return (
        <button
          onClick={this.props.onConfirm}
          className="btn btn-secondary btn-block btn-lg"
        >
          Bekreft
        </button>
      )
    }

    return (
      <button
        onClick={this.props.onCancel}
        className="btn btn-secondary btn-block btn-lg"
      >
        Gå tilbake
      </button>
    )
  }

  render() {
    return (
      <div className="mapview">
        <div className="mapview__row mapview__row--control">
          <label htmlFor="address_query" className="sr-only">
            Din adresse
          </label>
          <input
            type="text"
            ref={(ref) => {
              this.input = ref
            }}
            name="address_query"
            placeholder="Din adresse"
            className="form-control"
          />
        </div>
        <div className="mapview__row mapview__row--map">
          <div
            ref={(ref) => {
              this.mapContainer = ref
            }}
            className="mapview__map"
          />
        </div>
        <div className="mapview__row mapview__row--control">
          <br />
          {this.renderButton()}
        </div>
      </div>
    )
  }
}

export default MapViewWithAC
