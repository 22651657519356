import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TextInput from './TextInput'
import { v_otp } from '../../../lib/validations'
import { f_otp } from '../../../lib/filters'

// let ac
// let acTimeout

const OtpInput = (props) => {
  // const [code, setCode] = useState('')
  // const ref = useRef(null)

  // useEffect(() => {
  //   if (props.autoSubmit && v_otp(code)) props.autoSubmit()
  // }, [props, code])

  // useEffect(() => {
  //   if (
  //     !props.useSmsAutofill ||
  //     !('OTPCredential' in window) ||
  //     !ref.current
  //   ) {
  //     return
  //   }
  //   try {
  //     ac = new AbortController()
  //     const form = ref.current.closest('form')
  //     if (form) {
  //       // Abort listening for incoming SMS when form is submitted
  //       form.addEventListener('submit', (e) => {
  //         clearTimeout(acTimeout)
  //         ac.abort()
  //       })
  //     }

  //     navigator.credentials
  //       .get({
  //         otp: { transport: ['sms'] },
  //         signal: ac.signal,
  //       })
  //       .then((otp) => {
  //         setCode(otp.code)
  //         // if (form) form.submit()
  //       })
  //       .catch((err) => {
  //         // Ignore
  //       })

  //     // If all else fails, Abort listening for incoming SMS after 1 minute
  //     acTimeout = setTimeout(() => {
  //       ac.abort()
  //     }, 1 * 60 * 1000)

  //     return () => {
  //       // Abort listening for incoming SMS when component unmounts
  //       clearTimeout(acTimeout)
  //       ac.abort()
  //     }
  //   } catch (e) {
  //     // Ingore
  //   }
  // }, [props.useSmsAutofill, ref])

  return (
    <div>
      <TextInput {...props} value={props.value || ''} />
    </div>
    // <div ref={ref}>
    //   <TextInput {...props} value={code || props.value || ''} />
    // </div>
  )
}

OtpInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'otp_code',
  label: 'SMS-kode',
  autoComplete: 'one-time-code',
  maxLength: 4,
  filter: f_otp,
  validate: v_otp,
  pattern: '[0-9]{4}',
  inputMode: 'numeric',
  useSmsAutofill: true,
  autoSubmit: () => {},
}

OtpInput.propTypes = {
  ...TextInput.propTypes,
  useSmsAutofill: PropTypes.bool,
  autoSubmit: PropTypes.func,
}

export default OtpInput
