//if nothing change over livecycle and it is stateless it shouldn't be a class but a function.
//the naming convention should reflect that and indicate that it is a function
const markerPath = (type) => {
  switch (type) {
    case 'serviceProvider':
      return {
        path:
          'M54 26.267l7.555 7.3a.25.25 0 0 1-.174.43H54V62H40V44H24v18H10V34H2.618a.25.25 0 0 1-.174-.43L32 5l13 12.567V12h9z',
        labelOrigin: new google.maps.Point(19, -9),
        anchor: new google.maps.Point(21, 61),
        scale: 0.5,
      }

    case 'referral':
      return {
        path:
          'M38,36 L32,42 L26,36 L4.00348663,36 C1.79242202,36 0,34.2147544 0,32.0098214 L0,3.99017859 C0,1.7864638 1.79537646,0 4.00348663,0 L59.9965134,0 C62.207578,0 64,1.7852456 64,3.99017859 L64,32.0098214 C64,34.2135362 62.2046235,36 59.9965134,36 L38,36 Z',
        labelOrigin: new google.maps.Point(66 / 2, 18),
        anchor: new google.maps.Point(66 / 2, 42),
        scale: 1,
      }
    case 'booking':
      return {
        path:
          'M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z',
        labelOrigin: new google.maps.Point(100, 61 / 2),
        anchor: new google.maps.Point(21, 61),
        scale: 0.5,
      }
    default:
      return {
        path:
          'M32 2a20 20 0 0 0-20 20c0 18 20 39 20 39s20-21 20-39A20 20 0 0 0 32 2z',
        labelOrigin: new google.maps.Point(100, 61 / 2),
        anchor: new google.maps.Point(21, 61),
        scale: 0.5,
      }
  }
}

const styleSector = (sector) => {
  switch (sector) {
    case 'offentlig':
      return '#009e9d'
      break
    case 'privat':
      return 'red'
      break
    case 'bedrift':
      return '#6702c5'
      break
    default:
      return '#95989a'
  }
}

const isEmpty = (str) => {
  return !str || 0 === str.length
}

const buildInfoWindow = (props) => {
  const customPath = `<a href=${props.data.infoWindow.path} target='_blank' rel='noopener noreferrer'><strong>see detail</strong></p>`

  return new google.maps.InfoWindow({
    content:
      `<div class='p-3'>` +
      `<p class='small'><b>${props.data.infoWindow.title}</b></h6>` +
      `<p class='small'>${props.data.infoWindow.subtitle}</p>` +
      `<p class='small'>${props.data.infoWindow.body}</p>` +
      `${customPath}` +
      `</div>`,
  })
}

const buildIcon = (color, type, sector) => {
  return {
    path: markerPath(type).path,
    fillColor: color,
    fillOpacity: 0.9,
    scale: markerPath(type).scale,
    strokeColor: type === 'booking' ? styleSector(sector) : color,
    strokeWeight: 2,
    origin: new google.maps.Point(0, 0),
    labelOrigin: markerPath(type).labelOrigin,
    anchor: markerPath(type).anchor,
  }
}

const buildMarker = (icon, data, infoWindow) => {
  if (isEmpty(data.lat) || isEmpty(data.lng)) {
    return null
  } else {
    const marker = new google.maps.Marker({
      visible: true,
      position: {
        lat: data.lat,
        lng: data.lng,
      },
      icon: icon,
      label: {
        text: data.label,
        fontFamily: 'Fabriga Regular',
        fontWeight: 'bold',
        color: data.type === 'referral' ? 'black' : data.color,
      },
    })
    marker.addListener('click', () => {
      infoWindow.open(window.map, marker)
    })
    return marker
  }
}

const addMarkerToMap = (props) => {
  const marker = buildMarker(
    buildIcon(props.data.color, props.data.type, props.data.sector),
    props.data,
    buildInfoWindow(props),
  )
  return marker
}

export default addMarkerToMap
