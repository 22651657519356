import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatCurrencyNoDec } from '../../../lib/formatCurrencyOneDec'
import WarningCartModal from '../WarningCartModal'
import gqlCall from '../../../api/graphql'
import { parseQueryResponse } from '../../../api/graphqlExtended'

const LineItem = ({
  id,
  onLineTotalChange,
  product,
  showDelete,
  productIdName,
  quantity,
  onLoad,
}) => {
  const [netPrice, setNetPrice] = useState(0)
  const [averagePrice, setAverageNetPrice] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    graphqlSearch()
  }, [product.id, quantity])

  const graphqlSearch = async () => {
    onLoad(true)
    setError(null)
    const searchOptions = `lineItemAttributes: [
        { v2ProductId: ${product.id}, quantity: ${quantity} },
      ]`

    const query = `query {
        v2OrderPrice(${searchOptions}) {
          totalNetPrice
          lineItems
        } }`
    try {
      const response = await gqlCall(query)
      const parsedResponse = parseQueryResponse({
        response: response,
        resourceName: 'v2OrderPrice',
        multiple: false,
      })
      const lineItem = parsedResponse.lineItems[0]
      setNetPrice(lineItem.net_price)
      setAverageNetPrice(lineItem.net_price / quantity)
      onLineTotalChange(lineItem.net_price, lineItem.v2_product_id)
    } catch (e) {
      setError(e.toString())
    }
    onLoad(false)
  }

  const close = () => setIsOpen(false)

  return (
    <>
      <div
        key={id}
        className="row mb-1 align-items-start text-center"
      >
        <input type="hidden" name={productIdName} value={id} />
        <div className="col-7 product-name small text-left">
          {product.public_name}
          {quantity > 1 && (
            <>
              <br />
              <i className="text-muted">
                Stykkpris :{' '}
                {formatCurrencyNoDec(averagePrice, true, true)}
              </i>
            </>
          )}
        </div>

        <div className="col-1 m-0 p-0">
          {quantity}
          {showDelete && (
            <a
              href={`/line_items/${id}`}
              className="btn-compact remove-line-item"
              data-method="DELETE"
            >
              Slett
            </a>
          )}
        </div>
        <div className="col-4 text-right pl-0">
          {formatCurrencyNoDec(netPrice, true, true)}
        </div>
        <WarningCartModal isOpen={isOpen} close={close} />
      </div>
      {error && <p className="small">{error.toString()}</p>}
    </>
  )
}

LineItem.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onLineTotalChange: PropTypes.func.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    public_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  showDelete: PropTypes.bool,
  productIdName: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
}

export default LineItem
