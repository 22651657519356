import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PhoneValidationModal from './PhoneValidationModal'

class PhoneValidationModalButton extends Component {
  static propTypes = {
    phoneClass: PropTypes.string.isRequired,
    formClass: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  submitForm = () => {
    $('.' + this.props.formClass).submit()
  }

  openModal = () => this.setState({ showModal: true })
  closeModal = () => this.setState({ showModal: false })

  render() {
    return (
      <div>
        <div
          className="btn btn-secondary btn-block"
          onClick={this.openModal}
        >
          Fortsett
        </div>
        <PhoneValidationModal
          isOpen={this.state.showModal}
          close={this.closeModal}
          submit={this.submitForm}
          phoneClass={this.props.phoneClass}
        />
      </div>
    )
  }
}

export default PhoneValidationModalButton
