import React from 'react'

function PrintPage(props) {
  function handleClick(e) {
    window.print()
  }

  return (
    <div
      className="d-flex flex-row justify-content-end p-3"
      id="print-button"
    >
      <i className="fas fa-print" onClick={handleClick}></i>
    </div>
  )
}

export default PrintPage
