import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react'
import { ClinicAppointmentListNavbar as Navbar } from './ClinicAppointmentListNavbar'
import { ClinicAppointmentContent as Content } from './ClinicAppointmentContent'
import { ClinicCreateAppointmentForm } from './ClinicCreateAppointmentForm'
import { ClinicAppointmentForm } from './ClinicAppointmentForm'
import ClinicContext from './ClinicContext'
import {
  refreshAppointmentPatientList,
  refreshOneAppointmentPatient,
} from './websocketResponse'
import {
  updateAppointmentsPatient,
  updateAppointment,
  fetchAppointmentsPatients,
} from './queries'
import ErrorHandler from './ErrorHandler'
import VaccinationStatus from './VaccinationStatus'
import Skeleton from '../Skeleton'

const ClinicAppointmentList = () => {
  const {
    socket,
    clinic,
    booking,
    serviceProvider,
    addError,
    nextBooking,
  } = useContext(ClinicContext)

  const [currentDate, setCurrentDate] = useState(
    !!booking ? new Date(booking.begins_at) : new Date(),
  )
  const [appointmentsPatients, setAppointmentsPatients] = useState([])
  const [isAddingPatient, setIsAddingPatient] = useState(false)
  const [
    isCompletingAppointment,
    setIsCompletingAppointment,
  ] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isConnected, setIsConnected] = useState(false)
  const [
    refreshAppointmentEvents,
    setRefreshAppointmentEvents,
  ] = useState([])
  const [
    refreshAppointmentPatientEvents,
    setRefreshAppointmentPatientEvents,
  ] = useState([])
  const [
    unpersistedAppointment,
    setUnpersistedAppointment,
  ] = useState({})

  useEffect(() => {
    socket.connect({
      dataHandler: onReceived,
      statusHandler: setIsConnected,
    })
    return socket.disconnect
  }, [socket, onReceived, setIsConnected])

  const getAppointmentsPatients = useCallback(
    async (date) => {
      setIsLoading(true)
      try {
        const newAppointmentsPatients = await fetchAppointmentsPatients(
          clinic?.id,
          booking?.id,
          date,
        )
        setIsLoading(false)
        setAppointmentsPatients((prev) => [
          ...prev.filter(
            (appointmentPatient) =>
              !newAppointmentsPatients.some(
                (a) => a.id === appointmentPatient.id,
              ),
          ),
          ...newAppointmentsPatients,
        ])
        return appointmentsPatients
      } catch (e) {
        setIsLoading(false)
        addError(`Error fetching appointments patients: ${e.message}`)
      }
    },
    [booking?.id, clinic?.id],
  )

  const onReceived = useCallback(async (data) => {
    switch (data.event) {
      case 'list_appointment_changed':
        setRefreshAppointmentEvents((prev) => [
          ...prev,
          data.appointment,
        ])
        return
      case 'one_appointment_patient_changed':
        setRefreshAppointmentPatientEvents((prev) => [
          ...prev,
          data.appointments_patient,
        ])
        return
      default:
        return
    }
  }, [])

  useEffect(() => {
    if (refreshAppointmentEvents.length === 0) return

    async function refreshList() {
      try {
        const [
          newState,
          isNewAppointment,
          newAppointment,
        ] = await refreshAppointmentPatientList(
          refreshAppointmentEvents,
          appointmentsPatients,
        )

        setAppointmentsPatients(newState)

        setRefreshAppointmentEvents([])

        if (
          isNewAppointment &&
          newAppointment.currently_edited_by_id === serviceProvider.id
        ) {
          setSelectedId(newAppointment.id)

          setIsCompletingAppointment(false)
        }
      } catch (error) {
        addError(`Error in refreshList: ${error}`)
      }
    }

    refreshList()
  }, [refreshAppointmentEvents, serviceProvider.id])

  useEffect(() => {
    if (refreshAppointmentPatientEvents.length == 0) return
    async function refreshPatient() {
      try {
        const newState = await refreshOneAppointmentPatient(
          refreshAppointmentPatientEvents,
          appointmentsPatients,
        )
        setAppointmentsPatients(newState)
        setRefreshAppointmentPatientEvents([])
      } catch (error) {
        addError(`Error in refreshPatient: ${error}`)
      }
    }
    refreshPatient()
  }, [
    refreshAppointmentPatientEvents,
    appointmentsPatients,
    addError,
  ])

  const getAppointmentById = (id) => {
    return appointmentsPatients.find(
      (appo) => parseInt(appo.appointment.id) === parseInt(id),
    )
  }

  const openAppointmentForm = async (item) => {
    const appointment = getAppointmentById(item.appointment.id)

    if (!appointment.currentlyEditedById) {
      try {
        const openedAppointment = await updateAppointment({
          ...appointment.appointment,
          currentlyEditedAction: 'open',
        })
        setSelectedId(openedAppointment.id)
      } catch (e) {
        addError(`Error in openAppointmentForm: ${e}`)
      }
    }
  }

  const toggleAppointmentPatientAttributes = (event, item, name) => {
    event.stopPropagation()
    const newItem = {
      ...item,
      [name]: item[name] ? '' : new Date().toISOString(),
    }
    updateAppointmentsPatient(newItem)
  }

  function handleDateChange(date) {
    setAppointmentsPatients([])
    setRefreshAppointmentPatientEvents([])
    setRefreshAppointmentEvents([])
    setCurrentDate(date)
  }

  useEffect(() => {
    getAppointmentsPatients(currentDate)
  }, [currentDate, getAppointmentsPatients])

  const onCompleteForm = async (appointment, isCancelled = false) => {
    try {
      if (!isCancelled) {
        await updateAppointment({
          ...appointment.appointment,
          currentlyEditedAction: 'close',
        })
      }
      setIsCompletingAppointment(false)
      setSelectedId(null)
    } catch (e) {
      addError(`Error in onCompleteForm: ${e}`)
    }
  }

  const renderUiState = () => {
    if (isAddingPatient) {
      return (
        <ClinicCreateAppointmentForm
          onComplete={(unpersistedAppointment) => {
            setIsCompletingAppointment(true)
            setUnpersistedAppointment(unpersistedAppointment)
            setIsAddingPatient(false)
            getAppointmentsPatients()
          }}
        />
      )
    } else if (isCompletingAppointment || selectedId) {
      return (
        <ClinicAppointmentForm
          onComplete={onCompleteForm}
          onCirclesClick={toggleAppointmentPatientAttributes}
          appointment={
            isCompletingAppointment
              ? unpersistedAppointment
              : getAppointmentById(selectedId)
          }
        />
      )
    } else {
      return (
        <>
          <Navbar
            isAddingPatient={isAddingPatient}
            onAddPatient={() => setIsAddingPatient((prev) => !prev)}
          />
          <VaccinationStatus
            booking={{
              begins_at: booking.begins_at,
              ends_at: booking.ends_at,
              non_bookable_time: booking.non_bookable_minutes,
              lat: booking.lat,
              lng: booking.lng,
            }}
            patients={appointmentsPatients}
            nextBooking={nextBooking}
            isLoadingPatients={isLoading}
          />

          {isLoading ? (
            <Skeleton
              numOfRows={5}
              shapeOfRows={'rectangular'}
              numOfHeaders={2}
            />
          ) : (
            <Content
              currentDate={currentDate}
              setCurrentDate={handleDateChange}
              appointments={appointmentsPatients}
              onListItemClick={openAppointmentForm}
              onCirclesClick={toggleAppointmentPatientAttributes}
            />
          )}
        </>
      )
    }
  }

  return (
    <div className="page__content page__content--clinic">
      {(isLoading || !isConnected) && (
        <div className="d-flex justify-content-center align-items-center">
          <span className="spinner-border text-primary ml-2" />
        </div>
      )}
      <ErrorHandler />
      {renderUiState()}
    </div>
  )
}

export default ClinicAppointmentList
