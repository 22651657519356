import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Booking from './Booking'

class Day extends Component {
  static propTypes = {
    bookingsDay: PropTypes.array.isRequired,
    firstBooking: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    trackingIndexStart: PropTypes.number,
    method: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.trackingIndex = props.trackingIndexStart
  }

  render() {
    const groupedBookings = _.groupBy(
      this.props.bookingsDay,
      'begins_at',
    )
    const bookings = _.map(groupedBookings, (bookingGroup, hour) => {
      // Exclude Peder from first hit
      const firstNotRemovedBooking = _.find(
        bookingGroup,
        (booking) => {
          return (
            !booking.reserved && booking.service_provider_id != 41
          )
        },
      )

      if (!firstNotRemovedBooking) {
        _.find(bookingGroup, (booking) => {
          return !booking.reserved
        })
      }

      const nBookings = Object.keys(groupedBookings).length

      const el = (
        <li
          className={`day__hour ${
            nBookings <= 2 ? 'day__hour--2up' : ''
          }`}
          key={hour}
        >
          <Booking
            booking={firstNotRemovedBooking || bookingGroup[0]}
            path={this.props.path}
            method={this.props.method}
            firstBooking={this.props.firstBooking}
            trackingIndex={this.trackingIndex}
          />
        </li>
      )
      this.trackingIndex++
      return el
    })

    return <ol className="day__hours">{bookings}</ol>
  }
}

export default Day
