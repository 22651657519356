import React from 'react'
import PropTypes from 'prop-types'

const SpecificSpTab = ({ serviceProviders, onChange, isOpen }) => {
  const handleChange = (e) => {
    const selectedSp = {}
    selectedSp[e.target.name] = e.target.checked
    onChange(selectedSp)
  }

  const checkSpSelection = (sp) => {
    if (
      sp.area_selected ||
      sp.profession_selected ||
      sp.individual_selected
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {isOpen && (
        <ul className="li-reset">
          {serviceProviders.map((sp) => (
            <li key={sp.id} className="tabs-content-checkbox">
              <input
                onChange={handleChange}
                type="checkbox"
                name={sp.id}
                id={sp.id}
                checked={checkSpSelection(sp)}
              />

              <label htmlFor={sp.id}>
                {sp.full_name} ({sp.professions.join(', ')} -{' '}
                {sp.area})
              </label>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

SpecificSpTab.propTypes = {
  serviceProviders: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default SpecificSpTab
