import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { formatCurrencyNoDec } from '../../lib/formatCurrencyOneDec'
import Checkbox from '../forms/fields/Checkbox'
import SubmitButton from '../forms/fields/SubmitButton'
import FormProgress from '../forms/FormProgress'
import HLForm from '../forms/HLForm'
import TermsModal from '../TermsModal'
import EditOrderDetailsList from './shared/EditOrderDetailsList'
import Layout from './shared/Layout'
import PickedTimeBox from './shared/PickedTimeBox'

const VaccineWizardConfirmTerms = ({
  path,
  steps,
  currentStep,
  organizationAddress,
  booking,
  bookingContactPerson,
  orderContactPerson,
  price,
  quantity,
  products,
  order,
  payment,
  googleMapsApiKey,
  organizationOrgNumber,
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const toggleTermsAccepted = (checked) => setTermsAccepted(checked)
  const openShowTermsModal = (e) => {
    e.preventDefault()
    setShowTermsModal(true)
  }
  const toggleAllFieldsValid = (bool) => setAllFieldsValid(bool)
  const toggleIsEditing = (bool) => setIsEditing(bool)

  const submitForm = (e) => {
    e.preventDefault()
    const form = document.forms['vaccine_wizard_edit_order_details']
    form.submit()
  }
  return (
    <Layout
      heading="Bekreft bestilling"
      subheading="Les gjennom og bekreft at opplysningene er korrekte."
    >
      <HLForm
        action={path}
        method="put"
        name="vaccine_wizard_edit_order_details"
      >
        <FormProgress steps={steps} currentStep={currentStep} />

        <PickedTimeBox
          timeStart={booking.begins_at}
          timeEnd={booking.ends_at}
          address={`i ${booking.address}, ${booking.zip} ${booking.city}`}
        />

        <EditOrderDetailsList
          order={order}
          booking={booking}
          bookingContactPerson={bookingContactPerson}
          orderContactPerson={orderContactPerson}
          organizationAddress={organizationAddress}
          organizationOrgNumber={organizationOrgNumber}
          payment={payment}
          products={products}
          price={formatCurrencyNoDec(price, true, true)}
          quantity={booking.epax_count}
          validateFields={toggleAllFieldsValid}
          toggleIsEditing={toggleIsEditing}
          googleMapsApiKey={googleMapsApiKey}
        />
        <h4 className="mt-4">Betingelser for bedriftsvaksinering</h4>
        <p>
          Ved å krysse av under bekrefter du at du har lest og
          forstått følgende:
        </p>
        <div className="d-flex flex-row">
          <div>
            <Checkbox
              autoComplete="off"
              onChangeText={toggleTermsAccepted}
              isValid={false}
            />
          </div>
          <div>
            Jeg bekrefter at informasjonen jeg har oppgitt er korrekt
            og at jeg har lest og forstått{' '}
            <a href="#" onClick={openShowTermsModal}>
              Hjemmelegenes generelle betingelser
            </a>{' '}
            og{' '}
            <a
              href="https://www.hjemmelegene.no/personvern"
              target="_blank"
              rel="noopener noreferrer"
            >
              personvernpolicy
            </a>
            .
          </div>
          <TermsModal
            isOpen={showTermsModal}
            close={() => setShowTermsModal(false)}
          />
        </div>
      </HLForm>
      <SubmitButton
        label="Bekreft bestillingen"
        className="btn-block mt-4"
        disabled={!termsAccepted || isEditing}
        onClick={submitForm}
      />
    </Layout>
  )
}

VaccineWizardConfirmTerms.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  organizationAddress: PropTypes.object.isRequired,
  bookingContactPerson: PropTypes.object.isRequired,
  orderContactPerson: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  products: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
}

VaccineWizardConfirmTerms.defaultProps = {
  steps: [],
  currentStep: '',
  order: {},
  booking: {},
  organizationAddress: {},
  bookingContactPerson: {},
  orderContactPerson: {},
  payment: {},
  products: '',
  productTerms: [],
  price: 0,
  quantity: 0,
}

export default VaccineWizardConfirmTerms
