import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedTextarea from '../inputs/ValidatedTextarea'

class TextareaInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    showError: PropTypes.bool,
    isValid: PropTypes.any,
  }

  validate(text) {
    const patt = /[a-z]*/i
    return patt.test(text)
  }

  errorClass() {
    if (this.props.showError && !this.props.isValid) {
      return 'error'
    }

    return ''
  }

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      name: 'user[patient_attributes][address_directions]',
      placeholder: prevProps.placeholder || 'Besøkets gateadresse',
      type: 'textarea',
    }

    return (
      <div>
        <ValidatedTextarea {...props} />
      </div>
    )
  }
}

export default TextareaInput


