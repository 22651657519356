import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RailsForm from './RailsForm'
import moment from 'moment-timezone'

class FirstTimeSlotButton extends Component {
  static propTypes = {
    searchPath: PropTypes.string.isRequired,
    urlPath: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    booking: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      disabled: '',
    }
  }

  handleClick = (e) => {
    this.setState({
      disabled: 'disabled',
    })
  }

  firstCharUppercase(text) {
    return text.replace(/^\w/, (c) => c.toUpperCase())
  }

  showTodayOrTomorrow(date) {
    const today = moment().tz('Europe/Oslo').startOf('day')
    const dateWithTZ = moment(date).tz('Europe/Oslo').startOf('day')

    const daysDiff = dateWithTZ.diff(today, 'days')

    if (daysDiff == 0) {
      return ` ${moment(date)
        .tz('Europe/Oslo')
        .format('[kl] HH:mm')} i dag`
    } else if (daysDiff == 1) {
      return ` ${moment(date)
        .tz('Europe/Oslo')
        .format('[kl] HH:mm')} i morgen`
    }

    return ` ${moment(date)
      .tz('Europe/Oslo')
      .format('[kl] HH:mm')} på ${this.firstCharUppercase(
      moment(date).tz('Europe/Oslo').format('dddd D'),
    )}`
  }

  render() {
    const booking = this.props.booking
    if (this.props.isLoading === false && booking.match === true) {
      return (
        <div>
          <RailsForm
            method="post"
            action={this.props.urlPath}
            className="no-margin"
          >
            <input
              type="hidden"
              name="booking[begins_at]"
              value={booking.begins_at}
            />
            <input
              type="hidden"
              name="booking[ends_at]"
              value={booking.ends_at}
            />
            <input
              type="hidden"
              name="booking[lat]"
              value={booking.lat || ''}
            />
            <input
              type="hidden"
              name="booking[lng]"
              value={booking.lng || ''}
            />
            <input
              type="hidden"
              name="booking[zip]"
              value={booking.zip || ''}
            />
            <input
              type="hidden"
              name="booking[address]"
              value={booking.address || ''}
            />
            <input
              type="hidden"
              name="booking[service_provider_id]"
              value={booking.service_provider_id}
            />
            <button
              type="submit"
              className="btn btn-outline-light user-card-btn primary mb-3"
            >
              Få hjelp
              {this.showTodayOrTomorrow(booking.begins_at)}
            </button>
          </RailsForm>
          <a className="zip-search" href={this.props.searchPath}>
            se alle ledige timer
          </a>
        </div>
      )
    } else if (
      this.props.isLoading === false &&
      booking.match === false
    ) {
      return (
        <a
          className="no-match btn btn-outline-light user-card-btn primary"
          href={this.props.searchPath}
        >
          se alle ledige timer
        </a>
      )
    } else {
      return (
        this.props.isLoading && (
          <button className="btn">
            <span className="spinner" />
          </button>
        )
      )
    }
  }
}

export default FirstTimeSlotButton
