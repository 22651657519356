import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ErrorMessage from './ErrorMessage'

class UploadFilePickerImage extends Component {
  static propTypes = {
    filepickerApiKey: PropTypes.string.isRequired,
    targetImageContainer: PropTypes.string.isRequired,
    outputWidth: PropTypes.number,
    outputHeight: PropTypes.number,
    serviceProviderId: PropTypes.number,
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  openFilepickerDialog = (event) => {
    event.preventDefault()
    filepicker.setKey(this.props.filepickerApiKey)
    filepicker.pickAndStore(
      {
        mimetype: 'image/*',
        multiple: false,
        container: 'window',
        services: ['COMPUTER'],
      },
      {
        location: 'S3',
      },
      this.handleUploadSuccess,
    )
  }

  clearAvatar = (event) => {
    event.preventDefault()

    const data = {
      avatar_url: null,
      service_provider_id: this.props.serviceProviderId,
    }

    $.ajax({ url: '/avatar', type: 'PUT', data: data })
      .done(() => {
        $(this.props.targetImageContainer).html(
          "<i class='icon icon-profile profile-picture__placeholder'></i>",
        )
        this.setState({ error: null })
      })
      .fail(() => {
        this.setState({
          error: 'Noe gikk galt med oppdatering av bildet',
        })
      })
  }

  transformedFilepickerUrl = () => {
    // return this.state.avatarUrl;
    const fileHandle = this.state.avatarUrl.split('/').pop()
    return `https://process.filepicker.io/${this.props.filepickerApiKey}/rotate=d:exif/output=format:jpg,background:white/resize=w:${this.props.outputWidth},h:${this.props.outputHeight},f:crop,a:faces/${fileHandle}`
  }

  handleUploadSuccess = (blobs) => {
    const avatarUrl = blobs[0].url
    this.setState({
      avatarUrl,
    })

    const data = {
      avatar_url: this.state.avatarUrl,
      service_provider_id: this.props.serviceProviderId,
    }

    $.ajax({ url: '/avatar', type: 'PUT', data: data })
      .done(() => {
        $(this.props.targetImageContainer).html(
          this.renderOutputImage(),
        )
        this.setState({ error: null })
        window.location.reload()
      })
      .fail(() => {
        this.setState({
          error: 'Noe gikk galt med oppdatering av bildet',
        })
      })
  }

  renderOutputImage() {
    return (
      <img
        alt="Profilbilde"
        src={this.transformedFilepickerUrl()}
        className="image-circle"
      />
    )
  }
  render() {
    return (
      <div>
        <div className="button-group profile-picture__action-wrapper">
          <a
            onClick={this.openFilepickerDialog}
            href="upload"
            className="button-group-item profile-picture__action"
          >
            Last opp bilde
          </a>
        </div>

        <div className="button-group">
          <a
            onClick={this.clearAvatar}
            href="clear"
            className="button-group-item profile-picture__action"
          >
            Fjern bilde
          </a>
        </div>

        <ErrorMessage errorMessage={this.state.error} />
      </div>
    )
  }
}

UploadFilePickerImage.defaultProps = {
  outputWidth: 500,
  outputHeight: 500,
}

export default UploadFilePickerImage
