import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class PasswordConfirmationInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    password: PropTypes.string,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    name: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  validateSimilarity = (text) => {
    if (text === '') {
      return false
    } else {
      return text === this.props.password
    }
  }

  wrapperClass = () =>
    this.props.isValid
      ? 'form-group booking-form__valid-icon'
      : 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'

  errorClass() {
    return this.props.showError && !this.props.isValid ? 'error' : ''
  }

  render() {
    const { ...prevProps } = this.props

    const placeholder =
      this.props.placeholder !== undefined
        ? this.props.placeholder
        : 'bekreft passord'

    const props = {
      ...prevProps,
      validate: this.validateSimilarity,
      name: 'user[password_confirmation]',
      type: 'password',
      placeholder,
    }

    return (
      <div className={this.wrapperClass()}>
        <div
          className={`form-control booking-form__icon-wrapper ${
            this.props.disabled ? 'disabled' : null
          } ${this.errorClass()}`}
        >
          <label className="sr-only">{props.placeholder}</label>
          <ValidatedInput {...props} />
        </div>
      </div>
    )
  }
}

export default PasswordConfirmationInput

