import React from 'react'
import PropTypes from 'prop-types'

const Option = (props) => {
  const handleChange = (e) => {
    props.onChange(e)
  }
  return (
    <div className="sp-form__options__item">
      <input
        type="checkbox"
        name={props.name}
        value={props.id}
        checked={props.checked}
        onChange={handleChange}
      />
      <label htmlFor={`option-${props.name}`}>{props.label}</label>
    </div>
  )
}

Option.propTypes = {
  id: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default Option
