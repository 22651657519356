import React from 'react'
import PropTypes from 'prop-types'

const Shield = ({ children, show = false }) => (
  <div className={`shield ${show ? 'show' : ''}`}>{children}</div>
)

Shield.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
}

export default Shield
