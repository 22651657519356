import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import RailsForm from '../RailsForm'

class Booking extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    firstBooking: PropTypes.object.isRequired,
    method: PropTypes.string,
    trackingIndex: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.tracked = false
  }

  firstBookingLabel() {
    if (this.props.booking == this.props.firstBooking) {
      return (
        <span className="day__hour-label">Første&nbsp;ledige</span>
      )
    } else if (this.props.booking.express_booking) {
      return <span className="day__hour-label">30–45 min</span>
    }

    return ''
  }

  hours_text() {
    const beginsAt = moment(this.props.booking.begins_at).tz(
      'Europe/Oslo',
    )
    const endsAt = moment(this.props.booking.ends_at).tz(
      'Europe/Oslo',
    )
    const hours = [beginsAt.format('HH:mm')]
    if (endsAt.diff(beginsAt, 'minutes') > 60) {
      const morningOrAfternoon =
        beginsAt.hour() < 11 ? 'Morgen' : 'Ettermiddag'
      return (
        <>
          <p className="m-0">{morningOrAfternoon}</p>
          <p className="m-0 tiny">
            (mellom {hours[0]} og {endsAt.format('HH:mm')})
          </p>
        </>
      )
    } else {
      return hours.join('–')
    }
  }

  onSubmit = (e) => {
    if (!this.tracked) {
      dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'booking',
        eventAction: 'select_time',
        eventLabel: this.props.trackingIndex,
        eventValue: 20,
        nonInteraction: false,
      })
      this.tracked = true
      setTimeout(this.form.submit, 200)
    }
  }

  render() {
    const booking = this.props.booking

    if (booking.reserved) {
      return (
        <div className="day__service-provider day__service-provider--disabled day__hour-heading">
          Reservert
        </div>
      )
    }

    return (
      <RailsForm
        method={this.props.method || 'post'}
        action={this.props.path}
        //onSubmit={this.onSubmit}
        className="no-margin"
        name="housecall_timeslot"
        // ref={(ref) => {
        //   this.form = ref
        // }}
      >
        <input
          type="hidden"
          name="booking[begins_at]"
          value={booking.begins_at}
        />
        <input
          type="hidden"
          name="booking[ends_at]"
          value={booking.ends_at}
        />
        <input
          type="hidden"
          name="booking[lat]"
          value={booking.lat || ''}
        />
        <input
          type="hidden"
          name="booking[lng]"
          value={booking.lng || ''}
        />
        <input
          type="hidden"
          name="booking[zip]"
          value={booking.zip || ''}
        />
        <input
          type="hidden"
          name="booking[address]"
          value={booking.address || ''}
        />
        <input
          type="hidden"
          name="booking[city]"
          value={booking.city || ''}
        />
        <input
          type="hidden"
          name="booking[service_provider_id]"
          value={
            booking.service_provider
              ? booking.service_provider.id
              : booking.service_provider_id
          }
        />
        <input
          type="hidden"
          name="booking[rebooking]"
          value={booking.rebooking || false}
        />
        <input
          type="hidden"
          name="booking[express_booking]"
          value={booking.express_booking || false}
        />

        <button
          type="submit"
          className={`day__service-provider day__hour-heading_multiline ${
            this.props.booking.express_booking
              ? 'day__service-provider--express'
              : ''
          }`}
          data-service-provider={
            booking.service_provider
              ? booking.service_provider.id
              : booking.service_provider_id
          }
        >
          {!this.props.booking.express_booking && (
            <>
              <div>{this.hours_text()}</div>

              {this.props.booking.remaining_capacity && (
                <div>
                  {this.props.booking.remaining_capacity} min.
                </div>
              )}
            </>
          )}

          {/* {this.props.booking.express_booking && "Snarest"} */}
          {this.props.booking.express_booking && (
            <>
              <span>Test for COVID-19</span>
              <span className="line2">
                Tidspunkt avtales etter bestilling
              </span>
            </>
          )}
          {/* {this.firstBookingLabel()} */}
        </button>
      </RailsForm>
    )
  }
}

export default Booking
