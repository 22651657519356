import React, { useEffect, useRef, useState } from 'react'

const scriptSrc = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'

async function boot(element, options) {
  const scriptTag = document.createElement('script')
  scriptTag.setAttribute('type', 'text/javascript')
  scriptTag.setAttribute('src', scriptSrc)

  if (options?.nonce) {
    scriptTag.setAttribute('nonce', options.nonce)
  }

  const scriptContainer = element ?? document.body

  const loadScriptPromise = new Promise((resolve, reject) => {
    scriptTag.onload = () => {
      if (!window.FrontChat) {
        throw new Error(
          '[front-chat-sdk] Could not set up window.FrontChat',
        )
      }

      resolve(window.FrontChat)
    }
  })

  scriptContainer.appendChild(scriptTag)

  return loadScriptPromise
}

const FrontChatStatusesEnum = {
  IDLE: 'idle',
  READY: 'ready',
  INITIALIZED: 'initialized',
}

function useFrontChatBoot(element, options) {
  const scriptTagAppended = useRef(false)

  const [status, setStatus] = useState(FrontChatStatusesEnum.IDLE)

  useEffect(() => {
    ;(async () => {
      if (scriptTagAppended.current) {
        return
      }
      scriptTagAppended.current = true

      await boot(element, options)

      setStatus(FrontChatStatusesEnum.READY)
    })().catch(console.error)
  }, [element, options])

  if (status === FrontChatStatusesEnum.IDLE) {
    return {
      frontChat: undefined,
      isInitialized: false,
      initialize: undefined,
    }
  }

  const frontChat = (cmdType, params) => {
    if (!window.FrontChat) {
      console.error(
        '[front-chat-sdk] Have not finished setting up window.FrontChat',
      )
      return
    }

    if (cmdType === 'init') {
      const onInitCompleted = () => {
        setStatus(FrontChatStatusesEnum.INITIALIZED)
      }

      return window.FrontChat(cmdType, { ...params, onInitCompleted })
    }

    if (cmdType === 'shutdown') {
      setStatus(FrontChatStatusesEnum.READY)
    }

    return window.FrontChat(cmdType, params)
  }

  const initialize = (params) => {
    if (status === FrontChatStatusesEnum.INITIALIZED) {
      return
    }

    frontChat('init', params)
  }

  return {
    frontChat,
    isInitialized: status === FrontChatStatusesEnum.INITIALIZED,
    initialize,
  }
}

const useFrontChat = (chatId, element) => {
  const { frontChat, isInitialized, initialize } = useFrontChatBoot(
    element,
  )

  useEffect(() => {
    if (isInitialized || !initialize || !chatId) {
      return
    }

    initialize({ chatId })
  }, [chatId, isInitialized, initialize])

  return { frontChat, isInitialized, initialize }
}

const CHAT_IDS = {
  open: 'd39773c1e7c705c97799f20479673bfc',
  closed: 'fa3916785ce5c3867e9365c84e21ccf1',
}

const getChatId = () => {
  const now = new Date()
  const utcHour = now.getUTCHours()
  const utcDay = now.getUTCDay()
  const osloOffset = 2

  const osloHour = (utcHour + osloOffset) % 24

  const weekday = utcDay !== 0 && utcDay !== 6

  if (weekday && osloHour >= 8 && osloHour < 20) {
    return CHAT_IDS.open
  } else if (!weekday && osloHour >= 8 && osloHour < 16) {
    return CHAT_IDS.open
  } else {
    return CHAT_IDS.closed
  }
}

const CustomFrontLauncher = (props) => {
  const locale = 'nb'

  const { frontChat, isInitialized } = useFrontChat(
    getChatId(),
    document.body,
  )

  const openFront = () => {
    if (!isInitialized || !frontChat) {
      console.warn('Front chat not initialized')
      return
    }
    frontChat('show')
  }

  return (
    <button
      id="hl-custom-front-launcher"
      className="custom-intercom-launcher"
      tabIndex="0"
      aria-label="Open Intercom Messenger"
      aria-live="polite"
      onClick={openFront}
    >
      Lurer du på noe?
    </button>
  )
}

export default CustomFrontLauncher
