import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../forms/fields/Button'
import { idType } from '../propShapes'

export function Selector({
  allowMultiple = false,
  options,
  onSelect,
  name,
}) {
  const [selectedIds, setSelectedIds] = useState([])

  function isSelected(optionId) {
    return selectedIds.some((id) => id === optionId)
  }

  function handleSelect(optionId) {
    const newSelectedIds = allowMultiple
      ? isSelected(optionId)
        ? selectedIds.filter((id) => id !== optionId)
        : [...selectedIds, optionId]
      : [optionId]
    setSelectedIds(newSelectedIds)
    onSelect(newSelectedIds, name)
  }

  return (
    <div className="d-flex flex-column w-100">
      {options.map((option) => (
        <SelectorButton
          key={option.id}
          title={option.title}
          isSelected={isSelected(option.id)}
          onSelect={(e) => {
            e.preventDefault()
            handleSelect(option.id)
          }}
        />
      ))}
    </div>
  )
}

Selector.propTypes = {
  allowMultiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: idType,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string,
}

function SelectorButton({
  title,
  description,
  isSelected,
  onSelect,
}) {
  return (
    <Button
      className="btn btn-outline btn-secondary btn-big ishadow d-flex justify-content-center align-items-center"
      onClick={onSelect}
    >
      <div style={{ margin: '0 auto' }}>
        <h2 className="h6" style={{ fontSize: '1.125rem' }}>
          {title}
        </h2>
        {description && (
          <p style={{ fontSize: '0.875rem' }}>{description}</p>
        )}
      </div>
      {isSelected && (
        <div className="circle-item">
          <div className="done" />
        </div>
      )}
    </Button>
  )
}

SelectorButton.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}
