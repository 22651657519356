import React, { useState } from 'react'
import LanguageSwitcher from '../../forms/fields/LanguageSwitcher'
import ErrorView from '../ErrorView'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, translate } from '../../../lib/locale'
import {
  appointmentShape,
  productShape,
  serviceCenterShape,
} from './propShapes'
import { Overview } from './Overview'
import { InvalidAppointment } from './InvalidAppointment'
import { DeletedAppointment } from './DeletedAppointment'
import { UpdateAppointment } from './UpdateAppointment'
import ShieldedSpinner from '../../ShieldedSpinner'
import * as Sentry from '@sentry/react'

const VIEWS = {
  overview: 'overview',
  invalid: 'invalid',
  deleted: 'deleted',
  update: 'update',
  delete: 'delete',
}

function ExistingAppointment({
  appointment,
  products,
  serviceCenter,
  deleted,
  numOfPatients,
  hour,
  map,
  calendarLink,
  cancelLink,
  assetRootUrl,
}) {
  const initialView = appointment
    ? deleted
      ? VIEWS.deleted
      : VIEWS.overview
    : VIEWS.invalid

  const [locale, setLocale] = useState(DEFAULT_LOCALE)
  const [view, setView] = useState(initialView)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)
  const [appointmentUpdated, setAppointmentUpdated] = useState()

  function reset() {
    location.reload()
  }

  const showLoadingShield = (status, message) => {
    setLoading({ status, message })
  }

  function t(prop, source, transform, vars) {
    return translate(`${locale}.${prop}`, source, transform, vars)
  }

  function onError(error) {
    Sentry.captureException(error, {
      extra: {
        location: 'ExistingAppointment.view',
        appointment,
        products,
        serviceCenter,
        deleted,
        numOfPatients,
        hour,
      },
    })
    setError(error)
  }

  function onUpdateSuccess(date, hour) {
    setView(VIEWS.overview)
    setAppointmentUpdated({ date, hour })
  }

  function renderView() {
    switch (view) {
      case VIEWS.overview:
        return (
          <Overview
            appointment={appointment}
            products={products}
            serviceCenter={serviceCenter}
            numOfPatients={numOfPatients}
            hour={hour}
            map={map}
            t={t}
            calendarLink={calendarLink}
            cancelLink={cancelLink}
            onUpdate={() => setView(VIEWS.update)}
            updated={appointmentUpdated}
          />
        )
      case VIEWS.invalid:
        return <InvalidAppointment t={t} />
      case VIEWS.deleted:
        return <DeletedAppointment t={t} />
      case VIEWS.update:
        return (
          <UpdateAppointment
            appointment={appointment}
            serviceCenter={serviceCenter}
            patientCount={numOfPatients}
            onSuccess={onUpdateSuccess}
            onError={onError}
            setLoading={showLoadingShield}
            reset={reset}
            t={t}
            products={products}
          />
        )
      default:
        return null
    }
  }

  return (
    <article className="appointment-wizard">
      <ShieldedSpinner
        show={loading?.status}
        message={loading?.message}
      />
      <div className="content">
        <LanguageSwitcher
          className="w-100 d-flex justify-content-end"
          style
          onChange={setLocale}
          defaultLocale={locale}
          flags={assetRootUrl.flags}
        />
        {renderView()}
      </div>
      {error && <ErrorView error={error} reset={reset} t={t} />}
    </article>
  )
}

ExistingAppointment.propTypes = {
  appointment: appointmentShape,
  products: PropTypes.arrayOf(productShape),
  serviceCenter: serviceCenterShape,
  numOfPatients: PropTypes.number.isRequired,
  hour: PropTypes.string.isRequired,
  map: PropTypes.string.isRequired,
  deleted: PropTypes.bool.isRequired,
  calendarLink: PropTypes.string,
  cancelLink: PropTypes.string,
  assetRootUrl: PropTypes.object.isRequired,
}

export default ExistingAppointment
