import React, { Component } from 'react'
import PropTypes from 'prop-types'

import RailsForm from '../RailsForm'
import FormField from '../FormField'
import loadGoogle from '../../lib/loadGoogle'
import SelectHL from '../forms/fields/SelectHL'
import Button from '../forms/fields/Button'
import SubmitButton from '../forms/fields/SubmitButton'
import CoverageMapEditorModal from './CoverageMapEditorModal'
import _ from 'lodash'
import { capitalizeFirstLetter } from '../../lib/utils'

function polygonToString(polygon) {
  let polygonString = ''

  let latlngArray = []
  latlngArray = polygon.getPath().getArray()
  latlngArray.forEach(function (latlng) {
    polygonString += `${latlng.lng()} ${latlng.lat()},`
  })
  polygonString += `${latlngArray[0].lng()} ${latlngArray[0].lat()}`

  return `POLYGON((${polygonString}))`
}

function polygonStringToHash(polygonString) {
  if (polygonString == null) return null
  const polygon = cleanPolygonString(polygonString)

  return _.map(polygon, (latlng) => {
    const latlngArray = latlng.split(' ')
    return {
      lat: parseFloat(latlngArray[1]),
      lng: parseFloat(latlngArray[0]),
    }
  })
}

function cleanPolygonString(polygonString) {
  if (polygonString == null) return null
  let polygon = polygonString.replace('POLYGON ((', '')
  polygon = polygon.replace('))', '')
  polygon = polygon.split(', ')
  return polygon
}

function getPolygonBounds(polygon) {
  const bounds = new google.maps.LatLngBounds()
  const paths = polygon.getPaths()
  paths.forEach((path, index) => {
    path.forEach((latLng) => bounds.extend(latLng))
  })

  return bounds
}

class CoverageMapEditor extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    isMapEditable: PropTypes.bool,
    isMapDeletable: PropTypes.bool,
    location: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    path: PropTypes.string,
    method: PropTypes.string,
    coverageMap: PropTypes.shape({
      map: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
    shouldLoadGoogle: PropTypes.bool.isRequired,
    coverageMaps: PropTypes.arrayOf(PropTypes.object),
    serviceProvider: PropTypes.object,
    categories: PropTypes.arrayOf(PropTypes.object),
  }

  constructor(props) {
    super(props)

    this.state = {
      polygonString: this.props.coverageMap?.map || '',
      name: _.get(props.coverageMap, 'name', ''),
      category: _.get(props.coverageMap, 'category', 'other'),
      isModalOpen: false,
      typeOfModal: 'notMainAnymore',
    }
  }

  centerLatLng() {
    if (_.get(this.props.coverageMap, 'map') != null) {
      const coordinates = polygonStringToHash(
        this.props.coverageMap.map,
      )
      return coordinates[0]
    }

    return this.props.location
  }

  componentDidMount = async () => {
    if (this.props.shouldLoadGoogle) {
      await loadGoogle(this.props.googleMapsApiKey)
    }

    const { lat, lng } = this.centerLatLng()
    this.map = new google.maps.Map(
      document.getElementById(
        `coverage-map-${this.props.coverageMap?.id}`,
      ),
      {
        center: { lat, lng },
        zoom: 13,
        streetViewControl: false,
      },
    )

    var icon = {
      path:
        'M38,36 L32,42 L26,36 L4.00348663,36 C1.79242202,36 0,34.2147544 0,32.0098214 L0,3.99017859 C0,1.7864638 1.79537646,0 4.00348663,0 L59.9965134,0 C62.207578,0 64,1.7852456 64,3.99017859 L64,32.0098214 C64,34.2135362 62.2046235,36 59.9965134,36 L38,36 Z',
      fillColor: '#8100f8',
      fillOpacity: 1,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1,
      size: new google.maps.Size(64, 42),
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(64 / 2, 18),
      anchor: new google.maps.Point(64 / 2, 42),
    }

    this.marker = new google.maps.Marker({
      position: this.props.location,
      map: this.map,
      icon: icon,
      label: { text: 'Bosted', color: 'white' },
    })

    this.shapes = []

    if (_.get(this.props.coverageMap, 'map') != null) {
      const poly = new google.maps.Polygon({
        paths: polygonStringToHash(this.props.coverageMap.map),
        editable: this.props.isMapEditable,
      })

      poly.setMap(this.map)
      this.shapes.push(poly)

      const bounds = getPolygonBounds(poly)

      this.map.fitBounds(bounds)

      if (this.props.isMapEditable) {
        google.maps.event.addListener(
          poly.getPath(),
          'set_at',
          () => {
            this.setState({ polygonString: polygonToString(poly) })
          },
        )

        google.maps.event.addListener(
          poly.getPath(),
          'insert_at',
          () => {
            this.setState({ polygonString: polygonToString(poly) })
          },
        )
      }
    }

    if (this.props.isMapEditable) {
      var drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.HAND,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon'],
        },
        polygonOptions: {
          editable: true,
        },
      })

      drawingManager.setMap(this.map)

      google.maps.event.addListener(
        drawingManager,
        'polygoncomplete',
        (polygon) => {
          this.setState({ polygonString: polygonToString(polygon) })
          this.shapes.forEach((shape) => shape.setMap(null))
          this.shapes.push(polygon)

          google.maps.event.addListener(
            polygon.getPath(),
            'set_at',
            () => {
              this.setState({
                polygonString: polygonToString(polygon),
              })
            },
          )

          google.maps.event.addListener(
            polygon.getPath(),
            'insert_at',
            () => {
              this.setState({
                polygonString: polygonToString(polygon),
              })
            },
          )
        },
      )
    }
  }

  handleNameChange = (name) => this.setState({ name: name })

  handleCategoryChange = (value) => {
    const typeOfChange = this.getTypeOfChange(value)
    switch (typeOfChange) {
      case 'firstMap':
        break
      case 'singleMap':
        break
      case 'regularChange':
        this.setState({ category: value })
        break
      case 'notMainAnymore':
        this.setState({ category: value })
        this.setState({ typeOfModal: 'notMainAnymore' }, () => {
          this.setState({ isModalOpen: true })
        })
        break
      case 'becomeMain':
        this.setState({ category: value })
        this.setState({ typeOfModal: 'becomeMain' }, () => {
          this.setState({ isModalOpen: true })
        })
    }
  }

  getTypeOfChange(value) {
    const initialValue = this.props.coverageMap?.category
    if (!initialValue && this.props.coverageMaps.length == 0) {
      return 'firstMap'
    } else if (
      !!initialValue &&
      this.props.coverageMaps.length == 1
    ) {
      return 'singleMap'
    } else if (initialValue == 'main') {
      return 'notMainAnymore'
    } else if (value == 'main') {
      return 'becomeMain'
    } else {
      return 'regularChange'
    }
  }

  categorySelectorStyle() {
    return {
      width: '100%',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #d4d1d7',
      borderRadius: '6px',
      marginBottom: '1rem',
      display: 'flex',
      justifyContent: 'center',
    }
  }

  possibleMainMaps() {
    return this.props.coverageMaps.filter(
      (map) => map.category != 'main',
    )
  }

  closeModal = () => {
    const initialValue = this.props.coverageMap?.category || 'other'
    this.setState({ category: initialValue })
    this.setState({ isModalOpen: false })
  }

  handleSubmitModal = () => {
    if (this.isSubmitToDelete()) {
      document.forms['delete-coverage-map'].submit()
    } else {
      document.forms['edit-coverage-map'].submit()
    }
  }

  isSubmitToDelete() {
    return (
      this.state.typeOfModal == 'regularDelete' ||
      this.state.typeOfModal == 'mainDelete'
    )
  }

  handleDelete = (e) => {
    e.preventDefault()
    if (
      this.props.coverageMap.category == 'main' &&
      this.possibleMainMaps().length > 0
    ) {
      this.setState({ typeOfModal: 'mainDelete' }, () => {
        this.setState({ isModalOpen: true })
      })
    } else {
      this.setState({ typeOfModal: 'regularDelete' }, () => {
        this.setState({ isModalOpen: true })
      })
    }
  }

  isValid() {
    return (
      this.state.polygonString.length < 1 ||
      this.state.name.length < 1
    )
  }

  render() {
    return (
      <div>
        <div
          id={`coverage-map-${this.props.coverageMap?.id}`}
          style={{ height: '500px' }}
        />
        {this.state.isModalOpen && (
          <CoverageMapEditorModal
            path={this.props.path}
            coverageMaps={this.possibleMainMaps()}
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            handleSubmitModal={this.handleSubmitModal}
            typeOfModal={this.state.typeOfModal}
            isValid={this.isValid()}
            serviceProvider={this.props.serviceProvider}
          />
        )}
        {this.props.isMapEditable && (
          <RailsForm
            ref={(ref) => {
              this.form = ref
            }}
            action={this.props.path}
            method={'post'}
            className="hlform"
            id="edit-coverage-map"
            name="admin_coverage_map"
          >
            <FormField
              id="name-input"
              type="text"
              name="coverage_map[name]"
              label="Navn"
              value={this.state.name}
              onChangeText={this.handleNameChange}
              className="mt-1"
            />

            <SelectHL
              label={'Velg kategori'}
              name="coverage_map[category]"
              value={this.state.category}
              onChange={this.handleCategoryChange}
              options={this.props.categories.map((category) => ({
                label: capitalizeFirstLetter(
                  category.nb.replace(/_/, ' '),
                ),
                value: category.en,
              }))}
            />
            <input
              type="hidden"
              name="coverage_map[map]"
              value={this.state.polygonString}
              className="mt-1"
            />

            <input
              type="hidden"
              name="_method"
              value={this.props.method}
            />

            <SubmitButton
              className="btn btn-primary text-right"
              disabled={this.isValid()}
            >
              Lagre
            </SubmitButton>
          </RailsForm>
        )}
        {this.props.isMapDeletable && (
          <RailsForm
            action={this.props.path}
            method={'delete'}
            className="hlform"
            id="delete-coverage-map"
            name="coverage_map_delete"
          >
            <Button
              label="slett"
              onClick={this.handleDelete}
              className="btn-link"
            />
          </RailsForm>
        )}
      </div>
    )
  }
}

export default CoverageMapEditor
