import React from 'react'
import TextInput from './TextInput'
import { v_testId } from '../../../lib/validations'

const upcaseValue = (value) => value.toUpperCase()

const TestIdInput = (props) => {
  return <TextInput {...props} />
}

TestIdInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'test_id',
  label: 'Test ID',
  maxLength: 8,
  autoComplete: 'off',
  sanitize: upcaseValue,
  validate: v_testId,
}

TestIdInput.propTypes = TextInput.propTypes

export default TestIdInput
