import global from './global'
import nb from './nb'
import en from './en'
import pl from './pl'

import tokenReplacer from '../token-replacer'

export const DEFAULT_LOCALE = 'nb'

const locales = { nb, en, pl }

const t_case = (str, transform) => {
  if (!transform) return str
  switch (transform.toLowerCase()) {
    case 'u': // uppercase
      return str.toUpperCase()
      break

    case 'l': // lowercase
      return str.toLocaleLowerCase()
      break

    case 't': // titlecase
      return str
        .split(' ')
        .map((w) => w[0].toLocaleUpperCase() + w.slice(1))
        .join(' ')
      break

    case 's': // sentence-case
      return (
        str[0].toLocaleUpperCase() + str.slice(1).toLocaleLowerCase()
      )
      break

    default:
      return str
  }
}

const drill = (path, root) =>
  path.split('.').reduce((r, k) => r?.[k], root)

const stripLocale = (path) => path.split('.').slice(1).join('.')

export const translate = (
  prop,
  source = locales,
  transform,
  vars,
) => {
  // eslint-disable-next-line no-param-reassign
  source = source || locales
  // eslint-disable-next-line no-param-reassign
  if (!prop) throw Error('Missing argument "prop"')
  const match =
    drill(prop, source) || drill(stripLocale(prop), global)

  let result
  if (match && vars) {
    result = tokenReplacer(match, vars)
  } else if (!match) {
    result = `*${prop}`
  } else {
    result = match
  }
  return transform ? t_case(result, transform) : result
}

const t = translate

export default t
