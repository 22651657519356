import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../forms/fields/TextInput'

export function ClinicAppointmentListSearchBar({ setSearchQuery }) {
  const handleSearchChange = (value) => {
    setSearchQuery(value.toLowerCase())
  }
  return (
    <div className="hlform mb-3">
      <TextInput
        name="searchQuery"
        placeholder="Telefonnummer, navn eller personnummer"
        label="Søk"
        showValidation={false}
        onChange={handleSearchChange}
      />
    </div>
  )
}

ClinicAppointmentListSearchBar.propTypes = {
  setSearchQuery: PropTypes.func.isRequired,
}
