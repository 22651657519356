import React from 'react'
import PropTypes from 'prop-types'

const VaccineWizardLayout = ({ children, heading, subheading }) => (
  <div className="page__content page__content--narrow">
    <h1 className="text-center mb-4">
      {heading || 'Bestill vaksinering'}
    </h1>
    {!!subheading && (
      <p className="info-box__text--no-margin text-center mb-4">
        {subheading}
      </p>
    )}
    {children}
  </div>
)

VaccineWizardLayout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

export default VaccineWizardLayout
