import React, { useContext } from 'react'
import ClinicContext from './ClinicContext'

export const ErrorHandler = () => {
  const { errors, clearErrors } = useContext(ClinicContext)
  if (errors.length > 0) {
    return (
      <div
        className="flash flash-error"
        style={{ margin: '-25px -25px 1rem -25px' }}
      >
        <h5 className="text-left mb-2">Det oppstod en feil.</h5>
        <p>Vennligst ta et skjermbilde og rapporter det.</p>
        <dl className="d-flex flex-column justify-content-center mb-0">
          {errors.map((error, index) => (
            <dt className="text-left mb-2" key={index}>
              <p> {error}</p>
            </dt>
          ))}
        </dl>
        <div className="close-icon" onClick={clearErrors}></div>
      </div>
    )
  } else {
    return null
  }
}

export default ErrorHandler
