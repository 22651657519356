import React from 'react'
import RouteMap from '../map/RouteMap'
import FilterTab from '../filters/FilterTab'
import ReferralTab from '../tabs/ReferralTab'
import BookingTab from '../tabs/BookingTab'
import RoutesTab from '../tabs/RoutesTab'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { loadGoogleMap } from '../../api/googleMap'
import { fetchDataset } from './state/globalDataActions'
import PropTypes from 'prop-types'

const RouteDashboard = (props) => {
  const dispatch = useDispatch()
  const appDataIsLoaded = useSelector((state) => state.allDataLoaded)

  const initialize = async () => {
    await loadGoogleMap(props.googleMapsApiKey)
    dispatch(fetchDataset())
  }

  useEffect(() => {
    initialize()
  }, [])

  const handleOpenFilter = (e) => {
    const filter = document.querySelector('.filter-tab')
    filter.classList.toggle('expended')
    e.currentTarget.classList.toggle('selected')
  }

  const handleOpenReferral = (e) => {
    const filter = document.querySelector(
      '.item-form__multiselect.for-referral',
    )
    filter.classList.toggle('expended')
    e.currentTarget.classList.toggle('selected')
  }

  const handleOpenBooking = (e) => {
    const filter = document.querySelector(
      '.item-form__multiselect.for-booking',
    )
    filter.classList.toggle('expended')
    e.currentTarget.classList.toggle('selected')
  }

  const handleOpenRoutes = (e) => {
    const filter = document.querySelector('.routes-description')
    filter.classList.toggle('expended')
    e.currentTarget.classList.toggle('selected')
  }

  const renderMap = () => {
    return <RouteMap />
  }

  if (!appDataIsLoaded) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '500px' }}
      >
        <span className="spinner-border text-primary" />
      </div>
    )
  }
  return (
    <div className="route-dashboard-wrapper">
      <div>
        <div className="route-dashboard__nav">
          <div
            className="route-dashboard__nav-item filter"
            onClick={handleOpenFilter}
          >
            <i className="fas fa-filter"></i>
            <div className="route-dashboard__nav-item__title">
              Filters
            </div>
          </div>
          <div
            className="route-dashboard__nav-item for-referral"
            onClick={handleOpenReferral}
          >
            <i className="fas fa-landmark"></i>
            <div className="route-dashboard__nav-item__title">
              Referrals
            </div>
          </div>
          <div
            className="route-dashboard__nav-item for-booking"
            onClick={handleOpenBooking}
          >
            <i className="fas fa-map-marker"></i>
            <div className="route-dashboard__nav-item__title">
              Bookings
            </div>
          </div>
          <div
            className="route-dashboard__nav-item for-route"
            onClick={handleOpenRoutes}
          >
            <i className="fas fa-map-marked-alt"></i>
            <div className="route-dashboard__nav-item__title">
              Routes
            </div>
          </div>
        </div>

        <div className="route-dashboard">
          <FilterTab />
          <ReferralTab />
          <BookingTab />
          <RoutesTab />
          <div style={{ width: '100%', height: '100%' }}>
            {renderMap()}
          </div>
        </div>
      </div>
    </div>
  )
}

RouteDashboard.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
}

export default RouteDashboard
