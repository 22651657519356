import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import HLForm from './forms/HLForm'
import Button from './forms/fields/Button'
import TextButton from './TextButton'
import TestIdInput from './forms/fields/TestIdInput'
import ConfirmPatientState from './ConfirmPatientState'

const WIZARD_STATE_INITIAL = 'initial'
const WIZARD_STATE_ENROLLMENT = 'enrollment'

const BackofficeAddTestWizard = (props) => {
  const [wizardState, setWizardState] = useState(WIZARD_STATE_INITIAL)
  const [testId, setTestId] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [submittable, setSubmittable] = useState(false)
  const [busy, setBusy] = useState(false)
  const [isIndependent] = useState(props.patient.independent)
  const [booking] = useState(props.booking)

  const deleteLabTest = async (id) => {
    try {
      await $.ajax({
        method: 'DELETE',
        url: `${props.labTestPath}/${id}`,
      })
      window.location.reload()
    } catch (e) {
      alert(e.statusText)
    }
  }

  const submitTest = async () => {
    submit(
      props.labTestPath,
      {
        test_id: testId,
      },
      // Redirect back to booking/show after successful test submit
      isIndependent ? WIZARD_STATE_ENROLLMENT : redirectToBookingPath,
    )
  }

  const submitConfirmation = async (patient = props.patient) => {
    submit(
      props.confirmPath,
      {
        patient: patient,
      },
      submitSms,
    )
  }

  const buildSmsPath = (patientId) => {
    return `/backoffice/bookings/${booking.id}/patients/${patientId}/send_sms`
  }

  const redirectToBookingPath = () => {
    window.location = props.bookingPath
  }

  const submitSms = async (response) => {
    submit(
      buildSmsPath(response.preserved_patient_id),
      {
        test_id: testId,
      },
      redirectToBookingPath,
    )
  }

  const submit = async (path, params, nextState) => {
    setBusy(true)
    try {
      const response = await $.post(path, params)
      if (response.success) {
        if (typeof nextState == 'string') setWizardState(nextState)
        else if (typeof nextState == 'function')
          nextState.call(this, response)
      } else {
        setErrorMessage(response.errors.join(', '))
      }
    } catch (e) {
      if (e.responseJSON) {
        setErrorMessage(e.responseJSON.errors.join(', '))
      } else {
        setErrorMessage(e.statusText)
      }
    }
    setBusy(false)
  }

  const handleTestIdChange = (value, valid) => {
    setTestId(value)
    setSubmittable(valid)
  }

  const renderWizardState = () => {
    switch (wizardState) {
      case WIZARD_STATE_INITIAL:
        return renderIntitialWizardState()
        break
      case WIZARD_STATE_ENROLLMENT:
        return renderConfirmPatientWizardState()
        // setSubmittable(PhoneInput.validate(patient.phone_number))
        break
    }
  }

  const renderIntitialWizardState = () => (
    <div className="">
      <h2 className="groupheading">
        Steg 1: Skriv inn Fürst test ID
      </h2>
      <div className="formgroup">
        <div className="formrow d-flex">
          <div className="col-8">
            <TestIdInput
              onChange={handleTestIdChange}
              className="large-input b-right"
              disabled={busy}
            ></TestIdInput>
          </div>
          <div className="col-4">
            <Button
              label="Legg til"
              onClick={submitTest}
              className="btn-primary btn-block btn-full-height collapse-left"
              disabled={!submittable || busy}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  )

  const renderConfirmPatientWizardState = () => (
    <>
      <input type="hidden" name="merge_patients" value={true} />
      <h2 className="groupheading">
        Steg 2: Bekreft navn og telefonnummer
      </h2>
      <ConfirmPatientState
        hasDuplicatePatients={props.hasDuplicatePatients}
        patient={props.patient}
        busy={busy}
        submit={submitConfirmation}
        cancel={() => window.location.reload()}
      ></ConfirmPatientState>
    </>
  )

  return (
    <HLForm
      preventSubmit={true}
      action={props.confirmPath}
      method="patch"
    >
      {renderWizardState()}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      {props.labTests && (
        <>
          <h2 className="groupheading">Registrerte tester</h2>
          {props.labTests.map((test) => (
            <div key={test.test_id} className="row my-2">
              <div className="col-6">ID: {test.test_id}</div>
              <div className="col-3">
                Dato: {moment(test.created_at).format('DD.MM.YY')}
              </div>
              <div className="col-3 text-right">
                <TextButton
                  className="btn-danger"
                  label="Slett"
                  onClick={() => deleteLabTest(test.test_id)}
                />
              </div>
            </div>
          ))}
          {!props.labTests.length && <p>Ingen tester registrert</p>}
        </>
      )}
    </HLForm>
  )
}

BackofficeAddTestWizard.propTypes = {
  confirmPath: PropTypes.string.isRequired,
  labTestPath: PropTypes.string.isRequired,
  bookingPath: PropTypes.string.isRequired,
  labTests: PropTypes.array,
  booking: PropTypes.object.isRequired,
  hasDuplicatePatients: PropTypes.bool.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    phone_number_old: PropTypes.string,
    national_id: PropTypes.string,
    independent: PropTypes.bool.isRequired,
  }),
}

export default BackofficeAddTestWizard
