import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LineItem from './LineItem'

const SingleItemCart = ({
  quantityName,
  productIdName,
  product,
  quantity,
}) => {
  const lineItem = {
    id: product.id,
    quantity: quantity || product.min_per_order,
    total: product.price * product.min_per_order,
    price: product.price,
    name: product.name,
    product,
  }

  const [lineTotal, setLineTotal] = useState(lineItem)

  const handleLineTotalChange = (_, total) => {
    setLineTotal({ ...lineItem, total })
  }

  return (
    <div className="cart-form">
      <div className="row">
        <div className="col-5 small" />
        <div className="col-3 small">Stykkpris</div>
        <div className="col-2 small">Antall</div>
        <div className="col-2 small text-right">Total</div>
      </div>
      <LineItem
        quantityName={quantityName}
        productIdName={productIdName}
        onLineTotalChange={handleLineTotalChange}
        showDelete={false}
        {...lineItem}
      />
      <div className="row sum">
        <div className="col-10">Sum</div>
        <div className="col-2 text-right">{lineTotal.total}</div>
      </div>
    </div>
  )
}

SingleItemCart.propTypes = {
  name: PropTypes.string,
  productIdName: PropTypes.string,
  quantityName: PropTypes.string,
  product: PropTypes.object.isRequired,
}

SingleItemCart.defaultProps = {
  productIdName: 'line_item[product_id]',
  quantityName: 'line_item[quantity]',
  product: {},
}

export default SingleItemCart
