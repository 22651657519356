import postalCodes from '../../../lib/housecall/postal_codes/postal_codes.json'

const NAME_EXP = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\\<>?:;|=,]{3,200}$/
const STREET_ADDRESS_EXP = /[a-z]{3,}.*[0-9]*/i
const CITY_EXP = /[a-zæøå]+/i
const COMPANY_EXP = /\w{2,}/
const NATIONAL_ID_EXP = /^[0-9]{11}$/
const ORG_NR_EXP = /^([0-9]{4}:)?([0-9]{9})$/
const OTP_EXP = /^\d{4}$/
const PHONE_EXP = /^\+\d{6,}$/
const TEST_ID_EXP = /^[0-9A-Z]{8}$/
const ZIP_EXP = /\d{4}/
const EMAIL_EXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const DATE_TIME_EXP = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])T([0-1][0-9]|[2][0-3]):([0-5][0-9])$/
const PHONE_NUMBER_EXP = /^\d{6,}$/
const NUMBER_EXP = /^\d+$/
const SENTECE_EXP = /[\w]{2,}\s[\w]{2,}/

const v_streetAddress = (value) => STREET_ADDRESS_EXP.test(value)
const v_city = (value) => CITY_EXP.test(value)
const v_company = (value) => COMPANY_EXP.test(value)
const v_nationalId = (value) => NATIONAL_ID_EXP.test(value)
const v_email = (value) => EMAIL_EXP.test(value)
const v_name = (value) => NAME_EXP.test(value)
const v_orgNr = (value) => ORG_NR_EXP.test(value)
const v_otp = (value) => OTP_EXP.test(value)
const v_phone = (value) => PHONE_EXP.test(value)
const v_testId = (value) => TEST_ID_EXP.test(value)
const v_zip = (value) => ZIP_EXP.test(value)
const v_id = (value) => parseInt(value, 10) > 0
const v_ids_uniq = (values) => {
  ;[...new Set(values)] === values &&
    values
      .map((value) => v_id(value))
      .every((value) => value === true)
}
const v_date_time = (value) => DATE_TIME_EXP.test(value)
const v_phoneNumber = (value) => PHONE_NUMBER_EXP.test(value)
const v_is_number = (value) => NUMBER_EXP.test(value)
const v_is_non_zero_number =(value)=> v_is_number(value) && value >= 1
const v_sentence = (value) => SENTECE_EXP.test(value)
const v_nonEmpty = (value) => value?.toString().length > 0

const v_fullGoogleAddress = (address) => {
  if (address.formatted) {
    return [
      address.zip && v_zip(address.zip),
      address.street && v_streetAddress(address.formatted),
      address.city && v_city(address.city),
      !!address.number,
    ].every(Boolean)
  } else {
    v_streetAddress(address)
  }
}

const v_partialGoogleAddress = (address) => {
  if (address.formatted) {
    return address.formatted && v_streetAddress(address.formatted)
  } else {
    return v_streetAddress(address)
  }
}

function ValidateZip(zip) {
  return !!postalCodes[zip]
}

export {
  v_ids_uniq,
  v_id,
  v_streetAddress,
  v_city,
  v_company,
  v_nationalId,
  v_email,
  v_name,
  v_orgNr,
  v_otp,
  v_phone,
  v_testId,
  v_zip,
  NAME_EXP,
  EMAIL_EXP,
  STREET_ADDRESS_EXP,
  CITY_EXP,
  COMPANY_EXP,
  NATIONAL_ID_EXP,
  PHONE_EXP,
  TEST_ID_EXP,
  ZIP_EXP,
  v_date_time,
  v_phoneNumber,
  v_fullGoogleAddress,
  v_partialGoogleAddress,
  ValidateZip,
  v_is_number,
  v_sentence,
  v_nonEmpty,
  v_is_non_zero_number,
}
