import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FormProgress from '../forms/FormProgress'
import HLForm from '../forms/HLForm'

import SubmitButton from '../forms/fields/SubmitButton'
import Layout from './shared/Layout'
import PickedTimeBox from './shared/PickedTimeBox'
import VaccOrganizationInputs from './VaccOrganizationInputs'

const VaccineWizardOrderDetails = ({
  steps,
  currentStep,
  path,
  booking,
  googleMapsApiKey,
  organizationAddress,
  organizationOrgNumber,
}) => {
  const [highlightErrors, setHighLightErrors] = useState(false)
  const [organizationInfo, setOrganizationInfo] = useState({
    'referral[organization]': booking?.organization || '',
    'referral[related_organization][org_nr]':
      organizationOrgNumber || '',
    'referral[organization_address][address]':
      organizationAddress?.address || '',
    'referral[organization_address][zip]':
      organizationAddress?.zip || '',
    'referral[organization_address][city]':
      organizationAddress?.city || '',
    'referral[billing_method]': booking?.billing_method || 'email',
    'referral[billing_email]': booking?.billing_email || '',
    'referral[billing_reference]': booking?.billing_reference || '',
  })
  const [contactPersonInfo, setcontactPersonInfo] = useState({
    'referral[contact_person_order][phone_number]':
      booking?.contact_person_order?.phone_number || '',
    'referral[contact_person_order][name]':
      booking?.contact_person_order?.name || '',
    'referral[contact_person_order][email]':
      booking?.contact_person_order?.email || '',
  })
  const [isFormValid, setIsFormValid] = useState(false)

  const handleElementInfosChange = (
    _element,
    _typeElement,
    isFormValid,
  ) => {
    setIsFormValid(isFormValid)
  }

  const submitForm = (e) => {
    if (!isFormValid) {
      e.preventDefault()
      setHighLightErrors(true)
    }
  }

  const showErrorMessage = highlightErrors && !isFormValid

  return (
    <Layout heading="Ordreinformasjon">
      <FormProgress steps={steps} currentStep={currentStep} />
      <PickedTimeBox
        timeStart={booking.begins_at}
        timeEnd={booking.ends_at}
        address={`i ${booking.address}, ${booking.zip} ${booking.city}`}
      />
      <HLForm
        onSubmit={submitForm}
        action={path}
        method="put"
        name="vaccine_wizard_organization"
      >
        <h2 className="groupheading">Detaljer om bedriften</h2>

        <VaccOrganizationInputs
          googleMapsApiKey={googleMapsApiKey}
          highlightErrors={highlightErrors}
          organization={organizationInfo}
          contactPerson={contactPersonInfo}
          onChange={handleElementInfosChange}
          hasVaccineText={true}
        />

        {showErrorMessage && (
          <div className="error-message">
            Vennligst gjennomgå feilene i skjemaet. <br />
            Se de røde feltene over for å rette opp.
          </div>
        )}

        <SubmitButton
          label="Neste"
          onClick={submitForm}
          className="btn-100"
        />
      </HLForm>
    </Layout>
  )
}

VaccineWizardOrderDetails.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  booking: PropTypes.object.isRequired,
  organization: PropTypes.object,
  contactPerson: PropTypes.object,
  organizationAddress: PropTypes.object,
  googleMapsApiKey: PropTypes.string.isRequired,
  organizationAddress: PropTypes.object || null,
  organizationOrgNumber: PropTypes.string || null,
}

VaccineWizardOrderDetails.defaultProps = {
  steps: [],
  currentStep: '',
  path: '',
  booking: {},
  organization: { billingDueBy: 30, billingMethod: 'email' },
  contactPerson: {},
}

export default VaccineWizardOrderDetails
