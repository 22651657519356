import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-modal'

import ExclamationMark from '../../assets/images/purple_exclamation_mark.png'
import { v_streetAddress } from '../lib/validations'
import PhoneInput from './forms/fields/PhoneInput'
import PhoneValidationModal from './PhoneValidationModal'
import RailsForm from './RailsForm'
import Select from './Select'
import Textarea from './Textarea'
import EmailInput from './user_data_form/EmailInput'
import MarketingConsent from './user_data_form/MarketingConsent'
import NameInput from './user_data_form/NameInput'
import UserDataButton from './user_data_form/UserDataButton'

const modalStyle = {
  content: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

class UserDataForm extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    booking: PropTypes.object.isRequired,
    auxTermsActive: PropTypes.bool,
    auxTermsText: PropTypes.string,
    price: PropTypes.number,
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      nameInputValid: false,
      secondaryNameInputValid: false,
      emailInputValid: false,
      phoneInputValid: false,
      locationInputValid: false,
      numberOfPatientsValid: false,
      twoInOneBooking: false,
      discountedPrice: null,
      phoneNumber: '',
      marketingConsent: props.booking.user.marketing_consent,
      patientJobDescription:
        props.booking.patient_job_description || '',
      addressDirections: props.booking.address_directions || '',
      showError: false,
      infoNotFound: false,
      patientJobDescriptionValid: false,
      auxTermsValid: !props.auxTermsActive,
      addressInputValid: v_streetAddress(props.booking.address),
      address: this.props.booking.address,
      showAttentionMessage: false,
      containsVaccine: false,
      modalIsOpen: false,
      countModalIsOpen: 0,
    }
    this.scrollRef = React.createRef()
  }

  submitForm = () => {
    document.dispatchEvent(new Event('safe-unload-pending')) // Let countdown know not to release booking on upcoming unload event
    this.form.submit()
  }

  formIsValid = () => {
    const inputsValid = _.every([
      this.state.nameInputValid,
      this.state.addressInputValid,
      this.state.phoneInputValid,
      this.state.emailInputValid,
      this.state.numberOfPatientsValid,
      this.state.patientJobDescriptionValid,
      this.state.auxTermsValid,
    ])

    if (this.state.twoInOneBooking) {
      if (inputsValid && this.state.secondaryNameInputValid) {
        return true
      }

      return false
    }

    return inputsValid
  }

  directSubmitOrOtp = () => {
    const signedInPhoneNumber = this.props.booking['user'][
      'phone_number'
    ]
    if (
      this.props.isSignedIn &&
      signedInPhoneNumber == this.state.phoneNumber
    ) {
      this.submitForm()
    } else {
      this.setState({ showModal: true })
    }
  }

  checkForVaccine = () => {
    return this.props.booking.products.some(
      (p) => p.slug === 'hcl-vac-flu-pr',
    )
  }

  showErrorDescription() {
    if (this.state.showError && !this.formIsValid()) {
      return 'Obs: Fyll ut alle nødvendige felter først!'
    }
  }

  handleSecondaryNameChange = (text, valid) =>
    this.setState({ name: text, secondaryNameInputValid: valid })

  renderSecondaryNameInput() {
    if (this.state.twoInOneBooking) {
      return (
        <div className="col-12 mb-1">
          <div className="booking-form__input-group-heading">
            Navn på andre person
          </div>
          <div className="booking-form__input-group">
            <div className="form-group">
              <NameInput
                onChangeText={this.handleSecondaryNameChange}
                isValid={this.state.secondaryNameInputValid}
                showError={this.state.showError}
                name="booking[secondary_patient_name]"
              />
            </div>
          </div>
        </div>
      )
    }
  }

  handleAuxBookingTermsChange = (e) =>
    this.setState({ auxTermsValid: e.target.checked })

  renderAuxBookingTerms() {
    if (!this.props.auxTermsActive) return null
    return (
      <div className="col-12 row">
        <h2 className="h4 my-4">Spesielle betingelser</h2>
        <div className="booking-form__input-group booking-form__main-group">
          <label className="booking-form__label">
            <div className="booking-form__checkbox-wrapper">
              <input
                type="checkbox"
                name="auxTerms"
                defaultChecked={false}
                onChange={this.handleAuxBookingTermsChange}
              />
            </div>
            <div className="booking-form__checkbox-text">
              {this.props.auxTermsText}
            </div>
          </label>
        </div>
      </div>
    )
  }

  handlePhoneChange = (value, valid) =>
    this.setState({
      phoneNumber: value,
      phoneInputValid: valid,
    })

  handleNameChange = (text, valid) =>
    this.setState({ nameInputValid: valid })

  handleNameRef = (ref) => (this.nameInput = ref)

  handleEmailChange = (text, valid) =>
    this.setState({ emailInputValid: valid })

  handleEmailRef = (ref) => (this.emailInput = ref)

  handleTwoInOneChange = (value) => {
    if (value == 0) {
      this.setState({ numberOfPatientsValid: false })
    } else {
      this.setState({ numberOfPatientsValid: true })
    }

    this.setState({
      twoInOneBooking: value == 'true',
    })
  }

  observeVisibility = () => {
    const observer = new IntersectionObserver((entries, _) => {
      const entry = entries[0]
      if (entry.isIntersecting && this.state.countModalIsOpen <= 1) {
        this.setState((prev, props) => ({
          modalIsOpen: entry.isIntersecting,
          countModalIsOpen: prev.countModalIsOpen + 1,
        }))
      }
    })
    return observer
  }

  componentDidUpdate = () => {
    const hasVacc = this.checkForVaccine()
    if (hasVacc) {
      const observer = this.observeVisibility()
      observer.observe(this.scrollRef.current)
    }
  }

  componentWillUnmount = () => {
    const observer = this.observeVisibility()
    observer.unobserve(this.scrollRef.current)
  }

  validateNumberOfPatients = () =>
    this.state.showError && !this.state.numberOfPatientsValid

  handleJobDescriptionChange = (text, valid) =>
    this.setState({
      patientJobDescription: text,
      patientJobDescriptionValid: valid,
    })

  handleAddressDirectionsChange = (text) =>
    this.setState({ addressDirections: text })

  disabledOnSubmit = () => this.setState({ showError: true })

  closeModal = () => this.setState({ showModal: false })

  optionMultipleCustomer = this.checkForVaccine()
    ? {
        value: true,
        text: '2-5 personer - Skriv antall i feltet under!',
      }
    : { value: true, text: '2 personer' }

  render() {
    Modal.setAppElement('body')
    return (
      <>
        <Modal
          style={modalStyle}
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => {
            this.setState({
              modalIsOpen: false,
            })
          }}
        >
          <img
            src={ExclamationMark}
            className="booking-form__modal-exclamation-mark"
          ></img>
          <div>
            <h6>Er dere 2-5 personer som skal vaksineres?</h6>
            <ul>
              <li>
                Husk å skrive korrekt antall personer i tekstboksen
                &quot;Har du mer info til oss?&quot;
              </li>
              <li>F.eks. &quot;3 personer skal vaksineres&quot;</li>
            </ul>
          </div>
          <div
            onClick={() =>
              this.setState({
                modalIsOpen: false,
              })
            }
            className="booking-form__modal-close-icon "
          >
            ⓧ
          </div>
        </Modal>
        <RailsForm
          ref={(ref) => {
            this.form = ref
          }}
          method="put"
          onKeyUp={this.reserveBookingIfActivity}
          action={this.props.path}
          className="hlform"
          name="housecall_user"
        >
          <div className="row">
            <div className="col-12">
              <h2 className="h4 my-4">Ditt telefonnummer</h2>
              <div className="row">
                <div className="booking-form__input-group col-sm-12 booking-form__phone-wrapper">
                  <aside role="alert" className="attention-box mb-2">
                    Finn frem telefonen din! Du vil motta en kode på
                    SMS til dette nummeret som du trenger for å
                    bekrefte bestillingen før neste steg.
                  </aside>
                  <PhoneInput
                    onChange={this.handlePhoneChange}
                    value={this.props.booking.user.phone_number || ''}
                    name="booking[user_attributes][phone_number]"
                    shadow={false}
                    isRequired
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="booking-form__input-group-heading">
                <h2 className="h4 my-4">
                  Navn og E-post på deg som bestiller
                </h2>
                <p className="small mb-0">
                  <span className="error-message">
                    Bestiller du for noen andre?
                  </span>{' '}
                  Skriv navn og eventuelt telefonnummer på
                  pasienten(e) i feltet “Hva gjelder henvendelsen“
                </p>
              </div>
              <input
                type="hidden"
                name="booking[city]"
                defaultValue={this.props.booking.city}
              />
              <input
                type="hidden"
                name="booking[zip]"
                defaultValue={this.props.booking.zip}
              />
              <input
                type="hidden"
                name="booking[address]"
                defaultValue={this.props.booking.address}
              />
              <div className="booking-form__input-group my-2">
                <NameInput
                  onChangeText={this.handleNameChange}
                  initialValue={this.props.booking.user.name}
                  isValid={this.state.nameInputValid}
                  showError={this.state.showError}
                  onRef={this.handleNameRef}
                />
                <EmailInput
                  onChangeText={this.handleEmailChange}
                  initialValue={this.props.booking.user.email}
                  isValid={this.state.emailInputValid}
                  showError={this.state.showError}
                  onRef={this.handleEmailRef}
                />
              </div>
            </div>

            <div className="col-12 mt-1">
              <h2 className="h4 my-4">
                {this.checkForVaccine()
                  ? 'Hvor mange skal vaksineres'
                  : 'Antall pasienter'}
              </h2>
              <p className="booking-form__input-description">
                {this.checkForVaccine()
                  ? 'Velg antall personer som skal vaksineres. Er dere 2-5 personer - skriv konkret antall i tekstboksen under.'
                  : 'Maksimalt 2 personer i samme husstand per bestilling. Medfører pristillegg.'}
              </p>
              <div className="booking-form__input-group">
                <div
                  className="form-group"
                  style={{ marginBottom: '10px' }}
                >
                  <Select
                    options={[
                      { value: 0, text: 'Velg antall' },
                      { value: false, text: '1 person' },
                      this.optionMultipleCustomer,
                    ]}
                    className="form-control"
                    onChange={this.handleTwoInOneChange}
                    name="booking[two_in_one]"
                    showError={this.validateNumberOfPatients}
                  />
                </div>
              </div>
            </div>

            {this.renderSecondaryNameInput()}

            <div className="col-12">
              <h2 className="h4 my-4">
                Hva gjelder henvendelsen?{' '}
                {this.checkForVaccine() ? (
                  <img
                    onClick={() =>
                      this.setState({
                        modalIsOpen: true,
                      })
                    }
                    src={ExclamationMark}
                    className="booking-form__info-icon"
                  ></img>
                ) : null}
              </h2>
              {this.checkForVaccine() && (
                <p className="booking-form__input-description">
                  Er dere flere enn 2 som skal vaksineres, skriv
                  antall personer her.Skriv også gjerne annen relevant
                  informasjon til oss.
                </p>
              )}
              <Textarea
                name="booking[patient_job_description]"
                className="form-control"
                placeholder={
                  this.checkForVaccine()
                    ? 'Skriv antall personer eller relevant informasjon her.'
                    : 'Kort om hva du trenger hjelp med. Unngå sensitiv informasjon. Bestiller du for andre, skriv pasientens navn og eventuelt telefonnummer her.'
                }
                value={this.state.patientJobDescription}
                onChangeText={this.handleJobDescriptionChange}
                isValid={this.state.patientJobDescriptionValid}
                showError={this.state.showError}
                minLength={5}
              />
            </div>

            <div className="col-12" ref={this.scrollRef}>
              <h2 className="h4 my-4">Veibeskrivelse og parkering</h2>
              <Textarea
                name="booking[address_directions]"
                className="form-control"
                placeholder="F.eks. 2. etasje i rødt hus, står Hansen på ringeklokke. Gjesteparkering utenfor port"
                value={this.state.addressDirections}
                onChangeText={this.handleAddressDirectionsChange}
              />
            </div>

            <div className="col-12">
              <p className="booking-form__input-description booking-form__input-description--error">
                {this.showErrorDescription()}
              </p>
            </div>
          </div>

          {this.renderAuxBookingTerms()}

          <MarketingConsent checked={this.state.marketingConsent} />

          <div className="row">
            <div className="col-12 text-md-center">
              <UserDataButton
                isDisabled={!this.formIsValid()}
                price={this.props.price}
                discountedPrice={this.state.discountedPrice}
                onSubmit={this.directSubmitOrOtp}
                disabledOnSubmit={this.disabledOnSubmit}
              />
              <PhoneValidationModal
                isOpen={this.state.showModal}
                close={this.closeModal}
                submit={this.submitForm}
                phoneNumber={this.state.phoneNumber}
                authenticityToken={this.props.authenticityToken}
                loginMode={true}
                buttonLabel="Gå videre"
              />
            </div>
          </div>
        </RailsForm>
      </>
    )
  }
}

export default UserDataForm
