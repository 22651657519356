import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import RailsForm from './RailsForm'

class TaskAndMessageModal extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    resourceName: PropTypes.string.isRequired,
    deadline: PropTypes.string,
    personalizedMessage: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isEnabled: props.enabled,
      isOpen: false,
      messagingDisabledIsOpen: false,
      confirmationIsOpen: false,
    }
    ReactModal.setAppElement('.page__content')
  }

  toggleModal = (e) => {
    e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  toggleConfirmation = (e) => {
    e.preventDefault()
    this.setState({
      confirmationIsOpen: !this.state.confirmationIsOpen,
    })
  }

  toggleMessagingDeactivated = (e) => {
    e.preventDefault()
    this.setState({
      messagingDisabledIsOpen: !this.state.messagingDisabledIsOpen,
    })
  }

  renderDeadlineInfo = () => {
    if (this.props.type === 'Task') {
      return (
        <p className="small-text">(Utløper: {this.props.deadline})</p>
      )
    }
  }

  renderMessagingDeactivated = () => {
    if (this.props.type === 'Task') {
      return (
        <div>
          <h1 className="backoffice__header">Du må vente litt</h1>
          <p>
            Du venter på svar for en annen melding. Du kan ikke sende
            flere meldinger før denne er besvart.
          </p>
        </div>
      )
    }
  }

  renderReplyInfo = () => {
    if (this.props.type === 'Task') {
      return (
        <p>
          {this.props.resourceName} har fått beskjeden din og vil
          svare innen 24 timer.
        </p>
      )
    }
  }

  formatNameForButton = () => {
    const name = this.props.resourceName
    if (name.length < 12) return name

    const nameParts = name.split(' ')
    return nameParts
      .map((part, i, parts) =>
        i < parts.length - 1 ? `${part.charAt(0)}.` : ` ${part}`,
      )
      .join('')
  }

  submitForm = (e) => {
    e.preventDefault()
    this.form.submit()
    this.setState({
      confirmationIsOpen: true,
      isOpen: false,
      isEnabled: false,
    })
  }

  render() {
    return (
      <div id="patientMessage">
        <button
          className="btn btn-outline-primary mr-2 btn-block"
          onClick={
            this.state.isEnabled
              ? this.toggleModal
              : this.toggleMessagingDeactivated
          }
        >
          Send beskjed til {this.formatNameForButton()}
        </button>

        <ReactModal
          isOpen={this.state.isOpen}
          onRequestClose={this.toggleModal}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
        >
          <div
            className="ReactModal__exit-button-new"
            onClick={this.toggleModal}
          />

          <h1 className="backoffice__header">
            Send beskjed til {this.props.resourceName}
          </h1>

          {this.renderDeadlineInfo()}
          <p>{this.props.personalizedMessage}</p>
          <RailsForm
            ref={(ref) => {
              this.form = ref
            }}
            onSubmit={this.submitForm}
            action={this.props.path}
            method="post"
            className="text-center d-flex"
            name="task"
          >
            {this.props.type === 'Task' ? (
              <select
                name="task[title]"
                className="form-control mr-2"
              >
                {this.props.options.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            ) : (
              <select
                name="message[selector_name]"
                className="form-control mr-2"
              >
                {this.props.options.map((x, y) => (
                  <option key={y} value={x.selector_name}>
                    {x.selector_name}
                  </option>
                ))}
              </select>
            )}
            <button
              type="submit"
              className="btn btn-outline-primary mr-2"
              onClick={this.submitForm}
            >
              Send
            </button>
          </RailsForm>
        </ReactModal>

        <ReactModal
          isOpen={this.state.confirmationIsOpen}
          onRequestClose={this.toggleConfirmation}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
        >
          <div
            className="ReactModal__exit-button-new"
            onClick={this.toggleConfirmation}
          />
          <h1 className="backoffice__header">Beskjed sendt!</h1>
          {this.renderReplyInfo()}
        </ReactModal>

        <ReactModal
          isOpen={this.state.messagingDisabledIsOpen}
          onRequestClose={this.toggleMessagingDeactivated}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
        >
          <div
            className="ReactModal__exit-button-new"
            onClick={this.toggleMessagingDeactivated}
          />
          {this.renderMessagingDeactivated()}
        </ReactModal>
      </div>
    )
  }
}

export default TaskAndMessageModal
