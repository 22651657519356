import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment, { duration } from 'moment-timezone'
import NextBooking from './NextBooking'
import Sparkles from '../Sparkles'
import Skeleton from '../Skeleton'

const time = (stamp) => moment(stamp).format('HH:mm')

const VaccinationStatus = ({
  booking,
  patients,
  isLoadingPatients,
}) => {
  const pax = useCallback(
    () =>
      patients.filter((p) => p.productAdministeredAt !== null).length,
    [patients],
  )

  const [currentTime, setCurrentTime] = useState(moment())
  const [patientStatus, setPatientStatus] = useState({
    epax: patients.length,
    pax: pax(),
    remaining: patients.length - pax(),
  })

  const beginsAt = moment(booking.begins_at)
  const endsAt = moment(booking.ends_at)
  const isOngoing =
    beginsAt.isBefore(currentTime) && endsAt.isAfter(currentTime)
  const observationTime = duration(
    booking.non_bookable_time,
    'minutes',
  )

  const observationTimeFactor =
    observationTime / endsAt.diff(beginsAt)

  const paxProgression = Math.max(
    Math.min(patientStatus.pax / patientStatus.epax, 1),
    0,
  )

  const timeRemaining = duration(
    Math.max(endsAt - observationTime - currentTime, 0),
  )

  const timePerRemainingPax = moment.utc(
    timeRemaining.asMilliseconds() / patientStatus.remaining,
  )

  const remainingObservationTime = moment.utc(
    duration(Math.max(endsAt.diff(currentTime)), 0).asMilliseconds(),
  )

  const timeProgression = beginsAt.isAfter(currentTime)
    ? 0
    : endsAt.isBefore(currentTime)
    ? 1
    : Math.max(
        0,
        Math.min(
          1,
          currentTime.diff(beginsAt) / endsAt.diff(beginsAt),
        ),
      )

  const timeBarTipLabel = () => {
    if (timeRemaining > 0) {
      if (patientStatus.remaining === 0) return null
      return `${timePerRemainingPax.format('mm:ss')} igjen per pas.`
    } else {
      return `${remainingObservationTime.format(
        'mm:ss',
      )} igjen av observasjon`
    }
  }

  useEffect(() => {
    const tick = setInterval(() => {
      setCurrentTime(moment())
      setPatientStatus({
        epax: patients.length,
        pax: pax(),
        remaining: patients.length - pax(),
      })
    }, 1000)
    return () => clearInterval(tick)
  })

  if (isOngoing && isLoadingPatients) {
    return (
      <Skeleton
        numOfRows={1}
        shapeOfRows="rectangular"
        rowsColor="var(--secondary)"
      />
    )
  }

  return (
    <div className="vaccination-status mb-5">
      <div className="row">
        <div className="col-8 pr-0">
          <div className="d-flex space-between justify-content-between">
            <div className="time begins-at ">
              {time(booking.begins_at)}
            </div>
            <div className="time ends-at text-right">
              {time(booking.ends_at)}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-8 pr-0">
          <div className="progress-container">
            {isOngoing &&
              patientStatus.remaining === 0 &&
              patientStatus.epax > 0 && (
                <Sparkles
                  minNumberOfSparkles={15}
                  maxNumberOfSparkles={25}
                  minSizeOfSparkle={10}
                  maxSizeOfSparkle={20}
                  stopAfterSeconds={5}
                />
              )}
            <div className="bar-wrapper">
              {isOngoing && (
                <div className="bar-clamper">
                  <div
                    className={`bar top ${
                      timePerRemainingPax.minutes() < 2
                        ? 'bad'
                        : 'good'
                    }`}
                    style={{
                      width: `${paxProgression * 100}%`,
                    }}
                  >
                    <div className="bar-label">Vaksinerte</div>
                    {paxProgression > 0.2 && (
                      <div className="bar-label tip">
                        {patientStatus.pax}
                      </div>
                    )}
                  </div>
                  <div
                    className="bar bottom"
                    style={{
                      width: `${timeProgression * 100}%`,
                    }}
                  >
                    <div className="bar-label">Tid</div>
                    {timeProgression > 0.2 && (
                      <div className="bar-label tip">
                        {time(currentTime)}
                      </div>
                    )}
                    <div className="bar-tip-marker">
                      {timeBarTipLabel()}
                    </div>
                  </div>
                </div>
              )}
              {isOngoing && (
                <div
                  className="observation"
                  style={{
                    width: `calc(${Math.round(
                      observationTimeFactor * 100,
                    )}% - 8px)`,
                  }}
                >
                  {booking.non_bookable_time}min.
                </div>
              )}
              {!isOngoing && (
                <p className="no-content">Ikke pågående oppdrag</p>
              )}
            </div>
          </div>
        </div>
        <NextBooking
          currentBookingLatitude={booking.lat}
          currentBookingLongitude={booking.lng}
        />
      </div>
    </div>
  )
}

VaccinationStatus.propTypes = {
  booking: PropTypes.shape({
    begins_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    non_bookable_time: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  nextBooking: PropTypes.shape({
    id: PropTypes.number.isRequired,
    begins_at: PropTypes.string.isRequired,
    ends_at: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }),
  patients: PropTypes.array,
  isLoadingPatients: PropTypes.bool,
}

export default VaccinationStatus
