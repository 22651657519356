export const get = async (path) => {
  try {
    const response = await $.ajax({
      dataType: 'json',
      type: 'GET',
      url: path,
    })

    return response
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const put = (path, data = null) => {
  return post(path, data, false, 'PUT')
}

export const patch = (path, data = null, serializeParams = false) => {
  return post(path, data, false, 'PATCH', serializeParams)
}

export const post = async (
  path,
  data = null,
  followRedirect = true,
  method = 'POST',
  serializeParams = false,
) => {
  const url_content = {
    dataType: 'json',
    type: method,
    url: path,
    data,
  }
  if (serializeParams) {
    url_content['contentType'] = 'application/json'
  }
  try {
    const response = await $.ajax(url_content)

    if (response.success && followRedirect && response.redirect) {
      window.location = response.redirect
    }

    return response
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const deleteRecord = async (
  path,
  data = null,
  followRedirect = true,
) => {
  try {
    const response = await $.ajax({
      dataType: 'json',
      type: 'DELETE',
      url: path,
    })

    if (response.success && followRedirect && response.redirect) {
      window.location = response.redirect
    }

    return response
  } catch (err) {
    return buildErrorResponse(err)
  }
}

const buildErrorResponse = (err) => ({
  success: false,
  err: {
    status: err.status,
    message:
      err.message ||
      err.responseJSON?.error ||
      `Ukjent feil (${err.status})`,
  },
})
