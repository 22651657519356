import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import gqlCall from '../../api/graphql'
import { buildGraphQLQuery, defaultGraphQLSerializer, parseQueryResponse } from '../../api/graphqlExtended'
import { DEFAULT_LOCALE, translate } from '../../lib/locale/'
import DateTimeInput from '../forms/fields/DateTimeInput.jsx'
import IntegerInput from '../forms/fields/IntegerInput'
import SelectHL from '../forms/fields/SelectHL'
import Submit from '../forms/fields/SubmitButton'
import TextInput from '../forms/fields/TextInput'
import HLForm from '../forms/HLForm'
import RichTextEditor from '../RichTextEditor'
import SwitcherToggle from '../SwitcherToggle'
import TypeaheadSearch from '../TypeaheadSearch'
import PriceModelTypeaheadList from './PriceModelTypeaheadList'
import Layout from './shared/Layout'
import { renderTier } from './utils/tiers'

const DealCreator = ({
  dealsPath,
  orgPath,
  couponPath,
  productPath,
  priceModelPath,
  dealProp,
  priceModels,
  isCurrentDefaultDeal = false,
  invoicing_method_list,
  payment_types_list,
}) => {
  const locale = DEFAULT_LOCALE
  const [error, setError] = useState(null)
  const [deal, setDeal] = useState({
    paymentType: 'CARD',
    invoicingMethod: 'EMAIL',
  })
  const cloningModeDefault = () => isCurrentDefaultDeal ? true : false

  const [cloningMode, setCloningMode] = useState(cloningModeDefault())

  useEffect(() => {
    dealProp.id ? graphqlSearch() : ''
  }, [dealProp.id, graphqlSearch])

  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  const redirectToDeals = () => (window.location = dealsPath)


  const graphqlSearch = useCallback(async () => {
    setError(null)
    try {
      const response = await gqlCall(
        buildGraphQLQuery({
          searchOptions: { id: dealProp.id },
          resourceName: 'deal',
          multiple: false,
        }),
      )
      const parsedResponse = parseQueryResponse({
        response: response,
        resourceName: 'deal',
        multiple: false,
      })
      setDeal(parsedResponse)
    } catch (e) {
      setError(e.toString())
    }
  }, [dealProp.id])

  const graphqlMutation = async (params) => {
    let action
    if (deal.id && !cloningMode) {
      action = 'updateDeal'
    } else {
      action = 'createDeal'
      delete params.id
    }
    try {
      await gqlCall(
        buildGraphQLQuery({
          type: 'mutation',
          resourceName: action,
          params: params,
        }),
      )
      redirectToDeals()
    } catch (e) {
      setError(e.toString())
    }
  }

  const billingEmailValidation = () => {
    if (deal.paymentType !== 'INVOICE') return true

    if (deal.invoicingMethod !== 'EMAIL') return true

    return deal.billingEmail != 'null' && !!deal.billingEmail
  }

  const runSubmitValidations = () => {
    const validations = {
      organization: !!deal.organizationId,
      priceModels: !!deal.priceModels && deal.priceModels.length > 0,
      effective_from: !!deal.effectiveFrom,
      billingEmail: billingEmailValidation(),
    }

    const valueInErrorString = Object.entries(validations)
      .filter(([key, value]) => !value)
      .map(([key, value]) => key)
      .join(', ')

    if (valueInErrorString === '') {
      setError(null)
    } else {
      setError(
        `Validation errors on those fields: ${valueInErrorString}`,
      )
    }

    return validations
  }

  const canSubmit = () => {
    const validations = runSubmitValidations()

    return Object.values(validations).every((v) => v)
  }

  const handleSubmit = (e, values) => {
    if (!canSubmit()) return

    const priceModelIds = deal.priceModels.map((pm) => pm.id)
    const updatedPayload = [
      defaultGraphQLSerializer(deal, 'deals'),
      `priceModelIds: [${priceModelIds}]`,
    ].join(',')

    graphqlMutation(updatedPayload)
  }

  const defaultTypeaheadSearchPriceModel = {
    path: window.location.origin + '/graphql',
    label: t('priceModel'),
    isGraphqlType: true,
    listAttributes: [
      { name: 'title', customClass: 'col-md-6' },
      {
        name: function Name(result) {
          return (
            <>
              <p className="mb-0">{result?.product?.name}</p>
              {result?.tiers?.map((tier, i) => (
                <div key={i}>
                  <div>{renderTier(tier, result.tiers[i + 1])}</div>
                </div>
              ))}
            </>
          )
        },
        customClass: 'col-md-6 text-md-right small',
      },
    ],
    createLink: priceModelPath,
    fieldName: 'priceModelIds',
    resourceName: 'priceModels',
    displayedAttributes: 'title',
  }

  const handleOrgSelection = (value, valid, name, data) => {
    setDeal((prevDeal) => {
      return { ...prevDeal, [name]: value, organization: data }
    })
  }

  const handleCouponSelection = (value, valid, name, data) => {
    setDeal((prevDeal) => {
      return { ...prevDeal, [name]: value, coupon: data }
    })
  }

  const handleChange = (value, valid, name) => {
    setDeal((prevDeal) => {
      return { ...prevDeal, [name]: value }
    })
  }

  const handleChangeReceiptText = (value) => {
    setDeal((prevDeal) => {
      return { ...prevDeal, receiptText: value }
    })
  }

  const handlePriceModelsChange = (values) => {
    setDeal((prevDeal) => {
      return { ...prevDeal, priceModels: values }
    })
  }

  const setToOrgBillingInfo = (e) => {
    e.preventDefault()
    setDeal((prevDeal) => {
      return {
        ...prevDeal,
        billingDueBy: deal.organization?.billingDueBy,
        billingEmail: deal.organization?.billingEmail,
        billingReference: deal.organization?.billingReference,
        invoicingMethod: deal.organization?.billingMethod?.toUpperCase(),
      }
    })
  }

  const handleToggleCloningMode = () => {
    setCloningMode((prevCloningMode) => {
      setDeal((prevDeal) => {
        return {
          ...prevDeal,
          id: prevCloningMode ? dealProp.id : null,
        }
      })
      return !prevCloningMode
    })
  }

  return (
    <>
      <Layout>
        {isCurrentDefaultDeal && (
          <p className="text-danger">
            This is the current default deal. Edit with caution.
          </p>
        )}
        {(deal?.id || cloningMode) && (
          <SwitcherToggle
            enabled={cloningMode}
            label="cloning mode"
            handleToggle={handleToggleCloningMode}
          />
        )}

        <HLForm
          preventSubmit={true}
          onSubmit={handleSubmit}
          name="DealForm"
          omitCsrf={true}
        >
          {deal?.id && (
            <input type="hidden" name="id" value={deal?.id} />
          )}

          <TypeaheadSearch
            label={t('organization')}
            isGraphqlType={true}
            listAttributes={[
              { name: 'name', customClass: 'col-md-6' },
              {
                name: 'orgNr',
                customClass: 'col-md-6 text-md-right small',
              },
            ]}
            createLink={orgPath}
            fieldName="organizationId"
            resourceName="organizations"
            value={{
              id: deal?.organization?.id,
              name: deal?.organization?.name,
            }}
            onSelect={handleOrgSelection}
          />
          <TypeaheadSearch
            label={t('coupon')}
            isGraphqlType={true}
            listQueryAttributes="id code"
            listAttributes={[
              { name: 'code', customClass: 'col-md-6' },
            ]}
            createLink={couponPath}
            fieldName="couponId"
            resourceName="coupons"
            displayedAttributes="code"
            value={{
              id: deal?.coupon?.id,
              name: deal?.coupon?.code,
            }}
            onSelect={handleCouponSelection}
          />

          <TextInput
            name="description"
            label={t('description')}
            value={deal?.description || ''}
            onChange={handleChange}
          />

          <TextInput
            name="adminDescription"
            label={t('adminDescription')}
            value={deal?.adminDescription || ''}
            onChange={handleChange}
          />

          <p
            className="text-muted m-0"
            style={{ fontSize: '18px', paddingLeft: '0.7rem' }}
          >
            Kvitteringstekst
          </p>

          <RichTextEditor
            name="receiptText"
            value={deal?.receiptText || ''}
            onChange={handleChangeReceiptText}
          />

          <TextInput
            name="responsible"
            label={t('responsible')}
            placeholder="Navn på ansvarlig person i Hjemmelegene"
            value={deal?.responsible || ''}
            onChange={handleChange}
          />

          <SelectHL
            name="paymentType"
            label={t('paymentType')}
            placeholder={t('paymentType')}
            options={payment_types_list.map((type) => {
              return {
                value: type.toUpperCase(),
                label: t(`paymentTypes.${type}`),
              }
            })}
            onChange={handleChange}
            value={deal?.paymentType?.toUpperCase()}
          />
          <SelectHL
            name="invoicingMethod"
            label={t('invoicingMethod')}
            placeholder={t('invoicingMethod')}
            options={invoicing_method_list.map((meth) => {
              return {
                value: meth.toUpperCase(),
                label: t(`invoicingMethods.${meth}`),
              }
            })}
            onChange={handleChange}
            value={deal?.invoicingMethod?.toUpperCase()}
          />
          <div>
            <div className="formrow">
              <TextInput
                name="billingEmail"
                label={t('billingEmail')}
                placeholder="accounting@company.no"
                className="b-right"
                value={deal?.billingEmail || ''}
                onChange={handleChange}
              />
              <TextInput
                name="billingReference"
                label={t('billingReference')}
                placeholder="REF123"
                value={deal?.billingReference || ''}
                onChange={handleChange}
              />
              <IntegerInput
                name="billingDueBy"
                label={t('billingDueBy')}
                placeholder="30"
                className="b-right"
                value={deal?.billingDueBy || 0}
                validate={(value) =>
                  value > 0 && /^[0-9]*$/.test(value)
                }
                onChange={handleChange}
              />
            </div>
          </div>
          <button
            className="btn btn-outline-primary"
            onClick={setToOrgBillingInfo}
          >
            Bruk organisasjonsinformasjon
          </button>

          <h2 className="groupheading">Tidsperiode</h2>
          <div className="formrow">
            <DateTimeInput
              value={deal?.effectiveFrom || ''}
              name="effectiveFrom"
              label={t('effectiveFrom')}
              // min={moment()
              //   .tz('Europe/Oslo')
              //   .add(1, 'days')
              //   .startOf('day')
              //   .format('yyyy-MM-DD')}
              className="b-right"
              onChange={handleChange}
            />

            <DateTimeInput
              value={deal?.effectiveTo || ''}
              name="effectiveTo"
              label={t('effectiveTo') + ' (valgfritt)'}
              // min={moment()
              //   .tz('Europe/Oslo')
              //   .add(1, 'days')
              //   .startOf('day')
              //   .format('yyyy-MM-DD')}
              onChange={handleChange}
            />
          </div>

          <h2 className="groupheading">
            {t('priceModel')}
            {'(er)'}
          </h2>
          <PriceModelTypeaheadList
            resourceName="priceModel"
            resources={deal?.priceModels}
            typeaheadProps={defaultTypeaheadSearchPriceModel}
            emptyResource={{ id: '', name: '' }}
            OnPriceModelsChange={handlePriceModelsChange}
          />
          {error && (
            <p className="text-danger small">{error.toString()}</p>
          )}
          <Submit label="Lagre"></Submit>
        </HLForm>
      </Layout>
    </>
  )
}

DealCreator.propTypes = {
  dealsPath: PropTypes.string.isRequired,
  isCurrentDefaultDeal: PropTypes.bool,
  orgPath: PropTypes.string.isRequired,
  couponPath: PropTypes.string.isRequired,
  productPath: PropTypes.string.isRequired,
  priceModelPath: PropTypes.string.isRequired,
  dealProp: PropTypes.object,
  priceModels: PropTypes.arrayOf(PropTypes.object),
  payment_types_list: PropTypes.arrayOf(PropTypes.string).isRequired,
  invoicing_method_list: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
}

export default DealCreator
