import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addReferralToList,
  removeReferralFromList,
} from './state/itemSelectedActions'

const ReferralListItem = (props) => {
  const [expended, setExpended] = useState(false)
  const dispatch = useDispatch()

  const referralInSelectedList = useSelector((state) =>
    state.referralsSelected.includes(props.referral.id),
  )

  const handleChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    if (referralInSelectedList === true) {
      dispatch(removeReferralFromList(value))
    } else {
      dispatch(addReferralToList(value))
    }
  }

  return (
    <div
      key={props.referral.id}
      className="item-form__referral d-flex align-items-center my-1"
    >
      <div className="item-form__checkbox-wrapper">
        <input
          type="checkbox"
          name={props.referral.id}
          value={props.referral.id}
          onChange={handleChange}
          checked={referralInSelectedList}
          className="item-form__checkbox-input mr-1"
        />
      </div>
      <li className="list-group-item list-group-item--no-border-vertical flex-grow-1">
        <div className="row">
          <div className="col-sm-2">
            <a
              href={props.referral.custom_admin_path}
              target="_blank"
              rel=" noopener noreferrer"
            >
              <strong>#Ref id: </strong>
              <span>{props.referral.id}</span>{' '}
            </a>
          </div>
          <div className="col-sm-3">
            <strong>Pasienter: </strong>
            <span>{props.referral.epax_count}</span>{' '}
          </div>
          <div className="col-sm-3">
            <strong>Testdato: </strong>
            <span>{props.referral.test_date}</span>{' '}
          </div>
          <div className="col-sm-3">
            <strong>Area:</strong>
            <span>&nbsp;{props.referral.city_area}</span>{' '}
          </div>
          <div className="col-sm-1 text-center">
            {expended ? (
              <i
                className="fas fa-angle-up"
                onClick={() => setExpended(false)}
              ></i>
            ) : (
              <i
                className="fas fa-angle-down"
                onClick={() => setExpended(true)}
              ></i>
            )}
          </div>
        </div>
        {expended && (
          <>
            <div className="row my-3">
              <div className="col-sm-2">
                <strong>Organisasjon:</strong>
                <br />
                <span>
                  {props.referral.organization
                    ? props.referral.organization
                    : '-'}
                </span>{' '}
              </div>
              <div className="col-sm-3">
                <strong>Mottatt:</strong>
                <br />
                <span>{props.referral.formattedDate}</span>{' '}
              </div>
              <div className="col-sm-3">
                <strong>Adress:</strong>
                <br />
                <span>{`${props.referral.address}, ${props.referral.city}, ${props.referral.zip}`}</span>{' '}
              </div>

              <div className="col-sm-3">
                <strong>Henvist av:</strong>
                <br />
                <span>{props.referral.referred_by}</span>{' '}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-sm-12">
                <strong>Vei, og parkeringsbeskrivelse:</strong>
                <br />
                <span>
                  {props.referral.address_directions
                    ? props.referral.address_directions
                    : '-'}
                </span>{' '}
              </div>
            </div>
          </>
        )}
      </li>
    </div>
  )
}

ReferralListItem.propTypes = {
  referral: PropTypes.object.isRequired,
}

export default ReferralListItem
