import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Day from './Day'

class Week extends Component {
  static propTypes = {
    avatar: PropTypes.string.isRequired,
    week: PropTypes.string.isRequired,
    weekData: PropTypes.array.isRequired,
    firstWeek: PropTypes.string.isRequired,
    lastWeek: PropTypes.string.isRequired,
    nextWeek: PropTypes.func.isRequired,
    prevWeek: PropTypes.func.isRequired,
    defaultCoverageSelector: PropTypes.object.isRequired,
    assetRootUrl: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  isFirstWeek() {
    return this.props.week == this.props.firstWeek
  }

  isLastWeek() {
    return this.props.week == this.props.lastWeek
  }

  scrollNext = () => {
    if (!this.isLastWeek()) {
      $('html, body').animate(
        {
          scrollTop: $(
            `#${this.props.nextWeek(this.props.week)}`,
          ).offset().top,
        },
        500,
        'swing',
      )
    }
  }

  scrollPrev = () => {
    if (!this.isFirstWeek()) {
      $('html, body').animate(
        {
          scrollTop: $(
            `#${this.props.prevWeek(this.props.week)}`,
          ).offset().top,
        },
        500,
        'swing',
      )
    }
  }

  render() {
    const days = this.props.weekData.map((day, i) => {
      const safeProps = _.omit(
        this.props,
        'weekData',
        'firstWeek',
        'lastWeek',
        'scrollPosition',
      )

      const childProps = {
        ...safeProps,
        key: day.date,
        dayData: day,
      }

      return <Day key={i} {...childProps} />
    })

    return (
      <div id={`${this.props.week}`}>
        <div
          className={`week-header ${
            this.props.defaultCoverageSelector ? 'd-flex' : ''
          }`}
        >
          {this.props.defaultCoverageSelector}
          <div
            className={`backoffice__mini-header ${
              this.props.defaultCoverageSelector ? '' : 'sub-header'
            }`}
          >
            Uke &nbsp;
            {this.props.week.substring(5)}
            <span className="button-group--small week-toggle">
              <div
                className="button-group-item"
                onClick={this.scrollPrev}
              >
                <img
                  src={this.props.assetRootUrl.chevronIcon}
                  className={`icon-flip-up ${
                    this.isFirstWeek() ? 'icon-disabled' : ''
                  }`}
                />
              </div>
              <div
                className="button-group-item"
                onClick={this.scrollNext}
              >
                <img
                  src={this.props.assetRootUrl.chevronIcon}
                  className={`icon-flip-down ${
                    this.isLastWeek() ? 'icon-disabled' : ''
                  }`}
                />
              </div>
            </span>
          </div>
          <hr className="full-width" />
        </div>

        {days}
      </div>
    )
  }
}

export default Week
