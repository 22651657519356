import React, { useState } from 'react'
import PropTypes from 'prop-types'

import HLForm from '../forms/HLForm'
import TextareaHL from '../forms/fields/TextareaHL'
import NameInput from '../forms/fields/NameInput'
import PhoneInput from '../forms/fields/PhoneInput'
import FormProgress from '../forms/FormProgress'
import SubmitButton from '../forms/fields/SubmitButton'
import EmailInput from '../forms/fields/EmailInput'
import Layout from './shared/Layout'
import PickedTimeBox from './shared/PickedTimeBox'
import SameCategoryItemCart from './shared/SameCategoryItemCart'
import {
  v_name,
  v_phone,
  v_nonEmpty,
  v_email,
} from '../../lib/validations'

const VaccineWizardBookingDetails = ({
  steps,
  currentStep,
  cart,
  booking,
  path,
  maxPerOrder,
  organizationAddress,
}) => {
  const [highlightErrors, setHighLightErrors] = useState(false)
  const [bookingInfo, setBookingInfo] = useState({
    ...booking,
    contact_person_booking: { ...booking.contact_person_booking },
  })
  const [validName, setValidName] = useState(false)
  const [validPhone, setValidPhone] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [
    validAddressDirections,
    setValidAddressDirections,
  ] = useState(false)
  const [validLineItems, setValidLineItems] = useState(true)

  const formIsValid = [
    validName || v_name(bookingInfo.contact_person_booking?.name),
    validPhone ||
      v_phone(bookingInfo.contact_person_booking?.phone_number),
    validAddressDirections ||
      v_nonEmpty(bookingInfo.address_directions),
    validEmail || v_email(bookingInfo.contact_person_booking?.email),
    validLineItems,
  ].every(Boolean)

  const submitForm = (e) => {
    if (!formIsValid) {
      e.preventDefault()
      setHighLightErrors(true)
    }
  }

  const handleContactPersonEmailChange = (value, valid, name) => {
    setBookingInfo({
      ...bookingInfo,
      contact_person_booking: {
        ...bookingInfo.contact_person_booking,
        email: value,
      },
    })
    setValidEmail(v_email(value))
  }

  const handleContactPersonNameChange = (value, valid, name) => {
    setBookingInfo({
      ...bookingInfo,
      contact_person_booking: {
        ...bookingInfo.contact_person_booking,
        name: value,
      },
    })
    setValidName(v_name(value))
  }

  const handleAddressDirectionsChange = (value, valid, name) => {
    setBookingInfo({
      ...bookingInfo,
      address_directions: value,
    })
    setValidAddressDirections(v_name(value))
  }

  const handleContactPersonPhoneNumberChange = (
    value,
    valid,
    name,
  ) => {
    setBookingInfo({
      ...bookingInfo,
      contact_person_booking: {
        ...bookingInfo.contact_person_booking,
        phone_number: value,
      },
    })
    setValidPhone(v_phone(value))
  }

  const showErrorMessage = highlightErrors && !formIsValid

  return (
    <Layout heading="Oppdragsinformasjon" subheading="">
      <HLForm
        onSubmit={submitForm}
        action={path}
        method="put"
        name="vaccine_wizard_booking"
      >
        <FormProgress steps={steps} currentStep={currentStep} />

        <PickedTimeBox
          timeStart={bookingInfo.begins_at}
          timeEnd={bookingInfo.ends_at}
          address={`i ${bookingInfo.address}, ${bookingInfo.zip} ${bookingInfo.city}`}
        />

        <h2 className="groupheading">Antall og pris</h2>

        <input
          type="hidden"
          name="referral[city]"
          defaultValue={bookingInfo.city}
        />
        <input
          type="hidden"
          name="referral[zip]"
          defaultValue={bookingInfo.zip}
        />
        <input
          type="hidden"
          name="referral[address]"
          defaultValue={bookingInfo.address}
        />

        <SameCategoryItemCart
          cart={cart}
          onChange={(_, valid) => {
            setValidLineItems(valid)
          }}
        />

        <h2 className="groupheading">
          Koordinator på vaksinasjonsdagen
        </h2>
        <p>
          Denne personen må være fysisk tilgjengelig for vårt
          personell på vaksinasjonsdagen. Vedkommende er også
          ansvarlig for å informere ansatte om påmelding. Dette gjøres
          superenkelt ved å sende ut en link til påmelding som dere
          får fra oss. Resten fikser vi.
        </p>
        <PhoneInput
          name="referral[contact_person_booking][phone_number]"
          onChange={handleContactPersonPhoneNumberChange}
          label="Telefonnummer"
          highlightErrors={highlightErrors}
          isRequired
          value={
            bookingInfo.contact_person_booking?.phone_number || ''
          }
          className="b-bottom"
        />
        <NameInput
          name="referral[contact_person_booking][name]"
          onChange={handleContactPersonNameChange}
          highlightErrors={highlightErrors}
          isRequired
          value={bookingInfo.contact_person_booking?.name || ''}
        />
        <EmailInput
          name="referral[contact_person_booking][email]"
          onChange={handleContactPersonEmailChange}
          highlightErrors={highlightErrors}
          isRequired
          value={bookingInfo.contact_person_booking?.email || ''}
        />

        <h2 className="groupheading">
          Praktisk informasjon til helsepersonellet
        </h2>
        <p>
          Informasjon som er nyttig ved ankomst. F.eks. Hvor skal vi
          parkere og henvende oss først.
        </p>
        <div className="formgroup mt-4">
          <TextareaHL
            name="referral[address_directions]"
            label="Praktisk informasjon"
            onChange={handleAddressDirectionsChange}
            highlightErrors={highlightErrors}
            isRequired={true}
            value={bookingInfo.address_directions || ''}
          />
        </div>

        {showErrorMessage && (
          <div className="error-message">
            Vennligst gjennomgå feilene i skjemaet. <br />
            Se de røde feltene over for å rette opp.
          </div>
        )}

        <SubmitButton
          label="Neste"
          className="btn btn-100"
          onClick={submitForm}
        />
      </HLForm>
    </Layout>
  )
}

VaccineWizardBookingDetails.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  cart: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  maxPerOrder: PropTypes.number,
  organizationAddress: PropTypes.object || null,
}

VaccineWizardBookingDetails.defaultProps = {
  steps: [],
  currentStep: '',
  path: '',
  cart: {},
  booking: {},
}

export default VaccineWizardBookingDetails
