import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import SubmitButton from './forms/fields/SubmitButton'

const modalStyle = {
  content: {
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
  },
}

class ReferralConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen,
    }
    ReactModal.setAppElement('.page__content')
  }

  toggleModal = (e) => {
    e.preventDefault()
    this.state.isOpen
      ? this.setState({ isOpen: false })
      : this.setState({ isOpen: true })
  }

  submitForm = () => {
    $('#new_referral').submit()
  }

  render() {
    return (
      <>
        <button
          className={`btn btn-secondary`}
          onClick={this.toggleModal}
        >
          Send inn
        </button>
        <ReactModal
          isOpen={this.state.isOpen}
          contentLabel="Bekreft"
          style={modalStyle}
          shouldCloseOnEsc={false}
        >
          <div
            className="ReactModal__exit-button-new"
            onClick={this.toggleModal}
          />
          <h1 className="ReactModal__heading">
            <span
              className="text-danger"
              style={{ textTransform: 'uppercase' }}
            >
              Viktig:{' '}
            </span>
            Er besøket gjennomførbart?
          </h1>
          <p>
            Dersom en pasient ikke klarer å lese SMS, ta telefon eller
            åpne døren for oss er du ansvarlig for:
          </p>
          <ul>
            <li>
              Å oppgi en egnet kontaktperson som er tilgjengelig og
              kan slippe vårt personell inn til pasienten på kort
              varsel.
            </li>
            <li>
              Å informere kontaktpersonen om at Hjemmelegene kommer
              for å teste pasienten, at de må svare oppringinger fra
              skjult nummer og åpne døren for oss.
            </li>
            <li>
              Så langt det er mulig, informere pasienten om at de skal
              testes for Covid-19 og at Hjemmelegene kommer hjem til
              dem for å gjøre dette.
            </li>
          </ul>
          <p>
            NB: Spesifiserer gjerne eventuelle ønsker om hvordan vi
            skal kontakte pasienten/kontaktpersonen.
          </p>
          <button
            className={`btn btn-secondary btn-block`}
            onClick={this.toggleModal}
          >
            Endre henvisning
          </button>
          <button
            className={`btn btn-secondary btn-block`}
            onClick={this.submitForm}
          >
            Jeg forstår. Send henvisning nå.
          </button>
        </ReactModal>
      </>
    )
  }
}

export default ReferralConfirmationModal
