import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

function renderOptions(from, to, options, prompt) {
  const selectOptions = []

  if (prompt) {
    selectOptions.push(
      <option key={0} className="prompt">
        {prompt}
      </option>,
    )
  }

  if (options) {
    for (const i in options) {
      const option = options[i]
      selectOptions.push(
        <option value={option.value} key={option.value}>
          {option.text}
        </option>,
      )
    }
  } else {
    if (to < from) throw new Error('from cannot be larger than to')

    for (let i = from; i <= to; i += 1) {
      selectOptions.push(
        <option value={i} key={i}>
          {i}
        </option>,
      )
    }
  }

  return selectOptions
}

class Select extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    from: PropTypes.number,
    to: PropTypes.number,
    options: PropTypes.array,
    prompt: PropTypes.string,
    className: PropTypes.string,
    showError: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {}

    if (props.prompt) {
      this.state = {
        promptSelectedClass: 'prompt-selected',
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.value

    this.props.onChange(value)

    if (this.props.prompt && value === this.props.prompt) {
      this.setState({ promptSelectedClass: 'prompt-selected' })
      return
    }

    this.setState({ promptSelectedClass: '' })
  }

  showError() {
    if (this.props.showError) {
      return this.props.showError() ? 'error' : ''
    }

    return ''
  }

  render() {
    const safeProps = _.omit(
      this.props,
      'onChange',
      'to',
      'from',
      'options',
      'prompt',
      'className',
      'showError',
    )

    const { from, to, options, prompt, className } = this.props

    return (
      <select
        {...safeProps}
        className={`${className} ${
          this.state.promptSelectedClass
        } ${this.showError()}`}
        onChange={this.handleChange}
      >
        {renderOptions(from, to, options, prompt)}
      </select>
    )
  }
}

export default Select
