import React from 'react'
import TextInput from './TextInput'
import { v_name } from '../../../lib/validations'

const NameInput = (props) => {
  return <TextInput {...props} />
}

NameInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'name',
  label: 'Fullt navn',
  autoComplete: 'name',
  validate: v_name,
}

NameInput.propTypes = TextInput.propTypes

export default NameInput
