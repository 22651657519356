import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { propTypes } from './FieldWrapper'
import Label from './Label'
import _ from 'lodash'

const NumericInput = (props) => {
  const sanitize = (val) =>
    Math.min(Math.max(props.min, val || 0), props.max)

  const validate = (val) =>
    val != undefined && props.min <= val && val <= props.max

  const sanitizedValue = sanitize(props.initialValue || props.value)
  const [value, setValue] = useState(sanitizedValue)
  const [valid, setValid] = useState(validate(sanitizedValue))

  const handleChange = (e) => {
    const newVal = e.target.value
    const newValid = validate(newVal)
    setValue(newVal)
    setValid(newValid)
    if (props.onChange) props.onChange(newVal, newValid, props.name)
  }

  const inputProps = _.pick(props, [
    'name',
    'placeholder',
    'autoComplete',
    'disabled',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'min',
    'max',
    'initialValue',
  ])

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    empty: !value,
    valid,
  }

  inputProps.placeholder =
    props.disabled && props.disabledPlaceholder
      ? props.disabledPlaceholder
      : props.placeholder

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <input
        type="number"
        value={value}
        onChange={handleChange}
        className="input textfield"
        {...inputProps}
      />
    </FieldWrapper>
  )
}

NumericInput.defaultProps = {
  placeholder: '',
  autoComplete: 'off',
  highlightErrors: false,
  disabled: false,
}

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  onRef: PropTypes.func,
  initialValue: PropTypes.number,
}

export default NumericInput
