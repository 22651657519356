import React from 'react'
import TextInput from './TextInput'

import { v_email } from '../../../lib/validations'

const EmailInput = (props) => {
  return <TextInput {...props} />
}

EmailInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'email',
  label: 'E-postadresse',
  autoComplete: 'email',
  inputMode: 'email',
  validate: v_email,
  sanitize: (val) => val.toLowerCase(),
}

EmailInput.propTypes = TextInput.propTypes

export default EmailInput
