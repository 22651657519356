import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { deleteRecord, patch } from '../lib/backend-client'
import Textarea from './forms/fields/Textarea'
import Button from './Button'

const Comment = (props) => {
  const buildPath = (comment_id) => {
    const urlParams = new URLSearchParams(props.commentPath)
    const commentable_id = urlParams.get('/comments?commentable_id')
    const commentable_type = urlParams.get('commentable_type')
    return `/comments/${comment_id}?commentable_id=${commentable_id}&commentable_type=${commentable_type}`
  }

  const [comment, setComment] = useState(props.comment)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isUpdatingComment, setIsUpdatingComment] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [busy, setBusy] = useState(false)
  const [builtCommentPath] = useState(buildPath(comment.id))

  const editCommentStyle = {
    backgroundColor: '#f3edf9',
    padding: '20px',
    margin: '20px 0',
    borderRadius: '5px',
  }

  const deleteComment = async (e) => {
    e.preventDefault()
    if (currentUserIsauthorOrAdmin()) {
      if (confirm('Er du sikker?')) {
        const response = await deleteRecord(builtCommentPath)
        if (response.success) {
          props.onChange()
        } else {
          setErrorMessage(response.errors.join(', '))
        }
      }
    } else {
      alert(
        "you are not the author of the post so you can't delete it",
      )
    }
  }

  const handleChangeCommentText = (value, valid) => {
    setIsValid(valid)
    const updatedComment = { ...comment }
    updatedComment.text = value
    setComment(updatedComment)
  }

  const submit = async (path, params) => {
    setBusy(true)
    try {
      const response = await patch(path, params)
      if (response.success) {
        props.onChange()
      } else {
        setErrorMessage(response.errors.join(', '))
      }
    } catch (e) {
      if (e.responseJSON) {
        setErrorMessage(e.responseJSON.errors.join(', '))
      } else {
        setErrorMessage(e.statusText)
      }
    }
    setBusy(false)
    setIsUpdatingComment(false)
  }

  const updateComment = () => {
    submit(builtCommentPath, {
      comment: {
        text: comment.text,
      },
    })
  }

  const d = (date) => moment(date).format('D.MMMM, HH:mm')

  const currentUserIsauthorOrAdmin = () =>
    (props.currentUserType === 'AdminUser' &&
      comment.author.type !== 'AdminUser') ||
    props.currentUser.full_name === comment.author.full_name ||
    props.currentUser.name === comment.author.full_name

  const renderComment = () => (
    <div className="comment my-3" key={comment.id} id={comment.id}>
      <div>
        <strong>{comment.author.full_name}</strong>:{' '}
        <div style={{ whiteSpace: 'pre-line' }}>{comment.text}</div>
      </div>
      <nav className="small mt-1">
        {d(comment.created_at)}
        {currentUserIsauthorOrAdmin() && (
          <>
            &nbsp;|&nbsp;
            <a href="#" onClick={updating}>
              Rediger
            </a>{' '}
            &nbsp;|&nbsp;
            <a href="#" onClick={deleteComment}>
              Slett
            </a>
          </>
        )}
      </nav>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  )

  const updating = (e) => {
    e.preventDefault()
    setIsUpdatingComment(true)
  }

  const cancelUpdate = (e) => {
    e.preventDefault()
    setIsUpdatingComment(false)
  }

  const renderCommentForm = () => (
    <div style={editCommentStyle}>
      <div>
        <strong>{comment.author.full_name}</strong> (
        {d(comment.created_at)})
      </div>
      <Textarea
        minLength={2}
        maxLength={500}
        showError={true}
        value={comment.text || ''}
        isValid={isValid}
        onChangeText={handleChangeCommentText}
      />
      <Button
        label="Lagre til kommentar"
        onClick={updateComment}
        className="btn btn-compact btn-primary mt-2 mr-2"
        disabled={busy || !isValid}
      ></Button>
      <Button
        label="Avbryt"
        onClick={cancelUpdate}
        className="btn btn-compact btn-secondary mt-2"
      ></Button>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  )

  return (
    <>
      {!isUpdatingComment && renderComment()}
      {isUpdatingComment && renderCommentForm()}
    </>
  )
}

Comment.propTypes = {
  onChange: PropTypes.func.isRequired,
  commentPath: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserType: PropTypes.string.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    author: PropTypes.object,
  }),
}

export default Comment
