import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import allReducers from './allReducers'
import thunkMiddleware from 'redux-thunk'

const composedEnhancers = composeWithDevTools(
  applyMiddleware(thunkMiddleware),
)

const store = createStore(allReducers, composedEnhancers)

export default store
