import React, { Component } from 'react'
import PropTypes from 'prop-types'

function toParams(object) {
  return Object.keys(object)
    .map((key) => {
      const value = encodeURIComponent(object[key])
      return `${key}=${value}`
    })
    .join('&')
}

class StaticGoogleMap extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    size: PropTypes.string.isRequired,
    zoom: PropTypes.number.isRequired,
    className: PropTypes.string,
    hideLabels: PropTypes.bool,
  }

  static defaultProps = {
    size: '160x160',
    zoom: 15,
    className: '',
  }

  constructor(props) {
    super(props)
    this.state = { size: this.props.size }
  }

  componentDidMount() {
    if (this.state.size === 'auto') this.calculateSize()
  }

  getSrc() {
    const { googleMapsApiKey: key, zoom } = this.props
    const { size } = this.state
    if (size === 'auto') return ''

    const { lat, lng } = this.props.center
    const center = [lat, lng].join(',')
    const style = this.getStyle()

    const queryParams = toParams({
      center,
      size,
      zoom,
      key,
      style,
      language: 'nb',
    })

    return [
      'https://maps.googleapis.com/maps/api/staticmap',
      queryParams,
    ].join('?')
  }

  getStyle() {
    if (!this.props.hideLabels) return ''
    return 'feature:all|element:labels|visibility:off'
  }

  calculateSize() {
    const $parent = $(this.image.parentNode)
    const size = $parent.width()
    this.setState({ size: `${size}x${size}` })
  }

  render() {
    return (
      <img
        ref={(ref) => {
          this.image = ref
        }}
        role="presentation"
        src={this.getSrc()}
        className={this.props.className}
      />
    )
  }
}

export default StaticGoogleMap
