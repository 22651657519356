import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import FieldWrapper, { propTypes } from './FieldWrapper'
import Label from './Label'

const TextInput = (props) => {
  const [state, setState] = useState({
    value: props.value || '',
    valid: props.validate(props.value),
  })

  useEffect(() => {
    if (props.value || props.value === '') {
      setState({
        value: props.value,
        valid: props.validate(props.value),
      })
    }
  }, [props.value, props.validate])

  const handleChange = (e) => {
    const newVal = e.target.value
    const newValid = props.validate(newVal)
    setState({ value: newVal, valid: newValid })
    if (props.onChange) props.onChange(newVal, newValid, props.name)
  }

  const handleInput = (e) => {
    const filteredVal = props.filter(e.target.value)
    e.target.value = props.sanitize(filteredVal)
  }

  const inputProps = _.pick(props, [
    'name',
    'placeholder',
    'autoComplete',
    'disabled',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'inputMode',
    'readOnly',
    'id',
  ])

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    ...props.showValidation,
    empty: !state.value || state.value.length === 0,
    valid: state.valid,
  }

  inputProps.placeholder =
    props.disabled && props.disabledPlaceholder
      ? props.disabledPlaceholder
      : props.placeholder

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <input
        type="text"
        value={state.value}
        onChange={handleChange}
        onInput={handleInput}
        className="input textfield"
        {...inputProps}
      />
    </FieldWrapper>
  )
}

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  autoComplete: 'off',
  highlightErrors: false,
  isRequired: false,
  disabled: false,
  showValidation: true,
  inputMode: 'text',
  validate: (value) => value?.length > 1,
  filter: (value) => value,
  sanitize: (value) => value,
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  pattern: PropTypes.string,
  sanitize: PropTypes.func,
  filter: PropTypes.func,
  validate: PropTypes.func,
  maxLength: PropTypes.number,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  attentionMessage: PropTypes.string,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  inputMode: PropTypes.string,
  showValidation: PropTypes.bool,
}

export default TextInput
