const initialState = ''

export const dateSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BOOKINGS/DATE_SELECTED':
      return action.payload
    default:
      return state
  }
}
