import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper from './FieldWrapper'
import Label from './Label'
import _ from 'lodash'

const SelectHL = (props) => {
  const [valid, setValid] = useState(props.validate(props.value))
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (e) => {
    const value = e.target.value
    const valid = props.validate(value)
    setValue(value)
    setValid(valid)
    if (props.onChange) props.onChange(value, valid, props.name)
  }

  const inputProps = _.pick(props, [
    'name',
    'autoComplete',
    'disabled',
  ])

  const propTypesArr = [
    'disabled',
    'children',
    'valid',
    'empty',
    'required',
    'highlightErrors',
    'className',
    'showValidation',
  ]

  const wrapperProps = {
    ..._.pick(props, propTypesArr),
    empty: !value || value.length === 0,
    valid,
  }

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <select
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        className={`select ${props.className}`}
        {...inputProps}
      >
        {props.options.map((option) => {
          const stringOption = option.toString()
          return (
            <option
              key={option['value'] || stringOption}
              value={option['value'] || stringOption}
              label={option['label'] || stringOption}
            >
              {option['label'] || stringOption}
            </option>
          )
        })}
      </select>
      <span className="select-arrows" />
    </FieldWrapper>
  )
}

SelectHL.defaultProps = {
  label: 'Select',
  value: '',
  placeholder: '',
  className: '',
  highlightErrors: false,
  showValidation: true,
  disabled: false,
  validate: (value) => value.length > 1,
}

SelectHL.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  showValidation: PropTypes.bool,
  onRef: PropTypes.func,
}

export default SelectHL
