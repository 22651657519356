const parse = (event) => {
  const meta = event.metadata.table
  switch (event.action) {
    case 'refund':
      return `${event.initiator_name} refunderte bestillingen`
    case 'resource_access':
      return `${event.initiator_name} åpnet bestillingen${
        meta.occurrences > 1 ? ' (rep. ' + meta.occurrences + ')' : ''
      }`
    case 'change_service_provider':
      return `${event.initiator_name} endret behandler fra ${meta.old_name} til ${meta.new_name}`
    default:
      return `${
        event.initiator_name
      }, ${event.action.toUpperCase()} ${ostr(meta)}`
  }
}

const ostr = (o) => {
  let s = ''
  for (const p in o) s += `${p}: ${o[p]}, `
  return s
}

export default parse
