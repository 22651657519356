import React from 'react'
import PropTypes from 'prop-types'

import Button from '../forms/fields/Button'

const PatientCount = ({
  maxCapacity,
  renderAppointmentSummary,
  who,
  onComplete,
  reset,
  t,
}) => {
  const firstOption = who === 'meplus' ? 2 : 1
  const capacity = maxCapacity || 5

  const numOfOptions = who === 'meplus' ? capacity - 1 : capacity
  const numPatientsOptions = Array.from(
    { length: numOfOptions },
    (_, i) => i + firstOption,
  )
  return (
    <section>
      {renderAppointmentSummary()}
      <h1 className="mb-4">{t('heading', s)}</h1>
      {numPatientsOptions.map((val) => (
        <Button
          key={val}
          className="btn btn-primary btn-mega ishadow"
          label={t('button', s)(val)}
          onClick={() => onComplete(val)}
        />
      ))}
      <Button
        label={t('restart')}
        className="btn-link mt-4"
        onClick={reset}
      />
    </section>
  )
}

PatientCount.propTypes = {
  maxCapacity: PropTypes.number.isRequired,
  renderAppointmentSummary: PropTypes.func.isRequired,
  who: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const polishPatientCount = (count) => {
  if (count == 1) return 'osoba'
  else if ([2, 3, 4].includes(count)) return 'osoby'
  else return 'osób'
}

const s = {
  nb: {
    heading: 'Hvor mange skal ha time totalt?',
    button: (num) => `${num} Person${num > 1 ? 'er' : ''}`,
  },
  en: {
    heading: 'How many are getting an appointment in total?',
    button: (num) => `${num} Person${num > 1 ? 's' : ''}`,
  },
  pl: {
    heading: 'Ile osób w sumie będzie umówionych?',
    button: (num) => `${num} ${polishPatientCount(num)}`,
  },
}

export default PatientCount
