import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import PickedTimeBox from './shared/PickedTimeBox'
import OrderDetailsList from './shared/OrderDetailsList'
import { formatCurrencyNoDec } from '../../lib/formatCurrencyOneDec'
import { purchaseEvent } from '../../lib/tracking'
import Layout from './shared/Layout'
import { useSessionStorage } from '../../lib/hooks'

const VaccineWizardReceipt = ({
  organization,
  booking,
  bookingContactPerson,
  orderContactPerson,
  products,
  quantity,
  price,
  order,
  payment,
  assetRootUrl,
}) => {
  const { removeValueFromSessionStorage } = useSessionStorage(
    'epax_count',
    6,
  )

  useEffect(() => {
    if (!order.lineItems) {
      const orderLikeObject = {
        id: `Referral/${booking.id}`,
        totalPrice: order.totalPrice,
        lineItems: order.products.map((pr) => ({
          id: `Cart/${order.id}/${pr.id}}`,
          defaultPrice: pr.price,
          netPrice: pr.price,
          quantity: pr.quantity,
          product: {
            name: pr.name,
            publicName: pr.publicName,
            slug: pr.slug,
            productCategory: {
              name: pr?.productCategory?.name,
            },
          },
          coupon: {
            code: '',
          },
        })),
      }

      purchaseEvent(orderLikeObject)

      return
    }

    purchaseEvent(order)
  }, [])

  useEffect(() => {
    removeValueFromSessionStorage()
  }, [])

  const timeToInfo =
    new Date(booking.begins_at).getTime() - Date.now() <
    1000 * 60 * 60 * 24 * 7
      ? 'snarest'
      : 'senest 7 dager før oppdraget'

  return (
    <Layout
      heading="Takk for bestillingen!"
      subheading={`Bestillingsbekreftelse er sendt til ${orderContactPerson.email}. Vi kontakter deg på e-post ${timeToInfo} for konkret tidspunkt.
      `}
    >
      <PickedTimeBox
        timeStart={booking.begins_at}
        timeEnd={booking.ends_at}
        address={`i ${booking.address}, ${booking.zip} ${booking.city}`}
      />
      <p className="info-box info-box-danger">{`Alle som skal vaksineres må melde seg på selv i vårt system. Når vi har satt konkret tidspunkt for oppdraget, vil vi sende informasjon om påmelding til ${bookingContactPerson.email}. Dette vil vi sende ${timeToInfo}.`}</p>
      <OrderDetailsList
        order={order}
        organization={organization}
        products={products}
        price={formatCurrencyNoDec(price, true, true)}
        quantity={quantity}
        payment={payment}
        booking={booking}
        bookingContactPerson={bookingContactPerson}
        orderContactPerson={orderContactPerson}
      />
    </Layout>
  )
}

VaccineWizardReceipt.propTypes = {
  organization: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  bookingContactPerson: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  products: PropTypes.string.isRequired,
  orderContactPerson: PropTypes.object.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  payment: PropTypes.object,
}

VaccineWizardReceipt.defaultProps = {
  organization: {},
  order: {},
  payment: {},
  booking: {},
  bookingContactPerson: {},
  products: '',
  price: 0,
  quantity: 0,
  orderContactPerson: {},
}

export default VaccineWizardReceipt
