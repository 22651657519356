import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CoverageMapEditor from './CoverageMapEditor'
import loadGoogle from '../../lib/loadGoogle'

const MultipleCoverageMapEditor = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function callGoogle() {
      await loadGoogle(props.googleMapsApiKey)
      setLoading(false)
    }
    callGoogle()
  }, [])

  return (
    <ul className="list-group list-group--full-width mb-1 mt-2">
      {props.coverageMaps.map((map) => {
        return (
          <li key={map.id} className="list-group-item">
            <h2 className="h6">
              {map.name} {map.category == 'main' && '(Hovedkart)'}
            </h2>
            <div className="row">
              <div className="col-12">
                {!loading && (
                  <CoverageMapEditor
                    googleMapsApiKey={props.googleMapsApiKey}
                    location={props.location}
                    path={props.path + map.id}
                    method={props.method}
                    isMapEditable={props.isMapEditable}
                    coverageMap={map}
                    shouldLoadGoogle={false}
                    coverageMaps={props.coverageMaps}
                  />
                )}
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

MultipleCoverageMapEditor.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  isMapEditable: PropTypes.bool,
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  path: PropTypes.string,
  method: PropTypes.string,
  coverageMaps: PropTypes.arrayOf(PropTypes.object),
}

export default MultipleCoverageMapEditor
