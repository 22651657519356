export const addReferralToList = (id) => {
  return {
    type: 'REFERRALS/ADD_REFERRALS_TO_SELECTED_LIST',
    payload: id,
  }
}

export const removeReferralFromList = (id) => {
  return {
    type: 'REFERRALS/REMOVE_FROM_REFERRALS_SELECTED_LIST',
    payload: id,
  }
}

export const addBookingToList = (id) => {
  return {
    type: 'BOOKINGS/ADD_BOOKINGS_TO_SELECTED_LIST',
    payload: id,
  }
}

export const removeBookingFromList = (id) => {
  return {
    type: 'BOOKINGS/REMOVE_FROM_BOOKINGS_SELECTED_LIST',
    payload: id,
  }
}
