import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Redirect = ({ backendUrl, redirectPath }) => {
  useEffect(() => {
    const bankIDRedirectUrl = `${backendUrl}/users/auth/signicat?type=service_provider&redirect=${backendUrl}${redirectPath}&redirect_error=${backendUrl}${redirectPath}?bankIdError=true`
    window.location.href = bankIDRedirectUrl
  }, [])

  return (
    <div className="d-flex justify-content-center align-items-center">
      <span className="spinner-border text-primary ml-2" />
    </div>
  )
}

Redirect.propTypes = {
  backendUrl: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
}

export default Redirect
