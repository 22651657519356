import React from 'react'

import PropTypes from 'prop-types'
import { serviceCenterShape } from './propShapes'

const AppointmentSummary = ({
  parentOfAppointment,
  organization,
  productNames,
  t,
}) => {
  const renderLocation = () => {
    const { street, zip, city, address } = parentOfAppointment
    const parentOfAppointmentAddress = `${
      street || address
    }, ${zip} ${city}`
    const parentTitle =
      parentOfAppointment?.title ||
      organization?.name ||
      `Booking ${parentOfAppointment.id}`

    return (
      <>
        <dt className="d-inline">{t('location')}: </dt>
        <dd className="d-inline">{parentOfAppointment.location}</dd>
      </>
    )
  }

  const renderProducts = () => (
    <>
      <dt className="d-inline">{t('chosen')}: </dt>
      <dd className="d-inline">{productNames.join(', ')}</dd>
      <br />
    </>
  )

  return (
    <dl className="my-4 small aside">
      {!!productNames?.length && renderProducts()}
      {!!parentOfAppointment && renderLocation()}
    </dl>
  )
}

AppointmentSummary.propTypes = {
  parentOfAppointment: PropTypes.oneOfType([
    serviceCenterShape,
    PropTypes.object,
  ]).isRequired,
  organization: PropTypes.object,
  productNames: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
}

export default AppointmentSummary
