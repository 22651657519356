import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClinicAppointmentItem } from './ClinicAppointmentItem'
import { ClinicAppointmentListActionBar as ActionBar } from './ClinicAppointmentListActionBar'
import { ClinicAppointmentListSearchBar as SearchBar } from './ClinicAppointmentListSearchBar'
export function ClinicAppointmentContent({
  currentDate,
  setCurrentDate,
  appointments,
  onListItemClick,
  onCirclesClick,
}) {
  const [onlyUpcoming, setOnlyUpcoming] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const filteredAppointments = searchAppointments(
    appointments,
    searchQuery,
  )
  const visibleAppointments = onlyUpcoming
    ? filterWithTime(filteredAppointments)
    : filteredAppointments

  const numAppointmentsWithAllConsentsFilled = visibleAppointments.filter(
    (a) => {
      return (
        a.options && Object.values(a.options).every((v) => v === true)
      )
    },
  ).length

  const numAppointsWithVaccinatedPatients = visibleAppointments.filter(
    (a) => {
      return a.productAdministeredAt
    },
  ).length

  const numAppointmentsWithJournalEntry = visibleAppointments.filter(
    (a) => {
      return a.sysvacRegisteredAt
    },
  ).length

  return (
    <>
      <ActionBar
        currentDate={currentDate}
        numberOfPatients={
          visibleAppointments?.length ? visibleAppointments.length : 0
        }
        isShowingUpcoming={onlyUpcoming}
        toggleShowingUpcoming={() => setOnlyUpcoming((prev) => !prev)}
        setCurrentDate={setCurrentDate}
      />
      <div className="row">
        <div className="col-sm-9">
          <SearchBar setSearchQuery={setSearchQuery} naked={true} />
        </div>
        <div className="col-sm-3 d-flex justify-content-between align-items-end pl-0">
          <p className="preamble" style={{ flex: 1 }}>
            {numAppointmentsWithAllConsentsFilled}
          </p>
          <p className="preamble" style={{ flex: 1 }}>
            {numAppointsWithVaccinatedPatients}
          </p>
          <p className="preamble" style={{ flex: 1 }}>
            {numAppointmentsWithJournalEntry}
          </p>
        </div>
      </div>
      <div className="vaccination-list list-group list-group-flush list-group--full-width">
        {visibleAppointments.length ? (
          <>
            <ListHeader />
            {visibleAppointments.map((appointment) => (
              <ClinicAppointmentItem
                key={appointment.id}
                item={appointment}
                onClick={onListItemClick}
                onCirclesClick={onCirclesClick}
              />
            ))}
          </>
        ) : (
          <p className="my-5 text-center">
            Ingen registrerte pasienter
          </p>
        )}
      </div>
    </>
  )
}

ClinicAppointmentContent.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  appointments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onListItemClick: PropTypes.func.isRequired,
  onCirclesClick: PropTypes.func.isRequired,
}

function ListHeader() {
  return (
    <div
      className="d-none d-sm-block"
      style={{ padding: '1rem 1.25rem', fontWeight: 600 }}
    >
      <div className="row">
        <div className="col-sm-2 table-heading" title="Ankommet">
          Ank.
        </div>
        <div className="col-sm-3 table-heading">Navn</div>
        <div className="col-sm-2 table-heading">Tlf</div>
        <div className="col-sm-2 table-heading">Pnr</div>
        <div
          className="col-sm-1 small table-heading"
          title="Samtykke fullstendig"
        >
          Samtykke
        </div>
        <div className="col-sm-1 small table-heading" title="Utfort">
          Vaksinert
        </div>
        <div
          className="col-sm-1 small table-heading"
          title="Journalført"
        >
          Journalført
        </div>
      </div>
    </div>
  )
}

const searchAppointments = (appointments, searchQuery) => {
  return searchQuery === ''
    ? appointments
    : appointments.filter(
        (listItem) =>
          listItem.name?.toLowerCase().includes(searchQuery) ||
          listItem.phoneNumber?.toLowerCase().includes(searchQuery) ||
          listItem.nationalId?.toLowerCase().includes(searchQuery),
      )
}

const filterWithTime = (appointmentList) => {
  return appointmentList.filter((item) => {
    return !item.productAdministeredAt || !item.sysvacRegisteredAt
  })
}
