import React from 'react'

const TermsText = () => (
  <>
    <h1 className="ReactModal__heading">
      BRUKERVILKÅR FOR HJEMMELEGENE
    </h1>
    <p className="ReactModal__text">
      Velkommen til brukervilkårene for Hjemmelegene.
    </p>
    <p className="ReactModal__text">
      Takk for at du velger å bruke vår tjeneste.
    </p>
    <p className="ReactModal__text">
      Nedenfor finner du en oversikt over våre brukervilkår som
      gjelder for alle som besøker nettstedet vårt eller benytter seg
      av vår tjeneste. Disse vilkårene er nødvendige for å beskytte
      både deg og oss, og for å gjøre det mulig å levere vår tjeneste
      og gjøre den bedre for alle.
    </p>
    <p className="ReactModal__text">
      Vi gjør oppmerksom på at Hjemmelegene ikke tilbyr formidling av
      akutte helsetjenester og ved behov for øyeblikkelig hjelp, må du
      ringe 113.
    </p>

    <h2 className="ReactModal__heading"> 1. GENERELT </h2>
    <p className="ReactModal__text">
      Eieren av tjenesten Hjemmelegene og dette nettstedet er
      Hjemmelegene AS, et norsk selskap som er registrert i
      Brønnøysundregisteret med organisasjonsnummer: NO 918 605 878.
    </p>
    <p className="ReactModal__text">
      Hjemmelegene AS innehar eierskap, opphavsrett, varemerker og
      alle andre immaterielle rettigheter knyttet til nettstedet, samt
      produktene og tjenestene omtalt på nettstedet – med mindre slike
      rettigheter tilhører en tredjepart.
    </p>
    <p className="ReactModal__text">
      Disse tjenestevilkår (heretter «Tjenestevilkårene») gjelder for
      tjenesten Hjemmelegene, som leveres av Hjemmelegene AS (heretter
      «Hjemmelegene») til deg. Hjemmelegene AS hjelper deg med å komme
      i kontakt med helsepersonell for å avtale et hjembesøk.
    </p>
    <p className="ReactModal__text">
      Tjenestevilkårene gjelder mellom deg og Hjemmelegene AS. Ved å
      akseptere tjenestevilkårene, for eksempel ved å ta i bruk
      tjenesten, inngår du en rettslig bindende avtale med
      Hjemmelegene AS. Det er helsepersonellet som står ansvarlig for
      helsehjelpen ved en konsultasjon.
    </p>

    <h2 className="ReactModal__heading">
      {' '}
      2. TILGANG TIL OG BRUK AV HJEMMELEGENE{' '}
    </h2>
    <h3 className="ReactModal__heading">
      {' '}
      2.1. TILGANG TIL HJEMMELEGENE{' '}
    </h3>

    <p className="ReactModal__text">
      Denne avtalen gir deg rett til å benytte tjenesten Hjemmelegene
      slik den til enhver tid tilbys av Hjemmelegene AS. Hjemmelegene
      AS videreutvikler tjenesten fortløpende med nye funksjoner og
      muligheter, men Hjemmelegene AS har også rett til å fjerne eller
      endre eksisterende funksjoner.
    </p>

    <h3 className="ReactModal__heading">
      {' '}
      2.2. BESKRIVELSE AV TJENESTEN - BESTILLING{' '}
    </h3>

    <p className="ReactModal__text">
      Hjemmelegene er en tjeneste for formidling av timebestilling og
      betaling mellom helsepersonell og pasient. Ansvaret for
      medisinsk behandling ligger hos den enkelte helsepersonell som
      tilbyr sine tjenester via Hjemmelegene AS&apos; plattform.
      Helsepersonell som kan kontaktes gjennom tjenesten er ikke
      ansatt i Hjemmelegene AS.
    </p>
    <p className="ReactModal__text">
      Kun personer fylt 18 år med et mobiltelefonnummer og gyldig
      betalingskort kan bestille en konsultasjon. Konsultasjon kan
      bestilles for mindreårige av deres foresatte.
    </p>
    <p className="ReactModal__text">
      Gjennom Hjemmelegene vil du få oversikt over tilgjengelige timer
      på din oppgitte adresse. Åpningstiden bestemmes av
      helsepersonellet som tilbyr tjenester via Hjemmelegene, og kan
      til enhver tid sjekkes på nettsiden. Du velger den ledige timen
      som passer deg. Deretter taster du inn ditt mobiltelefonnummer,
      som Hjemmelegene AS vil benytte til å sende en SMS med kode for
      å bekrefte din identitet. Deretter fyller du ut ditt navn og
      adressen som du ønsker å bestille et hjemmebesøk til. Du skal
      ikke oppgi konkrete opplysninger om ditt helsemessige behov til
      Hjemmelegene AS. Slike konkrete opplysninger regnes som
      sensitive personopplysninger (helseopplysninger), og skal kun
      oppgis direkte til helsepersonellet.
    </p>
    <p className="ReactModal__text">
      Hjemmelegene AS er ikke ansvarlig for situasjoner som oppstår
      som et resultat av at du har gitt feil informasjon under
      bestillingen.
    </p>
    <p className="ReactModal__text">
      Etter utfylt skjema vil du bli bedt om å oppgi et betalingskort.
      Når alle opplysninger er riktig utfylt vil du motta en
      bekreftelse på din bestilling og du har inngått en bindende
      avtale med Hjemmelegene AS.
    </p>
    <p className="ReactModal__text">
      Hjemmelegene AS vil reservere beløpet på ditt betalingskort ved
      bestilling og du blir normalt først belastet når
      helsepersonellet avslutter konsultasjonen. I visse tilfeller kan
      vi belaste kortet ditt før konsultasjonen er fullført, for
      eksempel dersom du bestiller time lang tid i forveien.
    </p>
    <p className="ReactModal__text">
      Tjenesten som Hjemmelegene AS yter og du betaler for, er
      avsluttet når helsepersonellet reiser fra konsultasjonsstedet.
      Det er helsepersonellet og ikke Hjemmelegene AS som yter
      helsehjelp, og Hjemmelegene AS er derfor ikke ansvarlig for
      oppfølging av pasienter etter konsultasjonen. Ytterligere behov
      for bistand/oppfølging ivaretas enten ved direkte avtale med
      helsepersonellet eller gjennom ny bestilling gjennom
      Hjemmelegene. Ved ny bestilling vil du kunne velge mellom de
      tilgjengelige timer, og Hjemmelegene AS kan ikke garantere at
      samme helsepersonell er tilgjengelig for konsultasjon.
    </p>

    <h3 className="ReactModal__heading">
      {' '}
      2.3. BETALING OG AVBESTILLING
    </h3>

    <p className="ReactModal__text">
      Hjemmelegene AS vil reservere beløpet på ditt betalingskort ved
      bestilling (se pkt. 2.2) og du blir først belastet når
      helsepersonellet avslutter konsultasjonen.
    </p>
    <p className="ReactModal__text">
      Du vil gjennomføre betalingen gjennom Hjemmelegene, som benytter
      Stripe som betalingsmetode. Betalingen er underlagt Stripes
      ordinære og til enhver tid gjeldende vilkår. Ved å benytte
      Hjemmelegene, aksepterer du også vilkårene for Stripe slik de
      til enhver tid fremgår på{' '}
      <a
        href="https://stripe.com/payment-terms/legal"
        target="_blank"
        rel="noreferrer noopener"
      >
        denne siden
      </a>
      .
    </p>
    <p className="ReactModal__text">
      Du kan avbestille en avtalt konsultasjon inntil 24 timer før
      oppsatt time. Avbestilling etter dette vil bli belastet
      betalingskortet.
    </p>
    <p className="ReactModal__text">
      Har helsepersonellet møtt opp på den adressen du opplyste i
      bestillingen, må du betale full konsultasjonspris uavhengig av
      resultatet av dette oppmøtet.
    </p>

    <h2 className="ReactModal__heading"> 3. REGULERING AV ANSVAR</h2>

    <p className="ReactModal__text">
      Hjemmelegene AS har som mål at tjenesten skal være operativ og
      tilgjengelig på dagtid (morgen til kveld) alle dager som ikke er
      helligdager. Det kan imidlertid forekomme brudd i tjenesten og
      du aksepterer at Hjemmelegene AS ikke har noe ansvar overfor deg
      for avbrudd, nedetid, forsinkelser, redusert kapasitet o.l.
    </p>
    <p className="ReactModal__text">
      Dersom Hjemmelegene AS misligholder sine forpliktelser etter
      denne avtalen, skal du gi skriftlig varsel til Hjemmelegene AS
      om dette omgående, og angi hva misligholdet består i.
      Hjemmelegene skal deretter avhjelpe misligholdet uten ugrunnet
      opphold.
    </p>
    <p className="ReactModal__text">
      Dersom en av partene vesentlig misligholder sine forpliktelser
      etter avtalen og misligholdet ikke blir utbedret uten ugrunnet
      opphold etter at parten fikk varsel om dette, kan den andre
      parten heve avtalen. Heving av avtalen skjer med virkning fra
      hevingstidspunktet og fremover.
    </p>
    <p className="ReactModal__text">
      Erstatning etter denne avtalen kan kun kreves for direkte
      dokumenterte tap. Det kan ikke kreves erstatning for indirekte
      tap. Som indirekte tap skal anses bl.a. tapt fortjeneste, tap
      som følge av forsinket driftsstart og driftsavbrudd, tap som
      følge av tap av data og krav fra tredjeparter.
    </p>
    <p className="ReactModal__text">
      Du skal holde Hjemmelegene AS skadesløs fra eventuelle krav som
      andre måtte fremme mot Hjemmelegene AS som følge av din bruk av
      Hjemmelegene eller bruk som er knyttet til din brukerkonto.
    </p>
    <p className="ReactModal__text">
      Erstatningsbegrensningene i dette punkt gjelder ikke dersom
      misligholdet skyldes forsett eller grov uaktsomhet.
    </p>

    <h2 className="ReactModal__heading">
      {' '}
      4. BEHANDLING AV PERSONOPPLYSNINGER
    </h2>

    <p className="ReactModal__text">
      Som ledd i Hjemmelegene AS ytelser etter avtalen, vil
      Hjemmelegene AS behandle personopplysninger om deg i form av
      navn og adresse, samt dine bestillinger. Hjemmelegene AS er
      behandlingsansvarlig for denne behandlingen av
      personopplysninger. Formålet med behandlingen er utelukkende å
      levere tjenesten Hjemmelegene etter ditt ønske.
    </p>
    <p className="ReactModal__text">
      Opplysningene som samles inn om deg gjennom din bruk av
      tjenesten Hjemmelegene, vil kun bli utlevert til
      helsepersonellet du kontakter gjennom Hjemmelegene. Opplysninger
      kan også bli utlevert til andre aktører dersom det er nødvendig
      for å oppfylle Hjemmelegene AS&apos; lovpålagte plikter.
    </p>
    <p className="ReactModal__text">
      Du har rett til å kreve innsyn i Hjemmelegene AS&apos;
      behandling av dine personopplysninger. Du har også rett til å
      kreve retting eller sletting av uriktige eller ufullstendige
      personopplysninger.
    </p>
    <p className="ReactModal__text">
      Hjemmelegene AS vil alltid rette seg etter og gjennomføre de
      relevante bestemmelser om personvern og informasjonssikkerhet i
      personopplysningsloven og helselovgivningen. Vil du vite mer om
      Hjemmelegene AS&apos; behandling av personopplysninger om deg,
      kan du se på våre sider:{' '}
      <a
        href="https://www.hjemmelegene.no/personvern"
        target="_blank"
        rel="noreferrer noopener"
      >
        https://www.hjemmelegene.no/personvern
      </a>
      . Dersom du har spørsmål kan du henvende deg på{' '}
      <a href="mailto:personvernombud@hjemmelegene.no">
        personvernombud@hjemmelegene.no
      </a>
      .
    </p>
    <p className="ReactModal__text">
      Hjemmelegene er ikke databehandlingsansvarlig etter
      personopplysningsloven eller pasientjournalloven for eventuelle
      journalpliktige helseopplysninger som blir behandlet i
      tilknytning til din bruk av Hjemmelegene. Dette ansvaret har det
      enkelte helsepersonell. Krav om innsyn i pasientjournal kan
      likevel rettes til Hjemmelegene AS, som vil videreformidle
      kravet til den ansvarlige legen.
    </p>

    <h2 className="ReactModal__heading"> 5. ANGRERETT</h2>

    <p className="ReactModal__text">
      Du har ikke angrerett på bestilling av konsultasjon gjort
      gjennom Hjemmelegene, jf. lov om opplysningsplikt og angrerett
      ved fjernsalg og salg utenom faste forretningslokaler
      (angrerettloven) § 22 bokstav i.
    </p>

    <h2 className="ReactModal__heading"> 6. OVERFØRING AV AVTALEN</h2>

    <p className="ReactModal__text">
      Hjemmelegene AS har rett til å overføre avtalen til en annen
      virksomhet som overtar hele eller deler av Hjemmelegene.
      Hjemmelegene AS har også rett til å overføre avtalen til et
      annet selskap i samme konsern som Hjemmelegene AS. For øvrig
      krever overdragelse eller annen overføring av avtalen samtykke
      fra den andre parten.
    </p>

    <h2 className="ReactModal__heading"> 7. TVISTELØSNING</h2>

    <p className="ReactModal__text">
      Avtalen mellom deg og Hjemmelegene AS er i sin helhet regulert
      av norsk rett. Dersom det oppstår tvist mellom deg og
      Hjemmelegene om tolkningen eller rettsvirkningene av avtalen,
      skal tvisten først søkes i minnelighet. Dersom slike
      forhandlinger ikke fører frem, kan hver av partene forlange
      tvisten avgjort med endelig virkning ved alminnelige domstoler.
      Partene vedtar Oslo tingrett som verneting.
    </p>
  </>
)

export default TermsText
