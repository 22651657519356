import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ValidatedInput from './ValidatedInput'

class Cvc extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.string,
  }

  filterInput(input) {
    return input.substring(0, 3).replace(/[^0-9.]+/g, '')
  }

  validate(text) {
    return text.length == 3
  }

  render() {
    const safeProps = _.omit(this.props, 'isValid', 'style')

    const childProps = {
      ...safeProps,
      validate: this.validate,
      filter: this.filterInput,
      placeholder: 'CVC',
      pattern: '[0-9]*',
      inputMode: 'numeric',
    }

    return (
      <div className="form-group" style={style}>
        <label className="payment-form__label">CVC</label>
        <br />
        <ValidatedInput {...childProps} />
      </div>
    )
  }
}

export default Cvc
