/**
 * Idempotently load an external script.
 *
 * @param {String} URL to a Javascript resource.
 * @returns {Promise}
 */
function loadExternalScript(url) {
  if (document.querySelector(`script[src="${url}"]`)) {
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.setAttribute('data-turbolinks-eval', 'false')
    script.crossorigin = 'anonymous'

    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)

    setTimeout(() => {
      reject(new Error(`Timed out loading ${url}`))
    }, 5000)
  })
}

export default loadExternalScript
