import React from 'react'
import PropTypes from 'prop-types'

import { filterEmpty } from '../../lib/utils'

const FormProgress = ({ steps, currentStep }) => {
  const currentStepIndex = steps.findIndex(({ step }) => step === currentStep)

  const hideSteps = (step) => {
    if (step - 2 > currentStepIndex || step + 2 < currentStepIndex) {
      return 'd-none'
    }
    return
  }

  return (
    <ul className="form-progress">
      {steps.map((step, i) => (
        <li
          key={i}
          className={filterEmpty([
            step.step === currentStep ? 'current' : undefined,
            hideSteps(i),
            'd-md-block',
          ])}
        >
          {step.text}
        </li>
      ))}
    </ul>
  )
}

FormProgress.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
}

export default FormProgress
