import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import gqlCall from '../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
} from '../api/graphqlExtended'
import Button from './Button'
import NationalIdInput from './forms/fields/NationalIdInput'
import ShieldedSpinner from './ShieldedSpinner'

const FetchPatientBySsn = () => {
  const [ssn, setSsn] = useState('')
  const [patient, setPatient] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [patientNotFound, setPatientNotFound] = useState(false)

  const onChangeSsn = (value) => {
    setSsn(value)
  }

  const graphqlSearch = async () => {
    setLoading(true)
    try {
      const response = await gqlCall(
        buildGraphQLQuery({
          searchOptions: { nationalId: ssn },
          resourceName: 'patient',
          multiple: false,
        }),
      )

      const parsedResponse = parseQueryResponse({
        response: response,
        resourceName: 'patient',
        multiple: false,
      })

      if (parsedResponse === null) {
        setPatientNotFound(true)
        setPatient(null)
        setLoading(false)
      } else {
        setPatientNotFound(false)
        setPatient(parsedResponse)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      setError(e)
    }
  }

  if (loading) return <ShieldedSpinner show={loading} />

  return (
    <div className="hlform">
      <div style={{ position: 'relative' }}>
        <NationalIdInput
          label="Personnummer"
          showValidation={false}
          highlightErrors={false}
          onChange={onChangeSsn}
          value={ssn}
          onBlur={graphqlSearch}
        />
        <Button
          onClick={graphqlSearch}
          label="Hent pasient"
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        />
      </div>
      {patientNotFound ? (
        <div className="info-box info-box-danger mb-2">
          <p>Patient not found</p>
        </div>
      ) : null}
      {error ? (
        <div className="info-box info-box-danger mb-2">
          <p>{error.message}</p>
        </div>
      ) : null}
      {patient !== null ? (
        <div className="info-box mb-2">
          <input
            autoComplete="off"
            type="hidden"
            name="prescription[patient_id]"
            id="prescription_patient_id"
            value={patient.id}
          />
          <dl className="dl-table">
            <dt>Navn:</dt>
            <dd>{patient.name}</dd>
            <dt>E-post:</dt>
            <dd>{patient.email}</dd>
            <dt>Telefon:</dt>
            <dd>{patient.phoneNumber}</dd>
          </dl>
        </div>
      ) : null}
    </div>
  )
}

export default FetchPatientBySsn
