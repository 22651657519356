import React from 'react'
import TextInput from './TextInput'

const InvoiceReference = (props) => {
  return <TextInput {...props} />
}

InvoiceReference.defaultProps = {
  ...TextInput.defaultProps,
  name: 'invoicereference',
  label: 'Fakturareferanse',
}

InvoiceReference.propTypes = TextInput.propTypes

export default InvoiceReference
