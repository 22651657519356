import React from 'react'
import { Provider } from 'react-redux'
import store from '../../store'
import RouteDashboard from './RouteDashboard'
import PropTypes from 'prop-types'

const RouteDashboardContainer = (props) => {
  return (
    <Provider store={store}>
      <RouteDashboard googleMapsApiKey={props.googleMapsApiKey} />
    </Provider>
  )
}

RouteDashboardContainer.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
}

export default RouteDashboardContainer
