import React from 'react'
import PropTypes from 'prop-types'

export function DeletedAppointment({ t }) {
  return (
    <>
      <h1 className="mb-2">{t('your_appointment', s)}</h1>{' '}
      <p>{t('already_cancelled', s)}</p>
    </>
  )
}

DeletedAppointment.propTypes = {
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    your_appointment: 'Din time',
    already_cancelled: 'Denne timen er allerede avbestilt',
  },
  en: {
    your_appointment: 'Your appointment',
    already_cancelled: 'This appointment is already cancelled',
  },
}
