import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { propTypes } from './FieldWrapper'
import Label from './Label'
import _ from 'lodash'

const IntegerInput = (props) => {
  const [valid, setValid] = useState(props.validate(props.value))
  const [value, setValue] = useState('')

  const handleChange = (e) => {
    const newVal = parseInt(e.target.value)
    const newValid = props.validate(newVal)
    setValue(newVal)
    setValid(newValid)
    if (props.onChange) props.onChange(newVal, newValid, props.name)
  }

  const handleInput = (e) => {
    const filteredVal = props.filter(e.target.value)
    e.target.value = props.sanitize(filteredVal)
  }

  useEffect(() => {
    if (props.value) {
      const newValid = props.validate(props.value)
      setValue(props.value)
      setValid(newValid)
    }
  }, [props])

  const inputProps = _.pick(props, [
    'name',
    'placeholder',
    'autoComplete',
    'disabled',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'min',
    'max',
  ])

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    empty: !value || value.length === 0,
    valid,
  }

  inputProps.placeholder =
    props.disabled && props.disabledPlaceholder
      ? props.disabledPlaceholder
      : props.placeholder

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <input
        type="number"
        value={value}
        onChange={handleChange}
        onInput={handleInput}
        className="input textfield"
        {...inputProps}
      />
    </FieldWrapper>
  )
}

IntegerInput.defaultProps = {
  value: 0,
  placeholder: '',
  autoComplete: 'off',
  highlightErrors: false,
  disabled: false,
  validate: (value) => /^[0-9]*$/.test(value),
  filter: (value) => value,
  sanitize: (value) => value,
}

IntegerInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  pattern: PropTypes.string,
  sanitize: PropTypes.func,
  filter: PropTypes.func,
  validate: PropTypes.func,
  maxLength: PropTypes.number,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  onRef: PropTypes.func,
}

export default IntegerInput
