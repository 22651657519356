import React from 'react'
import PropTypes from 'prop-types'
import { ClinicContextProvider } from './ClinicContext'
import ClinicAppointmentList from './ClinicAppointmentList'

const ClinicDashboard = (props) => {
  const maxCapacity = () => {
    if (props.clinic) return Math.min(props.clinic.max_capacity, 5)
    return 5
  }

  return (
    <ClinicContextProvider
      clinic={props.clinic}
      booking={props.booking}
      assetRootUrl={props.assetRootUrl}
      qrPosterUrl={props.qrPosterUrl}
      maxCapacity={maxCapacity()}
      serviceProvider={props.serviceProvider}
      qrCode={props.qrCode}
      nextBooking={props.nextBooking}
      googleMapsApiKey={props.googleMapsApiKey}
    >
      <ClinicAppointmentList />
    </ClinicContextProvider>
  )
}

ClinicDashboard.propTypes = {
  qrPosterUrl: PropTypes.string.isRequired,
  clinic: PropTypes.object,
  booking: PropTypes.object,
  serviceProvider: PropTypes.object.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  qrCode: PropTypes.string.isRequired,
  nextBooking: PropTypes.object,
  googleMapsApiKey: PropTypes.string.isRequired,
}

export default ClinicDashboard
