import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ReactModal from 'react-modal'

const CODE_VALIDATION_ERR_MSG = 'Feil kode. Prøv på nytt.'

import {
  verifyMobileCarrier,
  validateOTP,
  sendCode,
  createOtpSession,
  verifyOtpSession,
} from '../lib/phone-validation'

const smsSendDelaySeconds = 60

class PhoneValidationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string,
    loginMode: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    ReactModal.setAppElement('body')

    this.state = {
      phoneNumber: '',
      codeLastSentAt: null,
      isReadyForInput: false,
      hasResentCode: false,
      formValid: false,
      inputCode: '',
      codeValidationFail: true,
      numberValidationFail: true,
      errorMessage: null,
    }
  }

  start = async () => {
    const response = await verifyMobileCarrier(this.props.phoneNumber)
    if (response.err) {
      this.setState({
        isReadyForInput: false,
        errorMessage: `${this.props.phoneNumber} er ikke et gyldig mobiltelefonnummer. Lukk vinduet, sjekk nummeret og prøv på nytt!`,
      })
    } else {
      this.sendCodeToPhone(
        this.props.phoneNumber,
        this.props.authenticityToken,
      )
    }
  }

  sendCodeToPhone = async () => {
    const phoneNumber = this.props.phoneNumber
    const authenticityToken = this.props.authenticityToken

    if (!this.allowSendCode()) return

    this.setState({ codeLastSentAt: new Date() })

    if (this.props.loginMode) {
      const response = await createOtpSession(
        phoneNumber,
        authenticityToken,
      )
      if (response.err) {
        this.setState({
          codeValidationFail: response.err.status === 401,
          numberValidationFail: response.err.status === 422,
          errorMessage: response.err.message,
          isReadyForInput: false,
        })
      } else {
        this.setState({
          codeValidationFail: false,
          numberValidationFail: false,
          errorMessage: null,
          isReadyForInput: true,
          inputCode: response.code, // Only returned in Rails.env.development?
          formValid: response.code !== null,
        })
      }
    } else {
      const response = await sendCode(phoneNumber)
      if (response.err) {
        this.setState({
          isReadyForInput: false,
          errorMessage: response.err.message,
        })
      } else {
        // dev/test/state returns the code for easier debugging
        this.setState({
          codeValidationFail: false,
          numberValidationFail: false,
          errorMessage: null,
          isReadyForInput: true,
          inputCode: response.code, // Only returned in Rails.env.development?
          formValid: response.code !== null,
        })
      }
    }
  }

  validateCode = async () => {
    if (this.props.loginMode) {
      const response = await verifyOtpSession(
        this.props.phoneNumber,
        this.state.inputCode,
      )
      if (response.err) {
        this.setState({
          codeValidationFail: true,
          errorMessage:
            response.err.message || CODE_VALIDATION_ERR_MSG,
        })
      } else {
        this.setState({ codeValidationFail: false })
        this.props.close()
        this.props.submit()
      }
    } else {
      const response = await validateOTP(
        this.props.phoneNumber,
        this.state.inputCode,
      )
      if (response.err) {
        this.setState({
          codeValidationFail: true,
          errorMessage:
            response.err.message || CODE_VALIDATION_ERR_MSG,
        })
      } else {
        this.setState({ codeValidationFail: false })
        this.props.close()
        this.props.submit()
      }
    }
  }

  allowSendCode = () => {
    return (
      !this.state.codeLastSentAt ||
      (new Date() - this.state.codeLastSentAt) / 1000 <
        smsSendDelaySeconds
    )
  }

  codeInputChange = (e) => {
    const input = e.target.value
    this.setState({
      inputCode: input,
      formValid: /^\d{4}$/.test(input),
    })
  }

  handleKeyPress = (e) => {
    if (e.key == 'Enter' && this.state.formValid) {
      this.validateCode()
    }
  }

  ResendCodeToPhoneLink() {
    if (this.state.hasResentCode) {
      return <div>Ny kode har blitt sendt</div>
    } else {
      return (
        <div
          className="ReactModal__link"
          onClick={this.sendCodeToPhone}
        >
          Ikke mottatt en kode?
        </div>
      )
    }
  }

  CodeValidationFailMessage() {
    if (this.state.codeValidationFail) {
      return (
        <div className="ReactModal__text ReactModal__text--center ReactModal__text--error mb-1">
          {this.state.errorMessage}
        </div>
      )
    }
  }

  closeAndCleanState = () => {
    this.props.close()
    this.setState({
      errorMessage: null,
    })
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeAndCleanState}
        onAfterOpen={this.start}
        contentLabel="Phone code verification modal"
        id="PhoneValidationModal"
      >
        {!this.state.numberValidationFail && (
          <>
            <div
              ref={(subtitle) => (this.subtitle = subtitle)}
              className="ReactModal__heading"
            >
              Bekreft telefonnummer
            </div>
            <div
              className="ReactModal__exit-button-new"
              onClick={this.closeAndCleanState}
            />
            <p className="ReactModal__text">
              Vi trenger å bekrefte ditt telefonnummer. Vi har sendt
              kode til{' '}
              <strong className="nowrap">
                {this.props.phoneNumber}
              </strong>{' '}
              som du må skrive i feltet under.
            </p>
            {this.CodeValidationFailMessage()}
            <input
              type="text"
              name="code"
              autoComplete="one-time-code"
              className="form-control ReactModal__input"
              placeholder="Bekreftelseskode"
              value={this.state.inputCode}
              onChange={this.codeInputChange}
              onKeyPress={this.handleKeyPress}
              pattern="^\d{4}$"
              inputMode="numeric"
            />
            <button
              className="btn btn-secondary btn-block ReactModal__submit"
              disabled={!this.state.formValid}
              onClick={this.validateCode}
              id="PhoneValidationModalSubmit"
            >
              {this.props.buttonLabel || 'Bekreft'}
            </button>

            <div className="ReactModal__text ReactModal__text--center">
              {this.ResendCodeToPhoneLink()}
            </div>
          </>
        )}
        {this.state.numberValidationFail && (
          <>
            <div
              className="payment-form__icon ReactModal__exit-button-new"
              onClick={this.closeAndCleanState}
            />
            <h3
              ref={(subtitle) => (this.subtitle = subtitle)}
              className="ReactModal__heading"
            >
              {!this.state.errorMessage
                ? 'Sjekker mobilnummer...'
                : 'Feil'}
            </h3>
            <p>
              {this.state.errorMessage ? (
                this.state.errorMessage
              ) : (
                <span className="spinner-border text-primary" />
              )}
            </p>
          </>
        )}
      </ReactModal>
    )
  }
}

export default PhoneValidationModal
