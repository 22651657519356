import {
  DEFAULT_GRAPHQL_QUERY_PARAMS,
  GRAPHQL_TYPE_INPUT_PARAMS,
} from './defaultGraphQLParams'
import moment from 'moment-timezone'

export const queryArgTypeFormatting = (resourceName, name, value) => {
  switch (
    GRAPHQL_TYPE_INPUT_PARAMS[resourceName][name].split('.')[0]
  ) {
    case 'string':
      return `"${value}"`
      break
    case 'dateTimeOfDay':
      return `"${moment(value)
        .tz('Europe/Oslo')
        .format('yyyy-MM-DDTHH:mm:ssZ')}"`
      break
    case 'dateBeginningOfDay':
      return `"${moment(value)
        .tz('Europe/Oslo')
        .startOf('day')
        .format('yyyy-MM-DDTHH:mm:ssZ')}"`
      break
    case 'dateEndOfDay':
      return `"${moment(value)
        .tz('Europe/Oslo')
        .endOf('day')
        .format('yyyy-MM-DDTHH:mm:ssZ')}"`
      break
    default:
      return value
  }
}

// shortcut assuming that searchoptions or either an id (integer) or a sting
const objToString = (obj) => {
  const length = Object.entries(obj).length
  let i = 0
  return Object.entries(obj).reduce((str, [k, val]) => {
    i += 1
    const isInt = /id|first|last/.test(k)
    return `${str}${k}: ${isInt ? val : `"${val}"`}${
      i === length - 1 ? ',' : ''
    }`
  }, '')
}

const buildQueryList = (resourceName, searchOptions, params) => {
  return `query {
      ${resourceName}${
    searchOptions ? `(${objToString(searchOptions)})` : ''
  }{
        edges {
          cursor
          node {
            __typename
           ${
             params
               ? params
               : DEFAULT_GRAPHQL_QUERY_PARAMS[resourceName]
           }
          }
        }
      }
    }`
}

const buildQuery = (resourceName, searchOptions, params) => {
  return `query {
      ${resourceName}(${objToString(searchOptions)}){
        ${
          params ? params : DEFAULT_GRAPHQL_QUERY_PARAMS[resourceName]
        }
      }
    }`
}

const buildMutation = (resourceName, params) =>
  `mutation {
      ${resourceName}(
        ${
          params ? params : DEFAULT_GRAPHQL_QUERY_PARAMS[resourceName]
        } ) {
          ${DEFAULT_GRAPHQL_QUERY_PARAMS[resourceName]}
         }
    }`

export const buildGraphQLQuery = ({
  searchOptions = null,
  type = 'query',
  multiple = true,
  resourceName,
  params = null,
}) =>
  type === 'query'
    ? multiple
      ? buildQueryList(resourceName, searchOptions, params)
      : buildQuery(resourceName, searchOptions, params)
    : buildMutation(resourceName, params)

const parseQueryList = (response, resourceName) => {
  return response.data.data[resourceName].edges.map(
    ({ node, cursor }) => {
      return { ...node, cursor }
    },
  )
}

const parseQuery = (response, resourceName) => {
  return response.data.data[resourceName]
}

export const parseQueryResponse = ({
  response,
  resourceName,
  multiple = true,
}) => {
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  } else {
    return multiple
      ? parseQueryList(response, resourceName)
      : parseQuery(response, resourceName)
  }
}

export const defaultGraphQLSerializer = (data, resourceName) => {
  const attrArray = Object.entries(data).filter((array) =>
    Object.keys(GRAPHQL_TYPE_INPUT_PARAMS[resourceName]).includes(
      array[0],
    ),
  )

  const args = attrArray.reduce((prevVal, attr, step) => {
    const [parameter, value] = attr
    if (
      value ||
      /.*\.enableEmpty/.test(
        GRAPHQL_TYPE_INPUT_PARAMS[resourceName][parameter],
      )
    ) {
      return step === 0
        ? `${parameter}: ${queryArgTypeFormatting(
            resourceName,
            parameter,
            value,
          )}`
        : [
            prevVal,
            `${parameter}: ${queryArgTypeFormatting(
              resourceName,
              parameter,
              value,
            )}`,
          ].join(',')
    } else {
      return prevVal
    }
  }, '')

  return [args]
}
