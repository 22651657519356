import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

export default function CheckoutForm({ redirectPath, price }) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentElementReady, setPaymentElementReady] =
    useState(false)

  const errorMessages = {
    card_error: {
      incorrect_number: 'Kortnummer er feil',
      invalid_number: 'Kortnummeret er ikke et gyldig kortnummer',
      invalid_expiry_month: 'Utløpsmåned er feil',
      invalid_expiry_year: 'Utløpsår er feil',
      invalid_cvc: 'CVC er ugyldig',
      expired_card: 'Kortet har utløpt',
      incorrect_cvc: 'CVC er feil',
      amount_too_large: 'Beløpet er for stort',
      amount_too_small: 'Beløpet er for lite',
      incorrect_zip: 'Ugyldig postnummer',
      invalid_card_type:
        'Vi støtter ikke dette kortet. Forsøk et annet kort.',
      balance_insufficient: 'Ikke dekning på kortet',
      card_declined: 'Kortet ble avvist av banken',
      country_unsupported:
        'Kortet et utstedt i et land vi ikke støtter',
      bank_account_declined: 'Kortets konto ble avvist av banken',
      card_decline_rate_limit_exceeded:
        'Kortet ditt har blitt avvist for mange ganger. Kontakt banken eller prøv igjen om 24 timer.',
      missing: 'Det finnes ikke kort på kunden som blir belastet',
      processing_error: 'Noe gikk galt ved behandling av kortdata',
      rate_limit:
        'Vi opplever for tiden stor trafikk, vennligst prøv igjen om litt.',
    },
    invalid_request_error: {
      msg: 'Finner ingen betalingsinformasjon',
    },
  }

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(
      window.location.search,
    ).get('payment_intent_client_secret')

    if (!clientSecret) {
      return
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment succeeded!')
            break
          case 'processing':
            setMessage('Your payment is processing.')
            break
          case 'requires_payment_method':
            setMessage(
              'Your payment was not successful, please try again.',
            )
            break
          default:
            setMessage('Something went wrong.')
            break
        }
      })
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: redirectPath,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      error.type === 'card_error' ||
      error.type === 'validation_error'
    ) {
      const translatedError = errorMessages[error.type][error.code]
      setMessage(translatedError)
    } else {
      setMessage('An unexpected error occured.')
      Sentry.captureException('Stripe error', {
        extra: error,
      })
    }

    setIsLoading(false)
  }

  const handleReadyChange = () => {
    setPaymentElementReady(true)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        className="mb-1"
        onReady={handleReadyChange}
      />
      {!stripe || !elements || !paymentElementReady ? (
        <div className="spinner" id="spinner"></div>
      ) : (
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="btn btn-secondary btn-block payment-form__button mt-4"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              `Betal NOK ${price}`
            )}
          </span>
        </button>
      )}

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

CheckoutForm.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}
