const addressParams = `
    id
    address
    zip
    city
    countryCode
    type
  `

const organizationParams = `
  id
  name
  orgNr
  billingDueBy
  billingEmail
  billingReference
  billingMethod
  visitingAddress {${addressParams}}
  mailingAddress {${addressParams}}
  fullMailingAddress
  fullVisitAddress
  contactPersonId`

const productParams = `
  id
  name
  publicName
  price
  slug
  type
  description
  vatRate
  requireShipping
  requireHousecall
  requireDigital
  `

const priceModelParams = `
  id
  productId
  priceType
  currency
  product {${productParams}}
  tiers
  title
`

const couponParams = `
  id
  code
  active
  userId
  productId
  organizationId
  customerGroup
`
const dealParams = `
    id
    organizationId
    couponId
    description
    receiptText
    adminDescription
    effectiveFrom
    effectiveTo
    responsible
    paymentType
    billingDueBy
    billingEmail
    billingReference
    invoicingMethod
    organization {${organizationParams}}
    coupon {${couponParams}}
    priceModels {${priceModelParams}}
    customDealPath
  `
const dealChangeParams = `
  id
`

const serviceCenterParams = `
    id
    operationType
    name
    title
    street
    zip
    city
    googlePlaceId
    lat
    lng
`
const userParams = `
    id
    name
    email
    phoneNumber
    locale
    nationalId
    patientId
`

const patientParams = `
    id
    name
    email
    phoneNumber
    nationalId
`
const contactPersonParams = `
    id
    name
    email
    phoneNumber
`

const appointmentParams = `
    id
    serviceCenter {${serviceCenterParams}}
    booking { id }
    date
    hour
    timeslot
    createdAt
    deletedAt
    currentlyEditedById
    unitPrice
    user {${userParams}}
    products { consentFields { description, slug }}
    appointmentsPatients {id, patientId, name, phoneNumber, nationalId, appearedAt, readyAt, productAdministeredAt, sysvacRegisteredAt, options, companyName, consentForEmployerToSeeEmployeeName, whoIsPaying}
`

const minifiedAppointmentParams = `
    id
    hour
    date
    unitPrice
    products { consentFields { description, slug }}
    user {
      id
      name
    }
`

const appointmentPatientParams = `
    id
    patientId
    hour
    timeslot
    name
    phoneNumber
    nationalId
    appearedAt
    readyAt
    productAdministeredAt
    sysvacRegisteredAt
    currentlyEditedById
    appointment {${minifiedAppointmentParams}}
    options
    companyName
    consentForEmployerToSeeEmployeeName
    whoIsPaying
`

const lineItemsParams = `
    id
    orderId
    bookingIds
`
const orderParams = `
  id
  lineItems { id, quantity, product { id, name, slug, price } }
  payment { id, state }
`

const paymentParams = `
  id
  state
`

const serviceCenterPatientsParams = `
  ${appointmentPatientParams}
`

const availableHoursParams = `
  availableBookings { beginsAt, endsAt, date, spProductsNames, spTypesName, serviceProvider { id, fullName } }
`

export const DEFAULT_GRAPHQL_QUERY_PARAMS = {
  organization: `${organizationParams}`,
  organizations: `${organizationParams}`,
  createOrganization: `${organizationParams}`,
  updateOrganization: `${organizationParams}`,
  coupons: `${couponParams}`,
  priceModels: `${priceModelParams}`,
  product: `${productParams}`,
  deal: `${dealParams}`,
  deals: `${dealParams}`,
  createDeal: `${dealParams}`,
  updateDeal: `${dealParams}`,
  serviceCenterPatients: `${serviceCenterPatientsParams}`,
  bookingAppointmentPatients: `${serviceCenterPatientsParams}`,
  user: `${userParams}`,
  createUser: `${userParams}`,
  appointmentPatient: `${appointmentPatientParams}`,
  patient: `${patientParams}`,
  createPatients: `${patientParams}`,
  createAppointment: `${appointmentParams}`,
  appointment: `${appointmentParams}`,
  lineItems: `${lineItemsParams}`,
  updateOrder: `${orderParams}`,
  updatePaymentState: `${paymentParams}`,
  deleteAppointmentsPatient: `${appointmentPatientParams}`,
  createAppointmentsPatient: `${appointmentPatientParams}`,
  updateAppointmentsPatient: `${appointmentPatientParams}`,
  updateAppointment: `${appointmentParams}`,
  cancelAppointment: `${appointmentParams}`,
  createContactPerson: `${contactPersonParams}`,
  updateContactPerson: `${contactPersonParams}`,
  availableHours: `${availableHoursParams}`,
  changeDefaultDeal: `${dealChangeParams}`,
}

export const GRAPHQL_TYPE_INPUT_PARAMS = {
  deals: {
    id: 'integer',
    organizationId: 'integer',
    couponId: 'integer',
    effectiveFrom: 'dateTimeOfDay',
    effectiveTo: 'dateTimeOfDay',
    description: 'string',
    receiptText: 'string',
    adminDescription: 'string',
    responsible: 'string',
    billingDueBy: 'integer.enableEmpty',
    billingEmail: 'string.enableEmpty',
    invoicingMethod: 'enum',
    paymentType: 'enum',
    billingReference: 'string.enableEmpty',
    priceModelIds: 'Array',
  },
  appointments: {
    id: 'integer',
    currentlyEditedAction: 'string.enableEmpty',
    userId: 'integer',
  },
  createUser: {
    id: 'integer',
    name: 'string',
    phoneNumber: 'string',
    nationalId: 'string',
    email: 'string',
    locale: 'enum',
  },
  createPatients: {
    id: 'integer',
    name: 'string',
    phoneNumber: 'string',
    nationalId: 'string',
    email: 'string',
  },
  appointmentsPatients: {
    id: 'integer',
    name: 'string',
    phoneNumber: 'string',
    nationalId: 'string',
    email: 'string',
    readyAt: 'dateTimeOfDay',
    appearedAt: 'dateTimeOfDay',
    sysvacRegisteredAt: 'dateTimeOfDay',
    productAdministeredAt: 'dateTimeOfDay',
    currentlyEditedById: 'integer',
    appointmentId: 'integer',
  },
  updateLineItemInOrder: {
    id: 'integer',
    quantity: 'integer',
    productID: 'integer',
    productSlug: 'string',
  },
  createAppointment: {
    id: 'integer',
  },
  payment: {
    id: 'string',
    state: 'enum',
  },
  createOrganization: {
    id: 'integer',
    name: 'string',
    orgNr: 'string',
    contactPersonId: 'integer',
    billingDueBy: 'integer',
    visitingAddress: 'string',
    mailingAddress: 'string',
    billingEmail: 'string',
    billingReference: 'string',
    billingMethod: 'string',
  },
  updateOrganization: {
    id: 'integer',
    name: 'string',
    orgNr: 'string',
    contactPersonId: 'integer',
    billingDueBy: 'integer',
    visitingAddress: 'string',
    mailingAddress: 'string',
    billingEmail: 'string',
    billingReference: 'string',
    billingMethod: 'string',
  },
  createContactPerson: {
    id: 'integer',
    name: 'string',
    phoneNumber: 'string',
    email: 'string',
  },
  updateContactPerson: {
    id: 'integer',
    name: 'string',
    phoneNumber: 'string',
    email: 'string',
  },
}
