import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from './ValidatedInput'

class Checkbox extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    initialValue: PropTypes.bool,
    showError: PropTypes.bool,
  }

  validate(value) {
    return !!value
  }

  errorClass() {
    if (this.props.showError && !this.props.isValid) {
      return 'error'
    }

    return ''
  }

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      type: 'checkbox',
    }

    return (
      <label className="booking-form__label">
        <div
          className={`booking-form__checkbox-wrapper ${this.errorClass()}`}
        >
          <ValidatedInput {...props} />
        </div>
        <div
          className={`booking-form__checkbox-text ${this.errorClass()}`}
        >
          {props.label}
        </div>
      </label>
    )
  }
}

export default Checkbox
