import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

function ErrorModal(props) {
  ReactModal.setAppElement('body')

  return (
    <ReactModal isOpen={props.isOpen}>
      <div
        className="ReactModal__exit-button-new"
        onClick={props.close}
      />

      <div className="ReactModal__heading mb-3">
        🙀 Oisann, noe gikk galt
      </div>

      <p>Kunne ikke oppdatere timeboken</p>

      <p>{props.error}</p>

      <button
        className="btn btn-secondary btn-block ReactModal__submit"
        onClick={props.close}
      >
        Lukk
      </button>
    </ReactModal>
  )
}

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default ErrorModal
