import moment from 'moment'

import { capitalizeFirstLetter } from './utils'

export const dateFormat = (time) => capitalizeFirstLetter(
  moment(time)
    .tz('Europe/Oslo')
    .format('dddd Do MMMM [kl.] HH:mm')
  )

export const hourFormat = (time) => capitalizeFirstLetter(
moment(time)
  .tz('Europe/Oslo')
  .format('HH:mm')
)
