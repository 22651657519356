import React from 'react'
import PropTypes from 'prop-types'

const Chevron = ({ side, onChange, chevronIconPath }) => {
  const handleClick = (e) => {
    e.preventDefault()
    onChange()
  }
  return (
    <a
      className="button-group-item"
      style={{ width: '34px', height: '34px' }}
      onClick={handleClick}
    >
      {side === 'left' ? (
        <img
          className="backoffice__flip-image"
          src={chevronIconPath}
        ></img>
      ) : (
        <img src={chevronIconPath}></img>
      )}
    </a>
  )
}

Chevron.propTypes = {
  side: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  chevronIconPath: PropTypes.string.isRequired,
}

export default Chevron
