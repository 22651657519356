import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const RadioButtonsBoolean = ({
  options,
  name,
  defaultChecked,
  instanceId,
}) => {
  const findDefaultChecked = (optionValue) =>
    options.find((option) => option.value === defaultChecked)
      ? defaultChecked === optionValue
      : false

  return (
    <div className="hl-radio-group">
      {options.map((option, index) => (
        <Fragment key={option.value}>
          <input
            id={`hl-radio-${option.value}-${instanceId}`}
            name={name}
            value={option.value}
            type="radio"
            defaultChecked={findDefaultChecked(option.value)}
          />
          <label htmlFor={`hl-radio-${option.value}-${instanceId}`}>
            <h3 className="radio-header">{option.label1}</h3>
            {option.label2}
          </label>
        </Fragment>
      ))}
    </div>
  )
}

RadioButtonsBoolean.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultChecked: PropTypes.string,
  instanceId: PropTypes.number.isRequired,
}

export default RadioButtonsBoolean
