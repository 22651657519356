import PropTypes from 'prop-types'
import React from 'react'

const Button = (props) => {
  return (
    <button
      type="button"
      className={'btn ' + props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.style}
    >
      {props.label}
    </button>
  )
}
// btn btn-secondary btn-block payment-form__button',
Button.defaultProps = {
  label: 'OK',
  onClick: () => {},
  disabled: false,
  className: 'btn-primary',
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
}

export default Button
