import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
  return (
    <button
      type="button"
      className={'btn ' + props.className}
      style={props.style}
      disabled={props.disabled}
      onClick={props.onClick}
      id={props.id}
      data-type={props.dataType}
    >
      {props.children || props.label}
    </button>
  )
}

Button.defaultProps = {
  label: 'Submit',
  onClick: () => {},
  disabled: false,
  className: 'btn-primary',
  style: {},
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  id: PropTypes.string,
  dataType: PropTypes.string,
  style: PropTypes.object,
}

export default Button
