import React from 'react'
import PropTypes from 'prop-types'

const TextButton = (props) => {
  return (
    <button
      type="button"
      className={'btn btn-secondary btn-intext ' + props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  )
}
// btn btn-secondary btn-block payment-form__button',
TextButton.defaultProps = {
  label: 'OK',
  onClick: () => {},
  disabled: false,
  className: '',
}

TextButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default TextButton
