import React from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import Shield from '../Shield'

const ErrorView = ({ error, reset, t }) => (
  <Shield show={true}>
    <div className="p-5">
      <h1>{t('heading', s)}</h1>
      <p>{t('instructions', s)}</p>
      <Button
        className="btn-primary my-3"
        label={t('restart')}
        onClick={reset}
      ></Button>
      {error && (
        <p className="text-danger small">{error.toString()}</p>
      )}
    </div>
  </Shield>
)

ErrorView.propTypes = {
  error: PropTypes.any,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Vi beklager, det har oppstått en feil',
    instructions:
      'Dessverre var det noe som ikke virket helt som det skulle. Hjemmelegene har fått beskjed. Prøv gjerne på nytt!',
  },
  en: {
    heading: "We're sorry, but something went wrong",
    instructions:
      'Sorry, but it seems there was an error. Hjemmelegene have been notified. Try again!',
  },
  pl: {
    heading: 'Przepraszamy, ale coś poszło nie tak',
    instructions:
      'Przepraszamy, ale wygląda na to, że wyniknął błąd. Hjemmelegene zostało poinformowane. Spróbuj raz jeszcze!',
  },
}

export default ErrorView
