import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from '../forms/fields/PhoneInput'
import OtpInput from '../forms/fields/OtpInput'
import Submit from '../forms/fields/SubmitButton'
import Button from '../forms/fields/Button'
import HLForm from '../forms/HLForm'

import * as Sentry from '@sentry/react'

import { sendOtpCode, verifyOtpCode } from './queries'

const INITIAL_STATE = {
  phoneNumber: null,
  phoneValid: false,
  code: null,
  codeValid: false,
  error: null,
}

const Otp = ({
  onComplete,
  setLoading,
  locale,
  reset,
  onError,
  t,
}) => {
  const [state, setState] = useState(INITIAL_STATE)

  const resetOtp = () => {
    setState(INITIAL_STATE)
  }

  const handleOtpSubmit = async (e, values) => {
    setLoading(true)
    const response = await verifyOtpCode(
      state.phoneNumber,
      values.otp_code,
      locale,
    )

    if (response.errors) {
      setState({
        ...state,
        error: t('authFailed', s),
      })
    } else if (!response.data) {
      return onError(response)
    } else {
      onComplete(response.data)
    }
    setLoading(false)
  }

  const handlePhoneNumberSubmit = async (e, values) => {
    setLoading(true)
    try {
      const response = await sendOtpCode(values.phoneNumber)
      if (response.netError) {
        if (
          response.errors.message ===
          'Errors::MobileNumberInvalidError'
        ) {
          setState({
            ...state,
            error: t('numberInvalid', s),
          })
        } else {
          setState({
            ...state,
            error: response.errors.message.toString(),
          })
        }
        setLoading(false)
        return
      }

      setState({
        ...state,
        phoneNumber: values.phoneNumber,
        code: response.data?.otpCode || '',
        codeValid: response.data?.otpCode?.length === 4,
      })
    } catch (e) {
      setState({ error: e.toString() })
      Sentry.captureException(e, { extra: { state } })
      // console.error(e)
    }
    setLoading(false)
  }

  const handlePhoneChange = (value, valid) => {
    setState({ ...state, phoneValid: valid })
  }

  const handleCodeChange = (value, valid) => {
    setState({ ...state, code: value, codeValid: valid })
  }

  return (
    <section className="otp-auth">
      <h1 className="mb-4">{t('heading', s)}</h1>
      {!state.phoneNumber && (
        <>
          <p>{t('start', s)}</p>
          <HLForm
            preventSubmit={true}
            onSubmit={handlePhoneNumberSubmit}
            omitCsrf={true}
          >
            <PhoneInput
              highlightErrors={true}
              onChange={handlePhoneChange}
              label={t('phoneNumber')}
              autoComplete="off"
              autoFocus={true}
              name="phoneNumber"
              attentionMessage={t('findYourPhone', s)}
              isRequired
            ></PhoneInput>
            {state.error && (
              <p className="text-danger">{state.error}</p>
            )}
            <div className="row">
              <div className="col-6">
                <Button
                  label={t('restart')}
                  className="btn-link mt-4"
                  onClick={reset}
                />
              </div>
              <div className="col-6 text-right">
                <Submit
                  disabled={!state.phoneValid}
                  label={t('sendCode')}
                  className="my-3"
                ></Submit>
              </div>
            </div>
          </HLForm>
        </>
      )}
      {state.phoneNumber && (
        <>
          <p>{`${t('phoneNumber')}: ${state.phoneNumber}`}</p>
          <HLForm
            preventSubmit={true}
            onSubmit={handleOtpSubmit}
            omitCsrf={true}
          >
            <OtpInput
              autoFocus={true}
              label={t('smsCode')}
              onChange={handleCodeChange}
              value={state.code || ''}
            ></OtpInput>
            {state.error && (
              <p className="text-danger">{state.error}</p>
            )}
            <div className="row my-3">
              <div className="col-6">
                <Button
                  className="btn-link mt-2 text-left"
                  label={t('changePhone', s)}
                  onClick={resetOtp}
                ></Button>
              </div>
              <div className="col-6 text-right">
                <Submit
                  disabled={!state.codeValid}
                  label={t('confirm')}
                ></Submit>
              </div>
            </div>
          </HLForm>
        </>
      )}
    </section>
  )
}

Otp.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Bekreft telefonnummer',
    sendCode: 'Send kode',
    smsCode: 'SMS-kode',
    changePhone: 'Endre telefonnummer',
    numberInvalid: 'Ugyldig telefonnummer',
    authFailed:
      'Ugyldig kode. Forsøk igjen eller gå tilbake og endre telefonnummeret.',
    start: 'Skriv inn telefonnummeret ditt',
    findYourPhone:
      'Du vil motta en kode på SMS til dette nummeret innen et halvt minutt',
  },
  en: {
    heading: 'Confirm phone number',
    sendCode: 'Send code',
    smsCode: 'Code from SMS',
    changePhone: 'Change phone number',
    numberInvalid: 'Invalid phone number',
    authFailed:
      'Invalid code. Try again or go back and correct the phone number.',
    start: 'Enter your phone number',
    findYourPhone:
      'You will receive a code on SMS to to this number within 30 seconds',
  },
  pl: {
    heading: 'Potwierdź numer telefonu',
    sendCode: 'Wyślij kod',
    smsCode: 'Kod z SMS',
    changePhone: 'Zmień numer telefonu',
    numberInvalid: 'Nieprawidłowy numer telefonu',
    authFailed:
      'Kod jest nieprawidłowy. Spróbuj ponownie lub powróć i popraw numer telefonu.',
    start: 'Wprowadź numer telefonu',
    findYourPhone:
      'W ciągu 30 sekund otrzymasz na ten numer wiadomość SMS z jednorazowym kodem',
  },
}

export default Otp
