import React from 'react'
import TextInput from './TextInput'
import { v_zip } from '../../../lib/validations'

const ZipInput = (props) => {
  return <TextInput {...props} />
}

ZipInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'zip',
  label: 'Postnummer',
  inputMode: 'numeric',
  autoComplete: 'postal-code',
  validate: v_zip,
}

ZipInput.propTypes = TextInput.propTypes

export default ZipInput
