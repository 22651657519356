import React from 'react'
import PropTypes from 'prop-types'
import HLForm from '../forms/HLForm'
import Button from '../forms/fields/Button'
import Submit from '../forms/fields/SubmitButton'
import { AppointmentPatientItem } from './AppointmentPatientItem'
import { v_name, v_nationalId } from '../../lib/validations'

import { WHO } from './AppointmentWizard'
import { createPatients } from './queries'
import { useState } from 'react'

const PatientForm = ({
  who,
  userId,
  patientCount,
  onComplete,
  onError,
  reset,
  t,
}) => {
  const [patients, setPatients] = useState([])
  const [valid, setValid] = useState(false)
  const [error, setError] = useState(null)

  const fieldCount =
    who === WHO.others ? patientCount : patientCount - 1

  const validateForm = (values) => {
    let newValid = true
    const newPatients = []

    for (let i = 0; i < fieldCount; i++) {
      newPatients[i] = {
        name: values[`patient_${i}_name`],
        nationalId: values[`patient_${i}_nationalId`],
      }
    }

    setPatients(newPatients)

    newPatients.forEach((patient) => {
      if (
        !v_name(patient.name) ||
        !v_nationalId(patient.nationalId)
      ) {
        newValid = false
        return
      }

      if (
        patients.filter((p) => p.nationalId === patient.nationalId)
          .length > 1
      ) {
        setError(t('duplicateNationalId', s))
        newValid = false
        return
      }
    })

    if (newValid) setError(null)
    setValid(newValid)
  }

  const savePatients = async () => {
    const response = await createPatients(userId, patients)
    if (response.netError) return onError(response.errors)
    if (response.errors) setError(response.errors)
    else onComplete(response.data.map((p) => parseInt(p.id)))
  }

  const getGroupTitle = () => {
    if (fieldCount === 1) return ''
    return WHO.others ? t('person') : t('extraPerson', s)
  }

  const renderInputs = () => {
    const inputs = []
    for (let i = 0; i < fieldCount; i++) {
      inputs.push(
        <AppointmentPatientItem
          key={i}
          groupTitle={getGroupTitle()}
          index={i}
          t={t}
        />,
      )
    }
    return inputs
  }

  return (
    <section>
      <h1>{`${
        who === WHO.others
          ? t('headingExcludingUser', s)
          : t('headingIncludingUser', s)
      }`}</h1>
      <HLForm
        validateForm={validateForm}
        preventSubmit={true}
        onSubmit={savePatients}
        omitCsrf={true}
      >
        {renderInputs()}
        {error && (
          <div className="message message-danger p-2">
            <p className="m-0 text-danger">{error}</p>
          </div>
        )}
        <div className="row">
          <div className="col-6">
            <Button
              label={t('restart')}
              className="btn-link mt-4"
              onClick={reset}
            />
          </div>
          <div className="col-6 text-right">
            <Submit
              disabled={!valid}
              label={t('confirm')}
              className="my-3"
            ></Submit>
          </div>
        </div>
      </HLForm>
    </section>
  )
}

PatientForm.propTypes = {
  who: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  patientCount: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    duplicateNationalId:
      'Samme personnummer er brukt på flere pasienter',
    headingIncludingUser: 'Hvem kommer i tillegg til deg?',
    headingExcludingUser: 'Hvem er timen for?',
    extraPerson: 'Ekstra person',
  },
  en: {
    duplicateNationalId:
      'A national ID or D-number is used on more than one patient',
    headingIncludingUser: 'Who are the additional patients?',
    headingExcludingUser: 'Who is the appointment for?',
    extraPerson: 'Extra patient',
  },
  pl: {
    duplicateNationalId:
      'Podany numer dowodu lub D-number jest użyty dla więcej niż jednego pacjenta',
    headingIncludingUser:
      'Kogo oprócz Ciebie powinniśmy przetestować?',
    headingIncludingUser: 'Kim są pozostali pacjenci?',
    headingExcludingUser: 'Kto skorzysta z wizyty?',
  },
}

export default PatientForm
