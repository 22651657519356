import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const HLForm = (props) => {
  const form = useRef(0)

  const getFieldValue = (el) =>
    el.type != 'checkbox' ? el.value : el.checked

  const serialize = () => {
    const values = {}
    Array.prototype.slice
      .call(form.current.elements)
      .forEach((el) => {
        if (el.name !== '_method' && !/button|submit/.test(el.type))
          values[el.name] = getFieldValue(el)
      })
    return values
  }

  const _validate = (value, valid, name) => {
    props.validate(value, valid, name)
    props.validateForm(serialize())
  }

  const submit = (e) => {
    if (props.preventSubmit) e.preventDefault()
    if (props.onSubmit) props.onSubmit(e, serialize())
  }

  const {
    name,
    action,
    method,
    children,
    validate,
    multipart,
  } = props

  return (
    <form
      className={`hlform ${props.className}`}
      name={name}
      action={action}
      method={'post'}
      onSubmit={submit}
      onChange={_validate}
      multipart={multipart}
      encType={multipart ? 'multipart/form-data' : ''}
      ref={form}
    >
      {!props.omitCsrf && (
        <input
          type="hidden"
          value={$.rails.csrfToken() || ''}
          name={$.rails.csrfParam() || ''}
        />
      )}
      <input type="hidden" name="_method" value={method} />

      {children}
    </form>
  )
}

HLForm.defaultProps = {
  name: '',
  action: '',
  preventSubmit: false,
  method: 'post',
  validate: () => {},
  validateForm: () => {},
  omitCsrf: false,
  className: '',
}

HLForm.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  action: PropTypes.string,
  preventSubmit: PropTypes.bool,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  validateForm: PropTypes.func,
  omitCsrf: PropTypes.bool,
  className: PropTypes.string,
  multipart: PropTypes.bool,
}

export default HLForm
