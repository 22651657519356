import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RadioButtons from '../forms/fields/RadioButtons'
import Button from '../forms/fields/Button'
import HLForm from '../forms/HLForm'

const WhoIsPaying = ({
  t,
  onComplete,
  handleWhoIsPaying,
  billableOrganizations,
}) => {
  const [who, setWho] = useState('user')
  const [employerInfo, setEmployerInfo] = useState(null)

  const toggleChoice = (value) => {
    if (value === 'user') {
      setWho(value)
      setEmployerInfo(null)
    } else {
      setWho(value)
    }
  }

  const handleSelectedOrg = (e) => {
    setEmployerInfo(e.target.value)
  }

  const handleSubmit = () => {
    handleWhoIsPaying(employerInfo, who)
    onComplete()
  }
  return (
    <section>
      <h1>{t('whoIsPaying', s)}</h1>
      <RadioButtons
        className="mt-5"
        onChange={toggleChoice}
        name="whoIsPaying"
        options={[
          {
            value: 'user',
            label1: `${t('iAmPaying', s)}`,
          },
          {
            value: 'company',
            label1: `${t('employerIsPaying', s)}`,
          },
        ]}
      />
      {who === 'company' ? (
        <section>
          <HLForm preventSubmit={true} className="mt-4">
            <select
              className="select select-company-name"
              onChange={handleSelectedOrg}
              defaultChecked={false}
              name="choose-billable-organization"
            >
              <option value="">{t('selectCompany', s)}</option>
              {billableOrganizations.map((org) => {
                return (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                )
              })}
            </select>
          </HLForm>
          <article className="d-flex justify-content-between align-items-center w-100 mt-5">
            <button type="button" className="btn btn-link">
              {t('startOver', s)}
            </button>
            <Button
              label={t('next', s)}
              className={
                employerInfo
                  ? 'btn-primary '
                  : 'btn-primary disabled  '
              }
              onClick={() => (employerInfo ? handleSubmit() : null)}
            />
          </article>
        </section>
      ) : null}
      {who === 'user' && (
        <article className="d-flex justify-content-between align-items-center w-100 mt-4">
          <button type="button" className="btn btn-link">
            {t('startOver', s)}
          </button>
          <Button
            label={t('next', s)}
            className="btn-primary pl-5 pr-5"
            onClick={() => handleSubmit()}
          />
        </article>
      )}
      <section
        className="text-muted"
        style={{ fontSize: '0.7em', marginTop: '13em' }}
      >
        This data will be stored in encrypted state and handled by
        Hjemmelegene only. It will be automatically deleted after 30
        days.
      </section>
    </section>
  )
}

WhoIsPaying.propTypes = {
  t: PropTypes.func,
  onComplete: PropTypes.func,
  handleWhoIsPaying: PropTypes.func,
  billableOrganizations: PropTypes.array,
}

const s = {
  nb: {
    whoIsPaying: 'Hvem betaler?',
    startOver: 'Start på nytt',
    iAmPaying: 'Meg',
    employerIsPaying: 'Arbeidsgiver',
    companyNameLabel: 'Navn på bedrift/avdeling',
    selectCompany: '-- Velg bedrift --',
    consent:
      'Jeg godtar at min arbeidsgiver kan se navnet mitt på fakturaen',
    next: 'Neste',
  },
  en: {
    whoIsPaying: 'Who is paying?',
    startOver: 'Start over',
    iAmPaying: 'Me',
    employerIsPaying: 'My employer',
    companyNameLabel: 'Company/department name',
    selectCompany: '-- Select company --',
    consent:
      'I accept that my employer can see my name on the invoice.',
    next: 'Next',
  },
  pl: {
    whoIsPaying: 'Kto jest płatnikiem?',
    startOver: 'Rozpocznij jeszcze raz',
    iAmPaying: 'Jestem płatnikiem',
    employerIsPaying: 'Mój pracodawca jest płatnikiem',
    companyNameLabel: 'Firma/nazwa działu',
    selectCompany: '-- Wybierz firmę --',
    consent:
      'Wyrażam zgodę na umieszczenie mojego nazwiska na fakturze dla pracodawcy.',
    next: 'Dalej',
  },
}

export default WhoIsPaying
