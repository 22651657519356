import React from 'react'
import Button from '../Button.jsx'
import PropTypes from 'prop-types'
import { post } from '../../lib/backend-client'

const SubmitMultipleSms = (props) => {
  const handleClick = async () => {
    const message = document.getElementById(
      props.messageToRetrieveId,
    ).value
    const phoneNumbersSelected = extractPhoneNumbersFromSps()
    const fullPhoneNumbers = [
      ...props.phoneNumbers,
      ...phoneNumbersSelected,
    ]
    const response = await post(props.path, {
      sms: { phone_numbers: fullPhoneNumbers, message: message },
    })
    if (response.error) {
      alert(response.error)
    }
  }

  const extractPhoneNumbersFromSps = () => {
    const filteredSps = props.serviceProviders.filter((sp) => {
      return (
        sp.profession_selected == true ||
        sp.area_selected == true ||
        sp.individual_selected == true
      )
    })
    return filteredSps.map((sp) => sp.phone_number)
  }

  return (
    <div className="btn-sms-submit">
      <Button
        label={props.label}
        className="btn btn-primary"
        onClick={handleClick}
      />
    </div>
  )
}

SubmitMultipleSms.propTypes = {
  label: PropTypes.string.isRequired,
  messageToRetrieveId: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  serviceProviders: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SubmitMultipleSms
