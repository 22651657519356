import PropTypes from 'prop-types'
import { serviceCenterShape as scShape } from '../propShapes'

export const appointmentShape = PropTypes.shape({
  appointment_creator_id: PropTypes.number.isRequired,
  appointment_creator_type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  currently_edited_by_id: PropTypes.string,
  date: PropTypes.string.isRequired,
  deleted_at: PropTypes.string,
  duration: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  service_center_id: PropTypes.number.isRequired,
  timeslot: PropTypes.number.isRequired,
  updated_at: PropTypes.string,
  user_id: PropTypes.number.isRequired,
})

export const productShape = PropTypes.shape({
  created_at: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  public_name: PropTypes.string.isRequired,
  require_digital: PropTypes.bool.isRequired,
  require_housecall: PropTypes.bool.isRequired,
  require_shipping: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  vat_rate: PropTypes.number.isRequired,
})

export const serviceCenterShape = scShape
