import React from 'react'
import Switch from './Switch'
import PropTypes from 'prop-types'

const SwitcherToggle = ({ enabled, handleToggle, label }) => {
  return (
    <>
      <span
        style={{
          display: 'inline-block',
          marginRight: '0.5rem',
          marginBottom: '1rem',
        }}
      >
        {label}
      </span>
      <Switch isOn={enabled} onChange={handleToggle} />
    </>
  )
}

SwitcherToggle.propTypes = {
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
}

export default SwitcherToggle
