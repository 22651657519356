import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCoverageData,
  updateRouteData,
} from '../options/state/optionSelectedActions'
import Option from '../options/Option'
import moment from 'moment-timezone'

const ServiceProviderListItem = (props) => {
  const dispatch = useDispatch()
  const bookings = useSelector((state) => state.dataset.bookings)
  const dateSelected = useSelector((state) => state.dateSelected)
  const serviceProviderInCoverageSelectedList = useSelector((state) =>
    state.coverageSpSelected.includes(props.sp.id),
  )

  const serviceProviderInRouteOptimizedSelectedList = useSelector(
    (state) => state.routeOptimizedSpSelected.includes(props.sp.id),
  )
  const serviceProviderInRouteSelectedList = useSelector((state) =>
    state.routeSpSelected.includes(props.sp.id),
  )

  const handleChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    dispatch({
      type: 'DATA/SERVICE_PROVIDERS_TOGGLE',
      payload: value,
    })
  }

  const handleColorChange = (e) => {
    const value = e.currentTarget.value
    const id = parseInt(e.currentTarget.name, 10)
    dispatch({
      type: 'DATA/SERVICE_PROVIDERS_COLOR_CHANGE',
      payload: { id, color: value },
    })
  }

  const handleCoverageOptionChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    dispatch(updateCoverageData(value))
  }

  const handleRouteOptionChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    dispatch(updateRouteData({ spId: value, optimized: false }))
  }

  const handleRouteOptimizedOptionChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    dispatch(updateRouteData({ spId: value, optimized: true }))
  }

  const checkSpHasBookings = (sp) => {
    const spsWithBookingsIdList = bookings
      .filter(
        (booking) =>
          dateSelected ===
          moment(booking.begins_at)
            .tz('Europe/Oslo')
            .startOf('day')
            .format('yyyy-MM-DD'),
      )
      .map((booking) => booking.service_provider_id)
    return spsWithBookingsIdList.includes(sp.id)
  }

  const checkSpsBookingsNumber = () =>
    bookings
      .filter(
        (booking) =>
          booking.service_provider_id === props.sp.id &&
          dateSelected ===
            moment(booking.begins_at)
              .tz('Europe/Oslo')
              .startOf('day')
              .format('yyyy-MM-DD'),
      )
      .map((booking) => booking.id).length

  const displayCoverageOption = (sp) => {
    if (sp.coverage_maps.length > 0) {
      return (
        <Option
          id={sp.id}
          name="coverage"
          checked={serviceProviderInCoverageSelectedList}
          onChange={handleCoverageOptionChange}
          label="Display Coverage Map"
        />
      )
    }
  }

  const displayRouteOption = (sp) => {
    if (checkSpHasBookings(sp)) {
      return (
        <Option
          id={sp.id}
          name="route"
          checked={serviceProviderInRouteSelectedList}
          onChange={handleRouteOptionChange}
          label="Display Route"
        />
      )
    }
  }

  const displayRouteOptimizedOption = (sp) => {
    if (checkSpHasBookings(sp)) {
      return (
        <Option
          id={sp.id}
          name="routeOptimized"
          checked={serviceProviderInRouteOptimizedSelectedList}
          onChange={handleRouteOptimizedOptionChange}
          label="Display Optimized Route"
        />
      )
    }
  }

  const displaySpOptions = (sp) => {
    if (checkSpHasBookings(sp) || sp.coverage_maps.length > 0) {
      return (
        <div className="sp-form__options">
          {displayCoverageOption(sp)}
          {displayRouteOption(sp)}
          {displayRouteOptimizedOption(sp)}
        </div>
      )
    }
  }

  return (
    <>
      <label className="sp-form__label ml-1 mt-1">
        <div className="d-flex align-items-center flex-grow-1">
          <div className="sp-form__checkbox-wrapper">
            <input
              type="checkbox"
              name={props.sp.id}
              value={props.sp.id}
              checked={props.sp.checked}
              onChange={handleChange}
            />
          </div>

          <div
            className="sp-form__checkbox-text ml-1 flex-grow-1"
            style={{
              width: '80%',
            }}
          >
            {props.sp.first_name} {props.sp.last_name}{' '}
            {checkSpsBookingsNumber() > 0 && (
              <>
                &nbsp;|&nbsp;
                {checkSpsBookingsNumber() > 1
                  ? `${checkSpsBookingsNumber()} bestillinger`
                  : '1 bestilling'}
              </>
            )}
          </div>
          <div
          // style={{
          //   backgroundColor: props.sp.color,
          //   borderRadius: '50%',
          //   padding: '0.6rem',
          // }}
          >
            <input
              type="color"
              name={props.sp.id}
              id="route-color-picker"
              value={props.sp.color}
              onChange={handleColorChange}
            />
          </div>
        </div>
      </label>
      <div className="mr-5">
        {props.sp.checked && displaySpOptions(props.sp)}
      </div>
    </>
  )
}

ServiceProviderListItem.propTypes = {
  sp: PropTypes.object.isRequired,
}

export default ServiceProviderListItem
