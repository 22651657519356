import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CollapseQABox extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    answerClass: PropTypes.string.isRequired,
    questionClass: PropTypes.string.isRequired,
    assetRootUrl: PropTypes.object.isRequired,
  }

  static defaultProps = {
    questionClass: '',
    answerClass: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  showBody() {
    if (this.state.isOpen) {
      return (
        <div
          className={`list-group-item__add-on-buttons list-group-item__add-on-single-button backoffice__default-text ${this.props.answerClass}`}
          dangerouslySetInnerHTML={{ __html: this.props.body }}
        ></div>
      )
    }
  }

  openClass() {
    if (this.state.isOpen) {
      return 'list-group-item--no-radius-bottom'
    } else {
      return ''
    }
  }

  chevronClass() {
    if (this.state.isOpen) {
      return 'inline-block icon-flip-up'
    } else {
      return 'inline-block icon-flip-down'
    }
  }

  render() {
    return (
      <div>
        <div
          className={`list-group-item list-group-item__info list-group-item__gaps list-group-item--light ${this.openClass()} ${
            this.props.questionClass
          }`}
          onClick={this.toggle}
        >
          <div className="list-group-item__info--inner list-group-item__info--wide-inner">
            {this.props.header}
          </div>

          <div className="list-group-item__info--inner text-right">
            <span className={this.chevronClass()}>
              <img src={this.props.assetRootUrl.chevronIcon} />
            </span>
          </div>
        </div>

        {this.showBody()}
      </div>
    )
  }
}

export default CollapseQABox
