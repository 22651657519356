import React, { useState, useEffect } from 'react'
import TextInput from './TextInput'
import { CountryCodeSelector } from './country_codes/CountryCodeSelector'
import { v_phone, v_phoneNumber } from '../../../lib/validations'
import { f_phone } from '../../../lib/filters'
import {
  DEFAULT_COUNTRY_CODE,
  countries,
} from './country_codes/country_codes'

const PhoneInput = (props) => {
  const { name, value, onChange, ...rest } = props

  const [countryCode, setCountryCode] = useState(
    getCountryCode(value),
  )
  const [phoneNumber, setPhoneNumber] = useState(
    extractPhoneNumber(value, countryCode) || '',
  )

  const handleOnPhoneNumberChange = (value, valid, _) => {
    const phone = countryCode + value
    const newValid = valid && v_phone(phone)
    setPhoneNumber(value)

    if (onChange) onChange(phone, newValid, name)
  }

  const handleOnCountryCodeChange = (value) => {
    const phone = value + phoneNumber
    setCountryCode(value)

    if (onChange) onChange(phone, v_phone(phone), name)
  }

  useEffect(() => {
    if (!!onChange && value) {
      const v = countryCode + phoneNumber
      onChange(v, v_phone(v), name)
    }
  }, [])

  return (
    <div className={`d-flex ${props.className}`}>
      <CountryCodeSelector
        shadow={props.shadow}
        selectedOption={countryCode}
        onSelect={handleOnCountryCodeChange}
        countries={countries}
        disabled={props.disabled}
      />
      <div className="w-100">
        <TextInput
          {...rest}
          name="_phone_number_without_country_code"
          autoComplete="tel-national"
          onChange={handleOnPhoneNumberChange}
          value={phoneNumber || ''}
        />
      </div>
      <input
        hidden
        name={name}
        onChange={onChange}
        value={countryCode + phoneNumber}
      />
    </div>
  )
}

PhoneInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'phone_number',
  label: 'Mobilnummer',
  inputMode: 'tel',
  validate: v_phoneNumber,
  filter: f_phone,
}

PhoneInput.propTypes = TextInput.propTypes

function getCountryCode(value) {
  return (
    countries
      .map((country) => country.code)
      .find((code) => value.includes(code)) ??
    DEFAULT_COUNTRY_CODE.code
  )
}

function extractPhoneNumber(value, countryCode) {
  return countryCode ? value.replace(countryCode, '') : value
}

export default PhoneInput
