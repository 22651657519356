const initialState = []

// globalDataLoaded one by one
export const allDataLoadedReducer = (state = false, action) => {
  switch (action.type) {
    case 'DATA/ALL_LOADED': {
      return action.payload
    }
    default:
      return state
  }
}

//all datasetloaded as a single action

export const allDatasetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DATA/ALL_DATA_SET': {
      return action.payload
    }
    case 'DATA/SERVICE_PROVIDERS_TOGGLE': {
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((sp) => {
          if (sp.id !== action.payload) {
            return sp
          }

          return {
            ...sp,
            checked: !sp.checked,
          }
        }),
      }
    }
    case 'DATA/SERVICE_PROVIDERS_COLOR_CHANGE': {
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((sp) => {
          if (sp.id !== action.payload.id) {
            return sp
          }

          return {
            ...sp,
            color: action.payload.color,
          }
        }),
      }
    }

    case 'DATA/SERVICE_PROVIDERS_SELECT_ALL_DISPLAYED': {
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((sp) => {
          if (action.payload.includes(sp.id)) {
            return {
              ...sp,
              checked: true,
            }
          }
          return {
            ...sp,
          }
        }),
      }
    }
    case 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED': {
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((sp) => {
          if (action.payload.includes(sp.id)) {
            return {
              ...sp,
              checked: false,
            }
          }
          return {
            ...sp,
          }
        }),
      }
    }

    default:
      return state
  }
}
//if action type refer to full data set we return the full payload
// DATA SET VERSUS DATA ITEM => the action should determine

// not in use (the following are used if we perform multiple dispatch for each API endpoint)
// export function bookingsReducer(state = initialState, action) {
//   switch (action.type) {
//     case 'DATA/BOOKINGS_LOADED': {
//       // Replace the existing state entirely by returning the new value
//       return action.payload
//     }
//     default:
//       return state
//   }
// }

// export function referralsReducer(state = initialState, action) {
//   switch (action.type) {
//     case 'DATA/REFERRALS_LOADED': {
//       // Replace the existing state entirely by returning the new value
//       return action.payload
//     }
//     default:
//       return state
//   }
// }
// export function servicesReducer(state = initialState, action) {
//   switch (action.type) {
//     case 'DATA/SERVICES_LOADED': {
//       // Replace the existing state entirely by returning the new value
//       return action.payload
//     }
//     default:
//       return state
//   }
// }

// export function serviceProvidersReducer(
//   state = initialState,
//   action,
// ) {
//   switch (action.type) {
//     case 'DATA/SERVICE_PROVIDERS_LOADED': {
//       // Replace the existing state entirely by returning the new value
//       return action.payload
//     }
//     default:
//       return state
//   }
// }
