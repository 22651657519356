import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LineItem from './LineItem'
import { formatCurrencyNoDec } from '../../../lib/formatCurrencyOneDec'

const SameCategoryItemCart = ({ cart, onChange }) => {
  const [isLoading, setIsLoading] = useState(false)

  const firstlineTotals = () => {
    const itemList = [...cart.line_items]
    return {
      itemList,
      sum: computeSum(itemList),
    }
  }

  const computeSum = (lineTotals) =>
    lineTotals.reduce((sum, { total }) => sum + total, 0)

  const [lineTotals, setLineTotals] = useState(firstlineTotals)

  const handleLineTotalChange = (total, product_id) => {
    const itemList = lineTotals.itemList.map((item) =>
      item.product.id === product_id ? modifyItem(item, total) : item,
    )
    setLineTotals({ itemList, sum: computeSum(itemList) })
  }

  const modifyItem = (item, total) => {
    item.total = total
    return item
  }

  const handleOnLoad = (loading) => {
    setIsLoading(loading)
  }

  return (
    <div className="cart-form small">
      <div className="row mb-3 text-center align-items-start">
        <div className="col-7 p-0 m-0 text-left">
          {isLoading && <span className="spinner" />}
        </div>
        <div className="col-1 p-0 m-0">Ant.</div>
        <div className="col-4 text-right pl-0">Total</div>
      </div>
      {cart.line_items.map((lineItem, index) => (
        <LineItem
          onChange={onChange}
          key={index}
          onLineTotalChange={handleLineTotalChange}
          showDelete={false}
          onLoad={handleOnLoad}
          {...lineItem}
        />
      ))}
      <hr />
      <div className="row sum text-center small">
        <div className="col-3 text-left">Sum</div>
        <div className="col-6 p-0 m-0"></div>
        <div className="col-3 text-right pl-0">
          {formatCurrencyNoDec(lineTotals.sum, true, true)}
        </div>
      </div>
    </div>
  )
}

SameCategoryItemCart.propTypes = {
  name: PropTypes.string,
  productIdName: PropTypes.string,
  quantityName: PropTypes.string,
  cart: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  maxPerOrder: PropTypes.number,
  minPerOrder: PropTypes.number,
}

SameCategoryItemCart.defaultProps = {
  // productIdName: 'line_item[product_id]',
  // quantityName: 'line_item[quantity]',
  cart: {},
}

export default SameCategoryItemCart
