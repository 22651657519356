import React, { useState } from 'react'
import SubmitButton from './forms/fields/SubmitButton'
import PropTypes from 'prop-types'
import DeleteCalendarEntryModal from './DeleteCalendarEntryModal'
import { v_city, v_zip, v_streetAddress } from '../lib/validations'
import moment from 'moment-timezone'

const SubmitEditButton = ({
  oldServiceProviderIds,
  calendarEntries,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [changedCalendars, setChangedCalendars] = useState([])

  const begins_at = document.querySelector('input[name*="begins_at"]')
  const ends_at = document.querySelector('input[name*="ends_at"]')

  const MAX_TIMESPAN = 8 * 60 * 60 * 1000 // 8 hours in ms

  const validateTimespan = (e) => {
    const start = new Date(begins_at.value)
    const end = new Date(ends_at.value)
    const delta = end.getTime() - start.getTime()

    if (delta < 0) {
      alert('Sluttidspunkt kan ikke være før starttidspunkt')
      e.preventDefault()
    } else if (Math.abs(delta >= MAX_TIMESPAN)) {
      if (
        !confirm(
          'Vargihet over 8 timer. Er du sikker på at du vil lagre?',
        )
      ) {
        e.preventDefault()
      }
    }
  }

  const checkQuantity = (e) => {
    const patient_row_count = document.querySelectorAll(
      'input[name*="booking[patients_attributes][][id]"]',
    ).length

    const quantity = parseInt(
      document.querySelector('input[name*="booking[v2_quantity]"]')
        .value,
      10,
    )
    const quantity_check = quantity ? quantity : null

    if (quantity_check === null) {
      if (
        !confirm(
          'Antall pasienter er ikke fylt ut. Er du sikker på at du vil lagre?',
        )
      ) {
        e.preventDefault()
      }
    } else if (patient_row_count !== quantity) {
      if (
        !confirm(
          '⚠️ Antall pasienter samsvarer ikke med antall pasienter lagt til nederst i bookingen. Er du sikker på at du vil lagre?',
        )
      ) {
        e.preventDefault()
      }
    }
  }

  const checkAddress = (e) => {
    const address = document.querySelector(
      "input[name*='booking[address]']",
    )?.value
    const zip = document.querySelector("input[name*='booking[zip]']")
      ?.value
    const city = document.querySelector(
      "input[name*='booking[city]']",
    )?.value

    if (!(v_streetAddress(address) && v_city(city) && v_zip(zip))) {
      alert(
        'Sjekk at du har skrevet inn en fullstendig adresse med gatenummer',
      )
      e.preventDefault()
    }
  }

  const handleClick = (e) => {
    validateTimespan(e)
    checkQuantity(e)
    checkAddress(e)
    checkModal(e)
  }

  const checkModal = (e) => {
    if (
      oldServiceProviderIds.length > 0 &&
      oldCalendarEntryCanBeDeleted() &&
      hasSpsChanged()
    ) {
      e.preventDefault()
      setIsModalOpen(true)
    } else {
      submit()
    }
  }

  const submit = () => {
    document.forms['edit_booking_admin']
      ? document.forms['edit_booking_admin'].submit()
      : document.forms['new_booking'].submit()
  }

  const confirmModal = (result) => {
    setIsModalOpen(false)
    if (!result.success) {
      window.alert(
        'We could not delete the calendar entry, please do it manually after this',
      )
    }
    submit()
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const hasSpsChanged = () => {
    const calendars = getChangedCalendars()
    return calendars.length > 0
  }

  const oldCalendarEntryCanBeDeleted = () => {
    if (!calendarEntries) return

    const now = moment().tz('Europe/Oslo')
    const calendarEntry = calendarEntries.find((c) => !!c?.id)
    if (!calendarEntry) return false

    return moment(calendarEntry.beginsAt).tz('Europe/Oslo') > now
  }

  const getChangedCalendars = () => {
    const selectedOptions = document.querySelectorAll(
      '#service_provider_ids option:checked',
    )
    const spIds = [...selectedOptions].map((option) => option.value)

    const newChangedCalendarEntries = oldServiceProviderIds.reduce(
      (array, oldId) => {
        if (!spIds.includes(oldId)) {
          const calendarEntry = calendarEntries.find(
            (ca) => ca?.serviceProviderId?.toString() == oldId,
          )
          if (!!calendarEntry) array.push(calendarEntry)
        }
        return array
      },
      [],
    )
    setChangedCalendars(newChangedCalendarEntries)
    return newChangedCalendarEntries
  }

  return (
    <>
      <SubmitButton
        className="btn-primary mr-2"
        id="admin-edit-button"
        onClick={handleClick}
      >
        Lagre
      </SubmitButton>
      {oldCalendarEntryCanBeDeleted() && (
        <DeleteCalendarEntryModal
          oldServiceProviderIds={oldServiceProviderIds}
          calendarEntries={changedCalendars}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          confirmModal={confirmModal}
        ></DeleteCalendarEntryModal>
      )}
    </>
  )
}

SubmitEditButton.propTypes = {
  oldServiceProviderIds: PropTypes.array,
  calendarEntries: PropTypes.array,
}

export default SubmitEditButton
