import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

const modalSmsStyle = {
  content: {
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
  },
}

class SmsDescriptionModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen,
    }
    ReactModal.setAppElement('.page__content')
  }

  toggleModal = (e) => {
    e.preventDefault()
    this.state.isOpen
      ? this.setState({ isOpen: false })
      : this.setState({ isOpen: true })
  }

  render() {
    return (
      <div>
        <div>
          <p className="small mb-0">
            <span>
              <a href="#" onClick={this.toggleModal}>
                Klikk her
              </a>
            </span>
            &nbsp; for å se hva som står i SMSen som blir sendt.
          </p>
        </div>

        <ReactModal
          isOpen={this.state.isOpen}
          contentLabel="Viktig informasjon"
          style={modalSmsStyle}
          shouldCloseOnEsc={true}
        >
          <div
            className="ReactModal__exit-button-new"
            onClick={this.toggleModal}
          />
          <div className="mb-3"></div>
          <p>
            Hei, Du kan nå velge tidspunkt for korona-hjemmetest med
            Hjemmelegene, i regi av Oslo kommune. Gå til
            <span>
              <a href="https://www.hjemmelegene.no/oslokommune">
                &nbsp; hjemmelegene.no/oslokommune
              </a>
            </span>
            &nbsp;for å velge time. Det er gratis å ta testen. Dersom
            du blir dårligere, kontakt fastlege eller legevakten på
            116 117. Videre sørg for å:
          </p>
          <ul className="mb-0">
            <li>Være hjemme til avtalt tidspunkt</li>
            <li>Ha ID klart</li>
            <li>
              Ta telefonen, vi ringer fra skjult nummer før oppmøte
            </li>
          </ul>
          <div>
            <p>
              Prøvesvar finner du på
              <span>
                <a href="https://helsenorge.no">helsenorge.no</a>
              </span>
              . OBS: Du får ikke varsel når prøvesvaret er klart, og
              måcderfor selv sjekke din side på{' '}
              <span>
                <a href="https://helsenorge.no/">helsenorge.no</a>
              </span>
            </p>
            <div className="mb-0">Vennlig hilsen Hjemmelegene</div>
            <div>(Samarbeidspartner Oslo kommune)</div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default SmsDescriptionModal
