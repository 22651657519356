import React from 'react'
import TextInput from './TextInput'
import { v_streetAddress } from '../../../lib/validations'

const AddressInput = (props) => {
  return <TextInput {...props} />
}

AddressInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'address',
  label: 'Adresse',
  autoComplete: 'street-address',
  validate: v_streetAddress,
}

AddressInput.propTypes = TextInput.propTypes

export default AddressInput
