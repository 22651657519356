import React from 'react'
import PropTypes from 'prop-types'

const Skeleton = ({
  numOfRows,
  shapeOfRows,
  numOfHeaders,
  headersColor = '#c8c7c7',
  rowsColor = '#e8e7e7',
}) => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-header-container">
        {numOfHeaders &&
          Array(numOfHeaders)
            .fill()
            .map((_, index) => (
              <div
                key={index}
                className={`skeleton-header`}
                style={{
                  '--color': headersColor,
                }}
              ></div>
            ))}
      </div>
      {numOfRows &&
        Array(numOfRows)
          .fill()
          .map((_, index) => (
            <div
              className={`${shapeOfRows}`}
              key={index}
              style={{
                '--color': rowsColor,
              }}
            ></div>
          ))}
    </div>
  )
}

Skeleton.propTypes = {
  numOfRows: PropTypes.number,
  shapeOfRows: PropTypes.string,
  numOfHeaders: PropTypes.number,
  headersColor: PropTypes.string,
  rowsColor: PropTypes.string,
}

export default React.memo(Skeleton)
