import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FilteringTab from './FilteringTab'
import SpecificSpTab from './SpecificSpTab'
import CustomNumbersTab from './CustomNumbersTab'
import SubmitMultipleSms from './SubmitMultipleSms'
import MessageField from './MessageField'
import Tabs from './Tabs'

const TABS = {
  custom: 'custom',
  professions: 'professions',
  areas: 'areas',
  specific: 'specific',
}

const SmsAddressBook = ({
  serviceProviders,
  professions,
  areas,
  pathSubmit,
  numbers,
}) => {
  const [tab, setTab] = useState(TABS['custom'])
  const [spsSelected, setSpsSelected] = useState(
    serviceProviders.map((sp) => {
      return {
        ...sp,
        individual_selected: false,
        area_selected: false,
        profession_selected: false,
      }
    }),
  )
  const [phoneNumbers, setPhoneNumbers] = useState(numbers || [])
  const [checkedProfessions, setCheckedProfessions] = useState([])
  const [checkedAreas, setCheckedAreas] = useState([])

  const mapFilters = {
    area: {
      name: 'area',
      setter: setCheckedAreas,
      getter: checkedAreas,
      optionSelected: 'area_selected',
    },
    profession: {
      name: 'professions',
      setter: setCheckedProfessions,
      getter: checkedProfessions,
      optionSelected: 'profession_selected',
    },
  }

  const handleChangeFilter = (filter, type) => {
    const filterName = Object.keys(filter)[0]
    const isSelected = Object.values(filter)[0]
    const typeObject = mapFilters[type]
    const modifiedSpsSelected = spsSelected.map((sp) => {
      const dupe = { ...sp }
      const condition =
        type == 'area'
          ? sp[typeObject.name] == filterName
          : sp[typeObject.name].includes(filterName)
      if (condition) {
        dupe[typeObject.optionSelected] = isSelected
      }

      return dupe
    })
    setSpsSelected(modifiedSpsSelected)
  }

  const handleCheckFilters = (e, type) => {
    if (mapFilters[type].getter.includes(e.target.name)) {
      mapFilters[type].setter(
        mapFilters[type].getter.filter(
          (filter_name) => !(filter_name == e.target.name),
        ),
      )
    } else {
      mapFilters[type].setter([
        ...mapFilters[type].getter,
        e.target.name,
      ])
    }
  }

  const handleChangeSpecific = (selectedSp) => {
    const idSp = Object.keys(selectedSp)[0]
    const isSelected = Object.values(selectedSp)[0]
    const modifiedSpsSelected = spsSelected.map((sp) => {
      const dupe = { ...sp }

      if (idSp == sp.id) {
        dupe.individual_selected = isSelected
        if (!isSelected) {
          dupe.area_selected = false
          dupe.profession_selected = false
        }
      }

      return dupe
    })

    setSpsSelected(modifiedSpsSelected)
  }

  const handleChangeCustom = (customPhoneNumbers) => {
    setPhoneNumbers(customPhoneNumbers)
  }

  const handleTabClick = (e) => {
    setTab(e.target.id)
  }

  const countSpSelected = () => {
    const filteredSps = spsSelected.filter((sp) => {
      return (
        sp.profession_selected == true ||
        sp.area_selected == true ||
        sp.individual_selected == true
      )
    })
    return filteredSps.length
  }

  return (
    <div className="sms-address-book-container">
      <MessageField></MessageField>
      <div className="sms-address-book">
        <h2 className="h5 mb-3">Mottakere</h2>
        <Tabs
          buttonsInfos={[
            {
              id: TABS.custom,
              isSelected: tab === TABS.custom,
              label:
                phoneNumbers.length > 0
                  ? `Telefonnummer(${phoneNumbers.length})`
                  : 'Telefonnummer',
            },
            {
              id: TABS.professions,
              isSelected: tab === TABS.professions,
              label:
                checkedProfessions.length > 0
                  ? `Behandlertype(${checkedProfessions.length})`
                  : 'Behandlertype',
            },
            {
              id: TABS.areas,
              isSelected: tab === TABS.areas,
              label:
                checkedAreas.length > 0
                  ? `Område(${checkedAreas.length})`
                  : 'Område',
            },
            {
              id: TABS.specific,
              isSelected: tab === TABS.specific,
              label:
                countSpSelected() > 0
                  ? `Behandlere(${countSpSelected()})`
                  : 'Behandlere',
            },
          ]}
          onClick={handleTabClick}
        ></Tabs>
        <div className="tabs-content">
          <CustomNumbersTab
            onChange={handleChangeCustom}
            enteredNumbers={phoneNumbers}
            isOpen={tab === TABS.custom}
          ></CustomNumbersTab>
          <FilteringTab
            onChange={handleChangeFilter}
            filteringObjects={professions}
            isOpen={tab === TABS.professions}
            onCheck={handleCheckFilters}
            checked={checkedProfessions}
            type="profession"
          ></FilteringTab>
          <FilteringTab
            onChange={handleChangeFilter}
            filteringObjects={areas}
            isOpen={tab === TABS.areas}
            onCheck={handleCheckFilters}
            checked={checkedAreas}
            type="area"
          ></FilteringTab>
          <SpecificSpTab
            onChange={handleChangeSpecific}
            serviceProviders={spsSelected}
            isOpen={tab === TABS.specific}
          ></SpecificSpTab>
        </div>
      </div>
      <SubmitMultipleSms
        label="Send meldig"
        phoneNumbers={phoneNumbers}
        messageToRetrieveId="sms-message"
        path={pathSubmit}
        serviceProviders={spsSelected}
      ></SubmitMultipleSms>
    </div>
  )
}

SmsAddressBook.propTypes = {
  serviceProviders: PropTypes.array.isRequired,
  professions: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  pathSubmit: PropTypes.string.isRequired,
  numbers: PropTypes.string,
}

export default SmsAddressBook
