import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const BookingListItem = (props) => {
  const [expended, setExpended] = useState(false)
  const productsDetailed = useSelector(
    (state) => state.dataset.products.productsDetailed,
  )

  const findProductSector = () => {
    if (props.booking.v2_product.id) {
      return productsDetailed.find(
        (product) => product.id === props.booking.v2_product.id,
      ).sector
    } else if (props.booking.rebooking === true) {
      return 'rebooking'
    } else {
      return 'unknown'
    }
  }

  const styleSector = () => {
    switch (findProductSector()) {
      case 'offentlig':
        return 'badge-secondary'
        break
      case 'privat':
        return 'badge-danger'
        break
      case 'bedrift':
        return 'badge-primary'
        break
      default:
        return 'badge-light'
    }
  }

  const findProductName = () =>
    props.booking.v2_product.id
      ? productsDetailed.find(
          (product) => product.id === props.booking.v2_product.id,
        ).name
      : '-'

  return (
    <div
      key={props.booking.id}
      className="item-form__card d-flex align-items-center my-1"
    >
      <li className="list-group-item list-group-item--no-border-vertical flex-grow-1">
        <div className="row">
          <div className="col-sm-3">
            <strong>{props.booking.formattedDate}</strong>{' '}
          </div>
          <div className="col-sm-3">
            <a
              href={props.booking.custom_admin_path}
              target="_blank"
              rel=" noopener noreferrer"
            >
              <strong>#booking_id: </strong>
              <span>{props.booking.id}</span>{' '}
            </a>
          </div>
          <div className="col-sm-3">
            <strong>Area:</strong>
            <span>&nbsp;{props.booking.city_area}</span>{' '}
          </div>
          <div className="col-sm-1 text-center">
            <div className={`badge  p-1 ${styleSector()}`}>
              {' '}
              {findProductSector()}{' '}
            </div>
          </div>
          <div className="col-sm-2 text-center">
            {expended ? (
              <i
                className="fas fa-angle-up"
                onClick={() => setExpended(false)}
              ></i>
            ) : (
              <i
                className="fas fa-angle-down"
                onClick={() => setExpended(true)}
              ></i>
            )}
          </div>
        </div>
        {expended && (
          <>
            <div className="row my-3">
              <div className="col-sm-3">
                <strong>Organisasjon:</strong>
                <br />
                <span>
                  {props.booking.organization
                    ? props.booking.organization
                    : '-'}
                </span>{' '}
              </div>
              <div className="col-sm-3">
                <strong>Kontakt: </strong>
                <br />
                {props.booking.contact_person ? (
                  <span>
                    {props.booking.contact_person.name}
                    <br /> {props.booking.contact_person.phone_number}
                  </span>
                ) : (
                  '-'
                )}{' '}
              </div>
              <div className="col-sm-3">
                <strong>Tjenester: </strong>
                <br />
                <span>{findProductName()} </span>{' '}
              </div>
              <div className="col-sm-2">
                <strong>Pasienter: </strong>
                <br />
                <span>{props.booking.patient_count}</span>{' '}
              </div>
            </div>
            <div className="row my-">
              <div className="col-sm-6">
                <strong>Adress:</strong>
                <br />
                <span>
                  {`${props.booking.address}, ${props.booking.city}, ${props.booking.zip}`}
                </span>
              </div>
              <div className="col-sm-6">
                <strong>Vei, og parkeringsbeskrivelse:</strong>
                <br />
                <span>{props.booking.address_directions}</span>
              </div>
            </div>
          </>
        )}
      </li>
    </div>
  )
}

BookingListItem.propTypes = {
  booking: PropTypes.object.isRequired,
}

export default BookingListItem
