import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

const modalStyle = {
  content: {
    background: '#ffc9c9',
    WebkitOverflowScrolling: 'touch',
  },
}

class AuxWarningModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen,
      content: this.props.content,
    }
    ReactModal.setAppElement('.page__content')
  }

  close = () => this.setState({ isOpen: false })

  render() {
    return (
      <ReactModal
        isOpen={this.state.isOpen}
        contentLabel="Viktig informasjon"
        style={modalStyle}
        shouldCloseOnEsc={true}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={this.close}
        />
        <div
          dangerouslySetInnerHTML={{ __html: this.props.content }}
        />
      </ReactModal>
    )
  }
}

export default AuxWarningModal
