import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadGoogle from '../lib/loadGoogle'

class CalendarEventCoverageMap extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    serviceProviders: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount = async () => {
    await loadGoogle(this.props.googleMapsApiKey)

    this.map = new google.maps.Map(this.mapContainer, {
      center: { lat: 59.8939225, lng: 10.7150777 },
      zoom: 7,
      streetViewControl: false,
    })

    for (var serviceProvider of this.props.serviceProviders) {
      new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        center: {
          lat: serviceProvider.lat,
          lng: serviceProvider.lng,
        },
        radius: serviceProvider.max_distance_km * 1000 || 25000,
      })

      new google.maps.Marker({
        position: {
          lat: serviceProvider.lat,
          lng: serviceProvider.lng,
        },
        map: this.map,
        label: `${serviceProvider.first_name} ${serviceProvider.last_name}`,
      })
    }
  }

  render() {
    return (
      <div>
        <div
          ref={(ref) => {
            this.mapContainer = ref
          }}
          style={{ height: '500px' }}
        />
      </div>
    )
  }
}

export default CalendarEventCoverageMap
