import React from 'react'
import moment from 'moment-timezone'
import ReferralListItem from './ReferralListItem'
import { useDispatch, useSelector } from 'react-redux'

const ReferralTab = () => {
  const dispatch = useDispatch()
  const referrals = useSelector((state) => state.dataset.referrals)

  const displayReferrals = () => {
    if (referrals.length > 0) {
      const referralsToDisplay = referrals.map((referral) => {
        const formattedDate = moment(referral.created_at).format(
          'dddd Do MMM HH:mm',
        )
        return (
          <ReferralListItem
            key={referral.id}
            referral={{ ...referral, formattedDate }}
          />
        )
      })
      return referralsToDisplay
    }
  }

  const handleCloseReferrals = (e) => {
    const filter = document.querySelector(
      '.item-form__multiselect.for-referral',
    )
    filter.classList.toggle('expended')
    const nav = document.querySelector(
      '.route-dashboard__nav-item.for-referral',
    )
    nav.classList.toggle('selected')
  }

  return (
    <div className="item-form__multiselect for-referral">
      <div className="item-form__multiselect__clos-btn">
        <i
          className="fas fa-arrow-alt-circle-right"
          onClick={handleCloseReferrals}
        ></i>
      </div>
      <ul className="list-group list-group--full-width">
        <div className="d-flex item-form__multiselect__selections">
          <button
            className="left"
            onClick={() => {
              dispatch({
                type: 'REFERRALS/SELECT_ALL',
                payload: referrals.map((referral) => referral.id),
              })
            }}
          >
            Select All
          </button>
          <button
            className="right"
            onClick={() => {
              dispatch({ type: 'REFERRALS/UNSELECT_ALL' })
            }}
          >
            Unselect All
          </button>
        </div>
        {displayReferrals()}
      </ul>
    </div>
  )
}

export default ReferralTab
