import React from 'react'
import TextInput from './TextInput'

const ZipInput = (props) => {
  return <TextInput {...props} />
}

ZipInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'city',
  label: 'Poststed',
  autoComplete: 'city postal_town address-line3',
  validate: (value) => /[a-zæøå]+/i.test(value),
}

ZipInput.propTypes = TextInput.propTypes

export default ZipInput
