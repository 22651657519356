import React, { useCallback, useEffect, useState } from 'react'
import Sparkle from './Sparkle'
import PropTypes from 'prop-types'
import { randomNumber } from '../lib/utils'

const Sparkles = ({
  minNumberOfSparkles,
  maxNumberOfSparkles,
  minSizeOfSparkle,
  maxSizeOfSparkle,
  stopAfterSeconds,
}) => {
  const [sparkles, setSparkles] = useState([])

  const randomizeSparkleNumber = useCallback(() => {
    return randomNumber(minNumberOfSparkles, maxNumberOfSparkles)
  }, [maxNumberOfSparkles, minNumberOfSparkles])

  const randomizeSparkleColor = useCallback(() => {
    return Math.floor(Math.random() * 16777215).toString(16)
  }, [])

  const randomizeSparkleSize = useCallback(() => {
    return randomNumber(minSizeOfSparkle, maxSizeOfSparkle)
  }, [maxSizeOfSparkle, minSizeOfSparkle])

  const generateArrayOfRandomSparkles = useCallback(() => {
    return [...Array(randomizeSparkleNumber())].forEach((_, i) => {
      setSparkles((sparkles) => [
        ...sparkles,
        {
          color: randomizeSparkleColor(),
          size: randomizeSparkleSize(),
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          id: Math.random() * 1000000,
        },
      ])
    })
  }, [
    randomizeSparkleNumber,
    randomizeSparkleSize,
    randomizeSparkleColor,
  ])

  useEffect(() => {
    generateArrayOfRandomSparkles()
    const interval = setInterval(() => {
      setSparkles([])
    }, stopAfterSeconds * 1000)

    return () => clearInterval(interval)
  }, [generateArrayOfRandomSparkles, stopAfterSeconds])

  return (
    <div
      id="sparkles"
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        height: '100%',
        width: '100%',
        display: 'block',
        zIndex: 100,
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      >
        {sparkles.map((sparkle, index) => (
          <Sparkle
            key={sparkle.id}
            color={sparkle.color}
            size={sparkle.size}
            style={{
              top: sparkle.top,
              left: sparkle.left,
              '--sparkle-delay': index / sparkles.length,
            }}
          />
        ))}
      </div>
    </div>
  )
}

Sparkles.propTypes = {
  minNumberOfSparkles: PropTypes.number,
  maxNumberOfSparkles: PropTypes.number,
  minSizeOfSparkle: PropTypes.number,
  maxSizeOfSparkle: PropTypes.number,
  stopAfterSeconds: PropTypes.number,
}

export default React.memo(Sparkles)
