import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import { patientShape } from '../appointments/propShapes'
import InputToggleText from '../forms/fields/InputToggleText'
import formatCurrency from '../../lib/formatCurrency'
import TypeaheadInput from '../forms/fields/TypeaheadInput'
import ClinicContext from './ClinicContext'
import { fetchProduct } from './queries'
import { messageError, isValid } from './errors'
import { v_is_non_zero_number } from '../../lib/validations'

const ClinicLineItem = ({
  lineItem,
  onSave,
  onRemove,
  editable,
  isOnlyItem,
}) => {
  const isPersisted = lineItem.id > -1
  const {
    clinic,
    booking,
    filterProducts,
    assetRootUrl,
    addError,
  } = useContext(ClinicContext)

  const validateProduct = (value) => {
    const products = !!clinic
      ? clinic.currently_active_products
      : [booking.v2_product]
    return products.some((p) => p.id == value.id)
  }

  const validateQuantity = (value) => v_is_non_zero_number(value)

  const [state, setState] = useState({
    lineItem: { ...lineItem },
    changes: { ...lineItem },
    editing: !isPersisted,
    error: null,
    validations: {},
  })

  useEffect(() => {
    setState({ ...state, lineItem: { ...lineItem } })
  }, [lineItem])

  useEffect(() => {
    setState({
      ...state,
      validations: {
        product: validateProduct(lineItem.product),
        quantity: validateQuantity(lineItem.quantity),
      },
    })
  }, [])

  const edit = () => {
    setState({
      ...state,
      changes: { ...state.lineItem },
      editing: true,
    })
  }

  const save = () => {
    if (isValid(state.validations)) {
      setState({
        ...state,
        lineItem: { ...state.changes },
        editing: false,
        error: null,
      })
      if (onSave) onSave(state.changes, false)
    } else {
      setState({ ...state, error: messageError(state.validations) })
    }
  }

  const cancel = () => {
    if (isOnlyItem) {
      setState({ ...state, error: messageError(state.validations) })
    } else {
      setState({
        ...state,
        changes: null,
        editing: false,
        error: null,
      })
      if (onSave) onSave(state.lineItem, true)
    }
  }

  const remove = () => {
    if (confirm('Are you sure you want to remove this item?')) {
      if (onRemove) onRemove(state.lineItem.id)
    }
  }

  const handleQuantityChange = (value, valid) => {
    setState({
      ...state,
      changes: { ...state.changes, quantity: value || '' },
      validations: { ...state.validations, quantity: valid },
    })
  }

  const addProductToLineItem = async (value, valid) => {
    if (!valid) return

    try {
      const product = await fetchProduct(value.id)
      setState({
        ...state,
        changes: {
          ...state.changes,
          product,
        },
        validations: { ...state.validations, product: valid },
      })
    } catch (error) {
      addError(
        `Error fetching product in addProductToLineItem: ${error}`,
      )
    }
  }

  return (
    <div
      className="clinic-list-item row"
      key={state.lineItem.id}
      style={{
        backgroundColor: state.editing ? 'transparent' : '#fff',
      }}
    >
      <input type="hidden" value={state.lineItem.id} />
      <div className="col-4">
        <TypeaheadInput
          label="Produkt"
          name="product"
          validate={validateProduct}
          value={
            state.editing
              ? state.changes.product
              : state.lineItem.product
          }
          dataProvider={filterProducts}
          editable={state.editing}
          onChange={addProductToLineItem}
        />
      </div>
      <div className="col-1">
        <InputToggleText
          name="quantity"
          label="Antall"
          value={(state.editing
            ? state.changes.quantity
            : state.lineItem.quantity
          ).toString()}
          editable={state.editing}
          onChange={handleQuantityChange}
          validate={v_is_non_zero_number}
        />
      </div>
      <div className="col-2">
        <InputToggleText
          value={formatCurrency(state.lineItem.product.price / 100)}
          editable={false}
        />
      </div>
      <div className="col-2">
        <InputToggleText
          value={formatCurrency(
            state.editing
              ? state.changes.product.price * state.changes.quantity
              : state.lineItem.product.price *
                  state.lineItem.quantity,
          )}
          editable={false}
        />
      </div>
      <div className="col-3 action-bar">
        {state.editing && (
          <>
            {isPersisted && !isOnlyItem && (
              <Button
                onClick={remove}
                className="btn-link mx-1"
                style={{
                  textDecoration: 'underline',
                }}
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#d72520',
                  }}
                >
                  Slett
                </span>
              </Button>
            )}
            <Button
              className="btn-link mx-1"
              onClick={cancel}
              style={{
                textDecoration: 'underline',
              }}
              dataType="clinic-line-item"
            >
              <span
                style={{
                  textDecoration: 'underline',
                }}
              >
                Avbryt
              </span>
            </Button>
            <Button className="btn-link mx-1" onClick={save}>
              <span
                style={{
                  color: '#281837',
                  textDecoration: 'underline',
                }}
              >
                Bekreft
              </span>
            </Button>
          </>
        )}
        {!state.editing && editable && (
          <>
            <Button className="btn-link mx-1" onClick={edit}>
              <img src={assetRootUrl.pen} />
            </Button>
          </>
        )}
      </div>
      {state.error && (
        <p className="small error-message center mb-0">
          {state.error}
        </p>
      )}
    </div>
  )
}

ClinicLineItem.propTypes = {
  lineItem: patientShape,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  isOnlyItem: PropTypes.bool,
}

export default ClinicLineItem
