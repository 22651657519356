import React, { Component } from 'react'
import PropTypes from 'prop-types'

import formatCurrency from '../lib/formatCurrency'
import PrintPage from './PrintPage'

class ReceiptBooking extends Component {
  static propTypes = {
    price: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    baseTwoInOnePrice: PropTypes.number.isRequired,
    discountAmount: PropTypes.any,
    couponCode: PropTypes.any,
    mva: PropTypes.number.isRequired,
    booking: PropTypes.object.isRequired,
    bookingPaymentDate: PropTypes.string.isRequired,
    stripePaymentDetails: PropTypes.any,
    bookingType: PropTypes.string.isRequired,
    assetRootUrl: PropTypes.object.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }

  constructor(props) {
    super(props)

    this.state = {
      discountedPrice: null,
    }
  }

  showPatient() {
    return (
      <tr>
        <td>{this.props.bookingType}</td>
        <td>{this.props.bookingPaymentDate}</td>
        <td>{this.props.mva}%</td>
        <td>{formatCurrency(this.props.basePrice)}</td>
      </tr>
    )
  }

  showSecondaryPatient() {
    if (this.props.booking.two_in_one) {
      return (
        <tr>
          <td>Extra pasient</td>
          <td>{this.props.bookingPaymentDate}</td>
          <td>{this.props.mva}%</td>
          <td>{formatCurrency(this.props.baseTwoInOnePrice)}</td>
        </tr>
      )
    }
  }

  showCardInfos() {
    if (
      this.props.stripePaymentDetails != false &&
      this.props.price > 0
    ) {
      return (
        <div className="col-6 d-flex flex-column align-items-center">
          <p className="small strong mb-0">Betalingsmetode:</p>
          <p className="small mt-0">
            {this.get_card_logo()} -
            {
              this.props.stripePaymentDetails.payment_method_details.card.last4
            }
          </p>
        </div>
      )
    }
  }

  showDiscount() {
    if (this.props.discountAmount != false) {
      const discount = this.props.discountAmount
      return (
        <tr className="receipt-Total">
          <td>Rabatt (kode:{this.props.couponCode})</td>
          <td></td>
          <td></td>
          <td>-{formatCurrency(discount)}</td>
        </tr>
      )
    }
  }

  totalPrice() {
    // if (this.state.discountedPrice != null)
    //   return formatCurrency(this.state.discountedPrice)
    return formatCurrency(this.props.price)
  }

  totalPriceNegative() {
    // if (this.state.discountedPrice != null)
    //   return formatCurrency(this.state.discountedPrice)
    return formatCurrency(-this.props.price)
  }

  get_card_logo() {
    if (
      this.props.stripePaymentDetails.payment_method_details.card.brand.toUpperCase() ===
      'VISA'
    ) {
      return <i className="fab fa-cc-visa card-logo"></i>
    } else if (
      this.props.stripePaymentDetails.payment_method_details.card.brand.toUpperCase() ===
      'MASTERCARD'
    ) {
      return <i className="fab fa-cc-mastercard card-logo"></i>
    } else {
      return this.props.stripePaymentDetails.payment_method_details.card.brand.toUpperCase()
    }
  }

  render() {
    return (
      <div className="receipt-container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-row mt-1">
              <div className="col-6 d-flex flex-column align-items-center">
                <p className="small strong mb-0">Kvitteringsnr. :</p>
                <p className="small mt-0">#{this.props.booking.id}</p>
              </div>
              {this.showCardInfos()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table className="table receipt-table">
              <thead>
                <tr>
                  <th>Tjeneste</th>
                  <th>Bestillingstado</th>
                  <th>MVA</th>
                  <th>Sum</th>
                </tr>
              </thead>
              <tbody>
                {this.showPatient()}
                {this.showSecondaryPatient()}
                {this.showDiscount()}
                <tr>
                  <td colSpan="4" className="separator"></td>
                </tr>
                <tr className="receipt-total">
                  <td colSpan="3">Totalt</td>
                  <td>{this.totalPrice()}</td>
                </tr>
                <tr>
                  <td colSpan="3">Betalt Beløp</td>
                  <td> {this.totalPriceNegative()}</td>
                </tr>
                <tr>
                  <td colSpan="3">Gjenstående Beløp</td>
                  <td>kr 0,00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="Hjemmelegene-info d-flex flex-column align-items-center">
          <img src={this.props.assetRootUrl.logo} alt="logo" />
          <p className="small strong mb-0">Hjemmelegene AS</p>
          <p className="small strong mb-0">Foretaksregisteret</p>
          <p className="small strong mb-0">Org. nr. 918 605 878</p>
          <a
            href="mailto:hei@hjemmelegene.no"
            className="small strong mb-0"
          >
            hei@hjemmelegene.no
          </a>
        </div>
        <PrintPage />
      </div>
    )
  }
}

export default ReceiptBooking
