import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PhoneInput from './forms/fields/PhoneInput'
import UserDataButton from './user_data_form/UserDataButton'
import PhoneValidationModal from './PhoneValidationModal'

class UserAuthForm extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    authenticityToken: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      phoneInputValid: false,
      showError: false,
    }
  }

  submitForm = () => {
    window.location = this.props.path
  }

  formIsValid = () => {
    const inputsValid = this.state.phoneInputValid
    return inputsValid
  }

  showOtp = () => {
    this.setState({ showModal: true })
  }

  showErrorDescription() {
    if (this.state.showError && !this.formIsValid()) {
      return 'Obs: Fyll ut alle nødvendige felter først!'
    }
  }

  handlePhoneChange = (value, valid) =>
    this.setState({
      phoneNumber: value,
      phoneInputValid: valid,
    })

  disabledOnSubmit = () => this.setState({ showError: true })

  closeModal = () => this.setState({ showModal: false })

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="booking-form__input-group-heading">
              Mobilnummer
            </div>
            <div className="row">
              <div className="booking-form__input-group col-sm-12 booking-form__phone-wrapper hlform">
                <PhoneInput
                  onChange={this.handlePhoneChange}
                  value={this.state.phoneNumber || ''}
                  isRequired
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <p className="booking-form__input-description booking-form__input-description--error">
              {this.showErrorDescription()}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-md-center">
            <UserDataButton
              isDisabled={!this.formIsValid()}
              onSubmit={this.showOtp}
              disabledOnSubmit={this.disabledOnSubmit}
              price={1}
            />
            <PhoneValidationModal
              isOpen={this.state.showModal}
              close={this.closeModal}
              submit={this.submitForm}
              phoneNumber={this.state.phoneNumber || ''}
              authenticityToken={this.props.authenticityToken}
              loginMode={true}
              buttonLabel="Gå videre"
            />
          </div>
        </div>
      </>
    )
  }
}

export default UserAuthForm
