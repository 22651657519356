import React from 'react'
import PropTypes from 'prop-types'

const LowCoverage = ({ zip, onClick }) => (
  <div>
    <div className="row mb-2 mt-3">
      <div className="col-12">
        <div className="info-box">
          <div className="info-box__header">
            ⚠️ Warning: Ditt postnummer ({zip}) har variabel dekning
          </div>
          <div className=".info-box__text info-box__text--no-margin">
            Ingen av timene som passer? Vi jobber med å utvide
            dekningen i ditt område, men ta gjerne kontakt med oss på
            kundesupport så kanskje vi kan hjelpe deg allikevel!
            <div className="text-right">
              <a
                href="#"
                className="btn btn-primary intercom-chat-open-request-hours"
                onClick={onClick}
              >
                Åpne chat
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

LowCoverage.propTypes = {
  zip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default LowCoverage
