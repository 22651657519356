import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

const modalStyle = {
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

class ExistingUserWarningModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    recoverPasswordPath: PropTypes.any,
    signInPath: PropTypes.any,
    errorMessage: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    ReactModal.setAppElement('.page__content')
  }

  renderLinks = () => {
    if (this.props.action === 'post') {
      return (
        <div>
          <a
            href={this.props.signInPath}
            className="receipt__link receipt__link--block mr-2"
            style={{ marginBottom: 0 }}
            data-turbolinks="false"
          >
            Logg inn
          </a>
          <a
            href={this.props.recoverPasswordPath}
            className="receipt__link receipt__link--block"
            style={{ marginBottom: 0 }}
            data-turbolinks="false"
          >
            Få nytt passord
          </a>
        </div>
      )
    }
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        contentLabel="Timen din er ikke lenger tilgjengelig"
        style={modalStyle}
        shouldCloseOnEsc={false}
        id="ExistingUserWarningModal"
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={this.props.close}
        />
        <h1 className="ReactModal__heading">Hei igjen 👋</h1>
        <p>{this.props.errorMessage}</p>
        {this.renderLinks()}
      </ReactModal>
    )
  }
}

export default ExistingUserWarningModal
