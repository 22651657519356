import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import { updateAppointment } from './queries'
import { appointmentShape, userShape } from './propShapes'
import HLForm from '../forms/HLForm'
import CheckboxHL from '../forms/fields/CheckboxHL'

const ConsentForm = ({
  appointment,
  user,
  onComplete,
  patientId,
  appointmentPatient,
  t,
  onlyCheckboxes = false,
}) => {
  const [fields, setFields] = useState([])
  const toggleChecked = (slug) => {
    const toCheckIndex = fields.findIndex((f) => f.slug === slug)
    setFields((fields) => {
      const newFields = [...fields]
      newFields[toCheckIndex].value = !newFields[toCheckIndex].value
      return newFields
    })
  }

  const matchFieldsToOptions = (fieldsArr, options) => {
    const result = []
    fieldsArr.forEach((f) => {
      if (options.hasOwnProperty(f.slug)) {
        result.push({ ...f, value: options[`${f.slug}`] })
      }
    })
    return result
  }

  useEffect(() => {
    if (!appointment.products) return []
    let lineItems = []
    if (appointmentPatient && appointmentPatient.options) {
      const productWithFields = appointment.products.find(
        (p) => p.consentFields.length !== 0,
      )
      const fieldsArray = productWithFields?.consentFields?.sort(
        (a, b) => a.position - b.position,
      )
      lineItems = matchFieldsToOptions(
        fieldsArray,
        appointmentPatient.options,
      )
    } else {
      const productWithFields = appointment.products.find(
        (p) => p.consentFields.length !== 0,
      )
      productWithFields?.consentFields
        ?.sort((a, b) => a.position - b.position)
        .map((field) => {
          lineItems.push({ ...field, value: false })
        })
    }
    lineItems?.sort((a, b) => a.position - b.position)
    setFields(lineItems)
  }, [appointment, appointmentPatient])

  const submitConsent = async () => {
    await updateAppointment(user, {
      ...appointment,
      patientId: patientId,
      options: fields,
    })
    onComplete()
  }

  return (
    <section className="d-flex flex-column">
      {!onlyCheckboxes && (
        <>
          <h3 className="mb-4">{t('confirmFollowing', s)}</h3>
          <h5>{t('explanation', s)}</h5>
        </>
      )}
      <HLForm preventSubmit={true}>
        {fields?.map((field) => {
          return (
            <CheckboxHL
              className="naked"
              key={field.slug}
              name={`consent-for-${field.slug}`}
              value={field.value}
              onChange={() => toggleChecked(field.slug)}
              defaultChecked={fields[field.slug]}
            >
              {field.description}
            </CheckboxHL>
          )
        })}
      </HLForm>
      <Button
        label={t('confirm', s)}
        className={'btn-primary mt-5 w-100'}
        onClick={submitConsent}
      />
      <article
        className="text-muted mt-5"
        dangerouslySetInnerHTML={{ __html: t('dataStoring', s) }}
      ></article>
      <section
        className="text-muted"
        style={{ fontSize: '0.7em', marginTop: '13em' }}
      >
        This data will be stored in encrypted state and handled by
        Hjemmelegene only. It will be automatically deleted after 30
        days.
      </section>
    </section>
  )
}

ConsentForm.propTypes = {
  appointment: appointmentShape,
  user: PropTypes.oneOfType([userShape, PropTypes.number]),
  onComplete: PropTypes.func,
  patientId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  appointmentPatient: PropTypes.object,
  t: PropTypes.func,
  onlyCheckboxes: PropTypes.bool,
}

const s = {
  nb: {
    confirmFollowing: 'Vi trenger ditt samtykke',
    explanation:
      'Dersom du er usikker på noe kan du la være å krysse av. Vaksinatøren vil da spørre deg om disse tingene for å avklare om vaksinen kan settes.',
    confirm: 'Bekreft og fullfør',
    dataStoring: `Hjemmelegene oppbevarer samtykkeerklæringen i 6 måneder før den slettes. Vaksinen registreres i det nasjonale vaksinasjonsregisteret SYSVAK. Du finner alle dine vaksiner på <a href='https://www.helsenorge.no/' >www.helsenorge.no</a>`,
  },
  en: {
    confirmFollowing: 'Confirm the following',
    explanation:
      'If you are unsure about something, you can leave the field unchecked. The person performing the vaccination will ask you further questions to confirm the vaccine can be applied.',
    confirm: 'Confirm and complete',
    dataStoring: `Hjemmelegene stores your consent for 6 months before it is deleted. The vaccine registers in the national vaccination register SYSVAK. You can find all your vaccines at <a href='https://www.helsenorge.no/' >www.helsenorge.no</a>`,
  },
  pl: {
    confirmFollowing: 'Potwierdź co następuje',
    explanation:
      'Jeśli nie jesteś pewien którejkolwiek odpowiedzi, możesz zostawić pole puste. Osoba przeprowadzająca szczepienie zada Ci pytania w celu upewnienia się, że szczepienie może być przeprowadzone',
    confirm: 'Potwierdzam',
    dataStoring: `Hjemmelegene przechowuje Twoje zgody przez okres 6 miesięcy, po czym informacja zostanie usunięta. Szczepienie zostanie zarejestrowane w krajowym rejestrze szczepień SYSVAK. Informacja o Twoich szczepieniach jest dostępna na stronie <a href='https://www.helsenorge.no/' >www.helsenorge.no</a>`,
  },
}

export default ConsentForm
