import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import consumer from '../channels/consumer'
import InterestNotification from './InterestNotification'
import LowCoverage from './LowCoverage'
import Booking from './select_time/Booking'
import Day from './select_time/Day'
import DigimedSuggestion from './DigimedSuggestion'

const isFirstBookingSoonEnough = (bookings) => {
  const entries = Object.entries(bookings)
  if (entries.length === 0) return false

  // Total slots available > 5
  if (
    entries.reduce(
      (acc, slots) => acc + slots.length - 1, // First item is the key
      0,
    ) > 5
  ) {
    return false
  }

  // Slots available on first day > 5
  const firstDay = entries[0].slice(1) // First item is the key
  if (firstDay.length < 6) return false

  // First slot available in < 8 hours
  const firstAvailable = new Date(firstDay.begins_at)
  return firstAvailable - Date.now() < 1000 * 60 * 60 * 8
}
class SelectTimeView extends Component {
  static propTypes = {
    availableBookings: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    expressBooking: PropTypes.object,
    method: PropTypes.string,
    infoModalHeading: PropTypes.string,
    infoModalBody: PropTypes.string,
    infoModalButtonLabel: PropTypes.string,
    infoModalPath: PropTypes.string,
    inLowCoverage: PropTypes.bool,
    secondary: PropTypes.bool,
    digimedVideoIllustrationPath: PropTypes.string,
    suggestDigimed: PropTypes.bool,
  }

  static defaultProps = {
    secondary: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      availableBookings: props.availableBookings,
      soonEnough: isFirstBookingSoonEnough(props.availableBookings),
      infoModalOpen: false,
    }
  }

  componentDidMount() {
    this.setState(this.displayInterest(this.state.availableBookings))

    consumer.subscriptions.create('AvailabilityChannel', {
      received: this.updateBookingAvailability,
    })
  }

  between(zip, x, y) {
    return zip >= x && zip <= y
  }

  // specific and temporary function related to Bergen opening
  inBergenZipRange = () => {
    return this.between(this.props.zip, 5000, 5900)
  }

  displayInterest = (newAvailableBookings) => {
    if (this.props.secondary) return
    if (Object.keys(newAvailableBookings).length === 0) {
      $.ajax({
        url: '/interest_notifications/new',
        type: 'GET',
        data: { zip: this.props.zip || '' },
        dataType: 'json',
      }).done((data) => {
        this.setState({ data })
        this.setState({ displayInterestView: true })
      })
    }
  }

  showTodayOrTomorrow(date) {
    const today = moment().tz('Europe/Oslo').startOf('day')
    const dateWithTZ = moment(date).tz('Europe/Oslo').startOf('day')

    const daysDiff = dateWithTZ.diff(today, 'days')

    if (daysDiff == 0) {
      return '(i dag)'
    } else if (daysDiff == 1) {
      return '(i morgen)'
    }

    return ''
  }

  updateBookingAvailability = (booking) => {
    const searchAttributes = {
      service_provider: { id: booking.service_provider_id },
      begins_at: booking.begins_at,
    }

    const availableBookingsDupe = { ...this.state.availableBookings }

    _.each(availableBookingsDupe, (bookings) => {
      const bookingInCollection = _.find(bookings, searchAttributes)

      if (bookingInCollection) {
        bookingInCollection.reserved = booking.reserved
      }
    })
    this.setState({ availableBookings: availableBookingsDupe })
  }

  firstBooking() {
    let firstNotRemovedBooking = undefined

    _.each(this.state.availableBookings, (day) => {
      if (firstNotRemovedBooking) {
        return false
      }

      const groupedBookings = _.groupBy(day, 'begins_at')
      _.each(groupedBookings, (bookingGroup, hour) => {
        if (firstNotRemovedBooking) {
          return false
        }

        // Exclude Peder from first hit
        firstNotRemovedBooking = _.find(bookingGroup, (booking) => {
          return (
            !booking.reserved &&
            booking.service_provider_id != 41 &&
            !booking.express_booking
          )
        })

        if (!firstNotRemovedBooking) {
          firstNotRemovedBooking = _.find(bookingGroup, (booking) => {
            return !booking.reserved && !booking.express_booking
          })
        }
      })
    })

    return firstNotRemovedBooking || {}
  }

  firstCharUppercase(text) {
    return text.replace(/^\w/, (c) => c.toUpperCase())
  }

  openIntercom = (e) => {
    e.preventDefault()
    analytics.page()
    window.Intercom(
      'showNewMessage',
      `Hei, jeg etterlyser flere timer på postnummer ${this.props.zip}`,
    )

    setTimeout(function () {
      var intercomIframe = $(
        "iframe[name='intercom-messenger-frame']",
      ).contents()
      intercomIframe.find('.intercom-composer-send-button').click()
    }, 1000)
  }

  render() {
    let trackingIndex = 1
    const days = _.map(
      this.state.availableBookings,
      (bookings, date) => {
        const el = (
          <div key={date}>
            <div className="available__heading">
              <div className="available__date">
                <span>
                  {this.firstCharUppercase(
                    moment(date).format('dddd Do MMMM'),
                  )}
                </span>
                <span>{this.showTodayOrTomorrow(date)}</span>
              </div>
            </div>
            <div className="day" data-date={date}>
              <Day
                date={date}
                bookingsDay={bookings}
                firstBooking={this.firstBooking()}
                path={this.props.path}
                method={this.props.method}
                trackingIndexStart={trackingIndex}
              />
            </div>
            {this.props.suggestDigimed !== false &&
              !this.state.soonEnough &&
              trackingIndex === 1 && (
                <DigimedSuggestion
                  heading="Ikke raskt nok?"
                  imageUrl={this.props.digimedVideoIllustrationPath}
                />
              )}
          </div>
        )
        trackingIndex += bookings.length
        return el
      },
    )

    return (
      <>
        <>
          <div>
            {this.props.expressBooking && (
              <Booking
                booking={this.props.expressBooking}
                firstBooking={this.firstBooking()}
                path={this.props.path}
              />
            )}
            {days}
          </div>
          {!this.inBergenZipRange() && this.props.inLowCoverage && (
            <LowCoverage
              zip={this.props.zip}
              onClick={this.openIntercom}
            />
          )}
          {this.state.displayInterestView && (
            <InterestNotification {...this.state} />
          )}
        </>
      </>
    )
  }
}

export default SelectTimeView
