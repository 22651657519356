export default {
  order: 'Order',
  back: 'Back',
  continue: 'Continue',
  previous: 'Previous',
  next: 'Next',
  show: 'Show',
  chosen: 'Chosen',
  from: 'From',
  to: 'To',
  confirm: 'Confirm',
  cancel: 'Cancel',
  restart: 'Start over',
  startOver: 'Please start over.',
  phoneNumber: 'Phone number',
  thankYou: 'Takk!',
  sendCode: 'Send code',
  smsCode: 'Code from SMS',
  companyName: 'Company name',
  optional: 'Optional',
  nationalId: 'National ID number',
  fullName: 'Full name',
  today: 'Today',
  tomorrow: 'Tomorrow',
  atTime: 'at',
  person: 'Person',
  persons: 'Persons',
  time: 'Time',
  location: 'Location',
  address: 'Address',
  numberOf: 'Number of',
  tests: 'Tests',
  cancelAppointment: 'Cancel appointment',
  billing_reference: 'Billing reference',
  billing_due_by: 'Invoice due days',
  billing_email: 'Billing email',
  paymentType: 'Payment method',
  paymentTypes: {
    card: 'Card at checkout',
    invoice: 'Invoice',
  },
  invoicingMethods: {
    email: 'E-mail',
    letter: 'Letter',
    ehf: 'EHF',
  },
  products: 'Products/services',
  organizations: {
    name: 'Name of the company',
    number: "Company's registration number",
  },
  unpaid: 'Unpaid',
  paid: 'Paid',
}
