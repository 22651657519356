import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import ClinicContext from './ClinicContext'
import moment from 'moment-timezone'

export const ClinicAppointmentListNavbar = ({
  qrPosterUrl,
  isAddingPatient,
  onAddPatient,
}) => {
  return (
    <div className="d-flex justify-content-between mb-2">
      <ClinicAppointmentListHeader />
      <div>
        <Button
          label={isAddingPatient ? 'Tilbake til listen' : 'Ny time'}
          className="btn-primary"
          onClick={onAddPatient}
        />
      </div>
    </div>
  )
}

ClinicAppointmentListNavbar.propTypes = {
  ...headerPropShape,
  isAddingPatient: PropTypes.bool.isRequired,
  onAddPatient: PropTypes.func.isRequired,
}

function ClinicAppointmentListHeader() {
  const { clinic, booking } = useContext(ClinicContext)
  const { qrCode } = useContext(ClinicContext)
  return (
    <div className="d-flex justify-content-center">
      <div>
        <img
          style={{ border: '1px solid #bbb' }}
          src={`data:image/jpeg;base64,${qrCode}`}
          alt=""
          height={100}
          width={100}
          className="mr-2"
        />
      </div>
      <div>
        <h1>
          {clinic?.name ||
            booking?.organizational_customer?.name ||
            `Booking ${booking?.id}`}
          {!!booking && (
            <span className="light">
              {' '}
              {moment(booking.begins_at).format('D.M.YYYY')}
            </span>
          )}
        </h1>
        <p className="preamble">
          {booking.address}, {booking.city}
        </p>
      </div>
    </div>
  )
}

ClinicAppointmentListHeader.propTypes = { ...headerPropShape }

const headerPropShape = PropTypes.shape({
  qrPosterUrl: PropTypes.string.isRequired,
})
