import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PhoneInput from './forms/fields/PhoneInput'
import OtpInput from './forms/fields/OtpInput'
import Submit from './forms/fields/SubmitButton'
import Button from './forms/fields/Button'
import HLForm from './forms/HLForm'
import gql from '../api/graphql'

const INITIAL_STATE = {
  phone_number: null,
  phoneValid: false,
  code: null,
  codeValid: false,
  error: null,
}

const OtpAuth = ({ onComplete, setLoading }) => {
  const [state, setState] = useState(INITIAL_STATE)

  const sendCode = async (phone_number) => {
    return await gql(
      `mutation {
          newOtpSession(phoneNumber: "${phone_number}", userAgnostic: true) {
            otpCode
          }
        }
      `,
    )
  }

  const verifyCode = async (phone_number, code) => {
    return await gql(
      `mutation {
          verifyOtpSession(phoneNumber: "${phone_number}", code: "${code}") {
              id
              name
              phoneNumber
          }
        }`,
    )
  }

  const reset = () => {
    setState(INITIAL_STATE)
  }

  const handleOtpSubmit = async (e, values) => {
    setLoading(true)
    try {
      const response = await verifyCode(
        state.phone_number,
        values.otp_code,
      )
      if (response.data.errors) {
        setState({
          ...state,
          error:
            'Feil kode. Prøv på nytt eller gå tilbake for å få ny kode.',
        })
      } else if (!response.data.data) {
        setState({
          ...state,
          error:
            'Det oppstod en ukjent feil. Vennligst start på nytt.',
        })
      } else if (response.data.data) {
        onComplete({
          phone_number: state.phone_number,
          id: response.data.data.id,
        })
      }
    } catch (e) {
      setState({ error: e.toString() })
      console.error(e)
    }
    setLoading(false)
  }

  const handlePhoneNumberSubmit = async (e, values) => {
    setLoading(true)
    try {
      const response = await sendCode(values.phone_number)
      setState({
        ...state,
        phone_number: values.phone_number,
        code: response.data.data.newOtpSession.otpCode,
      })
    } catch (e) {
      setState({ error: e.toString() })
      console.error(e)
    }
    setLoading(false)
  }

  const handlePhoneChange = (value, valid) => {
    setState({ ...state, phoneValid: valid })
  }

  const handleCodeChange = (value, valid) => {
    setState({ ...state, codeValid: valid })
  }

  return (
    <section className="otp-auth">
      <h1 className="mb-4">Vaksineregistrering</h1>
      {!state.phone_number && (
        <>
          <p>
            Oppgi ditt telefonnummer, inkludert landskode (f.eks. +47)
            for å starte.{' '}
            <span className="text-danger">
              Finn frem telefonen din. Du vil motta en kode på SMS!
            </span>
          </p>
          <HLForm
            preventSubmit={true}
            onSubmit={handlePhoneNumberSubmit}
            omitCsrf={true}
          >
            <PhoneInput
              highlightErrors={true}
              onChange={handlePhoneChange}
              autoComplete="off"
              placeholder="+47..."
              autoFocus={true}
              isRequired
            ></PhoneInput>
            <Submit
              disabled={!state.phoneValid}
              label="Send kode"
              className="my-3"
            ></Submit>
          </HLForm>
        </>
      )}
      {state.phone_number && (
        <>
          <HLForm
            preventSubmit={true}
            onSubmit={handleOtpSubmit}
            omitCsrf={true}
          >
            <OtpInput
              autoFocus={true}
              onChange={handleCodeChange}
              value={state.code}
            ></OtpInput>
            <div className="row my-3">
              <div className="col-6">
                <Button
                  className="btn-outline btn-primary"
                  label="Tilbake"
                  onClick={reset}
                ></Button>
              </div>
              <div className="col-6 text-right">
                <Submit
                  disabled={!state.codeValid}
                  label="Bekreft"
                ></Submit>
              </div>
            </div>
          </HLForm>
        </>
      )}
      {state.error && <p className="text-danger">{state.error}</p>}
    </section>
  )
}

OtpAuth.propTypes = {
  onComplete: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OtpAuth
