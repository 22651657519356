import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ServiceProviderListItem from './ServiceProviderListItem'
import Products from './Products'
import moment from 'moment-timezone'

const ServiceProviderFilter = () => {
  const dispatch = useDispatch()
  const [productsSelected, setProductsSelected] = useState([])
  const [expanded, setExpanded] = useState(false)

  const handleProductsSelection = (productsSelected) => {
    setProductsSelected(productsSelected)
  }

  const productsList = useSelector(
    (state) => state.dataset.products.list,
  )
  const dateSelected = useSelector((state) => state.dateSelected)

  const calendarEntriesData = useSelector(
    (state) => state.dataset.calendarEntries,
  )

  const serviceProviders = useSelector(
    (state) => state.dataset.serviceProviders,
  )

  const constructCalendarEntries = () => {
    if (calendarEntriesData !== null) {
      const calendarEntriesArray = calendarEntriesData.map(
        (entry) => {
          const {
            id,
            begins_at,
            ends_at,
            service_provider: { id: spId },
          } = entry
          return {
            id,
            begins_at,
            ends_at,
            spId,
          }
        },
      )
      return calendarEntriesArray
    }
  }

  const spsFilteredByCalEntries = () => {
    const calendarEntries = constructCalendarEntries()
    if (
      dateSelected !== null &&
      dateSelected.length > 0 &&
      calendarEntries !== null &&
      calendarEntries.length > 0
    ) {
      const calEntriesFilteredbyDate = calendarEntries
        .filter(
          (entry) =>
            dateSelected ===
            moment(entry.begins_at)
              .tz('Europe/Oslo')
              .startOf('day')
              .format('yyyy-MM-DD'),
        )
        .map((entry) => entry.spId)
      const spFiltered = serviceProviders.filter((sp) =>
        calEntriesFilteredbyDate.includes(sp.id),
      )
      return spFiltered
    } else {
      return serviceProviders
    }
  }

  const spsFilteredByProducts = (sps) => {
    if (sps && sps.length > 0) {
      if (productsSelected.includes('All products')) {
        return sps
      }
      return sps.filter(
        (sp) =>
          productsSelected.length > 0 &&
          productsSelected.some((element) =>
            sp.products
              .map((product) => product.name)
              .includes(element),
          ),
      )
    }
  }

  const showCheckboxes = (e) => {
    e.preventDefault()
    if (!expanded) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  const displayFilterSpbyProduct = () => {
    if (serviceProviders.length > 0 && expanded) {
      return (
        <Products
          options={['All products', ...productsList]}
          onProductSelected={handleProductsSelection}
        />
      )
    }
  }

  const displayMultiSelectButtons = () => {
    const spsByCal = spsFilteredByCalEntries()
    const spsFiltered = spsFilteredByProducts(spsByCal)

    if (spsFiltered && spsFiltered.length > 0) {
      return (
        <div className="d-flex sp-form__multiselect__selections mb-3">
          <button
            className="left"
            onClick={() => {
              dispatch({
                type: 'DATA/SERVICE_PROVIDERS_SELECT_ALL_DISPLAYED',
                payload: spsFiltered.map((sp) => sp.id),
              })
            }}
          >
            Select All
          </button>
          <button
            className="right"
            onClick={() => {
              const spsByCal = spsFilteredByCalEntries()
              const spsFiltered = spsFilteredByProducts(spsByCal)
              dispatch({
                type: 'DATA/SERVICE_PROVIDERS_UNSELECT_ALL_DISPLAYED',
                payload: spsFiltered.map((sp) => sp.id),
              })
            }}
          >
            Unselect All
          </button>
        </div>
      )
    }
  }

  const displayServiceProviders = () => {
    const spsByCal = spsFilteredByCalEntries()
    const spsFiltered = spsFilteredByProducts(spsByCal)
    if (spsFiltered && spsFiltered.length > 0) {
      return spsFiltered.map((sp) => (
        <ServiceProviderListItem key={sp.id} sp={sp} />
      ))
    }
  }

  return (
    <div className="sp-form__multiselect">
      <div className="sp-form__selectBox" onClick={showCheckboxes}>
        <i className="fas fa-user-md"></i>
        Select a service provider
      </div>

      <div className="sp-form__sp-list mb-3">
        {displayFilterSpbyProduct()}
        {displayMultiSelectButtons()}
        {displayServiceProviders()}
      </div>
    </div>
  )
}

export default ServiceProviderFilter
