import React from 'react'
import PropTypes from 'prop-types'

import { DEFAULT_LOCALE, translate } from '../../lib/locale'
import { renderTier } from './utils/tiers'

const PriceModelInfoBox = ({ priceModel, expendable, expended }) => {
  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  return (
    expendable &&
    expended && (
      <div className={' info-box price-model_info-box mb-2'}>
        <div className={'price-model_info-box_element'}>
          <p className="strong my-0">{t('product')}:</p>
          <p className="my-0">
            {priceModel?.product ? priceModel?.product?.name : '-'}
          </p>{' '}
        </div>
        <div className={'price-model_info-box_element'}>
          <p className="strong my-0">{t('description')}:</p>
          <p className="my-0">
            {priceModel?.product
              ? priceModel?.product?.description
              : '-'}
          </p>{' '}
        </div>

        <div className={'price-model_info-box_element'}>
          <p className="strong my-0">{t('priceTiers')}:</p>
          {priceModel?.tiers?.map((tier, i) => (
            <div key={i} className={'price-model__tier'}>
              <div className={'price-model__tier-quantity'}>
                {renderTier(tier, priceModel.tiers[i + 1])}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  )
}

PriceModelInfoBox.defaultProps = {
  expended: false,
}

PriceModelInfoBox.propTypes = {
  priceModel: PropTypes.object.isRequired,
  expendable: PropTypes.bool.isRequired,
  expended: PropTypes.bool.isRequired,
}

export default PriceModelInfoBox
