import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import PatientList from './PatientList'
import Button from './forms/fields/Button'
import HLForm from './forms/HLForm'

const modalStyle = {
  content: {
    width: '80%',
    WebkitOverflowScrolling: 'touch',
  },
}

const AddNewPatientBySp = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [busy, setBusy] = useState(false)

  const openModal = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const cancel = (e) => {
    e.preventDefault()
    closeModal()
  }

  const addNewPatients = () => {
    setBusy(true)
    document.forms['backoffice-new-patients'].submit()
    setBusy(false)
  }

  ReactModal.setAppElement('body')

  return (
    <>
      <Button
        className="btn btn-link"
        id="add-new-patient"
        onClick={openModal}
      >
        Legg til pasient
      </Button>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Add new patient"
        style={modalStyle}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <HLForm
          action={props.path}
          method="patch"
          name="backoffice-new-patients"
        >
          <PatientList
            parent_model="booking"
            national_ids={{}}
            allowAddPatients={false}
          ></PatientList>
          <div className="row">
            <div className="col-6">
              <Button
                label="Avbryt"
                onClick={cancel}
                className="btn-link mt-4"
              />
            </div>
            <div className="col-6 text-right">
              <Button
                label="Lagre"
                type="submit"
                disabled={busy}
                onClick={addNewPatients}
                className="btn-primary"
              />
            </div>
          </div>
        </HLForm>
      </ReactModal>
    </>
  )
}

AddNewPatientBySp.propTypes = {
  path: PropTypes.string.isRequired,
}

export default AddNewPatientBySp
