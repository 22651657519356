import React from 'react'
import PropTypes from 'prop-types'

import Layout from './shared/Layout'

const VaccineWizardNoAvailableHours = ({
  nearbyServiceProviders,
  assetRootUrl,
}) => {
  const templates = {
    NO_COVERAGE: {
      title: 'Beklager!',
      body:
        'Vi tilbyr dessverre ikke bedriftsvaksinering på din adresse gjennom selvbestilling. Kontakt kundesupport gjennom chatten nederst på siden for å se om vi likevel kan hjelpe deg.',
    },
    NO_AVAILABLE_HOURS: {
      title: 'Alle timer er opptatt nå',
      body:
        'Alle tilgjengelige timer i ditt område er dessverre opptatte. Velkommen tilbake ved en senere anledning!',
    },
  }

  const template = nearbyServiceProviders
    ? templates.NO_AVAILABLE_HOURS
    : templates.NO_COVERAGE

  return (
    <Layout>
      <h4>{template.title}</h4>
      <p className="mb-5">{template.body}</p>
      <img
        className="wizard-images position-absolute d-none d-sm-block"
        src={assetRootUrl.doctorWalkingImage}
        alt="Doctor walking"
      />
    </Layout>
  )
}

VaccineWizardNoAvailableHours.propTypes = {
  nearbyServiceProviders: PropTypes.bool.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
}

VaccineWizardNoAvailableHours.defaultProps = {
  nearbyServiceProviders: false,
}

export default VaccineWizardNoAvailableHours
