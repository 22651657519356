import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

const modalStyle = {
  content: {
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
  },
}

class InfoModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen,
    }
    ReactModal.setAppElement('body')
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        contentLabel={this.props.heading}
        style={modalStyle}
        shouldCloseOnEsc={false}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={this.props.close}
        />
        <div className="ReactModal__heading mb-3"></div>
        <h4>{this.props.heading}</h4>
        <p>{this.props.body}</p>
        <button
          className="btn btn-secondary btn-block ReactModal__submit"
          onClick={this.props.close}
          id="bergenWarning"
        >
          {this.props.buttonLabel || 'Bekreft'}
        </button>
      </ReactModal>
    )
  }
}

export default InfoModal
