import React from 'react'
import PropTypes from 'prop-types'

const TelLink = ({ phoneNumber, hiddenCaller }) => {
  if (!phoneNumber) return null
  let title = 'Ring opp ' + phoneNumber
  if (hiddenCaller) title += ' fra skjult nummer'

  const safePhoneNumber = phoneNumber.replace('+', '00')
  const href = hiddenCaller
    ? `tel:%2331%23${safePhoneNumber}`
    : `tel:${safePhoneNumber}`

  return (
    <a href={href} title={title}>
      {phoneNumber}
    </a>
  )
}

TelLink.defaultProps = {
  hiddenCaller: true,
}

TelLink.propTypes = {
  phoneNumber: PropTypes.string,
  hiddenCaller: PropTypes.bool,
}

export default TelLink
