import React, { createContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { attributeFilter } from '../../lib/utils'
import { DEFAULT_LOCALE, translate } from '../../lib/locale/'
import {
  childrenType,
  serviceCenterShape,
} from '../appointments/propShapes'
import {
  useActionCable,
  useChannel,
} from '@aersoftware/react-use-action-cable'

const ClinicContext = createContext({})

export const ClinicContextProvider = (props) => {
  const { actionCable } = useActionCable('/cable')
  const { subscribe, unsubscribe, send } = useChannel(actionCable)
  const [errors, setErrors] = useState([])

  const addError = useCallback((error) => {
    setErrors((errors) => [...errors, error])
  }, [])

  const clearErrors = useCallback(() => {
    setErrors([])
  }, [])

  return (
    <ClinicContext.Provider
      value={{
        clinic: props.clinic,
        booking: props.booking,
        multipleCustomers: props.booking.multiple_customers,
        isMassVaccineBooking:
          props.booking['is_mass_vaccine_booking?'],
        // Should change when relation between patient and product
        hackConsentOptions: {
          hcl_vac_sit: false,
          hcl_vac_biv: false,
          hcl_vac_rea: false,
          hcl_vac_blo: false,
          hcl_vac_sic: false,
          hcl_vac_med: false,
          hcl_vac_egg: false,
        },
        maxCapacity: props.maxCapacity,
        serviceProvider: props.serviceProvider,
        assetRootUrl: props.assetRootUrl,
        qrPosterUrl: props.qrPosterUrl,
        qrCode: props.qrCode,
        t: (prop, source) =>
          translate(`${DEFAULT_LOCALE}.${prop}`, source),
        socket: {
          connect: (opts = {}) => {
            const handlers = {
              connected: opts.statusHandler
                ? () => opts.statusHandler(true)
                : null,
              disconnected: opts.statusHandler
                ? () => opts.statusHandler(false)
                : null,
              received: opts.dataHandler
                ? (data) => opts.dataHandler(data)
                : null,
            }

            subscribe(
              {
                channel: 'ServiceCentersChannel',
                service_center_id: props.clinic?.id,
                booking_id: props.booking?.id,
              },
              handlers,
            )
          },
          disconnect: unsubscribe,
          send: send,
        },
        filterProducts: (query = null) => {
          const products = !!props.clinic
            ? props.clinic.currently_active_products
            : [props.booking.v2_product]
          return attributeFilter(products, query)
        },
        allTrue: (opts) => {
          return (
            Object.keys(opts).length > 0 &&
            !Object.keys(opts).some((key) => opts[key] === false)
          )
        },
        errors: errors,
        addError: addError,
        clearErrors: clearErrors,
        nextBooking: props.nextBooking,
        googleMapsApiKey: props.googleMapsApiKey,
      }}
    >
      {props.children}
    </ClinicContext.Provider>
  )
}

ClinicContextProvider.propTypes = {
  maxCapacity: PropTypes.number.isRequired,
  serviceProvider: PropTypes.object.isRequired,
  qrPosterUrl: PropTypes.string.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  clinic: serviceCenterShape,
  booking: PropTypes.object,
  children: childrenType,
  qrCode: PropTypes.string,
  nextBooking: PropTypes.object,
  googleMapsApiKey: PropTypes.string,
}

export default ClinicContext
