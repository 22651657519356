function formatCurrency(val) {
  const thousandSeparator = ' '
  const decimalSeparator = ','
  const currencyPrefix = 'kr '

  function ins1000Sep(input) {
    const val = input.split('.')
    val[0] = val[0].split('').reverse().join('')
    val[0] = val[0].replace(/(\d{3})/g, '$1' + thousandSeparator)
    val[0] = val[0].split('').reverse().join('')
    val[0] = val[0].indexOf(',') == 0 ? val[0].substring(1) : val[0]
    return val.join(decimalSeparator)
  }

  function formatNum(input) {
    let val = Math.round(input * 100) / 100
    val = ('' + val).indexOf('.') > -1 ? val + '00' : val + '.00'
    var dec = val.indexOf('.')
    return dec == val.length - 3 || dec == 0
      ? val
      : val.substring(0, dec + 3)
  }

  return `${currencyPrefix} ${ins1000Sep(formatNum(val))}`
}

export default formatCurrency
