import React from 'react'
import PropTypes from 'prop-types'

const GoogleMapsLink = ({ address, text }) => (
  <a
    href={`https://www.google.no/maps?q=${address}&country=Norway`}
    target="_blank"
    rel="noreferrer"
  >
    {text || address}
  </a>
)

GoogleMapsLink.propTypes = {
  address: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default GoogleMapsLink
