import React from 'react'
import moment from 'moment-timezone'
import addMarkerToMap from './utils/marker'
import buildPolygon from './utils/coverage'
import { useSelector } from 'react-redux'
import Map from './Map'

const isEmpty = (str) => {
  return !str || 0 === str.length
}

const RouteMap = () => {
  const bookings = useSelector((state) => state.dataset.bookings)
  const referrals = useSelector((state) => state.dataset.referrals)
  const serviceProviders = useSelector(
    (state) => state.dataset.serviceProviders,
  )
  const dateSelected = useSelector((state) => state.dateSelected)
  const referralsSelected = useSelector(
    (state) => state.referralsSelected,
  )
  const coverageMapSelected = useSelector(
    (state) => state.coverageData,
  )

  const routesData = useSelector((state) => state.routesData)
  const routesOptimizedData = useSelector(
    (state) => state.routesOptimizedData,
  )

  const productsDetailed = useSelector(
    (state) => state.dataset.products.productsDetailed,
  )

  const findProductSector = (booking) => {
    if (booking.v2_product.id) {
      return productsDetailed.find(
        (product) => product.id === booking.v2_product.id,
      ).sector
    } else if (booking.rebooking === true) {
      return 'rebooking'
    } else {
      return 'unknown'
    }
  }

  const constructMarkersDataArray = () => {
    const spChecked = serviceProviders
      .filter((sp) => sp.checked === true)
      .map((sp) => sp.id)

    const serviceProviderMarkers = serviceProviders.reduce(
      (accum, sp) => {
        if (serviceProviders.length > 0 && sp.checked === true) {
          const {
            lat,
            lng,
            first_name,
            last_name,
            address,
            color,
            id,
          } = sp

          return [
            ...accum,
            {
              id,
              lat,
              lng,
              color,

              label: `${last_name}`,
              infoWindow: {
                title: `${first_name} ${last_name}`,
                subtitle: '',
                body: `adress: ${address}`,
              },
              type: 'serviceProvider',
            },
          ]
        } else {
          return accum
        }
      },
      [],
    )

    const referralMarkers = referrals.reduce((accum, rf) => {
      if (
        referralsSelected.length > 0 &&
        referralsSelected.includes(rf.id)
      ) {
        const {
          id,
          lat,
          lng,
          organization,
          address,
          patient_count,
          custom_admin_path,
        } = rf

        return [
          ...accum,
          {
            id,
            lat,
            lng,
            label: `ref: #${id}`,
            infoWindow: {
              title: `referral id: #${id} <br/>number of patients:  ${patient_count}`,
              subtitle: `${organization}`,
              body: `adress: ${address}`,
              path: custom_admin_path,
            },
            type: 'referral',
            color: '#f6f802',
          },
        ]
      } else {
        return accum
      }
    }, [])

    const bookingsMarkers = bookings.reduce((accum, booking) => {
      const sector = findProductSector(booking)
      if (
        spChecked.includes(booking.service_provider_id) &&
        dateSelected ===
          moment(booking.begins_at)
            .tz('Europe/Oslo')
            .startOf('day')
            .format('yyyy-MM-DD')
      ) {
        const {
          id,
          lat,
          lng,
          begins_at,
          address,
          patient_count,
          custom_admin_path,
        } = booking

        const color = serviceProviders.find(
          (sp) => sp.id === booking.service_provider_id,
        ).color

        return [
          ...accum,
          {
            id,
            lat,
            lng,
            label: moment(begins_at).format('HH:mm'),
            infoWindow: {
              title: `booking id:  #${id}<br/>number of patients:  ${patient_count}<br/>sector:  ${sector}`,
              subtitle: `begins at : ${moment(begins_at).format(
                'HH:mm (yyy-MM-DD)',
              )}`,
              body: `adress: ${address}`,
              path: custom_admin_path,
            },
            type: 'booking',
            color: color,
            sector: sector,
          },
        ]
      } else {
        return accum
      }
    }, [])

    return [
      ...serviceProviderMarkers,
      ...referralMarkers,
      ...bookingsMarkers,
    ]
  }

  const constructMarkers = () => {
    const markersArray = []
    // only pass the data needed : a flat array with type and lat long and maybe marker url
    const elementsList = constructMarkersDataArray()
    elementsList.forEach((element) => {
      if (!isEmpty(element.lat) && !isEmpty(element.lng)) {
        const marker = addMarkerToMap({
          data: element,
        })
        markersArray.push(marker)
      } else {
        alert(
          `${element.type} with id: #${element.id} doesn't have a latitude or longitude, we can't display it on the map.`,
        )
      }
    })

    return markersArray
  }

  const constructPolygons = () => {
    const polygonsArray = []
    const elementsList = coverageMapSelected

    elementsList.forEach((element) => {
      // doesn't take into account Sp with multiple coverage map => take the first one
      const polygon = buildPolygon({
        data: element.coverageMaps[0].map,
        color: element.color,
      })
      polygonsArray.push(polygon)
    })
    return polygonsArray
  }

  const contructDirectionsRenderers = (
    routes,
    supressMarkers,
    optimized,
  ) => {
    const directionsRendererArray = []
    const routesList = routes
    routesList.forEach((route) => {
      const directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: supressMarkers,
      })
      directionsRenderer.setDirections(route.response)
      if (!optimized) {
        directionsRenderer.setOptions({
          polylineOptions: {
            strokeColor: route.color,
            strokeOpacity: 0.6,
            strokeOpacity: 0.6,
            strokeWeight: 4,
          },
        })
      }

      directionsRendererArray.push(directionsRenderer)
    })
    return directionsRendererArray
  }

  const markers = constructMarkers()
  const polygons = constructPolygons()
  const directionsRenderersRoutes = contructDirectionsRenderers(
    routesData,
    true,
  )
  const directionsRenderersRoutesOptimized = contructDirectionsRenderers(
    routesOptimizedData,
    false,
    true,
  )

  return (
    <div>
      <Map
        markers={markers}
        polygons={polygons}
        directionsRenderers={directionsRenderersRoutes}
        directionsRenderersOptimized={
          directionsRenderersRoutesOptimized
        }
      />
    </div>
  )
}

export default RouteMap
