import gqlCall from '../../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
  defaultGraphQLSerializer,
} from '../../api/graphqlExtended'
import moment from 'moment-timezone'

export async function fetchCurrentServiceProvider() {
  try {
    const query = `
    query {
      currentServiceProvider {
        id
        firstName
        lastName
        authLevel
        lvl4AuthSessionExpiry
      }
    }
    `
    const response = await gqlCall(query)
    return response.data.data.currentServiceProvider
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchUser(phoneNumber) {
  try {
    const query = buildGraphQLQuery({
      searchOptions: { phoneNumber: phoneNumber },
      resourceName: 'user',
      multiple: false,
    })

    const response = await gqlCall(query)
    return parseQueryResponse({
      response: response,
      resourceName: 'user',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchAppointmentsPatients(
  serviceCenterId,
  bookingId,
  date,
) {
  try {
    const hasServiceCenter = !!serviceCenterId
    const searchDate = moment(date).format('YYYY-MM-DD')
    const options = hasServiceCenter
      ? {
          serviceCenterId,
          date: searchDate,
        }
      : {
          bookingId,
        }
    const resourceName = hasServiceCenter
      ? 'serviceCenterPatients'
      : 'bookingAppointmentPatients'
    const response = await gqlCall(
      buildGraphQLQuery({
        searchOptions: options,
        resourceName: resourceName,
        multiple: true,
      }),
    )
    return parseQueryResponse({
      response: response,
      resourceName: resourceName,
      multiple: true,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateAppointment(appointment) {
  try {
    if (!appointment.id) return
    const params = defaultGraphQLSerializer(
      { ...appointment, userId: appointment.user.id },
      'appointments',
    )
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'updateAppointment',
      params: params.join(','),
    })
    const { data } = (await gqlCall(query)).data
    return data['updateAppointment']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateAppointmentProducts(
  appointment,
  productSlugs,
) {
  try {
    const mutation = `mutation {
      updateAppointment(
        userId: ${appointment.user.id}
        id: ${appointment.id}
        date: "${appointment.date}"
        hour: "${appointment.hour}"
        productSlugs: ${JSON.stringify(productSlugs)}
        ) {
          id
        }
    }`
    await gqlCall(mutation)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchProduct(productId) {
  try {
    const product_query = buildGraphQLQuery({
      searchOptions: { id: productId },
      resourceName: 'product',
      multiple: false,
    })

    const product_response = await gqlCall(product_query)

    return parseQueryResponse({
      response: product_response,
      resourceName: 'product',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createAppointment(
  clinic,
  booking,
  appointment,
  productList,
  patientList,
  serviceProviderId = nil,
) {
  try {
    const appointmentTime = {
      date: moment(new Date()).format('YYYY-MM-DD'),
      hour: 'DROP_IN_NOW',
    }

    if (productList.length === 0) {
      return Promise.reject(new Error('Product list is empty'))
    }

    const multiplePatient = patientList.length > 1
    const patientParams = multiplePatient
      ? patientList.map((p) => p.patientId)
      : patientList[0]
    const stringifiedOptions = stringifyPatientOptions(patientParams)

    const params = [
      !!clinic
        ? `serviceCenterId: ${clinic.id}`
        : `bookingId: ${booking.id}`,
      `date: "${appointmentTime.date}"`,
      `hour: "${appointmentTime.hour}"`,
      `productSlugs: [${productList}]`,
      `userId: ${appointment.user.id}`,
      serviceProviderId
        ? `currentlyEditedById: ${serviceProviderId}`
        : '',
      !multiplePatient
        ? `patientAttributes: {
          patientId: ${patientParams.patientId},
          options: {${stringifiedOptions}}
          ${
            booking['multiple_customers']
              ? `whoIsPaying: "${patientParams.whoIsPaying}"`
              : ''
          }
          ${
            booking['multiple_customers'] &&
            patientParams.companyName &&
            patientParams.whoIsPaying === 'company'
              ? `companyName: "${patientParams.companyName.toString()}", consentForEmployerToSeeEmployeeName: ${true}`
              : ''
          }
        }`
        : '',
      multiplePatient ? `patientIds: [${patientParams}]` : '',
      `mode: DROP_IN_NOW`,
      'ignoreRules: true',
    ].join(',')

    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'createAppointment',
      params: params,
    })

    const { data } = (await gqlCall(query)).data
    return data['createAppointment']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function confirmAppointment(appointmentId) {
  try {
    await gqlCall(
      `mutation {
        confirmAppointment(id: ${appointmentId}) {
          id
          confirmed
        }
      }
      `,
    )
  } catch (e) {
    return Promise.reject(e)
  }
}

function stringifyPatientOptions(patient) {
  const attributes = []
  if (patient.options) {
    Object.entries(patient.options).forEach(([slug, value]) =>
      attributes.push(`${slug} : ${value}`),
    )
  }
  return attributes.join(',')
}

export async function cancelAppointment(
  appointmentId,
  userId,
  sendCommunications = true,
) {
  try {
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'cancelAppointment',
      params: [
        `id: ${appointmentId}`,
        `userId: ${userId},
        sendCommunications: ${sendCommunications}`,
      ].join(','),
    })
    const { data } = (await gqlCall(query)).data
    return data['cancelAppointment']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function addAppointmentsPatient(
  appointmentId,
  patientData,
) {
  try {
    const { id, ...patientFiltered } = patientData
    const params = defaultGraphQLSerializer(
      patientFiltered,
      'appointmentsPatients',
    )

    const stringifiedOptions = stringifyPatientOptions(
      patientFiltered,
    )

    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'createAppointmentsPatient',
      params: [
        ...params,
        `appointmentId: ${appointmentId}`,
        `options: {${stringifiedOptions}}`,
      ].join(','),
    })
    const { data } = (await gqlCall(query)).data
    return data['createAppointmentsPatient']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateAppointmentsPatient(patient) {
  try {
    const params = defaultGraphQLSerializer(
      patient,
      'appointmentsPatients',
    )
    const readyAt = patient.readyAt ? '' : `readyAt: ${null}`
    const appearedAt = patient.appearedAt ? '' : `appearedAt: ${null}`
    const sysvacRegisteredAt = patient.sysvacRegisteredAt
      ? ''
      : `sysvacRegisteredAt: ${null}`
    const productAdministeredAt = patient.productAdministeredAt
      ? ''
      : `productAdministeredAt: ${null}`
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'updateAppointmentsPatient',
      params: [
        ...params,
        readyAt,
        appearedAt,
        sysvacRegisteredAt,
        productAdministeredAt,
      ].join(','),
    })
    const { data } = (await gqlCall(query)).data
    return data['updateAppointmentsPatient']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function deleteAppointmentsPatient(patientId) {
  try {
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'deleteAppointmentsPatient',
      params: [`id: ${patientId}`],
    })
    const { data } = (await gqlCall(query)).data
    return data['deleteAppointmentsPatient']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function createUser(user) {
  try {
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'createUser',
      params: defaultGraphQLSerializer({ ...user }, 'createUser'),
    })
    const { data } = (await gqlCall(query)).data
    return data['createUser']
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchPatient(patientId) {
  try {
    const patient_query = buildGraphQLQuery({
      searchOptions: { id: patientId },
      resourceName: 'patient',
      multiple: false,
    })

    const patient_response = await gqlCall(patient_query)

    return parseQueryResponse({
      response: patient_response,
      resourceName: 'patient',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updatePayment(payment) {
  try {
    const params = defaultGraphQLSerializer({ ...payment }, 'payment')
    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'updatePaymentState',
      params: params,
    })

    const response = await gqlCall(query)
    return parseQueryResponse({
      response: response,
      resourceName: 'payment',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchOrder(appointmentId) {
  try {
    const query = buildGraphQLQuery({
      searchOptions: { id: appointmentId },
      resourceName: 'appointment',
      multiple: false,
    })

    const response = await gqlCall(query)
    return parseQueryResponse({
      response: response,
      resourceName: 'appointment',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateOrder(order) {
  try {
    if (
      order.lineItems.length === 0 ||
      order.lineItems.some((i) => !i.id)
    ) {
      return
    }
    const lineItemsAttributes = order.lineItems.map((li) => {
      return {
        id: li.id == 'new' ? null : li.id,
        productId: li.product.id,
        productSlug: li.product.slug,
        quantity: li.quantity,
      }
    })
    const sanitizedLineItemsAttributes = lineItemsAttributes
      .map((li) => {
        return `{${defaultGraphQLSerializer(
          li,
          'updateLineItemInOrder',
        )}}`
      })
      .join(',')

    const query = buildGraphQLQuery({
      type: 'mutation',
      resourceName: 'updateOrder',
      params: [
        `id:  ${order.id}`,
        `lineItems: [${sanitizedLineItemsAttributes}]`,
      ].join(','),
    })

    const response = await gqlCall(query)
    return parseQueryResponse({
      response: response,
      resourceName: 'order',
      multiple: false,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function fetchAppointmentPatient(id) {
  try {
    const { data } = (
      await gqlCall(
        `query {
          appointmentPatient(id: ${id}) {
              id
              appearedAt
              appointment {
                  createdAt
                  date
                  deletedAt
                  hour
                  id
                  unitPrice
                  currentlyEditedById
                  timeslot
                  products {
                    consentFields {
                      description,
                      slug
                    }
                  }
                  serviceCenter {
                      city
                      googlePlaceId
                      id
                      lat
                      lng
                      name
                      operationType
                      street
                      title
                      zip
                  }
                  user{
                      email
                      id
                      locale
                      name
                      nationalId
                      patientId
                      phoneNumber
                  }
                  appointmentsPatients {
                      email
                      id
                      name
                      nationalId
                      phoneNumber
                      appearedAt
                      readyAt
                      productAdministeredAt
                      sysvacRegisteredAt
                      options
                      companyName
                      consentForEmployerToSeeEmployeeName
                      whoIsPaying
                      patientId
                  }
                  order {
                    id
                    lineItems {
                      id
                      orderId
                      quantity
                      product {
                        id
                        name
                        publicName
                        price
                        description
                        slug
                        vatRate
                        requireShipping
                        requireHousecall
                        requireDigital
                        createdAt
                        updatedAt
                        type
                        consentFields {
                          description,
                          slug
                        }
                      }
                    }
                    payment {
                      id
                      state
                    }
                  }
              }
              currentlyEditedById
              email
              hour
              name
              options
              nationalId
              patientId
              phoneNumber
              readyAt
              sysvacRegisteredAt
              productAdministeredAt
              timeslot
              whoIsPaying
              companyName
              __typename
          }
      }`,
      )
    ).data
    return data.appointmentPatient
  } catch (error) {
    return Promise.reject(error)
  }
}
