import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextInput from './forms/fields/TextInput'
import { get } from '../lib/backend-client'
import gqlCall from '../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
} from '../api/graphqlExtended'

const TypeaheadSearch = ({
  fieldName,
  label,
  listAttributes,
  createLink,
  isGraphqlType,
  resourceName,
  listQueryAttributes,
  displayedAttributes,
  index,
  value,
  onChange,
  onSelect,
  onDelete,
  disabled,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [results, setResults] = useState([])
  const [currentValue, setCurrentValue] = useState({})
  const [error, setError] = useState(null)

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const search = async (q) => {
    const response = isGraphqlType
      ? await graphqlSearch(q)
      : await endPointSearch(q)
    setResults(response)
    setMenuOpen(true)
  }

  const endPointSearch = async (q) =>
    await get(`${window.location.origin + '/graphql'}?q=${q}`)

  const graphqlSearch = async (q) => {
    try {
      const gql = buildGraphQLQuery({
        resourceName: resourceName,
        searchOptions: { searchString: q },
        params: listQueryAttributes ? listQueryAttributes : null,
      })
      const response = await gqlCall(gql)
      return parseQueryResponse({
        response: response,
        resourceName: resourceName,
      })
    } catch (e) {
      setError(e.toString())
    }
  }

  const handleQueryChange = (value) => {
    if (value.length > 2) {
      setTimeout(search, 1000, value)
    } else if (value.length === 0 && onDelete) {
      onDelete()
    } else {
      setResults([])
    }
    setCurrentValue({ ...currentValue, id: '', name: value })
  }

  const handleFocus = () => {
    setMenuOpen(true)
  }

  const handleBlur = () => {
    setTimeout(() => setMenuOpen(false), 400)
  }

  const handleRowClick = (e) => {
    e.preventDefault()
    const result = results.find(
      (res) => res.id.toString() === e.currentTarget.dataset.id,
    )
    onSelect ? onSelect(result.id, true, fieldName, result) : ''
    onChange ? onChange(index, result) : ''
    setCurrentValue({
      id: result.id,
      name: displayedAttributes
        ? result[displayedAttributes]
        : result.name,
    })
  }

  const displayListAttributes = (result) => {
    const list = listAttributes.map((attr, i) => {
      if (typeof attr.name === 'string') {
        return (
          <span key={`attr-${i}`} className={attr.customClass}>
            {result[attr.name]}
          </span>
        )
      } else if (typeof attr.name === 'function') {
        return (
          <div key={`attr-${i}`} className={attr.customClass}>
            {attr.name(result)}
          </div>
        )
      }
    })
    return list
  }

  const renderSearchResults = () => {
    return (
      <div className="typeahead-search-results">
        <ul>
          {results.map((result, i) => (
            <a
              href="#"
              key={`result-${i}`}
              onClick={handleRowClick}
              data-id={result.id}
              className="no-link-decoration"
            >
              <li className="row">{displayListAttributes(result)}</li>
            </a>
          ))}
          {createLink && (
            <li className="create">
              <a
                href={createLink}
                className="small"
                target="_blank"
                rel="noreferrer"
              >
                ✚ Opprett...
              </a>
            </li>
          )}
          {!createLink && !results.length && (
            <li className="create">Skriv minst 3 tegn for å søke</li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <div>
      <div className={'formgroup'}>
        <div className="formrow d-flex">
          <div className={'col-12'}>
            <div
              className={`large-input`}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <input
                type="hidden"
                name={fieldName}
                value={currentValue.id || ''}
              />
              <TextInput
                name="q"
                label={label || 'Search'}
                value={currentValue?.name}
                onChange={handleQueryChange}
                placeholder="Søk..."
                readOnly={disabled}
                disabled={disabled}
              ></TextInput>
              {menuOpen && !error && renderSearchResults()}
            </div>
          </div>
        </div>
        {error && <p className="small">{error.toString()}</p>}
      </div>
    </div>
  )
}

TypeaheadSearch.defaultProps = {
  isGraphqlType: false,
  resourceName: '',
  listQueryAttributes: null,
}

TypeaheadSearch.propTypes = {
  path: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  listAttributes: PropTypes.array.isRequired,
  createLink: PropTypes.string,
  label: PropTypes.string,
  isGraphqlType: PropTypes.bool.isRequired,
  resourceName: PropTypes.string,
  listQueryAttributes: PropTypes.string,
  displayedAttributes: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
}

export default TypeaheadSearch
