import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Button from '../forms/fields/Button'
import { appointmentAvailabilityCalendar } from './queries'

const today = new Date()

const extractDate = (slot) => slot.substr(0, 10)
const extractHour = (slot) => slot.substr(11)

const WEEKEND_PRODUCTS = ['loc-ret-c19-pr', 'loc-ret-epcrc19-pr']

const hasAvailability = (availability, dateKey) => {
  const dateString = extractDate(dateKey)
  return (
    availability[dateString] &&
    Object.keys(availability[dateString]).length !== 0
  )
}

const Calendar = ({
  serviceCenter,
  booking,
  renderAppointmentSummary,
  patientCount,
  onComplete,
  onError,
  setLoading,
  slotTaken,
  reset,
  t,
  dropinMode,
  productSlugs,
}) => {
  const [dateShowing, setDateShowing] = useState(today)
  const [slots, setSlots] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [showCapacity, setShowCapacity] = useState(false)
  const [firstAvailableDay, setFirstAvailableDay] = useState(today)

  useEffect(() => {
    fetchSlots()
  }, [dateShowing])

  const capacityTrue = (e) => {
    if (e.keyCode === 67 /* C */) setShowCapacity(true)
  }

  const capacityFalse = (e) => {
    setShowCapacity(false)
  }

  const showingToday = () =>
    dateShowing.toDateString() === today.toDateString()

  useEffect(() => {
    document.body.addEventListener('keydown', capacityTrue)
    document.body.addEventListener('keyup', capacityFalse)
    return () => {
      document.body.removeEventListener('keydown', capacityTrue)
      document.body.removeEventListener('keyup', capacityFalse)
    }
  })

  const fetchSlots = async () => {
    setLoading(true)
    const response = await appointmentAvailabilityCalendar(
      serviceCenter?.id,
      booking?.id,
      dateShowing,
      patientCount,
      productSlugs,
    )

    if (response.errors) return onError(response.errors)

    const availability = response.data[0].availability
    const noAvailability = !!booking
      ? false
      : !hasAvailability(availability, moment(dateShowing).format())
    if (noAvailability && showingToday()) {
      setFirstAvailableDay(moment(dateShowing).add(1, 'day').toDate())
      nextDay()
    } else if (noAvailability) {
      setLoading(false)
      setSlots(null)
    } else {
      setSlots(response.data[0].availability)
      setLoading(false)
    }
  }

  const submit = () => {
    const date = extractDate(selectedSlot)
    const hour = extractHour(selectedSlot)
    onComplete({ date, hour })
  }

  const onSlotSelect = (e) => {
    setSelectedSlot(e.currentTarget.id)
  }

  const clearSelectedSlot = (e) => {
    e.preventDefault()
    setSelectedSlot(null)
  }

  const previousDay = () => {
    const isMonday = moment(dateShowing).day() === 1
    if (!isMonday) {
      setDateShowing(moment(dateShowing).subtract(1, 'day').toDate())
    } else {
      setDateShowing(
        moment(dateShowing)
          .day(5 - 7)
          .toDate(),
      )
    }
  }

  const nextDay = () => {
    setDateShowing(moment(dateShowing).add(1, 'day').toDate())
  }

  const renderDateString = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    } ${moment(date).calendar(null, {
      sameDay: `[(${t('today')})]`,
      nextDay: `[(${t('tomorrow')})]`,
      nextWeek: `[]`,
      lastDay: `[]`,
      lastWeek: `[]`,
      sameElse: `[]`,
    })}`
  }

  const renderSlotList = () => {
    if (!slots) return <p>{t('noAvailableSlots', s)}</p>
    const datesWithSections = Object.keys(slots)
      .map((key) => {
        return { name: key, sections: slots[key] }
      })
      .map((date) => {
        return {
          ...date,
          sections: Object.keys(date.sections)
            .map((key) => {
              return { ...date.sections[key], hour: key }
            })
            .sort((a, b) => {
              const isBeforeOrEqual = moment(
                a.hour,
                'HH:mm',
              ).isSameOrBefore(moment(b.hour, 'HH:mm'))

              return isBeforeOrEqual ? -1 : 1
            })
            .reduce((sections, slot) => {
              const slotSectionName = slot.hour.substr(0, 3) + '00'
              const existingSection = sections.find(
                (section) => section.name === slotSectionName,
              )
              if (existingSection) {
                return sections.map((section) =>
                  section.name === slotSectionName
                    ? { ...section, slots: [...section.slots, slot] }
                    : section,
                )
              } else {
                return [
                  ...sections,
                  { name: slotSectionName, slots: [slot] },
                ]
              }
            }, []),
        }
      })
      .map(({ name, sections }) => {
        return {
          name,
          sections: sections,
          // sections: sections.filter((section) =>
          //   slotInOpeningHours(section.name),
          // ),
        }
      })

    return (
      <>
        {datesWithSections.map((date) => (
          <div key={date} className="slot-list">
            <h2 className="h4 mb-4">{renderDateString(date.name)}</h2>
            <div className="hours">
              {date.sections.length === 0 ? (
                <p>{t('noAvailableSlots', s)}</p>
              ) : (
                date.sections.map((section) => (
                  <div key={section.name}>
                    <h3 key={section.name} className="my-3 h5">
                      {section.name}
                    </h3>
                    {section.slots.map((slot) => (
                      <Button
                        key={slot.hour}
                        id={`${date.name}T${slot.hour}`}
                        onClick={onSlotSelect}
                        disabled={
                          selectedSlot === `${date.name}T${slot.hour}`
                        }
                        className="btn btn-secondary m-1 ishadow time-slot-btn"
                      >
                        {slot.hour}
                        <p
                          className={`capacity ${
                            showCapacity ? 'show' : ''
                          }`}
                        >
                          {slot.available_capacity}
                        </p>
                      </Button>
                    ))}
                  </div>
                ))
              )}
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <section>
      {renderAppointmentSummary && renderAppointmentSummary()}
      <h1 className="mb-4 font-bold">
        {booking && productSlugs.some((s) => s.includes('vac'))
          ? t('vaccineHeading', s)
          : t('heading', s)}
      </h1>
      {slotTaken && (
        <aside role="alert" className="slot-taken">
          <p className="m-0">{t('slotTakenAlert', s)}</p>
        </aside>
      )}
      {!dropinMode && (
        <nav className="mt-4 mb-3">
          <ul className="li-reset row">
            <li className="col-6">
              {today < dateShowing &&
              firstAvailableDay < dateShowing ? (
                <Button
                  label={`◀ ${t('previous')} ${t('day', s)}`}
                  className="btn-link"
                  onClick={previousDay}
                />
              ) : (
                <p style={{ color: '#ccc' }}>{`◀ ${t('previous')} ${t(
                  'day',
                  s,
                )}`}</p>
              )}
            </li>
            <li className="col-6 text-right">
              <Button
                label={`${t('next')} ${t('day', s)} ▶`}
                className="btn-link"
                onClick={nextDay}
              />
            </li>
          </ul>
        </nav>
      )}
      {renderSlotList()}
      <div className="row my-3">
        <div className="col-6">
          <Button
            label={t('restart')}
            className="btn-link mt-4"
            onClick={reset}
          />
        </div>
      </div>
      <StickyConfirmation
        onConfirm={submit}
        show={selectedSlot !== null}
        onCancel={clearSelectedSlot}
      >
        <div className="text-center">
          {t('confirm', s)}
          <br />
          {selectedSlot && (
            <span>
              {`${renderDateString(selectedSlot)} ${t(
                'atTime',
              )} ${extractHour(selectedSlot)}`}
            </span>
          )}
        </div>
      </StickyConfirmation>
    </section>
  )
}

import { serviceCenterShape } from './propShapes'
import StickyConfirmation from '../StickyConfirmation'

Calendar.propTypes = {
  serviceCenter: serviceCenterShape,
  booking: PropTypes.object,
  renderAppointmentSummary: PropTypes.func.isRequired,
  patientCount: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  slotTaken: PropTypes.bool,
  dropinMode: PropTypes.bool,
  productSlugs: PropTypes.arrayOf(PropTypes.string),
}

const s = {
  nb: {
    heading: 'Velg tidspunkt',
    vaccineHeading: 'Velg tidspunkt for vaksinering',
    day: 'dag',
    instructions: 'Mandag 6. februar (første)',
    confirm: 'Klikk her for å bekrefte',
    noAvailableSlots: 'Ingen tilgjengelige timer denne dagen',
    slotTakenAlert:
      'Tidspunktet du valgte er ikke lenger tilgjengelig. Du må velge et annet tidspunkt.',
  },
  en: {
    heading: 'Select a time',
    vaccineHeading: 'Choose a timeslot for vaccination',
    day: 'day',
    instructions: 'Monday February 6 (earliest available)',
    confirm: 'Click here to confirm',
    noAvailableSlots: 'No available times on this date',
    slotTakenAlert:
      'Your selected time is no longer available. You will have to select a new time slot.',
  },
  pl: {
    heading: 'Wybierz czas',
    vaccineHeading: 'Wybierz godzinę szczepienia',
    day: 'dzień',
    instructions: 'Poniedziałek, 6 Lutego (najwcześniejszy możliwy)',
    confirm: 'Kliknij, aby potwierdzić',
    noAvailableSlots: 'Brak wolnych terminów tego dnia',
    slotTakenAlert:
      'Wybrany przez Ciebie termin przestał być dostępny. Wybierz nowy interesujący Cię termin.',
  },
}

export default Calendar

function isWeekend(date) {
  return moment(date).day() === 0 || moment(date).day() === 6
}

function isSunday(date) {
  return moment(date).day() === 0
}

// switched off
function slotInOpeningHours(slot) {
  const h = moment(slot, 'HH:mm')
  return h.hour() >= 10 && h.hour() < 16
}

// unused
function skipWeekends(today) {
  if (!isWeekend(today)) {
    return today
  } else {
    if (isSunday(today)) {
      return moment(today).day(1).toDate()
    } else {
      return moment(today)
        .day(1 + 7)
        .toDate()
    }
  }
}
