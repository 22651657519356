import React from 'react'
import PropTypes from 'prop-types'

const SubmitButton = (props) => {
  return (
    <button
      type="submit"
      className={'btn submit ' + props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      id={props.id}
    >
      {props.children || props.label}
    </button>
  )
}
// btn btn-secondary btn-block payment-form__button',
SubmitButton.defaultProps = {
  label: 'Submit',
  onClick: () => {},
  disabled: false,
}

SubmitButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  id: PropTypes.string,
}

export default SubmitButton
