import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Hour from './Hour'

const Row = (props) => {
  const hours = props.rowData.map((hour) => {
    let i = 0

    const safeProps = _.omit(props, 'rowData')

    const childProps = {
      ...safeProps,
      key: hour.time,
      status: hour.status,
      time: hour.time,
      hourCount: hour.hour_count,
      hourLimit: hour.hour_limit,
      dayCount: hour.day_count,
      dayLimit: hour.day_limit,
      coverageMap: hour.coverage_map,
      day: props.day,
    }

    return <Hour key={++i} {...childProps} />
  })

  return <div className="hour-row">{hours}</div>
}

Row.propTypes = {
  rowData: PropTypes.array.isRequired,
  avatar: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
}

export default Row
