import React, { Component } from 'react'

class NewsLetterSignup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      hasSubmitted: false,
      statusClass: '',
    }
  }

  onSubmit = () => {
    $.ajax({
      type: 'post',
      url: `/newsletter_signup/${this.state.email}`,
      success: (response) => {
        this.setState({ hasSubmitted: true, statusClass: '' })
      },
      error: (err) => {
        this.setState({ statusClass: 'error' })
        dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'newsletter_form',
          eventAction: 'error',
          eventLabel: err.message || err.toString(),
          eventValue: 1,
          nonInteraction: false,
        })
      },
    })
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  render() {
    return (
      <form name="newsletter">
        <div className="input-with-button">
          <input
            className={`form-control inverted input-with-button__input ${this.state.statusClass}`}
            placeholder="Din epost"
            type="email"
            onChange={this.handleEmailChange}
          />
          <button
            className="input-with-button__button"
            onClick={this.onSubmit}
          >
            Send
          </button>
        </div>

        {this.state.hasSubmitted ? 'Påmelding vellykket!' : ''}
      </form>
    )
  }
}

export default NewsLetterSignup
