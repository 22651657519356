import React from 'react'
import PropTypes from 'prop-types'

const Tabs = (props) => {
  const buttons = props.buttonsInfos.map((buttonInfo) => (
    <button
      type="button"
      role="tab"
      key={buttonInfo.id}
      id={buttonInfo.id}
      aria-controls={`nav-${buttonInfo.id}`}
      aria-selected={buttonInfo.isSelected}
      className={
        `nav-link ${buttonInfo.isSelected ? 'active ' : ''}` +
        props.className
      }
      onClick={props.onClick}
    >
      {buttonInfo.label}
    </button>
  ))

  return (
    <nav>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {buttons}
      </div>
    </nav>
  )
}

Tabs.defaultProps = {
  className: '',
}

Tabs.propTypes = {
  buttonsInfos: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Tabs
