import React from 'react'

const GreenCheckMark = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27Z"
        fill="#0FD5A5"
      />
      <path
        d="M8.5 14.1875L12.375 18.0625L20.5 9.9375"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default GreenCheckMark
