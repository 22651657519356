import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import EmailInput from '../forms/fields/EmailInput'
import AddressInput from '../forms/fields/AddressInput'
import ZipInput from '../forms/fields/ZipInput'
import CityInput from '../forms/fields/CityInput'
import CompanyNameInput from '../forms/fields/CompanyNameInput'
import OrganizationNumberInput from '../forms/fields/OrganizationNumberInput'
import InvoiceReference from '../forms/fields/InvoiceReference'
import RadioButtons from '../forms/fields/RadioButtons'
import NameInput from '../forms/fields/NameInput'
import PhoneInput from '../forms/fields/PhoneInput'

const VaccOrganizationInputs = ({
  highlightErrors = false,
  hasVaccineText = false,
  onChange,
  contactPerson,
  organization,
}) => {
  const [organizationInfo, setOrganizationInfo] = useState(
    organization,
  )
  const [contactPersonInfo, setContactPersonInfo] = useState(
    contactPerson,
  )

  const [validations, setValidations] = useState({
    organization: {
      'referral[organization]': !!organizationInfo[
        'referral[organization]'
      ],
      'referral[related_organization][org_nr]': !!organizationInfo[
        'referral[related_organization][org_nr]'
      ],
      'referral[organization_address][address]': !!organizationInfo[
        'referral[organization_address][address]'
      ],
      'referral[organization_address][zip]': !!organizationInfo[
        'referral[organization_address][zip]'
      ],
      'referral[organization_address][city]': !!organizationInfo[
        'referral[organization_address][city]'
      ],
      'referral[billing_method]': !!organizationInfo[
        'referral[billing_method]'
      ],
      'referral[billing_email]':
        (organizationInfo['referral[billing_method]'] === 'email' &&
          organizationInfo['referral[billing_email]'] === true) ||
        true,
      'referral[billing_reference]': !!organizationInfo[
        'referral[billing_reference]'
      ],
    },
    contactPerson: {
      'referral[contact_person_order][phone_number]': !!contactPersonInfo[
        'referral[contact_person_order][phone_number]'
      ],
      'referral[contact_person_order][name]': !!contactPersonInfo[
        'referral[contact_person_order][name]'
      ],
      'referral[contact_person_order][email]': !!contactPersonInfo[
        'referral[contact_person_order][email]'
      ],
    },
  })

  useEffect(() => {
    setContactPersonInfo({
      ...contactPerson,
    })
    setOrganizationInfo({
      ...organization,
    })
  }, [contactPerson, organization])

  const formIsValid = () => {
    return [
      Object.entries(validations.organization).every(
        ([_key, value]) => value == true,
      ),
      Object.entries(validations.contactPerson).every(
        ([_key, value]) => value == true,
      ),
    ].every(Boolean)
  }

  const handleOrganizationChange = (name, value) => {
    setOrganizationInfo((prevOrgInfo) => ({
      ...prevOrgInfo,
      [name]: value,
    }))

    if (name === 'referral[billing_method]') {
      setValidations((prevValidations) => ({
        ...prevValidations,
        organization: {
          ...prevValidations.organization,
          'referral[billing_email]':
            value === 'email'
              ? !!organizationInfo['referral[billing_email]']
              : true,
        },
      }))
    } else {
      setValidations((prevValidations) => ({
        ...prevValidations,
        organization: {
          ...prevValidations.organization,
          [name]: !!value,
        },
      }))
    }
  }

  const handleContactPersonChange = (name, value) => {
    setContactPersonInfo((prevContactPersonInfo) => ({
      ...prevContactPersonInfo,
      [name]: value,
    }))
    setValidations((prevValidations) => ({
      ...prevValidations,
      contactPerson: {
        ...prevValidations.contactPerson,
        [name]: !!value,
      },
    }))
  }

  useEffect(() => {
    onChange(organizationInfo, 'organization', formIsValid())
  }, [organizationInfo])

  useEffect(() => {
    onChange(contactPersonInfo, 'contactPerson', formIsValid())
  }, [contactPersonInfo])

  return (
    <>
      <OrganizationNumberInput
        name={'referral[related_organization][org_nr]'}
        onChange={(value) =>
          handleOrganizationChange(
            'referral[related_organization][org_nr]',
            value,
          )
        }
        highlightErrors={highlightErrors}
        isRequired
        value={
          organizationInfo[`referral[related_organization][org_nr]`]
        }
        className="b-bottom"
      />
      <CompanyNameInput
        name={'referral[organization]'}
        onChange={(value) =>
          handleOrganizationChange('referral[organization]', value)
        }
        highlightErrors={highlightErrors}
        isRequired
        value={organizationInfo[`referral[organization]`]}
        className="b-bottom"
      />
      <div className="formrow align-items-end">
        <div className="col">
          <AddressInput
            name={'referral[organization_address][address]'}
            onChange={(value) =>
              handleOrganizationChange(
                'referral[organization_address][address]',
                value,
              )
            }
            label="Postadresse"
            highlightErrors={highlightErrors}
            isRequired
            value={
              organizationInfo[
                `referral[organization_address][address]`
              ]
            }
            className="mr-sm-2"
          />
        </div>
        <div className="col-sm-4">
          <ZipInput
            name={'referral[organization_address][zip]'}
            onChange={(value) =>
              handleOrganizationChange(
                'referral[organization_address][zip]',
                value,
              )
            }
            highlightErrors={highlightErrors}
            isRequired
            value={
              organizationInfo[`referral[organization_address][zip]`]
            }
            className="ml-sm-2"
          />
        </div>
      </div>
      <CityInput
        name={'referral[organization_address][city]'}
        onChange={(value) =>
          handleOrganizationChange(
            'referral[organization_address][city]',
            value,
          )
        }
        highlightErrors={highlightErrors}
        isRequired
        value={
          organizationInfo[`referral[organization_address][city]`]
        }
        className="b-bottom"
      />

      <h2 className="groupheading">
        Kontaktperson {hasVaccineText && 'for ordren'}
      </h2>
      {hasVaccineText && (
        <p>
          Denne personen må vi kunne kontakte om ordre/faktura. Mottar
          ordrebekreftelse og informasjon om gjennomføring av
          oppdraget.
        </p>
      )}
      <PhoneInput
        name={'referral[contact_person_order][phone_number]'}
        onChange={(value) =>
          handleContactPersonChange(
            'referral[contact_person_order][phone_number]',
            value,
          )
        }
        label="Telefonnummer"
        highlightErrors={highlightErrors}
        isRequired
        value={
          contactPersonInfo[
            `referral[contact_person_order][phone_number]`
          ]
        }
        className="b-bottom"
      />
      <NameInput
        label="Fullt navn på kontaktperson"
        name={'referral[contact_person_order][name]'}
        onChange={(value) =>
          handleContactPersonChange(
            'referral[contact_person_order][name]',
            value,
          )
        }
        highlightErrors={highlightErrors}
        isRequired
        value={
          contactPersonInfo[`referral[contact_person_order][name]`]
        }
        className="b-bottom"
      />
      <EmailInput
        name={'referral[contact_person_order][email]'}
        onChange={(value) =>
          handleContactPersonChange(
            'referral[contact_person_order][email]',
            value,
          )
        }
        label="E-post kontaktperson"
        highlightErrors={highlightErrors}
        isRequired
        value={
          contactPersonInfo[`referral[contact_person_order][email]`]
        }
      />
      <h2 className="groupheading">Fakturadetaljer</h2>
      <RadioButtons
        name={'referral[billing_method]'}
        value={organizationInfo['referral[billing_method]']}
        onChange={(value) =>
          handleOrganizationChange('referral[billing_method]', value)
        }
        defaultChecked={organizationInfo['referral[billing_method]']}
        options={[
          { value: 'email', label1: 'E-post' },
          { value: 'ehf', label1: 'EHF' },
        ]}
      />
      <fieldset className="mt-2">
        {organizationInfo['referral[billing_method]'] === 'email' && (
          <EmailInput
            name={'referral[billing_email]'}
            onChange={(value) =>
              handleOrganizationChange(
                'referral[billing_email]',
                value,
              )
            }
            label="E-post fakturakontakt"
            highlightErrors={highlightErrors}
            autoComplete="off"
            isRequired
            value={organizationInfo['referral[billing_email]']}
            className="b-bottom"
          />
        )}
        <InvoiceReference
          onChange={(value) =>
            handleOrganizationChange(
              'referral[billing_reference]',
              value,
            )
          }
          name={'referral[billing_reference]'}
          highlightErrors={highlightErrors}
          isRequired={true}
          value={organizationInfo['referral[billing_reference]']}
        />
      </fieldset>
    </>
  )
}

VaccOrganizationInputs.propTypes = {
  highlightErrors: PropTypes.bool,
  organization: PropTypes.object,
  contactPerson: PropTypes.object,
  onChange: PropTypes.func,
  askVisitingAddress: PropTypes.bool,
  organizationAutocomplete: PropTypes.bool,
  hasVaccineText: PropTypes.bool,
}

export default VaccOrganizationInputs
