import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { propTypes } from './FieldWrapper'
import _ from 'lodash'

const CheckboxHL = (props) => {
  const [valid, setValid] = useState(props.validate(props.value))
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(() => props.value)
    setValid(() => props.validate(props.value))
  }, [props])

  const handleChange = (e) => {
    const newValue = !value
    const newValid = props.validate(newValue)
    setValue(newValue)
    setValid(newValid)
    if (props.onChange) props.onChange(newValue, newValid, props.name)
  }

  const inputProps = _.pick(props, ['name', 'disabled'])

  // inputProps.defaultChecked = value

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    empty: !value || value.length === 0,
    valid,
  }

  return (
    <FieldWrapper {...wrapperProps}>
      <label
        htmlFor={props.name}
        id={`${props.name}_label`}
        className="checkbox-label"
      >
        <input
          type="checkbox"
          id={props.name}
          className="checkbox"
          checked={value}
          onChange={handleChange}
          {...inputProps}
        />
        <span className="checkmark mr-1"></span>
        {props.children}
      </label>
    </FieldWrapper>
  )
}

CheckboxHL.defaultProps = {
  value: false,
  highlightErrors: false,
  disabled: false,
  validate: (value) => true,
  isRequired: false,
  showValidation: false,
}

CheckboxHL.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  onRef: PropTypes.func,
  isRequired: PropTypes.bool,
  showValidation: PropTypes.bool,
}

export default CheckboxHL
