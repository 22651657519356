import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

class DayMenu extends Component {
  static propTypes = {
    showMenu: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    cloneDay: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    dayData: PropTypes.shape({
      hours: PropTypes.array.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    $('.page').on('click', this.handleClick)
  }

  componentWillUnmount() {
    $('.page').off('click', this.handleClick)
  }

  handleClick = (e) => {
    if (!this.props.showMenu) {
      return
    }

    if (
      this.node == $(e.target).parent()[0] ||
      $(this.node).parent()[0] == e.target
    ) {
      return
    }

    this.props.close()
  }

  stopPropagation = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  closeDay = () => {
    const date = this.props.date

    this.props.onChange({
      date: date,
      hours: _.filter(this.props.dayData.hours, {
        status: 'selected',
      }),
    })

    this.props.close()
  }

  openDay = () => {
    const date = this.props.date

    this.props.onChange({
      date: date,
      hours: _.filter(this.props.dayData.hours, { status: 'open' }),
    })

    this.props.close()
  }

  cloneToNextDay = () => {
    this.props.cloneDay(this.props.dayData, 1)

    this.props.close()
  }

  cloneToNextSevenDays = () => {
    this.props.cloneDay(this.props.dayData, 7)

    this.props.close()
  }

  render() {
    if (this.props.showMenu) {
      return (
        <div
          className="day-menu"
          ref={(el) => (this.node = el)}
          onClick={this.stopPropagation}
        >
          <div className="day-menu-item" onClick={this.closeDay}>
            Lukk alle timer {moment(this.props.date).format('D. MMM')}
          </div>
          <div className="day-menu-item" onClick={this.openDay}>
            Åpne alle timer {moment(this.props.date).format('D. MMM')}
          </div>
          <div
            className="day-menu-item"
            onClick={this.cloneToNextDay}
          >
            Kopier oppsett til neste dag
          </div>
          <div
            className="day-menu-item"
            onClick={this.cloneToNextSevenDays}
          >
            Kopier oppsett til neste 7 dager
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }
}

export default DayMenu
