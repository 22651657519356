import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import HLForm from './forms/HLForm'
import PatientData from './PatientData'
import Button from './forms/fields/Button'

const allTrue = (opts) => {
  return (
    Object.keys(opts).length > 0 &&
    !Object.keys(opts).some((key) => opts[key] === false)
  )
}

const serialize = (form) => {
  const values = {}
  Array.prototype.slice.call(form.elements).forEach((el) => {
    if (
      el.name !== '_method' &&
      el.name !== 'authenticity_token' &&
      !/button|submit/.test(el.type)
    )
      values[el.name] = el.value
  })
  return values
}

const PatientVaccineCard = ({
  item,
  onClose,
  onUpdate,
  onUpdatePersonalData,
  serviceProviderId,
  onTakeover,
  onDelete,
  assetRootUrl,
}) => {
  ReactModal.setAppElement('body')

  const ok = allTrue(item.vaccination_attributes.options)

  const handlePatientDataSubmit = () => {
    const form = document.querySelector('form[name=PersonalData]')
    const updatedPayload = serialize(form)
    onUpdatePersonalData(item.patient_id, updatedPayload, item.id)
  }

  const modalStyle = {
    content: {
      width: '70%',
      maxWidth: '650px',
      WebkitOverflowScrolling: 'touch',
      border: `4px solid ${ok ? '#0f0' : '#f00'}`,
      transition: 'border-color 0.2s ease',
      background: '#fafafa',
      position: 'relative',
    },
  }

  const openBySomeoneElse = () =>
    item.vaccination_attributes.currently_edited_by_id &&
    item.vaccination_attributes.currently_edited_by_id !==
      serviceProviderId

  const deleteVaccination = (e) => {
    e.preventDefault()
    if (confirm('Er du sikker?')) {
      onDelete(item.id)
    }
  }

  const onClick = () => {
    if (openBySomeoneElse()) return
    const form = document.querySelector('form[name=checklist]')
    const updatedPayload = serialize(form)
    onUpdate(item.id, updatedPayload)
  }

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Patient Card"
      style={modalStyle}
      id={`patient-card-${item?.id}`}
    >
      <div
        className="ReactModal__exit-button-new"
        onClick={onClose}
      />

      {openBySomeoneElse() && (
        <div
          className="flash flash--attention"
          style={{ margin: '-25px -25px 1rem -25px' }}
        >
          <p className="small">
            Denne pasienten er åpen hos{' '}
            {item.vaccination_attributes.currently_edited_by_name} og
            kan derfor ikke endres av deg. Du kan overstyre dette, men
            da vil pasienten låses hos den andre isteden.
          </p>
          <Button
            className="btn-danger"
            label="Ta kontroll"
            onClick={onTakeover}
          ></Button>
        </div>
      )}
      <article
        className="vaccination-patient-card-content"
        style={{
          pointerEvents: openBySomeoneElse() ? 'none' : 'inherit',
        }}
      >
        <h3 className="ReactModal__heading mb-3">Pasientkort</h3>
        <PatientData
          name={item.name}
          national_id={item.national_id}
          phone_number={item.phone_number}
          company={item.vaccination_attributes.company}
          assetRootUrl={assetRootUrl}
          onSubmit={handlePatientDataSubmit}
        />
        <HLForm preventSubmit={true} name="checklist">
        </HLForm>
        <Button
          label="Fjern pasient"
          className="btn-link text-danger"
          onClick={deleteVaccination}
        />
      </article>
    </ReactModal>
  )
}

PatientVaccineCard.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onUpdatePersonalData: PropTypes.func.isRequired,
  item: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onTakeover: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  serviceProviderId: PropTypes.number.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
}

export default PatientVaccineCard
