import { DEFAULT_LOCALE, translate } from '../../../lib/locale'
import formatCurrency from '../../../lib/formatCurrency'

const locale = DEFAULT_LOCALE
const t = (prop, source) => translate(`${locale}.${prop}`, source)
const pluralizePatients = (qty) => {
  switch (qty) {
    case 1:
      return t('patient')
      break
    default:
      return t('patients')
  }
}

const buildPatientNumber = (tier, nextTier) => {
  if (!nextTier) {
    return `${tier.min_quantity} + ${pluralizePatients()}`
  } else if (nextTier.min_quantity - 1 === tier.min_quantity) {
    return `${tier.min_quantity} ${pluralizePatients(
      nextTier.min_quantity - 1,
    )}`
  }
  return `${tier.min_quantity} - ${
    nextTier.min_quantity - 1
  } ${pluralizePatients(nextTier.min_quantity - 1)}`
}

export const renderTier = (tier, nextTier) => {
  const patientNumber = buildPatientNumber(tier, nextTier)
  const amount = formatCurrency(tier.amount / 100)
  return [patientNumber, amount].join(': ')
}
