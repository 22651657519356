import React from 'react'
import PropTypes from 'prop-types'
import {
  appointmentShape,
  productShape,
  serviceCenterShape,
} from './propShapes'
import GoogleMapsDirections from '../../GoogleMapsDirections'
import moment from 'moment-timezone'
import HLForm from '../../forms/HLForm'
import Button from '../../forms/fields/Button'

export function Overview({
  appointment,
  products,
  serviceCenter,
  hour,
  numOfPatients,
  map,
  t,
  calendarLink,
  cancelLink,
  onUpdate,
  updated,
}) {
  let title, street, zip, city, lat, lng
  if (serviceCenter) {
    title = serviceCenter.title
    street = serviceCenter.street
    zip = serviceCenter.zip
    city = serviceCenter
    lat = serviceCenter.lat
    lng = serviceCenter.lng
  }

  const serviceCenterAddress = `${street}, ${zip} ${city}`
  const serviceCenterCoords = `${formatCoord(lat)},${formatCoord(
    lng,
  )}`
  const disableActions = !moment(
    `${appointment.date} ${hour}`,
    'YYYY-MM-DD HH:mm',
  ).isAfter(moment(new Date()).add(10, 'minutes'))

  const renderDateString = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    } ${moment(date).calendar(null, {
      sameDay: `[(${t('today')})]`,
      nextDay: `[(${t('tomorrow')})]`,
      nextWeek: `[]`,
      lastDay: `[]`,
      lastWeek: `[]`,
      sameElse: `[]`,
    })}`
  }

  return (
    <>
      <h1 className="mb-2">{t('your_appointment', s)}</h1>

      {updated && (
        <aside role="alert" className="slot-taken">
          <p className="m-0">{t('updated', s)}</p>
        </aside>
      )}

      <dl className="dl-table my-4">
        <dt>{t('service', s)}</dt>
        <dd>
          {products.reduce(
            (s, product, i) =>
              `${s} ${product.public_name}${
                i === products.length - 1 ? '' : ','
              }`,
            '',
          )}
        </dd>

        {serviceCenter ? <dt>{t('service_center', s)}</dt> : null}
        {title ? <dd>{title}</dd> : null}
        {serviceCenter ? <dt>{t('address', s)}</dt> : null}
        {serviceCenter ? (
          <>
            <dd>
              <GoogleMapsDirections
                destination={serviceCenterCoords}
                text={serviceCenterAddress}
              />
            </dd>
          </>
        ) : null}
        <dt>{t('date_and_time', s)}</dt>
        <dd>{`
             ${renderDateString(
               updated ? updated.date : appointment.date,
             )}
             ${t('atTime')}
             ${updated ? updated.hour : hour}
           `}</dd>
        <dt>{t('num_of_patients', s)}</dt>
        <dd>{numOfPatients}</dd>
      </dl>
      {serviceCenter ? (
        <>
          <h2 className="h6">{`${t('directions', s)}`}</h2>
          <p> {t('clickForGoogleMaps', s)}</p>
        </>
      ) : null}

      {serviceCenter?.directions && (
        <>
          <p>{serviceCenter.directions}</p>
        </>
      )}
      {map && <img src={map} className="w-100" />}
      <Button
        className="btn-primary mt-3 w-100"
        label={t('update_appointment', s)}
        onClick={onUpdate}
        disabled={disableActions}
      />
      <HLForm action={cancelLink}>
        <button
          className="btn btn-danger mt-2 w-100"
          type="submit"
          disabled={disableActions}
        >
          {t('cancel_appoinment', s)}
        </button>
      </HLForm>
      {calendarLink && (
        <div className="mt-3">
          <a className="btn-link" href={calendarLink} download>
            {t('calendar', s)}
          </a>
        </div>
      )}
    </>
  )
}

Overview.propTypes = {
  appointment: appointmentShape,
  products: PropTypes.arrayOf(productShape),
  serviceCenter: serviceCenterShape,
  numOfPatients: PropTypes.number.isRequired,
  hour: PropTypes.string.isRequired,
  map: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  calendarLink: PropTypes.string,
  cancelLink: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  updated: PropTypes.object,
}

const formatCoord = (val) => (val ? val.replace(',', '.') : '')

const s = {
  nb: {
    your_appointment: 'Din time',
    service: 'Tjeneste:',
    service_center: 'Klinikk:',
    address: 'Adresse:',
    date_and_time: 'Dato og tid:',
    num_of_patients: 'Antall pasienter:',
    calendar: 'Legg til kalender',
    cancel_appoinment: 'Avlys timen',
    update_appointment: 'Endre timen',
    updated: 'Timen din er endret',
    directions: 'Slik finner du fram:',
    clickForGoogleMaps:
      'Klikk på adressen over for å få nøyaktig veibeskrivelse med bil eller kollektivt fra Google Maps.',
  },
  en: {
    your_appointment: 'Your appointment',
    service: 'Service:',
    service_center: 'Service center:',
    address: 'Address:',
    date_and_time: 'Date and time:',
    num_of_patients: 'Number of patients:',
    calendar: 'Add to calendar',
    cancel_appoinment: 'Cancel appointment',
    update_appointment: 'Reschedule appointment',
    updated: 'Your appointment is rescheduled',
    directions: 'How to get there:',
    clickForGoogleMaps:
      'Click the address above for precise directions by car or public transport from Google Maps.',
  },
  pl: {
    your_appointment: 'Twoja wizyta',
    service: 'Usługa:',
    service_center: 'Centrum:',
    address: 'Adres:',
    date_and_time: 'Data i godzina:',
    num_of_patients: 'Liczba pacjentów:',
    calendar: 'Dodaj do kalendarza',
    cancel_appoinment: 'Anuluj wizytę',
    update_appointment: 'Zmień termin wizyty',
    updated: 'Termin wizyty został zmieniony',
    directions: 'Wskazówki dojazdu:',
    clickForGoogleMaps:
      'Kliknij powyższy adres, aby uzyskać dokładne wskazówki dojazdu samochodem lub komunikacją miejską z Google Maps.',
  },
}
