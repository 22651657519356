import React from 'react'
import PropTypes from 'prop-types'

const OrderDetailsList = ({
  order,
  organization,
  products,
  quantity,
  price,
  booking,
  bookingContactPerson,
}) => (
  <dl className="dl-table">
    <dt>Produkter:</dt> <dd>{products}</dd>
    <dt>Antall:</dt> <dd>{quantity}</dd>
    <dt>Total:</dt> <dd>{price},-</dd>
    <dt>Bedrift:</dt> <dd>{booking.organization}</dd>
    <dt>Org.nr.:</dt> <dd>{organization.org_nr}</dd>
    <dt>Fakturaform:</dt>{' '}
    {booking.billing_method === 'email' && (
      <dd>E-post til {booking.billing_email}</dd>
    )}
    {booking.billing_method === 'ehf' && <dd>EHF</dd>}
    {booking.billing_reference && (
      <>
        <dt>Fakturareferanse:</dt>{' '}
        <dd>{booking.billing_reference}</dd>
      </>
    )}
    <dt>Oppdragskontakt:</dt>{' '}
    <dd>
      {bookingContactPerson.name}, {bookingContactPerson.phone_number}
    </dd>
    {booking.address_directions && (
      <>
        <dt>Praktisk info:</dt> <dd>{booking.address_directions}</dd>
      </>
    )}
    {order.additional_info && (
      <>
        <dt>Annen info</dt>: <dd>{order.additional_info}</dd>
      </>
    )}
  </dl>
)

OrderDetailsList.propTypes = {
  order: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  bookingContactPerson: PropTypes.object.isRequired,
  products: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
}

OrderDetailsList.defaultProps = {
  order: {},
  organization: {},
  products: '',
  booking: {},
  bookingContactPerson: {},
  price: 0,
  quantity: 0,
}

export default OrderDetailsList
