import React from 'react'
const CloseIcon = (inverted = false) => {
  const cStroke = inverted ? '#fff' : 'none'
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#FF6864" stroke={cStroke} />
      <path
        d="M5.42311 12.5L12.4231 5.5"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M12.4231 12.5L5.42308 5.5"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default CloseIcon
