import React from 'react'
import PropTypes from 'prop-types'

import AddressSearchForm from '../AddressSearchForm'
import FormProgress from '../forms/FormProgress'
import Layout from './shared/Layout'

const VaccineWizardSearch = ({
  steps,
  currentStep,
  redirectTo,
  googleMapsApiKey,
  assetRootUrl,
}) => (
  <Layout subheading="Skriv inn bedriftens besøksadresse for å finne et tidspunkt som passer.">
    <FormProgress steps={steps} currentStep={currentStep} />
    <div style={{ marginBottom: '200px' }}>
      <AddressSearchForm
        redirectTo={redirectTo}
        googleMapsApiKey={googleMapsApiKey}
        textButton="Neste"
      />
    </div>
    <img
      className="wizard-images position-absolute d-none d-sm-block"
      src={assetRootUrl.doctorWalkingImage}
      alt="Doctor walking"
    />
  </Layout>
)

VaccineWizardSearch.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
}

VaccineWizardSearch.defaultProps = {
  steps: [],
  currentStep: '',
  redirectTo: '',
}

export default VaccineWizardSearch
