import React from 'react'
import TextInput from './TextInput'

const OrganizationNumberInput = (props) => <TextInput {...props} />

OrganizationNumberInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'OrganizationNumber',
  label: 'Organisasjonsnummer',
  maxLength: 9,
  inputMode: 'numeric',
  filter: (value) => value.replace(/[^0-9]/g, ''),
  validate: (value) => /^([0-9]{4}:)?([0-9]{9})$/.test(value),
}

OrganizationNumberInput.propTypes = TextInput.propTypes

export default OrganizationNumberInput
