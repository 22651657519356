const generateSessionToken = () => {
  if (!window.google) return

  return new google.maps.places.AutocompleteSessionToken()
}

const osloCenter = { lat: 59.544583, lng: 10.444592 }

const defaultOsloBounds = {
  north: osloCenter.lat + 0.1,
  south: osloCenter.lat - 0.1,
  east: osloCenter.lng + 0.1,
  west: osloCenter.lng - 0.1,
}

const norwayOptions = {
  locationBias: defaultOsloBounds,
  componentRestrictions: { country: 'no' },
  fields: [
    'place_id',
    'address_components',
    'formatted_address',
    'geometry',
    'name',
    'business_status',
  ],
  strictBounds: false,
}

const getAutocompletePredictions = async (input, sessionToken) => {
  if (!input) return

  if (typeof window === 'undefined') return

  const predictions = await new window.google.maps.places.AutocompleteService().getPlacePredictions(
    {
      input: input,
      sessionToken: sessionToken,
      ...norwayOptions,
    },
  )
  return predictions
}

const callGoogleAutocomplete = async (
  input = '',
  sessionToken,
  onReceivedPredictions,
  debounceTimeout = 500,
) => {
  return setTimeout(async () => {
    try {
      if (!input) {
        return
      }
      const resultPredictions = await getAutocompletePredictions(
        input,
        sessionToken,
      )
      onReceivedPredictions(resultPredictions.predictions)
    } catch (e) {
      console.error(e)
    }
  }, debounceTimeout)
}

const getPlaceDetails = (placeId, sessionToken, callback) => {
  if (!window.google) return

  const placeService = new google.maps.places.PlacesService(
    document.getElementById('pac-input'),
  )

  placeService.getDetails(
    {
      placeId: placeId,
      fields: norwayOptions.fields,
      sessionToken: sessionToken,
    },
    callback,
  )
}

const extractAddressDetail = (
  place,
  infoType,
  secondInfoType = '',
) => {
  return place.address_components.find(
    (component) =>
      component.types.includes(infoType) ||
      component.types.includes(secondInfoType),
  )?.long_name
}

const sanitizeAddress = (place) => {
  return {
    place_id: place.place_id,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    formatted: place.formatted_address,
    number: extractAddressDetail(place, 'street_number'),
    city: extractAddressDetail(place, 'postal_town', 'locality'),
    zip: extractAddressDetail(place, 'postal_code'),
    country: extractAddressDetail(place, 'country'),
    street: extractAddressDetail(place, 'route'),
    district: extractAddressDetail(
      place,
      'sublocality',
      'administrative_area_level_1',
    ),
  }
}

const getTestData = (writtenValue) => {
  if (writtenValue == '' || !writtenValue) return
  return {
    zip: writtenValue.match(/\d{4}/g)?.[0],
    street: writtenValue.match(/[a-zA-Z\u0080-\uFFFF]+/g)?.[0],
    number: writtenValue.match(/\d{2}/g)?.[0],
    city: writtenValue.match(/[a-zA-Z\u0080-\uFFFF]+/g)?.[1],
  }
}

export {
  sanitizeAddress,
  getTestData,
  generateSessionToken,
  callGoogleAutocomplete,
  getPlaceDetails,
}
