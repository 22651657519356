import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import Chevron from '../Chevron'
import moment from 'moment-timezone'
import ClinicContext from './ClinicContext'

export function ClinicAppointmentListActionBar({
  currentDate,
  numberOfPatients,
  isShowingUpcoming,
  toggleShowingUpcoming,
  setCurrentDate,
}) {
  const { assetRootUrl, clinic } = useContext(ClinicContext)

  const handleSetDate = (day) => {
    const newCurrentDate = moment(currentDate)
      .add(day, 'day')
      .toDate()
    setCurrentDate(newCurrentDate)
  }

  return (
    <div className="my-2">
      <Button
        className="btn-link mr-2"
        label={isShowingUpcoming ? 'Vis alle' : 'Skjul vaksinerte'}
        onClick={toggleShowingUpcoming}
      />
      {!!clinic && (
        <div className="button-group--small">
          <Chevron
            side={'left'}
            onChange={() => handleSetDate(-1)}
            chevronIconPath={assetRootUrl.chevronIcon}
          />
          <Chevron
            side={'right'}
            onChange={() => handleSetDate(1)}
            chevronIconPath={assetRootUrl.chevronIcon}
          />
        </div>
      )}
    </div>
  )
}

ClinicAppointmentListActionBar.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  numberOfPatients: PropTypes.number.isRequired,
  isShowingUpcoming: PropTypes.bool.isRequired,
  toggleShowingUpcoming: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
}
