import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { DEFAULT_LOCALE, translate } from '../../lib/locale'
import PriceModelListItem from './PriceModelListItem'
import moment from 'moment-timezone'

const DealListItem = ({
  id,
  customDealPath,
  effectiveFrom,
  effectiveTo,
  responsible,
  coupon,
  organization,
  priceModels,
  description,
  adminDescription,
  receiptText,
}) => {
  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)
  const [expended, setExpended] = useState(false)
  const now = moment().tz('Europe/Oslo').format()
  const isActive =
    effectiveFrom < now &&
    (effectiveTo === undefined ||
      effectiveTo === null ||
      effectiveTo > now)
  const isUpcoming = effectiveFrom > now

  return (
    <>
      <li className="list-group-item list-group-item--no-border-vertical">
        <div className="row">
          <div className="col-sm-11">
            <a
              href={customDealPath}
              rel=" noopener noreferrer"
              className="no-link-decoration"
            >
              <div className="row">
                <div className="col-sm-3">
                  <dl>
                    <dt>{t('organization')}:</dt>
                    <dd>{organization ? organization.name : '-'}</dd>
                  </dl>
                </div>
                <div className="col-sm-3">
                  <dl>
                    <dt>{t('coupon')}:</dt>
                    <dd>{coupon ? coupon.code : '-'}</dd>
                  </dl>
                </div>
                <div className="col-sm-3">
                  <dl>
                    <dt>{t('responsible')}:</dt>
                    <dd>{responsible ? responsible : '-'}</dd>
                  </dl>
                </div>

                <div className="col-sm-2">
                  <dl>
                    <dt>Status:</dt>
                    <dd
                      className={`badge  ${
                        isActive
                          ? 'badge-subscription-active'
                          : isUpcoming
                          ? 'badge-subscription-upcoming'
                          : 'badge-subscription-expired'
                      }`}
                    >
                      {isActive
                        ? t('active')
                        : isUpcoming
                        ? t('upcoming')
                        : t('expired')}
                    </dd>
                  </dl>
                </div>
              </div>
            </a>
          </div>
          <div className="col-sm-1 text-center">
            {expended ? (
              <i
                className="fas fa-angle-up"
                onClick={() => setExpended(false)}
              ></i>
            ) : (
              <i
                className="fas fa-angle-down"
                onClick={() => setExpended(true)}
              ></i>
            )}
          </div>
        </div>

        {expended && (
          <div className="row">
            <div className="col-sm-11">
              <div className="row my-3">
                <div className="col-sm-3">
                  <dl>
                    <dt>{t('effectiveFrom')}:</dt>
                    <dd>
                      {effectiveFrom
                        ? moment(effectiveFrom).format('DD.MM.YY')
                        : '-'}
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-3">
                  <dl>
                    <dt>{t('effectiveTo')}:</dt>
                    <dd>
                      {effectiveTo
                        ? moment(effectiveTo).format('DD.MM.YY')
                        : '-'}
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-6">
                  <dl>
                    <dt>{t('description')}:</dt>
                    <dd>{description || '-'}</dd>
                  </dl>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-sm-6">
                  <dl>
                    <dt>{t('adminDescription')}:</dt>
                    <dd>{adminDescription || '-'}</dd>
                  </dl>
                </div>
                <div className="col-sm-6">
                  <dl>
                    <dt>{t('receiptText')}:</dt>
                    <dd>{receiptText || '-'}</dd>
                  </dl>
                </div>
              </div>
              <div className="row my-1">
                <div className="col-sm-12">
                  <dl>
                    <dt>{t('priceModel')}:</dt>
                    <dd>
                      {priceModels.map((pm, i) => (
                        <PriceModelListItem key={i} priceModel={pm} />
                      ))}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        )}
      </li>
    </>
  )
}

DealListItem.propTypes = {
  id: PropTypes.string.isRequired,
  customDealPath: PropTypes.string.isRequired,
  effectiveFrom: PropTypes.string.isRequired,
  effectiveTo: PropTypes.string,
  responsible: PropTypes.string,
  coupon: PropTypes.object,
  organization: PropTypes.object.isRequired,
  priceModels: PropTypes.arrayOf(PropTypes.object.isRequired),
  description: PropTypes.string,
  receiptText: PropTypes.string,
  adminDescription: PropTypes.string,
}

export default DealListItem
