import React from 'react'
import PropTypes from 'prop-types'
import { renderTier } from './utils/tiers'

const PriceModelListItem = ({ priceModel }) => {
  return (
    <div className={' price-model__card list-group-item'}>
      <div className={'price-model__title '}>{priceModel.title}</div>

      <div className={'price-model__tier-container '}>
        {priceModel?.tiers?.map((tier, i) => (
          <div key={i} className={'price-model__tier'}>
            <div className={'price-model__tier-quantity'}>
              {renderTier(tier, priceModel.tiers[i + 1])}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

PriceModelListItem.propTypes = {
  priceModel: PropTypes.object.isRequired,
}

export default PriceModelListItem
