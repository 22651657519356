import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactModal from 'react-modal'

const modalSmsStyle = {
  content: {
    background: '#fff',
    WebkitOverflowScrolling: 'touch',
  },
}

class WarningCartModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen,
    }
    ReactModal.setAppElement('body')
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.close}
        contentLabel="Viktig informasjon"
        style={modalSmsStyle}
        shouldCloseOnEsc={true}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={this.props.close}
        />
        <div className="ReactModal__heading mb-3"></div>
        <h4>Velg mellom 5 og 30 vaksiner</h4>
        <p>
          For mer informasjon om Influensavaksine :
          <span>
            <a href="https://hjemmelegene.no/influensavaksine">
              &nbsp; hjemmelegene.no/influensavaksine
            </a>
          </span>
        </p>
      </ReactModal>
    )
  }
}

export default WarningCartModal
