import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NameInput from './forms/fields/NameInput'
import PhoneInput from './forms/fields/PhoneInput'
import Button from './forms/fields/Button'
import { verifyMobileCarrier } from '../lib/phone-validation'

const ConfirmPatientState = (props) => {
  const [patient, setPatient] = useState(props.patient)
  const [isIndependent, setIsIndependant] = useState(
    props.patient.independent,
  )
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const receivedPatient = props.patient
    setIsIndependant(receivedPatient.independent)
    setPatient((patient) => ({
      ...patient,
      independent: receivedPatient.independent,
      phone_number: receivedPatient.phone_number,
    }))
  }, [props.patient])

  const verifyPhoneNumber = async () => {
    const phoneToTest =
      patient.phone_number || patient.phone_number_old
    const phone_verification_response = await verifyMobileCarrier(
      phoneToTest,
    )
    if (phone_verification_response.err) {
      setErrorMessage(
        `Ugyldig mobiltelefonnummer. Sjekk nummeret og prøv på nytt!`,
      )
    }
    return phone_verification_response
  }

  const submitConfirmation = async () => {
    const phoneVerifResp = patient.independent
      ? await verifyPhoneNumber()
      : true
    if (phoneVerifResp && !phoneVerifResp.err) {
      props.submit(patient)
    }
  }

  const handleNameChange = (name) => {
    setPatient({ ...patient, name })
  }

  const handlePhoneNumberChange = (phone_number) => {
    if (isIndependent) {
      setPatient({ ...patient, phone_number })
    }
  }

  return (
    <>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      <NameInput
        name={props.nameInputName}
        className="large-input b-bottom"
        value={patient.name || ''}
        onChange={handleNameChange}
        highlightErrors={false}
        required={true}
      />
      {isIndependent && (
        <PhoneInput
          name={props.phoneInputName}
          className="large-input"
          value={
            patient.phone_number || patient.phone_number_old || ''
          }
          onChange={handlePhoneNumberChange}
          highlightErrors={false}
          isRequired
        />
      )}

      {props.children}

      {props.hasDuplicatePatients && (
        <p className="text-danger">
          OBS: Telefonnummer er registrert på flere pasienter. Vær
          ekstra varsom!
        </p>
      )}
      <div className="row">
        <div className="col-6">
          <Button
            label={props.buttonCancelLabel}
            onClick={props.cancel}
            className="btn-link mt-4"
          />
        </div>
        <div className="col-6 text-right">
          <Button
            label={props.buttonConfirmLabel}
            onClick={submitConfirmation}
            disabled={props.busy}
            className="btn-primary"
          />
        </div>
      </div>
    </>
  )
}

ConfirmPatientState.defaultProps = {
  buttonCancelLabel: 'Hopp over',
  buttonConfirmLabel: 'Bekreft',
  busy: false,
  nameInputName: 'patient[name]',
  phoneInputName: 'patient[phone_number]',
  checkIndependence: false,
  isDeletable: false,
}

ConfirmPatientState.propTypes = {
  children: PropTypes.node,
  hasDuplicatePatients: PropTypes.bool.isRequired,
  busy: PropTypes.bool,
  buttonCancelLabel: PropTypes.string,
  buttonConfirmLabel: PropTypes.string,
  nameInputName: PropTypes.string,
  phoneInputName: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    phone_number_old: PropTypes.string,
    national_id: PropTypes.string,
    independent: PropTypes.bool,
  }),
}

export default ConfirmPatientState
