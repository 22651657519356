import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import ConsentForm from '../appointments/ConsentForm'
import ClinicContext from './ClinicContext'

const ClinicPatientVaccineCard = ({
  item,
  onClose,
  appointment,
  editable,
}) => {
  ReactModal.setAppElement('body')
  const { t, allTrue } = useContext(ClinicContext)

  const ok = () => {
    if (!item.options) return false

    return allTrue(item.options)
  }

  const modalStyle = {
    content: {
      maxWidth: '70%',
      maxHeight: '95%',
      WebkitOverflowScrolling: 'touch',
      border: `4px solid ${ok() ? '#0f0' : '#f00'}`,
      transition: 'border-color 0.2s ease',
      background: '#fafafa',
      position: 'relative',
    },
  }
  return (
    <ReactModal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Patient Card"
      style={modalStyle}
      id={`patient-card-${item?.id}`}
    >
      <div
        className="ReactModal__exit-button-new"
        onClick={onClose}
      />
      <article className="vaccination-patient-card-content">
        <h3 className="ReactModal__heading mb-3">{`Samtykkeskjema ${item.name}`}</h3>
        <p className="mb-0">Se over informasjon</p>
        <div className="formgroup">
          <ConsentForm
            appointment={appointment}
            user={appointment.user}
            appointmentPatient={item}
            patientId={item.patientId}
            onComplete={onClose}
            t={t}
            onlyCheckboxes={true}
            editable={editable}
          />
        </div>
      </article>
    </ReactModal>
  )
}

ClinicPatientVaccineCard.propTypes = {
  item: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  appointment: PropTypes.object,
  editable: PropTypes.bool,
}

export default ClinicPatientVaccineCard
