import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { serviceCenterShape } from './propShapes'
import { appointmentAvailabilityCalendar } from './queries'
import Button from '../forms/fields/Button'
import moment from 'moment-timezone'
import _ from 'lodash'

export function FirstAvailableSlot({
  serviceCenter,
  booking,
  renderAppointmentSummary,
  productSlugs,
  patientCount,
  onComplete,
  onError,
  setLoading,
  reset,
  t,
}) {
  const [firstSlot, setFirstSlot] = useState(null)
  const [availableSlotsToday, setAvailableSlotsToday] = useState(true)
  const today = new Date()

  const renderDateString = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    }`
  }

  const fetchSlots = async () => {
    setLoading(true, t('searching', s))
    const response = await appointmentAvailabilityCalendar(
      serviceCenter?.id,
      booking?.id,
      today,
      patientCount,
      productSlugs,
    )
    setLoading(false)
    if (response.errors) return onError(response.errors)
    const allSlots = response.data[0].availability
    const date = Object.keys(allSlots)[0]
    const allSlotsToday = allSlots[date]
    if (allSlotsToday && !_.isEmpty(allSlotsToday)) {
      const firstAvailableTimeSlot = Object.keys(allSlotsToday)[0]
      setFirstSlot({ date, hour: firstAvailableTimeSlot })
    } else {
      setAvailableSlotsToday(false)
    }
  }

  useEffect(() => {
    if (firstSlot === null) {
      fetchSlots()
    }
  }, [firstSlot])

  return (
    <section>
      {renderAppointmentSummary()}
      {availableSlotsToday && (
        <>
          <Button
            className="btn btn-primary btn-mega ishadow"
            onClick={() => onComplete(firstSlot)}
            disabled={firstSlot === null}
          >
            <h2 className="h6 text-white">
              {firstSlot
                ? `${renderDateString(firstSlot.date)}, ${
                    firstSlot.hour
                  }`
                : t('loading_appointment', s)}
            </h2>
            <p className="my-0 text-white">
              {firstSlot ? t('click_to_book', s) : ''}
            </p>
          </Button>
          <div className="mt-4 d-flex justify-content-between">
            <Button
              label={t('restart')}
              className="btn-link "
              onClick={reset}
            />
            <Button
              label={t('select_different_time', s)}
              className="btn-link "
              onClick={() => onComplete(null)}
            />
          </div>
        </>
      )}
      {!availableSlotsToday && (
        <>
          <h1 className="mb-4">{t('headingFull', s)}</h1>
          <div className="info-box info-box-danger mb-4">
            <p>
              {t('no_spot_available', s, null, {
                stationTitle: serviceCenter.title,
              })}
            </p>
          </div>
          <Button
            label={t('restart')}
            className="btn-link "
            onClick={reset}
          />
        </>
      )}
    </section>
  )
}

FirstAvailableSlot.propTypes = {
  serviceCenter: serviceCenterShape,
  booking: PropTypes.object,
  productSlugs: PropTypes.arrayOf(PropTypes.string),
  renderAppointmentSummary: PropTypes.func.isRequired,
  patientCount: PropTypes.number.isRequired,
  productSlugs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Første tilgjengelige time på {{stationTitle}}:',
    headingFull: 'Fullbooket',
    select_different_time: 'Velg blant alle ledige timer',
    no_spot_available:
      'Dessverre har vi ingen flere ledige timer på {{stationTitle}} i dag.',
    click_to_book: 'Klikk her for å velge denne',
    loading_appointment: 'Laster',
    searching: 'Søker etter ledige timer',
  },
  en: {
    heading: 'First available time at {{stationTitle}}:',
    headingFull: 'Fully booked today',
    select_different_time: 'Show all available time slots',
    no_spot_available:
      'Unfortunately there are no more available time slots at {{stationTitle}} today.',
    click_to_book: 'Click here to book',
    loading_appointment: 'Loading',
    searching: 'Searching for available times',
  },
  pl: {
    heading: 'Pierwszy dostępny termin w {{stationTitle}}:',
    headingFull:
      'Dziś zarezerwowane są już wszystkie możliwe terminy',
    select_different_time: 'Pokaż wszystkie wolne jeszcze terminy',
    no_spot_available:
      'Niestety, dziś nie ma już więcej wolnych terminów w {{stationTitle}}.',
    click_to_book: 'Kliknij aby zarezerwować',
    loading_appointment: 'Ładowanie',
    searching: 'Szukanie wolnych terminów',
  },
}
