import React, { useState } from 'react'
import Calendar from '../Calendar'
import PropTypes from 'prop-types'
import { appointmentShape, serviceCenterShape } from './propShapes'
import * as Sentry from '@sentry/react'
import { updateAppointment } from '../queries'

export function UpdateAppointment({
  appointment,
  serviceCenter,
  patientCount,
  onSuccess,
  onError,
  setLoading,
  reset,
  products,
  t,
}) {
  const [slotTaken, setSlotTaken] = useState(false)
  async function onUpdateAppointment({ date, hour }) {
    try {
      setSlotTaken(false)
      setLoading(true)
      const user = { id: appointment.user_id }
      const newAppointment = {
        id: appointment.id,
        code: appointment.code,
        date,
        hour,
      }
      const response = await updateAppointment(user, newAppointment)
      if (
        response.errors &&
        response.errors.message === 'Timeslot not available'
      ) {
        setSlotTaken(true)
      } else if (response.netError) {
        onError(response.errors)
      } else {
        onSuccess(date, hour)
      }
      setLoading(false)
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          location: 'ExistingAppointment.onUpdateAppointment',
        },
      })
      setError(e.toString())
      setLoading(false)
    }
  }

  return (
    <>
      <Calendar
        booking={
          appointment.booking_id
            ? { id: appointment.booking_id }
            : null
        }
        serviceCenter={serviceCenter ? serviceCenter : null}
        patientCount={patientCount}
        onComplete={onUpdateAppointment}
        onError={onError}
        setLoading={setLoading}
        reset={reset}
        t={t}
        slotTaken={slotTaken}
        productSlugs={products.map((p) => p.slug)}
      />
    </>
  )
}

UpdateAppointment.propTypes = {
  appointment: appointmentShape,
  serviceCenter: serviceCenterShape.isRequired,
  patientCount: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}
