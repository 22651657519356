import gql from '../../../api/graphql'

export async function serviceCenterProducts(params = {}) {
  const queryParams = Object.entries(params)
    .filter(([_, value]) => value !== null)
    .reduce(
      (prev, [key, value]) =>
        prev + `${key}: ${JSON.stringify(value)} `,
      '',
    )
  const response = await gql(
    `query {
        serviceCenterProducts${
          queryParams ? `(${queryParams})` : ''
        } {
                serviceCenters {
                    id
                    name
                    title
                    operationType
                    street
                    zip
                    city
                    googlePlaceId
                    lat
                    lng
                    directions
                    wheelchairAccessible
                    openingHours
                    maxCapacity
                }
                products {
                    id
                    name
                    publicName
                    price
                    description
                    imageUrl
                    slug
                    vatRate
                    requireShipping
                    requireHousecall
                    requireDigital
                    createdAt
                    updatedAt
                    type
                }
            }
        }`,
  )
  return response.data
}
