import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import TermsText from './TermsText'

const modalStyle = {
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

class TermsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    ReactModal.setAppElement('.page__content')
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.close}
        contentLabel="Terms of use"
        style={modalStyle}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={this.props.close}
        />
        <TermsText />
      </ReactModal>
    )
  }
}

export default TermsModal
