import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Layout from './shared/Layout'
import DealListItem from './DealListItem'
import gqlCall from '../../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
} from '../../api/graphqlExtended'
import ShieldedSpinner from '../ShieldedSpinner'

const DealsList = () => {
  const [deals, setDeals] = useState('')
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    graphqlSearch()
  }, [graphqlSearch])

  const graphqlSearch = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await gqlCall(
        buildGraphQLQuery({
          resourceName: 'deals',
          multiple: true,
        }),
      )
      const parsedResponse = parseQueryResponse({
        response: response,
        resourceName: 'deals',
        multiple: true,
      })
      setDeals(parsedResponse)
    } catch (e) {
      setError(e.toString())
    }
    setIsLoading(false)
  }, [])

  return (
    <>
      <Layout>
        {!isLoading && (
          <ul className="list-group">
            {deals.length > 0 &&
              deals.map((deal, i) => (
                <DealListItem key={i} {...deal} />
              ))}
          </ul>
        )}
        <ShieldedSpinner show={isLoading} />
        {error && <p className="small">{error.toString()}</p>}
      </Layout>
    </>
  )
}

export default DealsList
