import React, { useContext } from 'react'
import CircleItem from '../CircleItem'
import CopyToClipboard from '../CopyToClipboard'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import ClinicContext from './ClinicContext'

export const ClinicAppointmentItem = ({
  item,
  onClick,
  onCirclesClick,
}) => {
  const { assetRootUrl, multipleCustomers } = useContext(
    ClinicContext,
  )

  const renderHour = () => {
    switch (true) {
      case !!item.appearedAt:
        return (
          <>
            <span>{item.hour} / </span>
            <span className={'clock-arrived-at'}>
              {moment(item.appearedAt).format('HH:mm')}
            </span>
          </>
        )
      case item.hour < moment().format('HH:mm'):
        return <span className={'text-danger'}>{item.hour}</span>
      default:
        return <span>{item.hour}</span>
    }
  }
  const checkConsentandReadyAt = () => {
    if (
      item.readyAt ||
      (item.options &&
        Object.values(item.options).every((v) => v === true))
    ) {
      return true
    } else {
      return false
    }
  }

  const shouldPay = () => {
    return item.whoIsPaying === 'user'
  }

  return (
    <div
      className="appointment-item"
      onClick={() => onClick(item)}
      title={item.appointment?.order?.lineItems
        .map((i) => i.product.name)
        .join('\n')}
    >
      <div className="row">
        <div className="col-sm-2">{renderHour()}</div>
        <div className="col-sm-3 d-flex align-items-center justify-content-between">
          <span>{item.name}</span>
          {multipleCustomers && shouldPay() && (
            <span
              style={{ fontSize: '24px' }}
              title="Betaling med iZettle"
            >
              💳
            </span>
          )}
        </div>
        <div className="col-sm-2">
          <CopyToClipboard
            clipboardIconPath={assetRootUrl.clipboardIcon}
            value={item.phoneNumber}
            stopPropagation={true}
          />
        </div>
        <div className="col-sm-2">
          <span>
            {item.nationalId
              ? item.nationalId.slice(0, 6) + '*****'
              : null}
          </span>
          <CopyToClipboard
            clipboardIconPath={assetRootUrl.clipboardIcon}
            value={item.nationalId}
            stopPropagation={true}
            showValue={false}
          />
        </div>
        <div
          className="col-sm-1"
          style={{ paddingLeft: '10px' }}
          title="Samtykke fullstendig"
        >
          <span>{checkConsentandReadyAt() ? '✅' : '❌'}</span>
        </div>

        <div
          className="col-sm-1"
          style={{ paddingLeft: '10px' }}
          onClick={(e) => {
            onCirclesClick(e, item, 'productAdministeredAt')
          }}
          title="Vaksinert"
        >
          <span className="d-sm-none">
            OK: {item.productAdministeredAt ? '✅' : '❌'}
          </span>
          <CircleItem
            id={`appointment_item_ready_at`}
            className="circle-item applied_at"
            status={item.productAdministeredAt ? 'done' : 'notDone'}
          />
        </div>

        <div
          className="col-sm-1"
          style={{ paddingLeft: '10px' }}
          onClick={(e) => {
            onCirclesClick(e, item, 'sysvacRegisteredAt')
          }}
          title="Journalført"
        >
          <span className="d-sm-none">
            JF: {item.sysvacRegisteredAt ? '✅' : '❌'}
          </span>
          <span className="backoffice__red-text">
            <CircleItem
              id={`appointment_item_sysvac_registered_at`}
              className="circle-item applied_at"
              status={item.sysvacRegisteredAt ? 'done' : 'notDone'}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

ClinicAppointmentItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onCirclesClick: PropTypes.func.isRequired,
}
