import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
class Input extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onChangeText: PropTypes.func.isRequired,
  }

  handleChange = (event) => {
    if (this.props.onChange) this.props.onChange(event)
    this.props.onChangeText(event.target.value)
  }

  render() {
    const safeProps = _.omit(this.props, 'onChangeText')

    const childProps = {
      ...safeProps,
      onChange: this.handleChange,
    }

    return <input {...childProps} />
  }
}

export default Input
