import React from 'react'
import PropTypes from 'prop-types'

import Button from '../forms/fields/Button'
import { WHO } from './AppointmentWizard'

const WhoToTest = ({
  renderAppointmentSummary,
  maxCapacity,
  onComplete,
  showReset,
  reset,
  t,
}) => {
  const tracking = {
    context: 'clinic-booking',
    intent: 'select-who',
  }
  return (
    <section>
      {renderAppointmentSummary()}
      <h1 className="mb-4">{t('heading', s)}</h1>
      <Button
        className="btn btn-primary btn-mega ishadow"
        label={t(WHO.me, s)}
        onClick={() => onComplete(WHO.me)}
      ></Button>
      {maxCapacity > 1 && (
        <Button
          className="btn btn-primary btn-mega ishadow"
          label={t(WHO.meplus, s)}
          onClick={() => onComplete(WHO.meplus)}
        ></Button>
      )}
      <Button
        className="btn btn-primary btn-mega ishadow foo"
        label={t(WHO.others, s)}
        onClick={() => onComplete(WHO.others)}
      ></Button>
      {showReset && (
        <div className="mt-5">
          <Button
            className="btn-link"
            label={t('restart')}
            onClick={reset}
          />
        </div>
      )}
    </section>
  )
}

WhoToTest.propTypes = {
  renderAppointmentSummary: PropTypes.func.isRequired,
  maxCapacity: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  showReset: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Hvem skal ha timen?',
    me: 'Bare meg',
    meplus: 'Meg og noen andre',
    others: 'Jeg registrerer bare for noen andre',
  },
  en: {
    heading: 'Who is the appointment for?',
    me: 'Just me',
    meplus: 'Me and others',
    others: 'I am only registering for someone else',
  },
  pl: {
    heading: 'Kto skorzysta z wizyty?',
    me: 'Tylko ja',
    meplus: 'Ja i inni',
    others: 'Chcę zarejestrować kogoś innego',
  },
}

export default WhoToTest
