import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import RailsForm from './RailsForm'

const RefundModal = ({
  path,
  options,
  t,
  rootElement = '.page__content',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const form = useRef()
  ReactModal.setAppElement(rootElement)

  const toggleModal = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  const submitForm = (e) => {
    e.preventDefault()
    form.current.submit()
    setIsOpen(false)
  }

  return (
    <div>
      <a href="#" className="text-danger" onClick={toggleModal}>
        {t.linkText}
      </a>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={toggleModal}
        />

        <h1 className="backoffice__header">{t.heading}</h1>
        <p>{t.text}</p>
        <RailsForm
          ref={form}
          onSubmit={submitForm}
          action={path}
          method="post"
          className="text-center d-flex"
          name="refund_modal"
        >
          <select name="refund_reason" className="form-control mr-2">
            {Object.entries(options).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="btn btn-outline-primary mr-2"
            onClick={submitForm}
          >
            {t.buttonLabel}
          </button>
        </RailsForm>
      </ReactModal>
    </div>
  )
}

RefundModal.propTypes = {
  path: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  rootElement: PropTypes.string,
  t: PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }),
}

export default RefundModal
