import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from '../RichTextEditor.jsx'
import HLForm from '../forms/HLForm.jsx'
import CheckBox from '../forms/fields/CheckboxHL.jsx'
import Button from '../forms/fields/Button'
import { updateDigimedSettings } from './queries'
import ShieldedSpinner from '../ShieldedSpinner.jsx'

const EnableChannels = ({ settings }) => {
  const [chatEnabled, setChatEnabled] = useState(
    settings.chat_enabled,
  )
  const [videoEnabled, setVideoEnabled] = useState(
    settings.video_enabled,
  )
  const [phoneEnabled, setPhoneEnabled] = useState(
    settings.phone_enabled,
  )
  const [textEn, setTextEn] = useState(settings.text_en)
  const [textNb, setTextNb] = useState(settings.text_nb)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)

  const hasEmptyHtmlTags = (string) => {
    const emptyTagRegex = /<[^\/>][^>]*><\/[^>]+>/g
    return string.match(emptyTagRegex)
  }

  const updateNorwegianText = (value) => {
    setTextNb(value)
  }

  const updateEnglishText = (value) => {
    setTextEn(value)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      await updateDigimedSettings({
        chat_enabled: chatEnabled,
        video_enabled: videoEnabled,
        phone_enabled: phoneEnabled,
        text_en: hasEmptyHtmlTags(textEn) ? '' : textEn,
        text_nb: hasEmptyHtmlTags(textNb) ? '' : textNb,
      })
      setIsLoading(false)
      setIsError(false)
      setMessage('Available digimed channels updated')
    } catch (error) {
      setMessage(error.message)
      setIsError(true)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <ShieldedSpinner />
  }

  return (
    <>
      {message && (
        <p
          className={`flash ${
            !isError ? 'flash--success' : 'flash--error'
          }`}
        >
          {message}
        </p>
      )}
      <h6>Enabled channels</h6>
      <HLForm preventSubmit={true}>
        <p>Message to patients in Norwegian</p>
        <RichTextEditor
          value={textNb}
          onChange={updateNorwegianText}
        />
        <br />
        <p>Message to patients in English</p>
        <RichTextEditor value={textEn} onChange={updateEnglishText} />
        <br />

        <p>Active channels</p>
        <CheckBox
          label="Chat enabled"
          name="chat_enabled"
          value={chatEnabled}
          onChange={setChatEnabled}
        >
          {' '}
          Chat{' '}
        </CheckBox>
        <CheckBox
          label="Video enabled"
          name="video_enabled"
          value={videoEnabled}
          onChange={setVideoEnabled}
        >
          {' '}
          Video{' '}
        </CheckBox>
        <CheckBox
          label="Phone enabled"
          name="phone_enabled"
          value={phoneEnabled}
          onChange={setPhoneEnabled}
        >
          {' '}
          Phone{' '}
        </CheckBox>
        <Button onClick={handleSubmit}>
          Confirm digimed channels
        </Button>
      </HLForm>
    </>
  )
}

EnableChannels.propTypes = {
  settings: PropTypes.shape({
    chat_enabled: PropTypes.bool,
    video_enabled: PropTypes.bool,
    phone_enabled: PropTypes.bool,
    text_en: PropTypes.string,
    text_nb: PropTypes.string,
  }).isRequired,
}

export default EnableChannels
