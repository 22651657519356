import React from 'react'
import PropTypes from 'prop-types'

export function InvalidAppointment({ t }) {
  return <p>{t('invalid_appointment', s)}</p>
}

InvalidAppointment.propTypes = {
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    invalid_appointment: 'Beklager. Vi kunne ikke finne timen din.',
  },
  en: {
    invalid_appointment: "Sorry. We couldn't find your appointment.",
  },
}
