import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import ConsentOverviewPatientItem from './ConsentOverviewPatientItem.jsx'
import ConsentForm from './ConsentForm.jsx'

import { appointmentShape, userShape } from './propShapes'

const ConsentOverview = ({ appointment, user, onComplete, t }) => {
  // To handle multiple types of vaccinations per appointment:
  // const [products, setProducts] = useState(() => appointment.products)
  const [patients, setPatients] = useState(() => {
    const appPatients = appointment.appointmentsPatients.map((p) => {
      return {
        ...p,
        consentGiven: false,
        phoneNumber: '',
        willNotVaccinate: false,
        smsSent: false,
        openConsentForm: false,
      }
    })
    return appPatients
  })
  const [openConsentFormFor, setOpenConsentFormFor] = useState()
  const [allActionsTaken, setAllActionsTaken] = useState(0)

  /*

  const setItemComplete = (id) => {
    setItemsComplete({ ...setItemsComplete, [id]: true })
  }

  const [itemsComplete, setItemsComplete] = useState(() =>
    patients.map((p) => ({ [p.id]: false })),
  )

  const submitOk = !Object.keys(items).find((i) => !items[i])
  */
  const handleActionsTaken = () => {
    setAllActionsTaken(allActionsTaken + 1)
  }

  const updateDetails = (obj) => {
    const newDetails = patients
    const toReplace = newDetails.find(
      (p) => p.patientId === obj.patientId,
    )
    Object.assign(toReplace, obj)
    setPatients(newDetails)
  }

  const checkChangesInPatientsStatus = JSON.stringify(patients)

  useEffect(() => {
    const patientToGiveConsent = () => {
      const patient = patients.filter((p) => {
        return p.openConsentForm === true
      })
      if (patient) {
        setOpenConsentFormFor(patient[0])
      }
    }
    patientToGiveConsent()
  }, [checkChangesInPatientsStatus, patients])

  const onConsentGiven = () => {
    updateDetails({
      ...openConsentFormFor,
      openConsentForm: false,
      willNotVaccinate: false,
      smsSent: false,
      consentGiven: true,
    })
    setOpenConsentFormFor(null)
  }

  const renderConfirmButton = () => {
    return patients.every((p) => {
      return (
        p.willNotVaccinate === true ||
        p.smsSent === true ||
        p.consentGiven === true
      )
    }) ? (
      <Button
        className="btn-primary w-100"
        label={t('confirm', s)}
        onClick={onComplete}
      />
    ) : (
      <Button
        className="btn-outline disabled w-100"
        label={t('confirm', s)}
      />
    )
  }

  return (
    <>
      {patients.length > 1 ? (
        openConsentFormFor ? (
          <ConsentForm
            appointment={appointment}
            user={user}
            patientId={openConsentFormFor.patientId}
            onComplete={onConsentGiven}
            t={t}
          />
        ) : (
          <article className="d-flex flex-column">
            <h2 className="text-left mb-4">
              {t('vaccinationConsent', s)}
            </h2>
            <section className="text-danger mb-4">
              {t('allPatientsWarning', s)}
            </section>
            <section className="text-left mb-4">
              {t('explanation', s)}
            </section>

            {patients.map((p, i) => {
              return (
                <ConsentOverviewPatientItem
                  key={i}
                  actionTaken={handleActionsTaken}
                  appointment={appointment}
                  //onComplete={setItemComplete}
                  user={user}
                  patient={p}
                  updateDetails={updateDetails}
                  t={t}
                />
              )
            })}
            <section className="d-flex justify-content-center">
              {renderConfirmButton()}
            </section>
            <section
              className="text-muted"
              style={{ fontSize: '0.7em', marginTop: '13em' }}
            >
              This data will be stored in encrypted state and handled
              by Hjemmelegene only. It will be automatically deleted
              after 30 days.
            </section>
          </article>
        )
      ) : (
        <ConsentForm
          appointment={appointment}
          user={user}
          onComplete={onComplete}
          patientId={patients[0].patientId}
          t={t}
        />
      )}
    </>
  )
}

ConsentOverview.propTypes = {
  appointment: appointmentShape,
  user: userShape,
  onComplete: PropTypes.func,
  t: PropTypes.func,
}
const s = {
  nb: {
    vaccinationConsent: 'Samtykke vaksinering',
    allPatientsWarning:
      'Alle pasientene må fylle ut samtykkeskjema for vaksinering før timen.',
    explanation:
      'Som kontaktperson kan du fylle ut samtykkeskjemaet for deg selv og andre som du er en rettmessig verge for. Du kan sende samtykkeskjemaet på SMS til de som skal fylle ut skjemaet selv.',
    confirm: 'Bekreft',
  },
  en: {
    vaccinationConsent: 'Vaccination consent',
    allPatientsWarning:
      'All patients must fill out a consent form before vaccination.',
    explanation:
      'As a contact person, you can fill out the form for yourself and your dependents. You can send a link to the form via SMS to those who fill fill it out themselves.',
    confirm: 'Confirm',
  },
  pl: {
    vaccinationConsent: 'Zgody na szczepienie',
    allPatientsWarning:
      'Wszyscy pacjenci powinni wypełnić formularz zgód przed zaszczepieniem się.',
    explanation:
      'Jako osoba do kontaktu, możesz wypełnić taki formularz za siebie oraz w imieniu osób pozostających pod Twoją prawną opieką. Możesz wysłać link do formularza SMS-em do tych osób, które powinny wypełnić go samodzielnie.',
    confirm: 'Potwierdzam',
  },
}

export default ConsentOverview
