const initialState = []

export const referralSelectedReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'REFERRALS/ADD_REFERRALS_TO_SELECTED_LIST':
      return [...state, action.payload]
    case 'REFERRALS/REMOVE_FROM_REFERRALS_SELECTED_LIST':
      return [...state].filter(
        (element) => element !== action.payload,
      )
    case 'REFERRALS/SELECT_ALL':
      return action.payload
    case 'REFERRALS/UNSELECT_ALL':
      return initialState
    default:
      return state
  }
}

export const bookingSelectedReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'BOOKINGS/ADD_BOOKINGS_TO_SELECTED_LIST':
      return [...state, action.payload]
    case 'BOOKINGS/REMOVE_FROM_BOOKINGS_SELECTED_LIST':
      return [...state].filter(
        (element) => element !== action.payload,
      )
    case 'BOOKINGS/SELECT_ALL':
      return action.payload
    case 'BOOKINGS/UNSELECT_ALL':
      return initialState
    default:
      return state
  }
}
