import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'

import ErrorModal from './calendar_entry/ErrorModal'
import Week from './calendar_entry/Week'
import SelectHL from './forms/fields/SelectHL'

class CalendarEntryEditor extends Component {
  static propTypes = {
    avatar: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    perHourProfit: PropTypes.number.isRequired,
    coverageMapsAvailable: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    assetRootUrl: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      timeData: JSON.parse(this.props.data),
      isSubmitting: [],
      showError: false,
      error: '',
      coverageMap: this.props.coverageMapsAvailable.find(
        (map) => map.category == 'main',
      ),
    }
  }

  handleSaveResponse = (response) => {
    const timeData = this.state.timeData
    const index = _.findIndex(this.state.timeData, {
      date: response[0].date,
    })

    let daysToSplice = response.length
    const daysUntilEndOfArray = timeData.length - index

    if (daysToSplice > daysUntilEndOfArray) {
      daysToSplice = daysUntilEndOfArray
    }

    timeData.splice(
      index,
      daysToSplice,
      ...response.splice(0, daysToSplice),
    )

    this.setState({ timeData: timeData, isSubmitting: [] })
  }

  handleChange = (data, useDefault = true) => {
    if (useDefault) {
      data.hours.forEach(
        (hour) => (hour.coverage_map = this.state.coverageMap),
      )
    }
    const oldTimeData = this.state.timeData

    let paramData = []

    if (Array.isArray(data)) {
      paramData = data
    } else {
      paramData.push(data)
    }

    this.setState({ isSubmitting: paramData })

    $.ajax({
      method: 'PUT',
      data: { timeSlots: JSON.stringify(paramData) },
      url: '/backoffice/calendar_entries',
      success: this.handleSaveResponse,
      error: (response) => {
        this.handleSaveResponse(
          response.responseJSON.calendar_entries,
        )
        this.setState({
          timeData: oldTimeData,
          isSubmitting: [],
          showError: true,
          error: response.responseJSON.error,
        })
      },
    })
  }

  cloneDay = (dayData, days) => {
    const dayIndex = _.findIndex(this.state.timeData, {
      date: dayData.date,
    })
    const clonedDays = []

    for (let idx = 1; idx <= days; idx++) {
      const daysAhead = idx
      const dayToBeCloned = this.state.timeData[dayIndex + daysAhead]

      if (!dayToBeCloned) {
        break
      }

      const dupeSlotsToMaintainSequence = []
      let clonedHours = _.filter(dayToBeCloned.hours, (slot, idx) => {
        const referenceSlot = dayData.hours[idx]

        const selected = ['selected', 'booked']
        const notSelected = ['open', 'unavailable']

        if (
          _.includes(notSelected, referenceSlot.status) &&
          _.includes(selected, slot.status)
        ) {
          return true
        }

        if (
          _.includes(notSelected, referenceSlot.status) &&
          _.includes(notSelected, slot.status)
        ) {
          return false
        }

        if (
          _.includes(selected, referenceSlot.status) &&
          slot.status == 'selected'
        ) {
          dupeSlotsToMaintainSequence.push({
            time: slot.time,
            status: 'dupeSlotToMaintainSequence',
            coverage_map: slot.coverage_map,
          })
          return true
        }

        if (
          _.includes(selected, referenceSlot.status) &&
          _.includes(selected, slot.status)
        ) {
          return false
        }

        if (referenceSlot.status == slot.status) {
          return false
        }

        return true
      })

      clonedHours.forEach((hour) => {
        const referenceHour = dayData.hours.find(
          (referenceHour) => referenceHour.time == hour.time,
        )
        hour.coverage_map = referenceHour.coverage_map
      })

      clonedHours = clonedHours.concat(dupeSlotsToMaintainSequence)

      clonedHours.sort(function (a, b) {
        if (a.status == 'selected') {
          return -1
        }

        if (b.status == 'selected') {
          return 1
        }

        return a.time < b.time ? -1 : 1
      })

      clonedDays.push({
        date: dayToBeCloned.date,
        hours: clonedHours,
      })
    }

    this.handleChange(clonedDays, false)
  }

  weekGroups() {
    return _.groupBy(this.state.timeData, function (day) {
      return 'week-' + moment(day.date).isoWeek()
    })
  }

  nextWeek = (week) => {
    const weekGroups = this.weekGroups()
    const weekGroupKeys = _.keys(weekGroups)
    const idx = weekGroupKeys.indexOf(week)
    return weekGroupKeys[idx + 1]
  }

  prevWeek = (week) => {
    const weekGroups = this.weekGroups()
    const weekGroupKeys = _.keys(weekGroups)
    const idx = weekGroupKeys.indexOf(week)
    return weekGroupKeys[idx - 1]
  }

  coverageSelector() {
    if (this.props.coverageMapsAvailable.length) {
      return (
        <div className="hlform flex-grow-1">
          <SelectHL
            label={'Dekningskart'}
            name="select-coverage-map"
            value={this.state.coverageMap.id.toString()}
            onChange={this.changecoverageMap}
            showValidation={false}
            options={this.props.coverageMapsAvailable.map((map) => ({
              label: map.name,
              value: map.id,
            }))}
          />
        </div>
      )
    } else {
      return ''
    }
  }

  changecoverageMap = (value) => {
    const newCoverageMap = this.props.coverageMapsAvailable.find(
      (map) => map.id == value,
    )
    this.setState({ coverageMap: newCoverageMap })
  }

  render() {
    const weekGroups = this.weekGroups()

    const weekGroupKeys = _.keys(weekGroups)

    const coverageSelector = this.coverageSelector()

    const weeks = _.map(weekGroups, (weekData, yearAndWeek) => {
      return (
        <Week
          key={yearAndWeek}
          week={yearAndWeek}
          nextWeek={this.nextWeek}
          prevWeek={this.prevWeek}
          firstWeek={weekGroupKeys[0]}
          lastWeek={weekGroupKeys[weekGroupKeys.length - 1]}
          weekData={weekData}
          avatar={this.props.avatar}
          onChange={this.handleChange}
          cloneDay={this.cloneDay}
          isSubmitting={this.state.isSubmitting}
          perHourProfit={this.props.perHourProfit}
          defaultCoverageSelector={coverageSelector}
          assetRootUrl={this.props.assetRootUrl}
        />
      )
    })

    return (
      <div>
        {weeks}
        <ErrorModal
          isOpen={this.state.showError}
          error={this.state.error}
          close={() => this.setState({ showError: false })}
        />
      </div>
    )
  }
}

export default CalendarEntryEditor
