export default {
  back: 'Wstecz',
  continue: 'Kontynuuj',
  previous: 'Poprzedni',
  next: 'Następny',
  confirm: 'Potwierdź',
  show: 'Pokazać',
  from: 'From',
  to: 'To',
  cancel: 'Anuluj',
  restart: 'Zacznij od nowa',
  startOver: 'Zacznij od nowa.',
  phoneNumber: 'Numer telefonu',
  thankYou: 'Dziękujemy!',
  sendCode: 'Wyślij kod',
  smsCode: 'kod SMS',
  companyName: 'Nazwa firmy',
  optional: 'Opcjonalny',
  nationalId: 'Numer dowodu/D-nummer',
  fullName: 'Pełne imię i nazwisko',
  name: 'Nazwisko',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
  atTime: 'o godz.',
  person: 'Osoba',
  persons: 'Osób',
  time: 'Czas',
  location: 'Miejsce',
  address: 'Adres',
  numberOf: 'liczba',
  tests: 'testów',
  cancelAppointment: 'Anuluj wizytę',
  organization: 'Organizacja',
  coupon: 'Kupon',
  responsible: 'Osoba odpowiedzialna',
  active: 'Aktiv',
  expired: 'Nieaktywny',
  product: 'Produkt',
  description: 'Opis',
  seeDetails: 'Pokaż szczegóły',
  willBeDeleted: 'zostanie usunięty',
  areYouSure: 'Czy na pewno',
  patient: 'Pacjent',
  patients: 'Pacjenci',
  billingReference: 'opis na fakturze',
  billingEmail: 'E-post for faktura',
  language: 'Język',
  ready: 'Gotowy',
}
