import React, { Component } from 'react'
import PropTypes from 'prop-types'

class UserDataButton extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    price: PropTypes.number,
    disabledOnSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
  }

  handleClick = (e) => {
    e.preventDefault()

    if (this.props.isDisabled) {
      return this.props.disabledOnSubmit()
    }

    this.props.onSubmit()
  }

  disabledClass() {
    if (this.props.isDisabled) {
      return 'disabled'
    }

    return ''
  }

  render() {
    return (
      <button
        className={`btn btn-secondary btn-block ${this.disabledClass()}`}
        onClick={this.handleClick}
        id="booking_button"
      >
        {this.props.price == 0 ? 'Fullfør' : 'Neste'}
      </button>
    )
  }
}

export default UserDataButton
