import constructRouteParams from '../../map/utils/routeParams'
import { getRoute } from '../../../api/directionService'
import moment from 'moment-timezone'

export const updateCoverageData = (id) => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: 'OPTION/SERVICE_PROVIDERS_COVERAGE_TOGGLE',
      payload: {
        id: id,
        serviceProvidersSelected: state.coverageSpSelected,
        serviceProviders: state.dataset.serviceProviders,
      },
    })
  }
}

export const updateRouteData = ({ spId, optimized }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const dateSelected = state.dateSelected
    const bookings = state.dataset.bookings
    const serviceProvidersList = state.dataset.serviceProviders

    const sp = serviceProvidersList.find((sp) => sp.id === spId)
    const routeData = constructRouteData(sp, bookings, dateSelected)
    const routeParamsArray = constructRouteParams(
      optimized,
      routeData,
    )
    const finalRoute = await directionServiceApiCall(routeParamsArray)
    const newPayload = {
      spId: spId,
      serviceProviders: state.dataset.serviceProviders,
      route: finalRoute[0],
    }
    if (optimized === true) {
      dispatch({
        type: 'OPTION/SERVICE_PROVIDERS_ROUTE_OPTIMIZED_TOGGLE',
        payload: {
          ...newPayload,
          serviceProvidersSelected: state.routeOptimizedSpSelected,
        },
      })
    } else {
      dispatch({
        type: 'OPTION/SERVICE_PROVIDERS_ROUTE_TOGGLE',
        payload: {
          ...newPayload,
          serviceProvidersSelected: state.routeSpSelected,
        },
      })
    }
  }
}

const directionServiceApiCall = async (routeParamsArray) => {
  const responses = await getRoute(routeParamsArray)
  return responses
}

const constructRouteData = (sp, bookings, dateSelected) => {
  const bookingsOfTheDay = bookings
    .filter(
      (booking) =>
        bookings.length > 0 &&
        sp.id === booking.service_provider_id &&
        dateSelected ===
          moment(booking.begins_at)
            .tz('Europe/Oslo')
            .startOf('day')
            .format('yyyy-MM-DD'),
    )
    .map((booking) => {
      const { lat, lng } = booking
      return {
        lat,
        lng,
      }
    })

  const { id, first_name, last_name, lat, lng, color } = sp
  return [
    {
      id,
      lat,
      lng,
      color,
      spName: `${first_name} ${last_name}`,
      bookings: [...bookingsOfTheDay],
    },
  ]
}
