import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadGoogle from '../lib/loadGoogle'
import TextInput from './forms/fields/TextInput'
import {
  v_fullGoogleAddress,
  v_partialGoogleAddress,
} from '../lib/validations'
import {
  getTestData,
  generateSessionToken,
  callGoogleAutocomplete,
  sanitizeAddress,
  getPlaceDetails,
} from '../lib/googleAutocompletePlaces'

const GooglePlacesAutocompleteInput = ({
  googleMapsApiKey,
  onComplete,
  name = 'address-search',
  label = 'Gatenavn',
  initialValue = '',
  isSingleLine = false,
  required = true,
  showValidation = true,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [sessionToken, setSessionToken] = useState('')
  const [address, setAddress] = useState({})
  const [predictions, setPredictions] = useState([])
  const [selectedPrediction, setSelectedPrediction] = useState({})
  const [writtenValue, setWrittenValue] = useState(initialValue)
  const [numberInputValue, setNumberInputValue] = useState('')
  const [errorMessages, setErrorMessages] = useState({})
  const [candidate, setCandidate] = useState(0)
  const [hasSelected, setHasSelected] = useState(false)
  const [showNumberInput, setShowNumberInput] = useState(false)
  const [timeOutId, setTimeOutId] = useState('')
  const [focusedOnNumber, setFocusedOnNumber] = useState(false)

  const errorNoSelected = 'Velg en adresse fra listen'

  const errorUncomplete = 'Skriv inn gatenr'

  const checkForIntercomCollision = () => {
    const inputsElements = document.querySelector('#pac-fields')
    const intercomElement = document.querySelector(
      '#hl-custom-front-launcher',
    )
    if (!intercomElement)
      if (areElementsOverlapping(inputsElements, intercomElement)) {
        intercomElement.classList.add('hide')
      } else {
        intercomElement.classList.remove('hide')
      }
  }

  const areElementsOverlapping = (firstElement, secondElement) => {
    if (!firstElement || !secondElement) return

    const bottom1 = firstElement.getBoundingClientRect().bottom
    const firstElementHeigth = firstElement.getBoundingClientRect()
      .height
    const bottom2 = secondElement.getBoundingClientRect().bottom
    const top1 = firstElement.getBoundingClientRect().top
    const top2 = secondElement.getBoundingClientRect().top
    return (
      (top2 < top1 + 20 && top2 > top1 - firstElementHeigth) ||
      (bottom2 > bottom1 - 20 &&
        bottom2 < bottom1 + firstElementHeigth)
    )
  }

  useEffect(() => {
    if (!!window.google && !!window.google.maps) {
      setIsLoading(false)
      return
    }

    async function callGoogle() {
      await loadGoogle(googleMapsApiKey)
      setIsLoading(false)
    }
    callGoogle()

    window.addEventListener('resize', checkForIntercomCollision)
    window.addEventListener('scroll', checkForIntercomCollision)

    return () => {
      window.removeEventListener('resize', checkForIntercomCollision)
      window.removeEventListener('scroll', checkForIntercomCollision)
    }
  }, [googleMapsApiKey])

  const onWrittenChange = (value, _valid, _name) => {
    setWrittenValue(value)
  }

  const onNumberChange = (value, _valid, _name) => {
    setNumberInputValue(value)
    if (/\d\w*/.test(value)) {
      delete errorMessages.errorUncomplete
    } else {
      setErrorMessages({
        ...errorMessages,
        errorUncomplete: errorUncomplete,
      })
    }
  }

  useEffect(() => {
    if (isLoading) return
    if (hasSelected) {
      setHasSelected(false)
      return
    }

    const tokenToUse =
      sessionToken == '' ? generateSessionToken() : sessionToken

    setSessionToken(tokenToUse)

    const newTimeOutId = callGoogleAutocomplete(
      userInput,
      tokenToUse,
      onReceivedPredictions,
    )
    setTimeOutId(newTimeOutId)
  }, [writtenValue, numberInputValue])

  const userInput = `${writtenValue}  ${numberInputValue}`

  const onReceivedPredictions = (predictions) => {
    setPredictions(predictions)
    clearTimeout(timeOutId)
  }

  const handlePlaceChoice = (e, place) => {
    e.preventDefault()
    if (!!errorMessages.errorNoSelected)
      delete errorMessages.errorNoSelected
    setSelectedPrediction(place)
    setCandidate(0)
  }

  useEffect(() => {
    if (!Object.keys(selectedPrediction).length) return

    getPlaceDetails(
      selectedPrediction.place_id,
      sessionToken,
      getAddressFromPlace,
    )
    setPredictions([])
    setSessionToken('')
  }, [selectedPrediction])

  const getAddressFromPlace = (place) => {
    const newAddress = sanitizeAddress(place)
    setAddress(newAddress)
    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'address_search',
      eventAction: 'select',
      eventLabel: 'address_suggestion',
      eventValue: 10,
      nonInteraction: false,
    })
  }

  useEffect(() => {
    if (!Object.keys(address).length) return
    setHasSelected(true)

    const addressFormatted = address.street
      ? `${address.street} ${address.number ? address.number : ''}, ${
          address.zip ? address.zip : ''
        } ${address.city ? address.city : ''}`
      : address.formatted

    let newWrittenValue = ''

    switch (true) {
      case !!showNumberInput && !isSingleLine:
        newWrittenValue = address.street || address.formatted
        break
      case !!showNumberInput:
        newWrittenValue = `${address.street || address.formatted}, ${
          address.zip ? address.zip : ''
        } ${address.city ? address.city : ''}`
        break
      case isSingleLine:
        newWrittenValue = addressFormatted
        break
      default:
        newWrittenValue = `${address.street || address.formatted} ${
          address.number ? address.number : ''
        }`
        break
    }

    setWrittenValue(newWrittenValue)
    if (showNumberInput) setNumberInputValue(address.number || '')

    const valid = isSingleLine
      ? v_fullGoogleAddress(address)
      : v_partialGoogleAddress(address)

    if (valid) {
      setErrorMessages({})
    } else {
      if (showNumberInput)
        setErrorMessages({
          ...errorMessages,
          errorUncomplete: errorUncomplete,
        })
      dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'address_search',
        eventAction: 'error',
        eventLabel: errorUncomplete,
        eventValue: 1,
        nonInteraction: false,
      })
    }

    if (!address.number) setShowNumberInput(true)

    onComplete(address, valid, name)
  }, [address, name, isSingleLine])

  const onBlurInput = (e, checkValidity = false) => {
    setFocusedOnNumber(false)

    if (window.__env == 'test') {
      hackTestEnv()
      return
    }

    const isStillValid = checkStillValid()
    if (!isStillValid && checkValidity) {
      setErrorMessages({
        ...errorMessages,
        errorNoSelected: errorNoSelected,
      })
      onComplete('', false, name)
    }
    setPredictions([])
  }

  const hackTestEnv = () => {
    const testAddress = getTestData(writtenValue)
    if (!testAddress?.city) {
      setErrorMessages({
        ...errorMessages,
        errorUncomplete: errorUncomplete,
      })
      onComplete(testAddress, false, name)
    } else {
      onComplete(testAddress, true, name)
    }
  }

  const checkStillValid = () => {
    if (!address.number) return false

    const writtenWords = userInput.split(/\s+|\,/).filter((e) => e)
    const elementsToCheck = isSingleLine
      ? [address.number, address.zip]
      : [address.number || initialValue?.match(/\d+\w*/)[0]]
    return elementsToCheck.every((el) => writtenWords.includes(el))
  }

  const onFocusNumber = () => {
    setFocusedOnNumber(true)
  }

  const trackFocus = () => {
    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'address_search',
      eventAction: 'focus',
      eventValue: 10,
      nonInteraction: false,
    })
  }

  const onKeyNav = (e) => {
    if (predictions?.length < 1) return

    switch (e.key) {
      case 'ArrowUp':
        setCandidate(
          candidate > 0 ? candidate - 1 : predictions.length - 1,
        )
        break
      case 'ArrowDown':
        setCandidate(
          candidate < predictions.length - 1 ? candidate + 1 : 0,
        )
        break
      case 'Enter':
        const newVal = predictions[candidate]
        setSelectedPrediction(newVal)
        setCandidate(0)
        break
    }
  }

  const inputStreetClass = () => {
    return `pac-input-street flex-grow-2 ${
      showNumberInput && 'pac-input-street-shorter'
    }`
  }

  const inputStreetNumberClass = () => {
    return `pac-input-number flex-grow-1 ${
      showNumberInput && `appeared`
    }`
  }

  return (
    <>
      {!isLoading && (
        <>
          <div
            onKeyUp={onKeyNav}
            id="pac-fields"
            className="typeahead-input d-flex"
          >
            <TextInput
              id="pac-input"
              type="text"
              label={label}
              name={name}
              className={inputStreetClass()}
              style={{ marginBottom: '0px' }}
              onChange={onWrittenChange}
              onFocus={trackFocus}
              onBlur={onBlurInput}
              autoComplete="off"
              spellCheck="off"
              autoCorrect="off"
              value={writtenValue}
              shadow={false}
              isRequired={required}
              showValidation={showValidation}
              validate={
                isSingleLine
                  ? v_fullGoogleAddress
                  : v_partialGoogleAddress
              }
            />
            {showNumberInput && (
              <TextInput
                className={inputStreetNumberClass()}
                style={{ marginBottom: '0px' }}
                type="text"
                name="pac-input-number"
                label="Nr"
                value={numberInputValue}
                onChange={onNumberChange}
                onBlur={(e) => onBlurInput(e, true)}
                onFocus={onFocusNumber}
                shadow={false}
                showValidation={false}
                autoFocus
              />
            )}
            {predictions.length > 0 && (
              <div
                className="typeahead-results"
                style={{ left: '0', top: '80%' }}
              >
                <ul>
                  {predictions?.map((prediction, index) => (
                    <li
                      key={prediction?.place_id}
                      onMouseDown={(e) =>
                        handlePlaceChoice(e, prediction)
                      }
                      className={index == candidate ? 'current' : ''}
                      style={{
                        textAlign: focusedOnNumber ? 'right' : 'left',
                      }}
                    >
                      {prediction?.description || 'Not found'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            {Object.keys(errorMessages).length > 0 && (
              <>
                <p className="text-danger mb-0 mt-0">
                  {errorMessages.errorNoSelected || ''}
                </p>
                <p className="text-danger mb-0 mt-0">
                  {errorMessages.errorUncomplete || ''}
                </p>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

GooglePlacesAutocompleteInput.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  isSingleLine: PropTypes.bool,
  required: PropTypes.bool,
  showValidation: PropTypes.bool,
}

export default GooglePlacesAutocompleteInput
