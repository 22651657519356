import _ from 'lodash'

const constructRouteParams = (optimized, routeData) => {
  const routeParamsArray = []
  if (routeData !== null) {
    routeData.forEach((route) => {
      if (route.bookings !== undefined && route.bookings.length > 0) {
        const waypoints = route.bookings.map((booking, i) => ({
          location: { lat: booking.lat, lng: booking.lng },
          stopover: true,
        }))

        const routeParams = {
          origin: { lat: route.lat, lng: route.lng },
          destination: { lat: route.lat, lng: route.lng },
          travelMode: 'DRIVING',
          // transitOptions: TransitOptions,
          //  You must supply an API key or a Google Maps Platform Premium Plan client ID when loading the API if you want to include a drivingOptions field in the DirectionsRequest.
          // drivingOptions: DrivingOptions,
          unitSystem: google.maps.UnitSystem.METRIC,
          waypoints: waypoints,
          optimizeWaypoints: optimized,
          provideRouteAlternatives: false,
          // avoidFerries: true,
          // avoidHighways: false,
          // avoidTolls: false,
          // region: 'NO',
        }
        route.routeParams = routeParams

        routeParamsArray.push(_.omit(route, 'bookings'))
      }
    })
  }
  return routeParamsArray
}

export default constructRouteParams
