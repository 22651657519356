import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadGoogle from '../lib/loadGoogle'

import getCurrentPosition from '../lib/getCurrentPosition'

class BookingLocation extends Component {
  static propTypes = {
    googleMapsApiKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    clickToLoad: PropTypes.bool,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    showDirections: PropTypes.bool,
  }

  static defaultProps = {
    showDirections: false,
    clickToLoad: false,
  }

  constructor(props) {
    super(props)
    this.state = { mapLoaded: false }
  }

  componentDidMount() {
    if (!this.props.clickToLoad) {
      this.loadMap()
    }
  }

  loadMap = async () => {
    if (!window.google) {
      await loadGoogle(this.props.googleMapsApiKey)
    }

    if (this.hasLocation()) {
      this.setState({ mapLoaded: true })
      this.showMap()
    }
  }

  hasLocation() {
    return (
      this.props.location &&
      this.props.location.lat &&
      this.props.location.lng
    )
  }

  getDirectionsOrigin = async () => {
    const options = { timeout: 5000 }
    const result = await getCurrentPosition(options)
    if (result && result.coords) {
      return {
        lat: result.coords.latitude,
        lng: result.coords.longitude,
      }
    }
    return null
  }

  async showMap() {
    const { lat, lng } = this.props.location
    this.map = new google.maps.Map(this.mapContainer, {
      center: { lat, lng },
      zoom: 13,
      disableDefaultUI: true,
      draggable: false,
    })
    var icon = {
      path:
        'M38,36 L32,42 L26,36 L4.00348663,36 C1.79242202,36 0,34.2147544 0,32.0098214 L0,3.99017859 C0,1.7864638 1.79537646,0 4.00348663,0 L59.9965134,0 C62.207578,0 64,1.7852456 64,3.99017859 L64,32.0098214 C64,34.2135362 62.2046235,36 59.9965134,36 L38,36 Z',
      fillColor: '#8100f8',
      fillOpacity: 1,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1,
      size: new google.maps.Size(64, 42),
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(64 / 2, 18),
      anchor: new google.maps.Point(64 / 2, 42),
    }

    this.marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      icon: icon,
      label: {
        text: this.props.label,
        color: 'white',
        fontSize: '18px',
        fontFamily: '"Fabriga Regular", Courier,Monospace',
      },
    })
    if (this.props.showDirections) {
      const origin = await this.getDirectionsOrigin()
      if (origin) {
        this.calculateAndShowDirections(origin, { lat, lng })
      } else {
        console.warn(
          'Cannot show directions, failed to get current position',
        )
      }
    }
  }

  calculateAndShowDirections(origin, destination) {
    const directionsService = new google.maps.DirectionsService()
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      map: this.map,
    })
    // directionsDisplay.setPanel(document.getElementById('js-directions-panel'));

    directionsService.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      },
      (response, status) => {
        if (status === 'OK') {
          response.routes.forEach((route, i) => {
            // route.legs.forEach((leg) => {
            //   new google.maps.InfoWindow({
            //     map: this.map,
            //     content: `${leg.distance.text}, ${leg.duration.text}`,
            //     position: route.overview_path[Math.round(route.overview_path.length / 2)],
            //   });
            // });
            this.DirectionsRenderer = new google.maps.DirectionsRenderer(
              {
                map: this.map,
                directions: response,
                routeIndex: i,
                polylineOptions: {
                  // strokeColor: '#4ab5dd',
                  strokeColor: '#383838',
                  strokeOpacity: i === 0 ? 0.7 : 0.5,
                  strokeWeight: 5,
                },
              },
            )
          })
        } else {
          console.warn('Directions request failed due to ', status)
        }
      },
    )
  }

  render() {
    return (
      <div
        style={{ height: this.state.mapLoaded ? '500px' : 'auto' }}
      >
        {this.props.clickToLoad && !this.state.mapLoaded && (
          <button
            className="btn btn-outline-primary"
            onClick={this.loadMap}
          >
            Vis
          </button>
        )}
        <div
          ref={(ref) => {
            this.mapContainer = ref
          }}
          style={{ height: this.state.mapLoaded ? '100%' : '0' }}
        />
      </div>
    )
  }
}

export default BookingLocation
