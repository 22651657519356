import gql from '../../api/graphql'

const netError = (e) => ({
  status: e.request?.status,
  netError: true,
  errors: e,
})

const updateDigimedSettings = async (settings) => {
  const jsonizedSettings = JSON.stringify(settings).replace(
    /"/g,
    '\\"',
  )

  try {
    const response = await gql(`
        mutation {
          updateDigimedSettings(settings: "${jsonizedSettings}") {
           value
          }
        }
      `)
    return response
  } catch (e) {
    return netError(e)
  }
}

const fetchDigimedSettings = async () => {
  try {
    const response = await gql(`
            query {
            settings(key: "digimed_enabled_channels") {
                value
            }
            }
        `)
    return response
  } catch (e) {
    return netError(e)
  }
}

export { updateDigimedSettings, fetchDigimedSettings }
