import React from 'react'
import PropTypes from 'prop-types'

import InfoBox from './InfoBox'
import AddressSearchForm from './AddressSearchForm'

const Frontpage = (props) => {
  const text = props.redirectLink
    ? {
        title: 'Hva er din adresse?',
        body: 'Bekreft adressen vi skal møte opp på. Søk med feltet over og velg ett av resultatene.',
      }
    : {
        title: 'Finn en ledig time',
        body: 'Skriv inn adresse for å finne ledige timer i ditt område.',
      }

  const redirectTo = props.redirectLink
    ? props.redirectLink
    : '/booking_wizard/start'

  return (
    <div className="text-xs-center frontpage">
      <h1 className="frontpage__title">{text.title}</h1>
      <p className="frontpage__sub-title">{text.body}</p>
      <AddressSearchForm
        redirectTo={redirectTo}
        googleMapsApiKey={props.googleMapsApiKey}
      />
      <InfoBox className="frontpage__info-box" />
    </div>
  )
}

Frontpage.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  redirectLink: PropTypes.string,
}

export default Frontpage
