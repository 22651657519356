import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class VerticalRow extends Component {
  static propTypes = {
    rowData: PropTypes.array.isRequired,
    handleClickRow: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }

  rowClass() {
    const hours = this.props.rowData

    if (_.every(hours, { status: 'open' })) {
      return ''
    } else if (
      _.every(hours, function (h) {
        return h.status == 'booked' || h.status == 'selected'
      })
    ) {
      return 'multi-select-button--active'
    } else if (_.every(hours, { status: 'unavailable' })) {
      return 'multi-select-button--unavailable'
    } else if (
      _.every(hours, function (h) {
        return h.status == 'open' || h.status == 'unavailable'
      })
    ) {
      return ''
    } else {
      return 'multi-select-button--partially-active'
    }
  }

  handleClick = () => {
    if (!this.props.isSubmitting) {
      const hours = this.props.rowData

      if (!_.every(hours, { status: 'unavailable' })) {
        this.props.handleClickRow(this.props.rowData)
      }
    }
  }

  render() {
    return (
      <div
        className={`multi-select-button ${this.rowClass()}`}
        onClick={this.handleClick}
      ></div>
    )
  }
}

export default VerticalRow
