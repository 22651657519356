import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, translate } from '../lib/locale/'
import gql from '../api/graphql'
import { parseQueryResponse } from '../api/graphqlExtended'

import EmailInput from './forms/fields/EmailInput'
import AddressInput from './forms/fields/AddressInput'
import ZipInput from './forms/fields/ZipInput'
import CityInput from './forms/fields/CityInput'
import CompanyNameInput from './forms/fields/CompanyNameInput'
import OrganizationNumberInput from './forms/fields/OrganizationNumberInput'
import InvoiceReference from './forms/fields/InvoiceReference'
import RadioButtons from './forms/fields/RadioButtons'
import NameInput from './forms/fields/NameInput'
import PhoneInput from './forms/fields/PhoneInput'

const DEFAULT_ORG = {
  billingMethod: 'email',
  billingDueBy: 30,
  mailingAddress: {},
  visitingAddress: {},
}

const OrganizationInputs = ({
  highlightErrors = false,
  organization = {},
  contactPerson = {},
  onChange,
  askVisitingAddress = false,
  organizationAutocomplete = false,
  hasVaccineText = false,
  isReferral = false,
}) => {
  const [organizationInfo, setOrganizationInfo] = useState({
    ...organization,
  })
  const [contactPersonInfo, setcontactPersonInfo] = useState({
    ...contactPerson,
  })
  const [validations, setValidations] = useState({
    organization: {
      name: !!organization?.name,
      orgNr: !!organization?.org_nr || !!organization?.orgNr,
      address:
        !!organization?.mailing_address?.address ||
        !!organization?.mailingAddress?.address,
      zip:
        !!organization?.mailing_address?.zip ||
        !!organization?.mailingAddress?.zip,
      city:
        !!organization?.mailing_address?.city ||
        !!organization?.mailingAddress?.city,
      billingDueBy: true,
    },
    contactPerson: {
      email: !!contactPerson?.email,
      name: !!contactPerson?.name,
      phoneNumber:
        !!contactPerson?.phone_number || !!contactPerson?.phoneNumber,
    },
  })

  useEffect(() => {
    if (
      !organization?.billingMethod ||
      !organization?.billing_method
    ) {
      setOrganizationInfo({
        ...organization,
        billingMethod: 'email',
        billingDueBy: 30,
      })
    } else {
      setOrganizationInfo(organization)
    }
  }, [])

  useEffect(() => {
    if (!validations.organization.orgNr) return
    if (!organizationAutocomplete) return

    fetchOrganizationByOrgNr()
  }, [validations.organization.orgNr])

  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  const formIsValid = () => {
    return [
      Object.entries(validations.organization).every(
        ([_key, value]) => value == true,
      ),
      Object.entries(validations.contactPerson).every(
        ([_key, value]) => value == true,
      ),
    ].every(Boolean)
  }

  const fetchOrganizationByOrgNr = async () => {
    const query = `query {
        organization(orgNr: "${
          organizationInfo.orgNr || organizationInfo.org_nr
        }") {
          id
          name
          orgNr
          mailingAddress {
            address
            city
            zip
          }
          visitingAddress {
            address
            city
            zip
          }
        }
      }`
    const response = await gql(query)
    const receivedOrg = parseQueryResponse({
      response: response,
      resourceName: 'organization',
      multiple: false,
    })
    if (receivedOrg?.id) {
      setOrganizationInfo({ ...organizationInfo, ...receivedOrg })
      setValidations({
        ...validations,
        organization: {
          ...validations.organization,
          name: true,
          address: true,
          zip: true,
          city: true,
          orgNr: true,
        },
      })
    } else {
      const orgNr = organizationInfo.orgNr || organization.org_nr
      setOrganizationInfo({
        orgNr: orgNr,
        ...DEFAULT_ORG,
      })
    }
  }

  const getValueOrganization = (value) => {
    let newValue = ''
    switch (true) {
      case value.includes('name'):
        newValue = 'name'
        break
      case value.includes('org_nr'):
        newValue = 'orgNr'
        break
      case value.includes('billing_method'):
        newValue = 'billingMethod'
        break
      case value.includes('billing_email'):
        newValue = 'billingEmail'
        break
      case value.includes('billing_reference'):
        newValue = 'billingReference'
        break
      case value.includes('billing_due_by'):
        newValue = 'billingDueBy'
        break
      case value.includes('city'):
        newValue = 'city'
        break
      case value.includes('zip'):
        newValue = 'zip'
        break
      case value.includes('address'):
        newValue = 'address'
        break
      default:
        newValue
    }
    return newValue
  }

  const getValueContactPerson = (value) => {
    let newValue = ''
    switch (true) {
      case value.includes('name'):
        newValue = 'name'
        break
      case value.includes('phone_number'):
        newValue = 'phoneNumber'
        break
      case value.includes('email'):
        newValue = 'email'
        break
      default:
        newValue
    }
    return newValue
  }

  const handleOrganizationChange = (value, valid, name) => {
    const nameKey = getValueOrganization(name)
    const typeOfAddress = name.includes('mailing_address')
      ? 'mailingAddress'
      : 'visitingAddress'
    if (partOfAddress(nameKey)) {
      setOrganizationInfo({
        ...organizationInfo,
        [typeOfAddress]: {
          ...organizationInfo[typeOfAddress],
          [nameKey]: value,
        },
      })
    } else {
      setOrganizationInfo({ ...organizationInfo, [nameKey]: value })
    }
    if (Object.keys(validations.organization).includes(nameKey)) {
      if (
        partOfAddress(nameKey) &&
        typeOfAddress == 'visitingAddress'
      )
        return
      setValidations({
        ...validations,
        organization: {
          ...validations.organization,
          [nameKey]: valid,
        },
      })
    }
  }

  const partOfAddress = (element) => {
    return ['address', 'zip', 'city'].includes(element)
  }

  useEffect(() => {
    onChange(organizationInfo, 'organization', formIsValid())
  }, [organizationInfo])

  const handleContactPersonChange = (value, valid, name) => {
    const nameKey = getValueContactPerson(name)
    if (Object.keys(validations.contactPerson).includes(nameKey)) {
      setValidations({
        ...validations,
        contactPerson: {
          ...validations.contactPerson,
          [nameKey]: valid,
        },
      })
    }
    setcontactPersonInfo({ ...contactPersonInfo, [nameKey]: value })
  }

  useEffect(() => {
    onChange(contactPersonInfo, 'contactPerson', formIsValid())
  }, [contactPersonInfo])

  return (
    <>
      <OrganizationNumberInput
        name={
          isReferral
            ? 'referral[related_organization][org_nr]'
            : 'organization[org_nr]'
        }
        onChange={handleOrganizationChange}
        highlightErrors={highlightErrors}
        isRequired
        value={
          organizationInfo.org_nr || organizationInfo.orgNr || ''
        }
        className="b-bottom"
      />
      <CompanyNameInput
        name={
          isReferral
            ? 'referral[organization]'
            : 'organization[name]'
        }
        onChange={handleOrganizationChange}
        highlightErrors={highlightErrors}
        isRequired
        value={organizationInfo.name || ''}
        className="b-bottom"
      />
      <div className="formrow align-items-end">
        <div className="col">
          <AddressInput
            name={
              isReferral
                ? 'referral[organization_address][address]'
                : 'organization[mailing_address][address]'
            }
            onChange={handleOrganizationChange}
            label="Postadresse"
            highlightErrors={highlightErrors}
            isRequired
            value={
              organizationInfo.mailingAddress?.address ||
              organizationInfo.mailing_address?.address ||
              ''
            }
            className="mr-sm-2"
          />
        </div>
        <div className="col-sm-4">
          <ZipInput
            name={
              isReferral
                ? 'referral[organization_address][zip]'
                : 'organization[mailing_address][zip]'
            }
            onChange={handleOrganizationChange}
            highlightErrors={highlightErrors}
            isRequired
            value={
              organizationInfo.mailing_address?.zip ||
              organizationInfo.mailingAddress?.zip ||
              ''
            }
            className="ml-sm-2"
          />
        </div>
      </div>
      <CityInput
        name={
          isReferral
            ? 'referral[organization_address][city]'
            : 'organization[mailing_address][city]'
        }
        onChange={handleOrganizationChange}
        highlightErrors={highlightErrors}
        isRequired
        value={
          organizationInfo.mailing_address?.city ||
          organizationInfo.mailingAddress?.city ||
          ''
        }
        className="b-bottom"
      />

      {askVisitingAddress && (
        <>
          <h2 className="groupheading">
            Besøksadresse
            <p>Kun hvis forskjellig fra postadresse</p>
          </h2>
          <div className="formrow align-items-end">
            <div className="col">
              <AddressInput
                name="organization[visiting_address][address]"
                onChange={handleOrganizationChange}
                label="Besøksadresse"
                highlightErrors={highlightErrors}
                value={
                  organizationInfo.visiting_address?.address ||
                  organizationInfo.visitingAddress?.address ||
                  ''
                }
                className="mr-sm-2"
              />
            </div>
            <div className="col-sm-4">
              <ZipInput
                name="organization[visiting_address][zip]"
                label="Postnummer"
                onChange={handleOrganizationChange}
                highlightErrors={highlightErrors}
                value={
                  organizationInfo.visiting_address?.zip ||
                  organizationInfo.visitingAddress?.zip ||
                  ''
                }
                className="ml-sm-2"
              />
            </div>
          </div>
          <CityInput
            name="organization[visiting_address][city]"
            label="Sted"
            onChange={handleOrganizationChange}
            highlightErrors={highlightErrors}
            value={
              organizationInfo.visiting_address?.city ||
              organizationInfo.visitingAddress?.city ||
              ''
            }
            className="b-bottom"
          />
        </>
      )}

      <h2 className="groupheading">
        Kontaktperson {hasVaccineText && 'for ordren'}
      </h2>
      {hasVaccineText && (
        <p>
          Mottaker av ordrebekreftelse. Denne personen må vi kunne
          kontakte om ordre/fakturering.
        </p>
      )}
      <PhoneInput
        name={
          isReferral
            ? 'referral[contact_person_order][phone_number]'
            : 'organization[contact_person_attributes][phone_number]'
        }
        onChange={handleContactPersonChange}
        label="Telefonnummer"
        highlightErrors={highlightErrors}
        isRequired
        value={
          contactPersonInfo.phone_number ||
          contactPersonInfo.phoneNumber ||
          ''
        }
        className="b-bottom"
      />
      <NameInput
        label="Fullt navn på kontaktperson"
        name={
          isReferral
            ? 'referral[contact_person_order][name]'
            : 'organization[contact_person_attributes][name]'
        }
        onChange={handleContactPersonChange}
        highlightErrors={highlightErrors}
        isRequired
        value={contactPersonInfo.name || ''}
        className="b-bottom"
      />
      <EmailInput
        name={
          isReferral
            ? 'referral[contact_person_order][email]'
            : 'organization[contact_person_attributes][email]'
        }
        onChange={handleContactPersonChange}
        label="E-post kontaktperson"
        highlightErrors={highlightErrors}
        isRequired
        value={contactPersonInfo.email || ''}
      />
      <h2 className="groupheading">Fakturadetaljer</h2>
      <RadioButtons
        name={
          isReferral
            ? 'referral[billing_method]'
            : 'organization[billing_method]'
        }
        defaultChecked={
          organizationInfo.billing_method ||
          organizationInfo.billingMethod ||
          'email'
        }
        onChange={handleOrganizationChange}
        options={[
          { value: 'email', label1: 'E-post' },
          { value: 'ehf', label1: 'EHF' },
        ]}
      />
      <fieldset className="mt-2">
        {organizationInfo.billingMethod == 'email' && (
          <EmailInput
            name={
              isReferral
                ? 'referral[billing_email]'
                : 'organization[billing_email]'
            }
            onChange={handleOrganizationChange}
            label="E-post fakturakontakt"
            highlightErrors={highlightErrors}
            autoComplete="off"
            isRequired
            value={
              organizationInfo.billingEmail ||
              organizationInfo.billing_email ||
              ''
            }
            className="b-bottom"
          />
        )}
        <InvoiceReference
          onChange={handleOrganizationChange}
          name={
            isReferral
              ? 'referral[billing_reference]'
              : 'organization[billing_reference]'
          }
          highlightErrors={false}
          isRequired={false}
          value={
            organizationInfo.billingReference ||
            organizationInfo.billing_reference ||
            ''
          }
        />
      </fieldset>
    </>
  )
}

OrganizationInputs.propTypes = {
  highlightErrors: PropTypes.bool,
  organization: PropTypes.object,
  contactPerson: PropTypes.object,
  onChange: PropTypes.func,
  askVisitingAddress: PropTypes.bool,
  organizationAutocomplete: PropTypes.bool,
  hasVaccineText: PropTypes.bool,
  isReferral: PropTypes.bool,
}

export default OrganizationInputs
