import React, { useState } from 'react'
import moment from 'moment-timezone'
import { useSelector, useDispatch } from 'react-redux'
import { dateSelected } from './state/dateSelectedActions'

const DateFilter = (props) => {
  const dispatch = useDispatch()
  const dateSelectedinState = useSelector(
    (state) => state.dateSelected,
  )
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    const dateSelectedByUser = document.getElementById(
      'route-opt-calendar',
    ).value
    dispatch(dateSelected(dateSelectedByUser))
  }

  const showCalendar = (e) => {
    e.preventDefault()
    if (!expanded) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  const displayCalendar = (today) =>
    expanded && (
      <input
        className="form-control"
        type="date"
        html5="true"
        id="route-opt-calendar"
        min={moment()
          .tz('Europe/Oslo')
          .subtract(2, 'days')
          .startOf('day')
          .format('yyyy-MM-DD')}
        max={moment()
          .tz('Europe/Oslo')
          .add(7, 'days')
          .startOf('day')
          .format('yyyy-MM-DD')}
        value={dateSelectedinState}
        onChange={handleChange}
      />
    )

  const today = moment()
    .tz('Europe/Oslo')
    .startOf('day')
    .format('yyyy-MM-DD')

  return (
    <div className="date-form mb-3">
      <div className="date-form__selectBox" onClick={showCalendar}>
        <i className="far fa-calendar-alt"></i>
        Select a date
      </div>
      <div className="date-form__date-selector">
        {displayCalendar(today)}
      </div>
    </div>
  )
}

export default DateFilter
