import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class NameInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func,
    isValid: PropTypes.bool,
    initialValue: PropTypes.string,
    showError: PropTypes.bool,
    name: PropTypes.string,
  }

  validate = (text) => text.length > 2

  wrapperClass = () =>
    this.props.isValid
      ? 'form-group booking-form__valid-icon'
      : 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'

  errorClass = () =>
    this.props.showError && !this.props.isValid ? 'error' : ''

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      name: this.props.name || 'booking[user_attributes][name]',
      placeholder: 'Fullt navn',
    }

    return (
      <div className={this.wrapperClass()}>
        <div
          className={`form-control booking-form__icon-wrapper ${
            props.disabled ? 'disabled' : null
          } ${this.errorClass()}`}
        >
          <label className="sr-only">{props.placeholder}</label>
          <ValidatedInput {...props} />
        </div>
      </div>
    )
  }
}

export default NameInput
