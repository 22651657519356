import React from 'react'
import PropTypes from 'prop-types'
import NameInput from '../forms/fields/NameInput'
import NationalIdInput from '../forms/fields/NationalIdInput'

export const AppointmentPatientItem = ({ groupTitle, index, t }) => {
  const nName = `patient_${index}_name`
  const nNId = `patient_${index}_nationalId`

  return (
    <div className="hlform patientFields mt-4">
      {groupTitle && (
        <p className="groupheading">{`${groupTitle} ${index + 1}`}</p>
      )}
      <NameInput
        name={nName}
        id={nName}
        autoComplete="off"
        label={t('fullName')}
        isRequired={true}
      />
      <NationalIdInput
        name={nNId}
        id={nNId}
        autoComplete="off"
        label={t('nationalId')}
        isRequired={true}
      />
    </div>
  )
}

AppointmentPatientItem.propTypes = {
  groupTitle: PropTypes.string,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
}
