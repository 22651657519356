import React from 'react'
import PropTypes from 'prop-types'

const Switch = ({ isOn, onChange }) => {
  const handleToggle = (e) =>
    onChange && onChange(e.target.value == 'on')

  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  )
}

Switch.propTypes = {
  isOn: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Switch
