import React from 'react'
import PropTypes from 'prop-types'

const QrCode = ({ label, image, url, largeUrl }) => (
  <>
    <a href={largeUrl} target="_blank" rel="noreferrer">
      <img
        src={`data:image/jpeg;base64,${image}`}
        alt={label}
        className="qr-preview"
      />
    </a>
    <p className="small m-0">
      <a href={url} target="_blank" rel="noreferrer">
        {label}
      </a>
    </p>
  </>
)

QrCode.propTypes = {
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  largeUrl: PropTypes.string.isRequired,
}

export default QrCode
