import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import StaticMap from '../StaticMap'
import moment from 'moment-timezone'
import { cancelAppointment } from './queries'

import { appointmentShape } from './propShapes'

const ExistingAppointments = ({
  onComplete,
  appointments,
  onError,
  reset,
  t,
}) => {
  const [
    previousAppointmentCancelled,
    setPreviousAppointmentCancelled,
  ] = useState(false)

  const onCancelledPreviousAppointment = () => {
    setPreviousAppointmentCancelled(true)
  }
  return (
    <section>
      <h1>{t('heading', s)}</h1>
      <h3 color="error">{t('mustCancelPrevious', s)}</h3>
      {appointments.map((appointment) => (
        <Appointment
          key={`${appointment.id}`}
          appointment={appointment}
          onCancelledPreviousAppointment={
            onCancelledPreviousAppointment
          }
          onError={onError}
          t={t}
        />
      ))}
      <div className="d-flex justify-content-between my-5">
        <Button
          className="btn-link"
          label={t('restart')}
          onClick={reset}
        />
        <Button
          disabled={
            appointments.length !== 0 && !previousAppointmentCancelled
          }
          className="btn-primary"
          label={t('proceed', s)}
          onClick={onComplete}
        />
      </div>
    </section>
  )
}

ExistingAppointments.propTypes = {
  onComplete: PropTypes.func.isRequired,
  appointments: PropTypes.arrayOf(appointmentShape).isRequired,
  onError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const Appointment = ({
  appointment,
  onCancelledPreviousAppointment,
  onError,
  t,
}) => {
  const [cancelled, setCancelled] = useState(false)

  const onCancelAppointment = async () => {
    const response = await cancelAppointment(appointment.id, true)
    if (response.errors) {
      onError(response.errors)
    } else {
      setCancelled(true)
      onCancelledPreviousAppointment()
    }
  }

  const renderDateString = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    }`
  }

  const hasServiceCenter = !!appointment.serviceCenter

  const parentElements = {
    street: hasServiceCenter
      ? appointment.serviceCenter.street
      : appointment.booking.address,
    zip: hasServiceCenter
      ? appointment.serviceCenter.zip
      : appointment.booking.zip,
    city: hasServiceCenter
      ? appointment.serviceCenter.city
      : appointment.booking.city,
    lat: hasServiceCenter
      ? appointment.serviceCenter.lat
      : appointment.booking.lat,
    lng: hasServiceCenter
      ? appointment.serviceCenter.lng
      : appointment.booking.lng,
  }

  return (
    <div className="my-4">
      {cancelled && <p>{t('cancelledConfirmation', s)}</p>}
      {!cancelled && (
        <>
          <div className="row my-5">
            <div className="col-md-7">
              <h2 className="h5">{`${renderDateString(
                appointment.date,
              )} ${t('atTime')}${appointment.hour}`}</h2>
              <dl className="dl-table my-3">
                <dt>{t('address')}:</dt>
                <dd>
                  {`
                    ${parentElements.street},
                    ${parentElements.zip}
                    ${parentElements.city}
                  `}
                </dd>
                <dt>{t('persons', null, 's')}:</dt>
                <dd>{appointment.appointmentsPatients.length}</dd>
                <dt>{t('products', s)}</dt>
                <dd>
                  {appointment.order.lineItems
                    .map((li) => li.product.publicName)
                    .join(', ')}
                </dd>
              </dl>
              <Button
                className="btn-danger btn-intext m-0 ishadow"
                onClick={onCancelAppointment}
                label={t('cancelAppointment')}
              />
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              <StaticMap
                lat={parentElements.lat}
                lng={parentElements.lng}
                styles={{ height: '200px' }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

Appointment.propTypes = {
  appointment: appointmentShape.isRequired,
  onError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Du har allerede time',
    mustCancelPrevious:
      'Du må avlyse den gamle timen før du kan lage en ny.',
    proceed: 'Lag ny time',
    cancelledConfirmation:
      'Alt i orden. Denne timen er avlyst og du trenger ikke gjøre mer. Hvis du likevel trenger time, er det bare å lage en ny.',
    products: 'Tjeneste',
  },
  en: {
    heading: 'You have upcoming appointments',
    mustCancelPrevious:
      'You must cancel the previous appointment before creating a new one.',
    proceed: 'Continue with new appointment',
    cancelledConfirmation:
      'Got it. This appointment has been cancelled. Go ahead and create a new appointment if you would like.',
    products: 'Service',
  },
  pl: {
    heading: 'Masz już umówione wizyty',
    mustCancelPrevious:
      'Musisz anulować poprzednią wizytę, zanim umówisz nową.',
    proceed: 'Kontynuuj aby umówić nową wizytę',
    cancelledConfirmation:
      'Umówiona wizyta została właśnie anulowana. Jeśli chcesz, możesz umówić nową wizytę.',
    products: 'Usługi',
  },
}

export default ExistingAppointments
