import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import moment from 'moment-timezone'

import CloseIcon from '../../assets/CloseIcon'
import { capitalizeFirstLetter } from '../../lib/utils'
import ButtonReset from '../ButtonReset'

import ClinicContext from './ClinicContext'
import ClinicPatientList from './ClinicPatientList'
import ClinicPaymentMethod from './ClinicPaymentMethod'

import {
  updatePayment,
  createAppointment,
  deleteAppointmentsPatient,
  addAppointmentsPatient,
  updateAppointmentsPatient,
  updateAppointment,
  cancelAppointment,
  fetchOrder,
  fetchProduct,
  confirmAppointment,
} from './queries'

const determineInitialStateOnWhoIsPaying = (booking, appointment) => {
  return booking['multiple_customers'] &&
    appointment.whoIsPaying === 'company'
    ? 'company'
    : !booking['multiple_customers']
    ? 'company'
    : 'user'
}

const determineBillableOrg = (booking, id) => {
  if (!booking.billable_organizations.length) {
    return {
      name: booking.organizational_customer.name,
      id: booking.organizational_customer.id,
    }
  }

  const org = booking.billable_organizations.find((o) => o.id == id)
  if (org) {
    return {
      name: org.name,
      id: org.id,
    }
  } else {
    return {
      name: booking.organizational_customer.name,
      id: booking.organizational_customer.id,
    }
  }
}

export const ClinicAppointmentForm = ({
  onComplete,
  appointment,
  onCirclesClick,
}) => {
  const {
    clinic,
    t,
    serviceProvider,
    booking,
    addError,
  } = useContext(ClinicContext)

  const [patients, setPatients] = useState([])
  const [order, setOrder] = useState(() => {
    if (appointment && appointment.order) {
      return appointment.order
    } else if (
      appointment &&
      appointment.appointment &&
      appointment.appointment.order
    ) {
      return appointment.appointment.order
    } else {
      return {}
    }
  })

  const [whoIsPaying, setWhoIsPaying] = useState(() =>
    determineInitialStateOnWhoIsPaying(booking, appointment),
  )
  const [employerInfo, setEmployerInfo] = useState(
    determineBillableOrg(booking, appointment.companyName),
  )

  const [paymentIsConfirmed, setPaymentIsConfirmed] = useState(
    !booking['multiple_customers'] || appointment.id !== 'new'
      ? true
      : false,
  )
  const determineNewPatientState = useCallback((appointment) => {
    if (!appointment) {
      return []
    }

    if (
      appointment.appointmentsPatients &&
      appointment.appointmentsPatients.length > 0
    ) {
      return appointment.appointmentsPatients
    }

    if (
      appointment.appointment &&
      appointment.appointment.appointmentsPatients &&
      appointment.appointment.appointmentsPatients.length > 0
    ) {
      return appointment.appointment.appointmentsPatients
    }

    if (appointment) {
      return [appointment]
    }
  }, [])

  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = await fetchProduct(booking.v2_product.id)
        setOrder({
          ...order,
          lineItems: [
            {
              product: product,
              quantity: 1,
            },
          ],
        })
      } catch (e) {
        addError(`Error fetching product: ${e}`)
      }
    }
    if (!appointment.id) {
      getProduct()
    }
  }, [appointment])

  useEffect(() => {
    const newPatientsState = determineNewPatientState(appointment)
    setPatients(newPatientsState)
    const getOrderDetails = async () => {
      try {
        const order = await fetchOrder(appointment.appointment.id)
        setOrder({ ...(order || {}) })
      } catch (e) {
        addError(`Error fetching order: ${e}`)
      }
    }
    if (!order) {
      getOrderDetails()
    }
  }, [addError, appointment, determineNewPatientState])

  const [confirming, setConfirming] = useState(false)
  const [sendCancelMessage, setSendCancelMessage] = useState(true)

  const cartRef = useRef(null)
  const shouldConfirmOrder = useRef(false)

  const canConfirm = () => {
    return (
      !orderIsPaid &&
      !openBySomeoneElse() &&
      !appointmentIsPersisted &&
      order.lineItems.filter((li) => li.quantity > 0).length > 0
    )
  }

  const isEditing = () => {
    return (
      isEditingPatient() || isEditingLineItem() || !paymentIsConfirmed
    )
  }

  const isEditingPatient = () => {
    return (
      [...document.querySelectorAll('button')].filter(
        (el) =>
          el.innerText == 'Avbryt' &&
          el.dataset.type == 'clinic-patient',
      ).length > 0
    )
  }

  const isEditingLineItem = () => {
    return (
      [...document.querySelectorAll('button')].filter(
        (el) =>
          el.innerText == 'Avbryt' &&
          el.dataset.type == 'clinic-line-item',
      ).length > 0
    )
  }

  const appointmentIsComplete = () => {
    if (isEditing()) return false

    const patientsFiltered = patients.filter(
      (p) => p.nationalId != '',
    )
    const lineItemsFiltered = order.lineItems?.filter(
      (li) => li.product.name != '',
    )
    return (
      patientsFiltered.length > 0 &&
      Object.entries(lineItemsFiltered).length > 0
    )
  }

  const openBySomeoneElse = () => {
    return (
      appointment.currentlyEditedById &&
      parseInt(appointment.currentlyEditedById) !== serviceProvider.id
    )
  }

  const appointmentIsPersisted =
    appointment.id !== 'new' &&
    appointment.id !== undefined &&
    appointment.id !== null

  const modifyPatient = (patient, newPatientList) => {
    const isDeleting = patients.length > newPatientList.length
    if (appointmentIsPersisted) {
      modifyAppointmentsPatient(patient, isDeleting)
    } else {
      setPatients(newPatientList)
    }
  }

  const modifyAppointmentsPatient = async (patient, isDeleting) => {
    const isNewPatient = patient.id === 'new'

    try {
      if (isNewPatient) {
        await addAppointmentsPatient(appointment.id, patient)
      } else if (isDeleting) {
        await deleteAppointmentsPatient(patient.id)
      } else {
        await updateAppointmentsPatient(patient)
      }
    } catch (error) {
      let errorMessage = 'Error modifying appointments patient:'

      if (isNewPatient) {
        errorMessage += ' Adding patient'
      } else if (isDeleting) {
        errorMessage += ' Deleting patient'
      } else {
        errorMessage += ' Updating patient'
      }

      errorMessage += ` - ${error}`

      addError(errorMessage)
    }
  }

  useEffect(() => {
    if (
      appointmentIsPersisted ||
      !canConfirm() ||
      !appointmentIsComplete() ||
      !paymentIsConfirmed
    )
      return

    async function confirmAppointmentCall(id) {
      try {
        await confirmAppointment(id)
      } catch (e) {
        addError(`Error in confirmAppointmentCall: ${e}`)
      }
    }

    async function createAppointmentCall() {
      try {
        const products = buildProductList()
        const patients = buildPatientsIdsList()
        const app = await createAppointment(
          clinic,
          booking,
          appointment,
          products,
          patients,
          serviceProvider.id,
        )
        appointment.id = app.id
        confirmAppointmentCall(app.id)
      } catch (e) {
        addError(`Error in createAppointmentCall: ${e}`)
      }
    }
    if (order.id === 'new') {
      createAppointmentCall()
    }
  }, [patients, order, paymentIsConfirmed])

  const onCancel = async () => {
    if (
      confirm('Er du sikker på at du ønsker å avlyse denne timen?')
    ) {
      try {
        onComplete(
          {
            id: appointment.appointment.id || appointment.id,
          },
          true,
        )
        const appointmentId = appointment.appointment
          ? appointment.appointment.id
          : appointment.id
        const appointmentUser = appointment.appointment
          ? appointment.appointment.user.id
          : appointment.user.id

        if (appointmentId && appointmentUser) {
          await cancelAppointment(
            appointmentId,
            appointmentUser,
            sendCancelMessage,
          )
        }
      } catch (error) {
        addError(`Error cancelling appointment: ${error}`)
      }
    }
  }

  const buildPatientsIdsList = () => {
    if (patients.length > 1) {
      return patients.map((p) => p.patientId)
    } else if (booking['multiple_customers']) {
      return [
        {
          patientId: patients[0]?.patientId,
          options: patients[0]?.options,
          whoIsPaying: whoIsPaying,
          companyName: employerInfo.id || null,
        },
      ]
    } else {
      return [
        {
          patientId: patients[0]?.patientId,
          options: patients[0]?.options,
        },
      ]
    }
  }

  const buildProductList = () => {
    const newProducts = order.lineItems.map((li) => li.product)
    return newProducts.map((p) => `"${p.slug}"`).join(',')
  }

  const confirmOrder = () => {
    shouldConfirmOrder.current = true
    setOrder({
      ...order,
      payment: { ...order.payment, state: 'CAPTURED_OR_INVOICED' },
    })
    setConfirming(false)
  }

  const updatePaymentStatus = async () => {
    try {
      await updatePayment(order.payment)
    } catch (e) {
      addError(`Error updating payment status: ${e}`)
    }
  }

  useEffect(() => {
    if (shouldConfirmOrder.current) {
      updatePaymentStatus()
      shouldConfirmOrder.current = false
    }
  }, [order.payment])

  const cancelConfirm = () => setConfirming(false)

  const paid = () => {
    setConfirming(true)
    cartRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const notPaid = () => {
    if (
      confirm(
        'Are you sure you want to set payment state to unfinished?',
      )
    ) {
      shouldConfirmOrder.current = true
      setOrder({
        ...order,
        payment: { ...order.payment, state: 'INCOMPLETE' },
      })
    }
  }

  const orderIsPaid =
    order &&
    order.payment &&
    order.payment.state == 'CAPTURED_OR_INVOICED'

  const onTakeOver = async () => {
    try {
      await updateAppointment({
        ...appointment,
        currentlyEditedAction: 'open',
      })
    } catch (e) {
      addError(`Error updating appointment in onTakeOver: ${e}`)
    }
  }

  const goBack = () => {
    if (isEditing()) {
      if (
        confirm(
          'Skjemaet er ikke lagret. Hvis du trykker OK vil du miste det du har lagt inn!',
        )
      ) {
        onComplete(appointment, true)
      }
    } else {
      onComplete(appointment)
    }
  }

  const userIsPaying = () => {
    return whoIsPaying == 'user'
  }

  const toggleWhoIsPaying = (value) => {
    if (value == 'user') {
      setEmployerInfo(null)
    }
    setWhoIsPaying(value)
  }

  const handleSelectedOrg = (value) => {
    setEmployerInfo(value)
  }

  const statusPayment = () => {
    //Useless at the moment as Payment is not fixed on the backend and !booking condition is in effect in this file for the status and button
    switch (true) {
      case !userIsPaying() && !!booking && orderIsPaid:
        return 'Timen er bekreftet'
      case !userIsPaying() && !!booking:
        return 'Timen er ikke bekreftet'
      default:
        return t(order.payment.state, s)
    }
  }

  const calculateUnitPrice = () => {
    if (appointment.order && appointment.order.id === 'new') return 0
    if (appointment.unitPrice) {
      return appointment.unitPrice
    } else if (appointment.appointment.unitPrice) {
      return appointment.appointment.unitPrice
    } else {
      return 0
    }
  }

  const renderPaymentInfo = () => {
    switch (true) {
      case !!booking &&
        booking['multiple_customers'] &&
        booking.billable_organizations.length > 0 &&
        !appointmentIsPersisted:
        return (
          <ClinicPaymentMethod
            booking={booking}
            handleSelectedOrg={handleSelectedOrg}
            toggleWhoIsPaying={toggleWhoIsPaying}
            whoIsPaying={whoIsPaying}
            setPaymentIsConfirmed={setPaymentIsConfirmed}
          />
        )
      case !!booking && userIsPaying():
        return 'Betales på stedet. Se informasjon om aktuell pris i booking-oversikten.'
      case !!booking && !booking['multiple_customers']:
        return `Faktureres ${booking.organizational_customer.name}`
      case booking['multiple_customers'] && whoIsPaying == 'company':
        return `Faktureres ${employerInfo.name}`
      default:
        return 'Betales på stedet. Se informasjon om aktuell pris i booking-oversikten.'
    }
  }

  const onChangeCheckboxCancel = () => {
    setSendCancelMessage(!sendCancelMessage)
  }

  return (
    <section>
      <div className="row">
        <div className="col-10">
          <h1>{`${
            (appointment.user && appointment.user.name) ||
            appointment.appointment.user.name
          }`}</h1>
        </div>
        <div className="col-2 text-right">
          <Button
            className="btn-link mr-2"
            onClick={goBack}
            label={`⬅ ${t('back')}`}
          ></Button>
        </div>
      </div>
      <p className="preamble">
        {capitalizeFirstLetter(
          `${moment(
            appointment.date || appointment.appointment.date,
          ).format('dddd Do MMMM')} ${
            appointment.hour == 'DROP_IN_NOW'
              ? ''
              : `kl. ${
                  appointment.hour || appointment.appointment.hour
                }`
          } at ${clinic?.title || booking?.id}`,
        )}
      </p>
      {openBySomeoneElse() && (
        <div className="flash flash--attention">
          <p className="small">
            Denne pasienten er åpnet hos en annen person og kan derfor
            ikke endres av deg. Du kan overstyre dette, men da vil da
            vil pasienten låses hos den andre isteden.
          </p>
          <Button
            className="btn-danger"
            label="Ta kontroll"
            onClick={onTakeOver}
          ></Button>
        </div>
      )}

      <div className="row my-5 align-items-center">
        <div className="col-6">
          <ul className="li-reset">
            <h4>Betaling</h4>
            {renderPaymentInfo()}
            {!booking && (
              <li>
                Status:{' '}
                <span
                  className={`${
                    orderIsPaid ? 'text-success' : 'text-danger'
                  } mr-2`}
                >
                  {statusPayment()}
                </span>
                {orderIsPaid && (
                  <ButtonReset onClick={notPaid}>
                    <CloseIcon />
                  </ButtonReset>
                )}
              </li>
            )}
          </ul>
        </div>
        <div className="col-6 text-right">
          <div className="d-flex align-items-center">
            <div style={{ flex: 1, textAlign: 'right' }}>
              <CheckboxHL
                name="cancel-message"
                className="naked"
                value={sendCancelMessage}
                onChange={onChangeCheckboxCancel}
              >
                <span
                  className="small"
                  title="Send pasienten SMS og e-post om at timen er avlyst"
                >
                  Send bekreftelse
                </span>
              </CheckboxHL>
            </div>
            <div style={{ flex: 1, textAlign: 'left' }}>
              <Button
                disabled={openBySomeoneElse() || isEditing()}
                className="btn btn-danger ml-2"
                onClick={onCancel}
                label="Avlys timen"
              />
            </div>
            {!booking && (
              <div style={{ flex: 1 }}>
                <Button
                  className="btn btn-primary"
                  onClick={paid}
                  label={!booking ? 'Betalt' : 'Bekreft timen'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ClinicPatientList
        editable={
          !orderIsPaid &&
          !openBySomeoneElse() &&
          !isEditingPatient() &&
          appointmentIsPersisted
        }
        patients={patients}
        onListModification={modifyPatient}
        appointment={appointment}
        onCirclesClick={onCirclesClick}
      ></ClinicPatientList>
      <div
        className={`shield dark ${confirming ? 'show' : ''}`}
      ></div>
      <div className="clinic-cart" ref={cartRef}>
        {confirming && (
          <div className="danger-box mb-2">
            <div className="row">
              <div className="col-8">
                <ButtonReset onClick={cancelConfirm}>
                  <CloseIcon inverted={true} />
                </ButtonReset>
                <span className="mx-2">{t('confirmHeader', s)}</span>
              </div>
              <div className="col-4 text-right">
                <a
                  className="btn-link inverse mx-2"
                  onClick={confirmOrder}
                >
                  {t('confirmBtnLabel', s)}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

const s = {
  nb: {
    INCOMPLETE: 'Ikke betalt',
    CAPTURED_OR_INVOICED: 'Betalt',
    confirmHeader: 'Bekreft at dette stemmer før du fortsetter',
    confirmBtnLabel: 'Ser bra ut - bekreftes!',
  },
  en: {
    INCOMPLETE: 'Unpaid',
    CAPTURED_OR_INVOICED: 'Paid',
    confirmHeader:
      'Please confirm that the products are correct before proceeding',
    confirmBtnLabel: 'Looks good - confirm!',
  },
}

import { appointmentShape } from '../appointments/propShapes'
import CheckboxHL from '../forms/fields/CheckboxHL'

ClinicAppointmentForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  appointment: appointmentShape,
  onCirclesClick: PropTypes.func,
}
