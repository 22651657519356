import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import BookingListItem from './BookingListItem'

const ServicerProviderListItemDetailed = (props) => {
  const dispatch = useDispatch()

  const serviceProviderInSelectedList = useSelector(
    (state) => state.dataset.serviceProviders,
  ).find((sp) => sp.id === props.serviceProvider.id).checked

  const handleChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10)
    dispatch({
      type: 'DATA/SERVICE_PROVIDERS_TOGGLE',
      payload: value,
    })
  }

  const displayBookings = () => {
    if (props.serviceProvider.bookingsList.length > 0) {
      const SpsToDisplay = props.serviceProvider.bookingsList.map(
        (booking) => {
          const formattedDate = moment(booking.begins_at).format(
            'ddd Do MMM HH:mm',
          )
          return (
            <BookingListItem
              key={booking.id}
              booking={{ ...booking, formattedDate }}
            />
          )
        },
      )
      return SpsToDisplay
    }
  }

  const avatarStyle = {
    height: '100px',
    width: '100px',
    display: 'block',
    textAlign: 'center',
    padding: '0.6rem',
    borderRadius: '50%',
  }

  return (
    <div
      key={props.serviceProvider.id}
      className="item-form__card d-flex align-items-center my-1"
    >
      <div className="item-form__checkbox-wrapper">
        <input
          type="checkbox"
          name={props.serviceProvider.id}
          value={props.serviceProvider.id}
          onChange={handleChange}
          checked={serviceProviderInSelectedList}
          className="item-form__checkbox-input mr-1"
        />
      </div>
      <div className="list-group-item list-group-item--no-border-vertical flex-grow-1">
        <div className="row">
          {props.serviceProvider.avatar_url ? (
            <img
              src={props.serviceProvider.avatar_url}
              style={avatarStyle}
            />
          ) : (
            <div style={avatarStyle}>
              {props.serviceProvider.first_name[0]}{' '}
              {props.serviceProvider.last_name[0]}
            </div>
          )}
          <div className="d-flex flex-column">
            <div>
              <strong>#id: {props.serviceProvider.id}</strong>
            </div>
            <div>
              <strong>Navn: </strong>
              <span>
                {props.serviceProvider.first_name}{' '}
                {props.serviceProvider.last_name}
              </span>{' '}
            </div>
            <div>
              <strong>Antall bestillinger: </strong>
              <span>{props.serviceProvider.bookingsList.length}</span>
            </div>
          </div>
        </div>
        {displayBookings()}
      </div>
    </div>
  )
}

ServicerProviderListItemDetailed.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
}

export default ServicerProviderListItemDetailed
