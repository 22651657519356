import React from 'react'
import TextInput from './TextInput'
import { v_nationalId } from '../../../lib/validations'
import { f_nationalId } from '../../../lib/filters'

const NationalIdInput = (props) => {
  return <TextInput {...props} />
}

NationalIdInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'national_id',
  label: 'Personnummer/D-nummer',
  maxLength: 11,
  autoComplete: 'off',
  inputMode: 'numeric',
  filter: f_nationalId,
  validate: v_nationalId,
}

NationalIdInput.propTypes = TextInput.propTypes

export default NationalIdInput
