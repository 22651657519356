import React from 'react'
import TextInput from './TextInput'
import { v_company } from '../../../lib/validations'

const CompanyNameInput = (props) => <TextInput {...props} />

CompanyNameInput.defaultProps = {
  ...TextInput.defaultProps,
  name: 'companyName',
  label: 'Navn på bedrift/avdeling',
  autoComplete: 'org',
  validate: v_company,
}

CompanyNameInput.propTypes = TextInput.propTypes

export default CompanyNameInput
