import React from 'react'
import DateFilter from './DateFilter'
import ServiceProviderFilter from './ServiceProviderFilter'

const FilterTab = () => {
  const handleCloseFilter = (e) => {
    const filter = document.querySelector('.filter-tab')
    filter.classList.toggle('expended')
    const nav = document.querySelector(
      '.route-dashboard__nav-item.filter',
    )
    nav.classList.toggle('selected')
  }

  return (
    <div className="filter-tab">
      <div className="filter-tab__clos-btn">
        <i
          className="fas fa-arrow-alt-circle-left"
          onClick={handleCloseFilter}
        ></i>
      </div>
      <DateFilter />
      <ServiceProviderFilter />
    </div>
  )
}

export default FilterTab
