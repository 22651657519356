import React from 'react'
import PropTypes from 'prop-types'
import TypeaheadSearch from '../TypeaheadSearch'
import PriceModelInfoBox from './PriceModelInfoBox'
import { DEFAULT_LOCALE, translate } from '../../lib/locale'

const PriceModelTypeaheadListItem = ({
  priceModel,
  typeaheadProps,
  onUpdate,
  onRemove,
  onExpend,
  hideRemove,
  index,
  expendable,
}) => {
  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)

  const deleteHandler = (e) => {
    e.preventDefault()
    if (priceModel.id != '') {
      if (
        confirm(
          `${t('priceModel')} ${t('willBeDeleted')}. ${t(
            'areYouSure',
          )}?`,
        )
      ) {
        onRemove(index)
      }
    } else {
      onRemove(index)
    }
  }

  const handleExpend = (e) => {
    e.preventDefault()
    onExpend ? onExpend(priceModel.id) : ''
  }

  return (
    <>
      <div className={'priceTypeheadListItem'}>
        <div className={'priceTypeheadListItem_actions'}>
          {expendable && (
            <button
              type="button"
              onClick={handleExpend}
              className="btn btn-secondary btn-compact"
            >
              {t('seeDetails')}
            </button>
          )}
          {!hideRemove && (
            <button
              type="button"
              onClick={deleteHandler}
              className="btn btn-danger btn-compact ml-1"
              data-index={index}
            >
              {t('suppress')}
            </button>
          )}
        </div>
      </div>

      <TypeaheadSearch
        index={index}
        {...typeaheadProps}
        onChange={onUpdate}
        onExpend={onExpend}
        expendable={!!priceModel?.id}
        value={{
          id: priceModel?.id,
          name: priceModel[typeaheadProps.displayedAttributes],
        }}
      />

      <PriceModelInfoBox
        priceModel={priceModel}
        expendable={!!priceModel?.id}
        expended={priceModel?.expended}
      />
    </>
  )
}

PriceModelTypeaheadListItem.defaultProps = {
  isGraphqlType: false,
  hideRemove: true,
}

PriceModelTypeaheadListItem.propTypes = {
  priceModel: PropTypes.object.isRequired,
  typeaheadProps: PropTypes.object.isRequired,
  onExpend: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  hideRemove: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  expendable: PropTypes.bool.isRequired,
}

export default PriceModelTypeaheadListItem
