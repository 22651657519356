import { useState } from 'react'
export const useSessionStorage = (key, initialValue) => {
  const [
    storedValueInSessionStorage,
    setStoredValueInSessionStorage,
  ] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('Error retrieving from session storage:', error)
      return initialValue
    }
  })

  const setValueInSessionStorage = (value) => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value))
      setStoredValueInSessionStorage(value)
    } catch (error) {
      console.error('Error setting session storage:', error)
    }
  }

  const removeValueFromSessionStorage = () => {
    try {
      window.sessionStorage.removeItem(key)
      setStoredValueInSessionStorage(null)
    } catch (error) {
      console.error('Error removing from session storage:', error)
    }
  }

  return {
    storedValueInSessionStorage,
    setValueInSessionStorage,
    removeValueFromSessionStorage,
  }
}
