import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const RadioButtons = ({
  onClick,
  options,
  name,
  defaultChecked,
  onChange,
  className,
  variant,
  shadow,
  disabled,
}) => {
  const handleRadioChange = (e) => {
    if (onChange) onChange(e.target.value, true, name)
  }
  const handleOnClick = (e) => {
    if (onClick) onClick(e.target.value)
  }

  const styleLabel = () => {
    return variant == 'primary'
      ? { width: `${100 / options.length - 2}%` }
      : {}
  }

  return (
    <div
      className={`hl-radio-group ${
        shadow ? 'ishadow' : ''
      } ${className}`}
    >
      {options.map((option, index) => (
        <Fragment key={option.value}>
          <input
            id={`hl-radio-${name}-${option.value}`}
            name={name}
            value={option.value}
            type="radio"
            onChange={handleRadioChange}
            className={`${variant == 'primary' ? 'primary' : ''}`}
            defaultChecked={defaultChecked === option.value}
            onClick={handleOnClick}
            disabled={disabled}
          />
          <label
            htmlFor={`hl-radio-${name}-${option.value}`}
            className={`${index == 0 ? 'left' : 'middle'} ${
              variant == 'primary' ? 'primary' : ''
            }`}
            style={styleLabel()}
          >
            <span className="radio-header">{option.label1}</span>
            {option.label2}
          </label>
        </Fragment>
      ))}
    </div>
  )
}

RadioButtons.defaultProps = {
  className: '',
  variant: 'secondary',
  shadow: true,
  disabled: false,
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultChecked: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  shadow: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default RadioButtons
