import React from 'react'
import PropTypes from 'prop-types'

const RadioListElement = (props) => {
  return (
    <input
      type="radio"
      name={props.name}
      id={props.id}
      defaultChecked={props.checked}
      onChange={props.onChange}
      className={props.className}
    ></input>
  )
}

RadioListElement.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

export default RadioListElement
