import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class TextInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    initialValue: PropTypes.string,
    showError: PropTypes.bool,
    validate: PropTypes.func,
    fullname: PropTypes.string,
    name: PropTypes.string,
  }
  validate = (text) => text.length > 1

  wrapperClass = () =>
    this.props.isValid
      ? 'form-group booking-form__valid-icon'
      : 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'

  errorClass = () =>
    this.props.showError && !this.props.isValid ? 'error' : ''

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.props.validate || this.validate,
      name:
        this.props.fullname ||
        `booking[user_attributes][${this.props.name || 'text'}]`,
    }

    return (
      <div className={this.wrapperClass()}>
        <div
          className={`form-control booking-form__icon-wrapper ${this.errorClass()}`}
        >
          <label htmlFor={props.name} className="sr-only">
            {props.label || props.placeholder}
          </label>
          <ValidatedInput {...props} />
        </div>
      </div>
    )
  }
}

export default TextInput
