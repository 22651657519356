import React from 'react'
import PropTypes from 'prop-types'
import { v_phone } from '../../lib/validations'

const CustomNumbersTab = ({ onChange, isOpen, enteredNumbers }) => {
  const handleChange = (e) => {
    const stringOfPhoneNumbers = e.target.value
    const phoneNumbers = stringOfPhoneNumbers
      .split(/\s+|\,|\n/)
      .filter((v, i, arr) => arr.indexOf(v) === i)
    const invalidNumbers = phoneNumbers.filter((n) => !v_phone(n))
    if (invalidNumbers.length) {
      alert(
        `Følgende telefonnummer er ikke gyldige:\n${invalidNumbers.join(
          '\n',
        )}`,
      )
    } else {
      onChange(phoneNumbers)
    }
  }

  return (
    <>
      {isOpen && (
        <textarea
          className="form-control sms-custom-numbers"
          onBlur={handleChange}
          placeholder="Skriv inn telefonnumre, separert med komma eller mellomrom"
          defaultValue={enteredNumbers.join(',')}
        ></textarea>
      )}
    </>
  )
}

CustomNumbersTab.propTypes = {
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  enteredNumbers: PropTypes.array,
}

export default CustomNumbersTab
