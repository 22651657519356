import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { propTypes } from './FieldWrapper'
import Label from './Label'
import _ from 'lodash'

const TextareaHL = (props) => {
  const [valid, setValid] = useState(props.validate(props.value))
  const [value, setValue] = useState(props.value)

  const handleChange = (e) => {
    const value = e.target.value
    const valid = props.validate(value)
    setValue(value)
    setValid(valid)
    if (props.onChange) props.onChange(value, valid)
  }

  const inputProps = _.pick(props, [
    'name',
    'placeholder',
    'autoComplete',
    'disabled',
  ])

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    empty: !value || value.length === 0,
    valid,
  }

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <textarea
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        className="input textarea"
        {...inputProps}
      />
    </FieldWrapper>
  )
}

TextareaHL.defaultProps = {
  value: '',
  label: 'Textarea',
  placeholder: '',
  highlightErrors: false,
  disabled: false,
  validate: (value) => value.length > 1,
}

TextareaHL.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  onRef: PropTypes.func,
}

export default TextareaHL
