import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FieldWrapper, { propTypes } from './FieldWrapper'
import Label from './Label'
import moment from 'moment-timezone'
import _ from 'lodash'

const DateTimeInput = (props) => {
  const [valid, setValid] = useState(props.validate(props.value))
  const [value, setValue] = useState('')

  useEffect(() => {
    if (props.value) {
      const newValid = props.validate(props.value)
      setValue(
        moment(props.value)
          .tz('Europe/Oslo')
          .format('yyyy-MM-DDTHH:mm'),
      )
      setValid(newValid)
    }
  }, [props])

  const handleChange = (e) => {
    const newVal = e.target.value
    const newValid = props.validate(newVal)
    setValue(newVal)
    setValid(newValid)
    if (props.onChange) props.onChange(newVal, newValid, props.name)
  }

  const handleInput = (e) => {
    const filteredVal = props.filter(e.target.value)
    e.target.value = props.sanitize(filteredVal)
  }

  const inputProps = _.pick(props, [
    'name',
    'disabled',
    'min',
    'max',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
  ])

  const wrapperProps = {
    ..._.pick(props, Object.keys(propTypes)),
    ...props.highlightErrors,
    empty: !value || value.length === 0,
    valid,
  }

  return (
    <FieldWrapper {...wrapperProps}>
      <Label for={props.name} label={props.label || props.name} />
      <div>
        <input
          type="datetime-local"
          html5="true"
          value={value}
          onChange={handleChange}
          onInput={handleInput}
          className="input textfield"
          {...inputProps}
        />
      </div>
    </FieldWrapper>
  )
}

DateTimeInput.defaultProps = {
  value: '',
  highlightErrors: false,
  disabled: false,
  validate: (value) => value?.length > 1,
  filter: (value) => value,
  sanitize: (value) => value,
}

DateTimeInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
  pattern: PropTypes.string,
  sanitize: PropTypes.func,
  filter: PropTypes.func,
  validate: PropTypes.func,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  onRef: PropTypes.func,
}

export default DateTimeInput
