import React from 'react'
import PropTypes from 'prop-types'
import VaccinationCircles from './VaccinationCircles'

const VaccinationItem = ({ item, onClick }) => {
  return (
    <div
      className="vaccination-item list-group-item"
      onClick={() => onClick(item)}
      id={`list_item_${item.patient_id}`}
    >
      <div className="row">
        <div className="col-sm-3">{item.name}</div>
        <div className="col-sm-3">{item.phone_number}</div>
        <div className="col-sm-3">{item.national_id}</div>
        <VaccinationCircles item={item} />
      </div>
    </div>
  )
}

VaccinationItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default VaccinationItem
