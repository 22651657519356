import React from 'react'
import PropTypes from 'prop-types'

const Label = (props) => (
  <label
    htmlFor={props.for}
    className="label"
    style={{ ...props.style }}
  >
    {props.label}
    {props.children}
  </label>
)

Label.propTypes = {
  for: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.object,
}

export default Label
