export const messageError = (validations) => {
  const asArray = Object.entries(validations)
  const filteredArray = asArray.filter(
    ([_name, valid]) => valid == false,
  )
  const names = filteredArray.map(([name, _valid]) =>
    name.replace(/_/, ' '),
  )
  return `Ugyldige felter: ${names.join(', ')}`
}

export const isValid = (validations) => {
  return Object.values(validations).every((v) => v == true)
}
