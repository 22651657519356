import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const InputToggleText = (props) => {
  const [state, setState] = useState({
    value: '',
    valid: null,
  })

  useEffect(() => {
    if (props.value || props.value === '') {
      setState({
        value: props.value,
        valid: props.validate(props.value),
      })
    }
  }, [props])

  const handleChange = (e) => {
    const newVal = e.target.value
    const newValid = props.validate(newVal)
    setState({ value: newVal, valid: newValid })
    if (props.onChange) props.onChange(newVal, newValid, props.name)
  }

  const handleInput = (e) => {
    const filteredVal = props.filter(e.target.value)
    e.target.value = props.sanitize(filteredVal)
  }

  const inputProps = _.pick(props, [
    'name',
    'autoComplete',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'inputMode',
    'readOnly',
    'id',
    'title',
  ])

  const classNameComposed = `input-toggle text ${
    props.editable
      ? `editable ${state.valid ? 'valid' : 'invalid'}`
      : ''
  }`

  return (
    <input
      type="text"
      value={state.value}
      onChange={handleChange}
      onInput={handleInput}
      className={classNameComposed}
      placeholder={props.label || props.name}
      disabled={!props.editable}
      {...inputProps}
    />
  )
}

InputToggleText.defaultProps = {
  value: '',
  placeholder: '',
  autoComplete: 'off',
  highlightErrors: false,
  isRequired: false,
  disabled: false,
  showValidation: true,
  inputMode: 'text',
  editable: false,
  name: 'Text',
  validate: (value) => value?.length > 1,
  filter: (value) => value,
  sanitize: (value) => value,
}

InputToggleText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  sanitize: PropTypes.func,
  filter: PropTypes.func,
  validate: PropTypes.func,
  maxLength: PropTypes.number,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  attentionMessage: PropTypes.string,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  inputMode: PropTypes.string,
}

export default InputToggleText
