import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Patient = (props) => {
  const [phoneEnabled, setPhoneEnabled] = useState(
    props.patient.phone_number || props.patient.phone_number_old,
  )
  const [patient, setPatient] = useState(props.patient)

  // we change the destroy property  of the patient when we click the slett button
  const changeHandler = (e) => {
    const propName = e.target.getAttribute('data-propname')
    const value = e.target.value
    updatePatient(propName, value)
  }

  const deleteHandler = (e) => {
    e.preventDefault()
    if (patient['id'] != null) {
      if (
        confirm(
          'Pasienten vil bli fjernet fra bestillingen. Er du sikker?',
        )
      ) {
        updatePatient('_destroy', true)
      }
    } else {
      props.onRemove(props.index)
    }
  }

  const updatePatient = (propName, value) => {
    const newPatient = { ...patient }
    newPatient[propName] = value
    setPatient(newPatient)
    props.onChange(props.index, newPatient)
    if (propName === 'national_id') {
      validatePatient(props.index, value)
    }
  }

  const validatePatient = (index, value) => {
    const NationalIdInput = document.getElementById(
      `patients_attributes__national_id_${index}`,
    )
    const isValid =
      NationalIdInput.value.length > 0
        ? /^[0-9]{11}$/.test(value)
        : true
    if (isValid) {
      NationalIdInput.classList.remove('error')
      NationalIdInput.classList.add('valid')
      document.getElementById('admin-edit-button').disabled = false
    } else {
      NationalIdInput.classList.remove('valid')
      NationalIdInput.classList.add('error')
      document.getElementById('admin-edit-button').disabled = true
    }
  }

  const renderMinimumFields = () => (
    <>
      <div className="col-12">
        <a
          href="#"
          className="mb-2 text-right remove-patient"
          style={{
            display: props.allowRemove ? 'none' : 'inherit',
          }}
          onClick={deleteHandler}
          data-propname="_destroy"
          data-index={props.index}
          data-patient_id={patient.id}
        >
          Slett pasient
        </a>
      </div>
      <div className="col-sm-6">
        <div className="form-group string optional">
          <label
            className="form-control-label string optional"
            htmlFor={`patients_attributes__name_${props.index}`}
          >
            Fullt navn
          </label>
          <input
            className="form-control string optional"
            type="text"
            data-propname="name"
            data-index={props.index}
            data-patient_id={props.patient.id}
            onChange={changeHandler}
            value={props.patient.name}
            name={`${props.parentModel}[patients_attributes][][name]`}
            id={`patients_attributes__name_${props.index}`}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group tel optional">
          <label
            className="form-control-label tel optional"
            htmlFor={`patients_attributes__phone_number_${props.index}`}
          >
            Telefonnummer
            {props.patient.phone_number_old &&
            !props.patient.phone_number
              ? ' ikke verifisert ⚠️'
              : ''}
          </label>
          {phoneEnabled && (
            <input
              className="form-control string tel optional"
              placeholder="Med landskode f.eks. +47"
              data-propname="phone_number"
              data-index={props.index}
              data-patient_id={patient.id}
              onChange={changeHandler}
              value={
                patient.phone_number || patient.phone_number_old || ''
              }
              type="tel"
              name={`${props.parentModel}[patients_attributes][][phone_number]`}
              id={`patients_attributes__phone_number_${props.index}`}
              disabled={
                !patient.phone_number && patient.phone_number_old
              }
            />
          )}
          {!phoneEnabled && (
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => setPhoneEnabled(true)}
            >
              Kan kontaktes direkte
            </button>
          )}
        </div>
      </div>
    </>
  )

  const renderOptionalFields = () => (
    <>
      <div className="col-sm-6">
        <div className="form-group string optional">
          <label
            className="form-control-label string optional"
            htmlFor={`patients_attributes__national_id_${props.index}`}
          >
            Personnummer
          </label>
          <input
            type="text"
            className="form-control string optional"
            data-propname="national_id"
            data-index={props.index}
            data-patient_id={patient.id}
            onChange={changeHandler}
            value={patient.national_id || ''}
            name={`${props.parentModel}[patients_attributes][][national_id]`}
            id={`patients_attributes__national_id_${props.index}`}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group tel optional">
          <label
            className="form-control-label tel optional"
            htmlFor={`patients_attributes__email_${props.index}`}
          >
            E-postadresse
          </label>
          <input
            className="form-control string optional"
            data-propname="email"
            data-index={props.index}
            data-patient_id={patient.id}
            onChange={changeHandler}
            value={patient.email || ''}
            type="email"
            name={`${props.parentModel}[patients_attributes][][email]`}
            id={`patients_attributes__email_${props.index}`}
          />
        </div>
      </div>
      <input
        className="form-control string optional"
        data-propname="id"
        data-index={props.index}
        data-patient_id={patient.id}
        onChange={changeHandler}
        value={patient.id || ''}
        type="hidden"
        name={`${props.parentModel}[patients_attributes][][id]`}
        id={`patients_attributes__id_${props.index}`}
      />
    </>
  )

  const renderDeleteField = () => (
    <input
      data-propname="_destroy"
      data-patient_id={patient.id}
      data-index={props.index}
      value={patient._destroy || false}
      type="hidden"
      name={`${props.parentModel}[patients_attributes][][_destroy]`}
      id={`patients_attributes___destroy_${props.index}`}
    />
  )

  return (
    <div
      className="row py-2 mb-1"
      id={props.patient.id}
      data-patient_id={patient.id}
      style={{
        display: patient['_destroy'] ? 'none' : 'flex',
      }}
    >
      {renderMinimumFields()}
      {props.renderOptionalFields && renderOptionalFields()}
      {patient['id'] !== undefined && renderDeleteField()}
    </div>
  )
}

Patient.propTypes = {
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  parentModel: PropTypes.string.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  renderOptionalFields: PropTypes.bool.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    phone_number_old: PropTypes.string,
  }),
}

export default Patient
