import React from 'react'
import PropTypes from 'prop-types'
import { StripeProvider, Elements } from 'react-stripe-elements'

import PaymentForm from './PaymentForm'

const Payment = (props) => (
  <div>
    <StripeProvider apiKey={props.stripePublishableKey} locale="no">
      <Elements
        fonts={[
          {
            src: props.assetRootUrl.font,
            family: 'Fabriga Regular',
            style: 'normal',
          },
        ]}
      >
        <PaymentForm
          path={props.formPath}
          stripePublishableKey={props.stripePublishableKey}
          price={props.price}
          booking={props.booking}
          user={props.user}
          couponCode={props.couponCode}
          basePrice={props.basePrice}
          baseTwoInOnePrice={props.baseTwoInOnePrice}
          discountAmount={props.discountAmount}
        />
      </Elements>
    </StripeProvider>
  </div>
)

Payment.propTypes = {
  formPath: PropTypes.string.isRequired,
  assetRootUrl: PropTypes.object.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  basePrice: PropTypes.number.isRequired,
  baseTwoInOnePrice: PropTypes.number.isRequired,
  booking: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  couponCode: PropTypes.string,
  discountAmount: PropTypes.any,
}

export default Payment
