import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import consumer from '../channels/consumer'
import axios from 'axios'
import StickyConfirmation from './StickyConfirmation'

class RebookingMultiselectTimeView extends Component {
  static propTypes = {
    availableBookings: PropTypes.object.isRequired,
    successRedirectPath: PropTypes.string.isRequired,
    calendarEntriesPath: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    bookingId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      availableBookings: props.availableBookings,
      infoModalOpen: false,
      createdBookings: null,
      chosenBookings: [],
      uiState: 'slotPicker',
      errorMessage: null,
    }
  }

  componentDidMount() {
    consumer.subscriptions.create('AvailabilityChannel', {
      received: this.updateBookingAvailability,
    })
  }

  updateBookingAvailability = (booking) => {
    const searchAttributes = {
      service_provider: { id: booking.service_provider_id },
      begins_at: booking.begins_at,
    }

    const availableBookingsDupe = { ...this.state.availableBookings }

    _.each(availableBookingsDupe, (bookings) => {
      const bookingInCollection = _.find(bookings, searchAttributes)

      if (bookingInCollection) {
        bookingInCollection.bookable = !booking.reserved
      }
    })
    this.setState({ availableBookings: availableBookingsDupe })
  }

  showTodayOrTomorrow(date) {
    const today = moment().tz('Europe/Oslo').startOf('day')
    const dateWithTZ = moment(date).tz('Europe/Oslo').startOf('day')

    const daysDiff = dateWithTZ.diff(today, 'days')

    if (daysDiff == 0) {
      return '(i dag)'
    } else if (daysDiff == 1) {
      return '(i morgen)'
    }

    return ''
  }

  firstCharUppercase = (text) =>
    `${text.charAt(0).toUpperCase()}${text.slice(1)}`

  exitRebookingClickHandler = () => {
    window.location.href = this.props.successRedirectPath
  }

  submitBookingsClickHandler = () => {
    axios({
      method: 'post',
      url: this.props.path,
      data: JSON.stringify({
        booking_id: this.props.bookingId,
        chosenBookings: this.state.chosenBookings,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            ...this.state,
            createdBookings: response.data,
            uiState: 'confirmation',
          })
        } else {
          this.setState({
            ...this.state,
            errorMessage: response.data.error.message,
            uiState: 'error',
          })
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          errorMessage: error.message,
          uiState: 'error',
        })
      })
  }

  renderedChosenBookings = () => (
    <ul className="li-reset">
      {this.state.chosenBookings.slice(0, 3).map((booking) => {
        return (
          <li key={booking.begins_at}>
            {this.firstCharUppercase(
              moment(booking.begins_at).format('dddd Do MMMM, HH:mm'),
            )}
          </li>
        )
      })}

      {this.state.chosenBookings.length > 3 && (
        <li>{this.state.chosenBookings.length - 3} flere...</li>
      )}
    </ul>
  )

  renderedBooking = (booking) => {
    if (booking.reserved) {
      return (
        <div className="day__service-provider day__service-provider--disabled day__hour-heading">
          Reservert
        </div>
      )
    }

    const isChosen = this.state.chosenBookings.includes(booking)
    const clickAction = () =>
      this.bookingClickAction(booking, isChosen)

    return (
      <button
        disabled={!booking.bookable}
        onClick={clickAction}
        className={`day__service-provider day__hour-heading_multiline
        ${isChosen ? 'day__hour-selected' : ''}
        ${booking.bookable ? '' : 'day__hour-unavailable'}
        `}
      >
        {moment(booking.begins_at).tz('Europe/Oslo').format('HH:00')}
      </button>
    )
  }

  backLink = () => (
    <a href="#" onClick={this.exitRebookingClickHandler}>
      Tilbake til opprinnelig bestilling
    </a>
  )

  renderedError = () => (
    <>
      <h2 className="h6">Dette virket visst ikke...</h2>
      <p className="text-danger">{this.state.errorMessage}</p>
      {this.backLink()}
    </>
  )

  confirmationPane = () => (
    <>
      <h2 className="h6">Følgende timer er opprettet:</h2>
      <ul>
        {this.state.createdBookings.map((booking) => (
          <li className="rebooking-success-pane" key={booking.id}>
            {this.firstCharUppercase(
              moment(booking.begins_at).format('dddd Do MMMM, HH:mm'),
            )}
          </li>
        ))}
      </ul>
      {this.backLink()}
    </>
  )

  bookingClickAction = (chosenBooking, alreadyChosen) => {
    const arr = this.state.chosenBookings
    const newChosenBookings = alreadyChosen
      ? arr.filter((item) => item !== chosenBooking)
      : arr.concat(chosenBooking)

    this.setState({
      ...this.state,
      chosenBookings: newChosenBookings,
    })
  }

  clearChosenBookings = () => {
    this.setState({
      ...this.state,
      chosenBookings: [],
    })
  }

  bookingsForDay = (dayBookings) => {
    const preRenderedBookings = _.map(dayBookings, (booking) => {
      return (
        <li className="day__hour" key={booking.begins_at}>
          {this.renderedBooking(booking)}
        </li>
      )
    })

    return <ol className="day__hours">{preRenderedBookings}</ol>
  }

  daysToChooseFrom = () =>
    Object.keys(this.state.availableBookings).map((date, index) => {
      const bookings = this.state.availableBookings[date]
      return (
        <div key={date}>
          <div className="available__heading">
            <div className="available__date">
              <span>
                {this.firstCharUppercase(
                  moment(date).format('dddd Do MMMM'),
                )}
              </span>
              <span>{this.showTodayOrTomorrow(date)}</span>
            </div>
          </div>
          <div className="day" data-date={date}>
            {this.bookingsForDay(bookings)}
          </div>
        </div>
      )
    })

  slotPickerPane = () => {
    if (Object.keys(this.state.availableBookings).length == 0) {
      return (
        <>
          <p>Du har ingen ledige timer. </p>
          <a
            href={this.props.calendarEntriesPath}
            className="btn btn-primary btn-block"
          >
            Gå til timeboken
          </a>
        </>
      )
    }

    return (
      <>
        <div>{this.daysToChooseFrom()}</div>
        <StickyConfirmation
          onConfirm={this.submitBookingsClickHandler}
          show={this.state.chosenBookings.length !== 0}
          onCancel={this.clearChosenBookings}
        >
          <div className="text-left">
            <h2 className="h6 text-white">
              Klikk her for å opprette følgende bestillinger:
            </h2>
            {this.renderedChosenBookings()}
          </div>
        </StickyConfirmation>
      </>
    )
  }

  render() {
    return (
      <>
        {this.state.uiState == 'slotPicker' && this.slotPickerPane()}
        {this.state.uiState == 'confirmation' &&
          this.confirmationPane()}
        {this.state.uiState == 'error' && this.renderedError()}
      </>
    )
  }
}

export default RebookingMultiselectTimeView
