import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CopyToClipboard = ({
  clipboardIconPath,
  value,
  showValue,
  valueIsLink,
  stopPropagation,
}) => {
  const [success, setSuccess] = useState(false)

  const write = async (event) => {
    if (stopPropagation) event.stopPropagation()
    const perm = await navigator.permissions.query({
      name: 'clipboard-write',
    })
    if (perm.state === 'granted') {
      const blob = new Blob([value], { type: 'text/plain' })
      const item = new ClipboardItem({ 'text/plain': blob })
      await navigator.clipboard.write([item])
      setSuccess(true)
      setTimeout(() => setSuccess(false), 1000)
    }
  }

  const style = {
    display: 'inline-block',
    cursor: 'pointer',
    filter: `hue-rotate(${success ? '90deg' : '0'})`,
    transition: 'all 0.15s ease',
  }

  return (
    <span style={style} onClick={write}>
      {showValue && !valueIsLink && value}
      {showValue && valueIsLink && <a href={value}>{value}</a>}
      <img
        src={clipboardIconPath}
        alt="copy to clipboard"
        style={{
          display: 'inline',
          verticalAlign: 'middle',
          marginBottom: '3px',
          marginLeft: '6px',
          cursor: 'pointer',
        }}
      />
    </span>
  )
}

CopyToClipboard.defaultProps = {
  showValue: true,
  valueIsLink: false,
  stopPropagation: false,
}

CopyToClipboard.propTypes = {
  clipboardIconPath: PropTypes.string.isRequired,
  value: PropTypes.string,
  showValue: PropTypes.bool,
  valueIsLink: PropTypes.bool,
  stopPropagation: PropTypes.bool,
}

export default CopyToClipboard
