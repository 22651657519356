import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import RadioButtons from './forms/fields/RadioButtons'
import SelectHL from './forms/fields/SelectHL'
import CheckboxHL from './forms/fields/CheckboxHL'

const PatientIndepencenceRow = (props) => {
  const [patient, setPatient] = useState(props.patient)
  const [patients, setPatients] = useState(props.patients)
  const [isValid, setIsValid] = useState(false)

  const getContacts = () => {
    const contacts = patients.filter(
      (patient) => patient.independent === true,
    )

    if (
      props.mainContact &&
      props.mainContact.name &&
      props.mainContact.phone_number
    ) {
      contacts.unshift({
        name: props.mainContact.name,
        phone_number: props.mainContact.phone_number,
        id: props.mainContact.id || 'main',
        isMain: true,
      })
    }

    contacts.unshift({
      name: 'Ingen',
      id: 'null',
    })

    return contacts
  }

  useEffect(() => {
    setPatients(props.patients)
  }, [props.patients])

  useEffect(() => {
    setContacts(getContacts())
  }, [patients])

  useEffect(() => {
    setIsValid(validate())
  }, [patient])

  useEffect(() => {
    if (isValid) {
      notify(patient, isValid)
    }
  }, [patient, isValid])

  const [contacts, setContacts] = useState(getContacts())

  const handleContactChange = (value, valid) => {
    const updatedPatient = {
      ...patient,
      contact_person: {
        ...patient.contact_person,
        id: valid ? value : null,
      },
    }
    setPatient(updatedPatient)
  }

  const handleIndependentChange = (value) => {
    const updatedPatient = {
      ...patient,
      independent: value === 'true',
    }
    setPatient(updatedPatient)
  }

  const handleLegalGuardianChange = (value) => {
    const updatedPatient = {
      ...patient,
      contact_person: {
        ...patient.contact_person,
        legal_guardian: value,
      },
    }
    setPatient(updatedPatient)
  }

  const validate = () => {
    if (patient.independent == true) {
      return true
    } else if (
      patient.contact_person &&
      typeof patient.contact_person.id === 'string'
    ) {
      return true
    } else {
      return false
    }
  }

  const notify = (updatedPatient, valid) =>
    props.onChange && props.onChange(updatedPatient, valid)

  const getContactLabel = (contact) => {
    let label = contact.name
    if (contact.id == null || contact.id == 'null') return label
    if (contact.id == 'main' || contact.isMain)
      label += ' (hovedkontakt)'
    else label += ' (annen pasient)'
    return label
  }

  return (
    <>
      {props.hasHeading && (
        <h2 className="groupheading">Pasient {patient.name}</h2>
      )}
      <RadioButtons
        name={
          props.forManyPatients
            ? `patients_attributes[${patient.id}][independent]`
            : `booking[patients_attributes][][independent]`
        }
        options={[
          { value: true, label1: 'Pasienten ' },
          { value: false, label1: 'Kontaktperson' },
        ]}
        onChange={handleIndependentChange}
        defaultChecked={patient.independent}
        className="mb-2"
      />
      {patient.independent == false && (
        <>
          <SelectHL
            label={'Velg kontaktperson'}
            name={
              props.forManyPatients
                ? `patients_attributes[${patient.id}][contact_person]`
                : `booking[patients_attributes][][contact_person][id]`
            }
            options={contacts.map((contact) => ({
              label: getContactLabel(contact),
              value: contact.id,
            }))}
            onChange={handleContactChange}
            validate={(value) => value != 'null'}
            className="b-bottom"
            value={props.lastContact?.id || ''}
          />
          <CheckboxHL
            name={
              props.forManyPatients
                ? `patients_attributes[${patient.id}][contact_person][legal_guardian]`
                : `booking[patients_attributes][][contact_person][legal_guardian]`
            }
            onChange={handleLegalGuardianChange}
            value={
              props.lastContact?.legal_guardian ||
              patient.contact_person.legal_guardian
            }
            className="naked"
          >
            {`Gi kontaktperson tilgang til ${
              patient.name.split(' ')[0]
            } sine data`}
          </CheckboxHL>
        </>
      )}
    </>
  )
}

PatientIndepencenceRow.defaultProps = {
  hasHeading: true,
  forManyPatients: true,
}

PatientIndepencenceRow.propTypes = {
  patient: PropTypes.object.isRequired,
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainContact: PropTypes.object,
  lastContact: PropTypes.object,
  onChange: PropTypes.func,
  hasHeading: PropTypes.bool,
  forManyPatients: PropTypes.bool,
}

export default PatientIndepencenceRow
