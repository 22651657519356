import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import CheckoutForm from './CheckoutForm'
import './Stripe.css'

const stripePromise = loadStripe(stripePublishableKey)
export default function Stripe({
  payment_intent: paymentIntent,
  redirect_path: redirectPath,
  price,
}) {
  const [clientSecret] = useState(paymentIntent.client_secret)

  const appearance = {
    theme: 'Default',
    variables: {
      colorPrimary: '#381d50',
      colorBackground: '#fff',
      colorText: '#381d50',
      colorDanger: '#ff6864',
      fontFamily: 'Fabriga Regular, Sans-Serif',
      fontSizeBase: window.innerWidth <= 543 ? '16px' : '18px',
      fontLineHeight: '28px',
      borderRadius: '4px',
    },
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className="stripe_payment">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm redirectPath={redirectPath} price={price} />
        </Elements>
      )}
    </div>
  )
}

Stripe.propTypes = {
  payment_intent: PropTypes.object.isRequired,
  redirect_path: PropTypes.string,
  price: PropTypes.number.isRequired,
}
