import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { v_zip } from '../lib/validations'
import RadioButtons from './forms/fields/RadioButtons'
import { availableHours } from './appointments/queries'
import _ from 'lodash'

const modalStyle = {
  content: {
    width: '80%',
    WebkitOverflowScrolling: 'touch',
  },
}

const getInitialBookingDuration = () => {
  return moment(
    document.querySelector("input[name='booking[ends_at]']")?.value,
  ).diff(
    document.querySelector("input[name='booking[begins_at]']")?.value,
    'minutes',
  )
}

const getHoursText = (timeFrom, timeTo) => {
  const beginsAt = moment(timeFrom)
  const endsAt = moment(timeTo)
  const hours = [beginsAt.format('HH:mm')]
  if (endsAt.diff(beginsAt, 'minutes') > 60) {
    hours.push(endsAt.format('HH:mm'))
  }
  return hours.join('–')
}

const AvailableBookingsModal = (props) => {
  const [slots, setSlots] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [bookingDuration, setBookingDuration] = useState(
    getInitialBookingDuration(),
  )
  let zip = document.querySelector("input[name='booking[zip]']")
    ?.value

  ReactModal.setAppElement('body')

  const openModal = () => {
    zip = document.querySelector("input[name='booking[zip]']")?.value
    if (!v_zip(zip)) {
      alert('Postnummerfeltet er ikke fylt ut riktig')
      return
    }
    setBookingDuration(getInitialBookingDuration())
    setModalOpen(true)
  }

  useEffect(async () => {
    if (!modalOpen) return
    setLoading(true)

    const zip = document.querySelector("input[name='booking[zip]']")
      ?.value
    const dateFrom = moment()
    const dateTo = moment().add(14, 'days')
    const response = await availableHours(
      zip,
      dateFrom.toISOString(true),
      dateTo.toISOString(true),
      bookingDuration,
    )
    const availableBookings = response.data.availableBookings

    //    if (response.errors) return onError(response.errors)
    setLoading(false)
    setSlots(_.groupBy(availableBookings, 'date'))
  }, [bookingDuration, zip, setSlots, availableHours])

  const getDateHeading = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    } ${moment(date).calendar(null, {
      sameDay: '[(i dag)]',
      nextDay: '[(i morgen)]',
    })}`
  }

  const setTimeSlot = (e) => {
    e.preventDefault()
    const key = e.target.id.split('+')
    const slot = slots[key[0]][key[1]]
    const beginsAt = moment(slot.beginsAt).format('yyyy-MM-DDTHH:mm')
    const endsAt = moment(slot.endsAt).format('yyyy-MM-DDTHH:mm')

    $(`#${props.booking_class}_service_provider_ids`).val([
      slot.serviceProvider.id,
    ])
    $(`#${props.booking_class}_begins_at`).val(beginsAt)
    $(`#${props.booking_class}_ends_at`).val(endsAt)
    setModalOpen(false)
  }

  const hasResults = Object.keys(slots).length != 0

  const handleDurationChange = (value) => {
    setBookingDuration(value)
  }

  return (
    <div className="next-available-booking">
      <button
        type="button"
        onClick={openModal}
        className="btn btn-secondary btn-intext"
      >
        Søk i timebok
      </button>

      <ReactModal
        isOpen={modalOpen}
        contentLabel="Velg time"
        style={modalStyle}
        shouldCloseOnEsc={true}
      >
        <div
          className="ReactModal__exit-button-new"
          onClick={() => setModalOpen(false)}
        />

        <h2 className="h5 mb-4">Timer for postnummer {zip}</h2>
        <RadioButtons
          name="organization[billing_method]"
          defaultChecked={bookingDuration}
          onChange={handleDurationChange}
          options={[
            { value: 60, label1: '1 time' },
            { value: 120, label1: '2 timer' },
          ]}
        />
        {loading && (
          <div style={{ position: 'relative', height: '100px' }}>
            <div className="spinner" />
          </div>
        )}
        {hasResults && !loading && (
          <ul className="available-bookings-modal">
            {_.map(slots, (bookings, date) => (
              <li key={date}>
                <h3 className="day-heading">
                  {getDateHeading(date)}
                </h3>
                <ul className="mb-3">
                  {_.map(bookings, (booking, i) => (
                    <li key={`${date}+${i}`}>
                      <a
                        href="#"
                        title={booking.spProductsNames[0]}
                        id={`${date}+${i}`}
                        onClick={setTimeSlot}
                      >
                        {`${getHoursText(
                          booking.beginsAt,
                          booking.endsAt,
                        )} ${booking.serviceProvider.fullName} (${
                          booking.spTypesName
                            .charAt(0)
                            .toUpperCase() +
                          booking.spTypesName.slice(1)
                        })`}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
        {!hasResults && !loading && (
          <p>Ingen timer for postnummer {zip}</p>
        )}
      </ReactModal>
    </div>
  )
}

AvailableBookingsModal.propTypes = {
  booking_class: PropTypes.string.isRequired,
}

export default AvailableBookingsModal
