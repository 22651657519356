import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { get } from '../lib/backend-client'

const ConsultationEventLog = (props) => {
  const [events, setEvents] = useState([])
  const [loaded, setLoaded] = useState(false)

  const fetchEvents = async (e) => {
    e.preventDefault()
    const response = await get(props.path)
    if (response.errors)
      return alert('Error: ' + response.errors.join(', '))

    setEvents(response.events)
    setLoaded(true)
  }

  const mtd = (event) => {
    switch (event.action) {
      case 'daily_api_call':
      case 'seald_api_call':
        return ''
      default:
        if (Object.keys(event.metadata_as_hash).length == 0) {
          return ''
        } else {
          return JSON.stringify(event.metadata_as_hash)
        }
    }
  }

  const d = (date) => moment(date).format('DD.MM.YY HH:MM')

  return (
    <div className="event-log my-3">
      {!loaded && (
        <button
          className="btn btn-outline-primary"
          onClick={fetchEvents}
        >
          Vis
        </button>
      )}
      {loaded && events.length == 0 && <p>ingen hendelser</p>}
      {events.length > 0 && (
        <div className="row my-2">
          <div className="col-3">Tidspunkt</div>
          <div className="col-3">Type</div>
          <div className="col-3">Detaljer</div>
        </div>
      )}
      {events &&
        events
          .sort((a, b) => a.id - b.id)
          .map((event, i) => (
            <div
              key={event.id}
              className={`row my-1 small-text ${
                i % 2 ? 'even' : 'odd'
              }`}
            >
              <div className="col-3">
                {new Date(event.created_at).toLocaleString('en-EU', {
                  timeZone: 'Europe/Oslo',
                  hour12: false,
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
              </div>
              <div className="col-3">{event.action}</div>
              <div className="col-6">{mtd(event)}</div>
            </div>
          ))}
    </div>
  )
}

ConsultationEventLog.propTypes = {
  path: PropTypes.string.isRequired,
}

export default ConsultationEventLog
