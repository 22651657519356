import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const RoutesTab = (props) => {
  const routesData = useSelector((state) => state.routesData)
  const routesOptimizedData = useSelector(
    (state) => state.routesOptimizedData,
  )
  const routesNonOptimized = routesData.map((route) => {
    const {
      spName,
      response,
      response: { request },
    } = route
    return {
      spName,
      legs: [...response.routes[0].legs],
      optimized: request.optimizeWaypoints,
    }
  })
  const routesOptimized = routesOptimizedData.map((route) => {
    const {
      spName,
      response,
      response: { request },
    } = route
    return {
      spName,
      legs: [...response.routes[0].legs],
      optimized: request.optimizeWaypoints,
    }
  })
  const routes = [...routesNonOptimized, ...routesOptimized]

  const calculateTotalDrivingTime = (legsArray) => {
    const totalDrivingTime = legsArray
      .map((leg) => leg.duration.value)
      .reduce((acc, currentValue) => acc + currentValue)
    const date = new Date(0)
    date.setSeconds(totalDrivingTime)
    const timeString = date.toISOString().substr(11, 8)
    return timeString
  }

  const calculateTotalDrivingDistance = (legsArray) => {
    const totalDrivingDistance = legsArray
      .map((leg) => leg.distance.value)
      .reduce((acc, currentValue) => acc + currentValue)
    return Math.floor(totalDrivingDistance / 1000)
  }

  const displayRoutesDescription = () => {
    if (routes && routes.length > 0) {
      const routesGroup = _.groupBy(routes, (route) => route.spName)
      const keys = Object.keys(routesGroup)
      const routesToDisplay = keys.map((key, i) => {
        const routes = routesGroup[key].map((route, i) =>
          buildRoutesDescription(route, i),
        )
        return (
          <div key={i} className="routes-description__route-details ">
            <h6>{key}</h6>
            <div className="d-flex">{routes}</div>
          </div>
        )
      })
      return routesToDisplay
    }
  }

  const buildRoutesDescription = (route, i) => {
    const routesLength = route.legs.length
    const optimized = route.optimized ? 'optimized' : ''
    const cardSubtitle = route.optimized
      ? 'Optimalisert rekkefølge'
      : 'Rekkefølge'
    return (
      <div
        key={i}
        className={
          'flex-grow-1 routes-description__route-card ' + optimized
        }
      >
        {route && (
          <>
            <div className="routes-description__route-details__header">
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <i className="far fa-clock"></i>
                  Total driving time: &nbsp;
                  {calculateTotalDrivingTime(route.legs)}
                </div>
                <div className="d-flex align-items-center ml-3">
                  <i className="fas fa-car-side"></i>
                  Total distance: &nbsp;
                  {calculateTotalDrivingDistance(route.legs)} Km
                </div>
              </div>
            </div>

            <p className="small">
              <u>{cardSubtitle}:</u>
            </p>
            <ol className="routes-description__leg-list">
              {route.legs.map((leg, i) => {
                if (i === 0) {
                  return (
                    <div key={i}>
                      <li>
                        <i className="fas fa-home"></i>
                        {leg.start_address}
                      </li>
                      <i className="fas fa-road"></i>
                      {leg.distance.text}-{leg.duration.text}
                      <li>{leg.end_address}</li>
                    </div>
                  )
                } else if (i === routesLength - 1) {
                  return (
                    <div key={i}>
                      <i className="fas fa-road"></i>
                      {leg.distance.text}-{leg.duration.text}
                      <li>
                        <i className="fas fa-home"></i>
                        {leg.end_address}
                      </li>
                    </div>
                  )
                } else {
                  return (
                    <div key={i}>
                      <i className="fas fa-road"></i>
                      {leg.distance.text}-{leg.duration.text}
                      <li>{leg.end_address}</li>
                    </div>
                  )
                }
              })}
            </ol>
          </>
        )}
      </div>
    )
    return routes
  }

  const handleCloseRoutes = (e) => {
    const filter = document.querySelector('.routes-description')
    filter.classList.toggle('expended')
    const nav = document.querySelector(
      '.route-dashboard__nav-item.for-route',
    )
    nav.classList.toggle('selected')
  }

  return (
    <div className="routes-description">
      <div className="routes-description-__clos-btn">
        <i
          className="fas fa-arrow-alt-circle-right"
          onClick={handleCloseRoutes}
        ></i>
      </div>

      {displayRoutesDescription()}
    </div>
  )
}

export default RoutesTab
