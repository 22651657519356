import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
class LoadingButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    textFail: PropTypes.string.isRequired,
    resetWhenChange: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      fail: false,
      isSubmitting: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ fail: false })
  }

  showSpinner() {
    if (this.state.isSubmitting) {
      return <span className="btn__loader" />
    }
  }

  handleClick = () => {
    this.setState({ isSubmitting: true })

    this.props.onClick((status) => {
      this.setState({ isSubmitting: false })

      if (status == 'fail') this.setState({ fail: true })
      else this.setState({ fail: false })
    })
  }

  render() {
    const safeProps = _.omit(
      this.props,
      'onClick',
      'text',
      'textFail',
      'resetWhenChange',
    )

    const childProps = {
      ...safeProps,
      onClick: this.handleClick,
    }

    return (
      <a {...childProps}>
        {this.state.fail == true
          ? this.props.textFail
          : this.props.text}
        {this.showSpinner()}
      </a>
    )
  }
}

export default LoadingButton
