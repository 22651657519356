import * as Sentry from '@sentry/react'
import _ from 'lodash'

export const getRoute = async (paramsArray) => {
  try {
    const response = await buildRoute(paramsArray)
    return response
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(err)
  }
}

const buildRoute = (paramsArray) => {
  const directionsService = new google.maps.DirectionsService()
  return Promise.all(
    paramsArray.map((params) => {
      return new Promise((resolve, reject) => {
        directionsService.route(params.routeParams, function (
          response,
          status,
        ) {
          if (status == 'OK') {
            params.response = response
            resolve(_.omit(params, 'routeParams'))
          } else {
            reject(status)
          }
        })
      })
    }),
  )
}
