import { client } from '../../../api/client'
import moment from 'moment-timezone'
import { randomColorGenerator } from '../../../../../lib/randomColorGenerator'
import * as Sentry from '@sentry/react'

const DEFAULT_BOOKINGS_PARAMS = {
  begins_after: moment()
    .tz('Europe/Oslo')
    .subtract(2, 'days')
    .startOf('day')
    .format('yyyy-MM-DDTHH:mm'),

  ends_before: moment()
    .tz('Europe/Oslo')
    .add(7, 'days')
    .endOf('day')
    .format('yyyy-MM-DDTHH:mm'),

  excluded_states: ['personal_data', 'awaiting_payment', 'cancelled'],
}

const DEFAULT_REFERRALS_PARAMS = {
  converted: false,
}

const DEFAULT_SERVICE_PROVIDERS_PARAMS = {}

const DEFAULT_PRODUCTS_PARAMS = {}

const DEFAULT_CALENDAR_ENTRIES_PARAMS = {
  begins_after: moment()
    .tz('Europe/Oslo')
    .subtract(2, 'days')
    .startOf('day')
    .format('yyyy-MM-DDTHH:mm'),

  ends_before: moment()
    .tz('Europe/Oslo')
    .add(7, 'days')
    .endOf('day')
    .format('yyyy-MM-DDTHH:mm'),
}

const compareBeginsAt = (a, b) => {
  const dateA = a.begins_at // ignore upper and lowercase
  const dateB = b.begins_at // ignore upper and lowercase
  if (dateA < dateB) {
    return -1
  }
  if (dateA > dateB) {
    return 1
  }
  // names must be equal
  return 0
}

const compareNames = (a, b) => {
  const nameA = a.last_name.toUpperCase() // ignore upper and lowercase
  const nameB = b.last_name.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  // names must be equal
  return 0
}

// second solution

export const fetchBookingsData = async () => {
  const response = await client.get(
    '/bookings',
    DEFAULT_BOOKINGS_PARAMS,
  )
  const bookingsSortedData = response.bookings.sort(compareBeginsAt)
  return bookingsSortedData
}

export const fetchcalendarEntriesData = async () => {
  const response = await client.get(
    '/calendar_entries',
    DEFAULT_CALENDAR_ENTRIES_PARAMS,
  )
  return response.calendar_entries
}

export const fetchReferralsData = async () => {
  const response = await client.get(
    '/referrals',
    DEFAULT_REFERRALS_PARAMS,
  )
  const referralsSortedData = response.referrals.sort(compareBeginsAt)
  return referralsSortedData
}

export const fetchServiceProvidersData = async () => {
  const response = await client.get(
    '/service_providers',
    DEFAULT_SERVICE_PROVIDERS_PARAMS,
  )
  const serviceProvidersSortedData = response.service_providers.sort(
    compareNames,
  )
  serviceProvidersSortedData.forEach(
    (sp) => (
      (sp.color = randomColorGenerator()),
      (sp.checked = false),
      (sp.coverageChecked = false),
      (sp.routeChecked = false),
      (sp.routeOptimizedChecked = false)
    ),
  )
  return serviceProvidersSortedData
}

export const fetchProductsData = async () => {
  const response = await client.get(
    '/products',
    DEFAULT_PRODUCTS_PARAMS,
  )
  const productsDetailed = response.products
  productsDetailed.forEach((product) => {
    if (/-pu$/.test(product.slug)) {
      product.sector = 'offentlig'
    } else if (/-pr$/.test(product.slug)) {
      product.sector = 'privat'
    } else if (/-or$/.test(product.slug)) {
      product.sector = 'bedrift'
    }
  })
  const productsList = response.products.map(
    (product) => product.name,
  )
  return { list: productsList, productsDetailed: productsDetailed }
}
// each async function return a promise with the
export const fetchDataset = () => {
  return (dispatch) => {
    return Promise.all([
      fetchBookingsData(),
      fetchcalendarEntriesData(),
      fetchReferralsData(),
      fetchProductsData(),
      fetchServiceProvidersData(),
    ])
      .then(
        ([
          bookings,
          calendarEntries,
          referrals,
          products,
          serviceProviders,
        ]) =>
          dispatch({
            type: 'DATA/ALL_DATA_SET',
            payload: {
              bookings,
              calendarEntries,
              referrals,
              products,
              serviceProviders,
            },
          }),
      )
      .then(() =>
        dispatch({
          type: 'DATA/ALL_LOADED',
          payload: true,
        }),
      )
      .catch((err) => {
        alert(err)
        Sentry.captureException(err)
      })
  }
}

// individual dispatch not in use :

// export const fetchBookingsData = () => async (dispatch) => {
//   const response = await client.get(
//     '/bookings',
//     DEFAULT_BOOKINGS_PARAMS,
//   )
//   const bookingsSortedData = response.bookings.sort(compareBeginsAt)
//   return dispatch({
//     type: 'DATA/BOOKINGS_LOADED',
//     payload: bookingsSortedData,
//   })
// }

// export const fetchReferralsData = () => async (dispatch) => {
//   const response = await client.get(
//     '/referrals',
//     DEFAULT_REFERRALS_PARAMS,
//   )
//   const referralsSortedData = response.referrals.sort(compareBeginsAt)
//   return dispatch({
//     type: 'DATA/REFERRALS_LOADED',
//     payload: referralsSortedData,
//   })
// }

// export const fetchServiceProvidersData = () => async (dispatch) => {
//   const response = await client.get(
//     '/service_providers',
//     DEFAULT_SERVICE_PROVIDERS_PARAMS,
//   )
//   const serviceProvidersSortedData = response.service_providers.sort(
//     compareNames,
//   )
//   serviceProvidersSortedData.forEach(
//     (sp) => (sp.color = randomColorGenerator()),
//   )
//   return dispatch({
//     type: 'DATA/SERVICE_PROVIDERS_LOADED',
//     payload: serviceProvidersSortedData,
//   })
// }

// export const fetchServicesData = () => async (dispatch) => {
//   const response = await client.get(
//     '/services',
//     DEFAULT_SERVICES_PARAMS,
//   )
//   const servicesList = response.services.map(
//     (service) => service.name,
//   )
//   return dispatch({
//     type: 'DATA/SERVICES_LOADED',
//     payload: servicesList,
//   })
// }

// export const fetchAllData = () => async (dispatch) => {
//   await Promise.all([
//     dispatch(fetchBookingsData()),
//     dispatch(fetchReferralsData()),
//     dispatch(fetchServicesData()),
//     dispatch(fetchServiceProvidersData()),
//   ])
//   return dispatch({
//     type: 'DATA/ALL_LOADED',
//     payload: true,
//   })
// }
