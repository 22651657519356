import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from './inputs/Input'

class FormField extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.element,
    inputClass: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    inputClass: '',
  }

  renderInner(inputProps) {
    if (this.props.children) {
      return React.cloneElement(this.props.children, inputProps)
    }
    return <Input {...inputProps} />
  }

  render() {
    const {
      label,
      className,
      id,
      inputClass,
      ...inputProps
    } = this.props

    inputProps.className = `form-control ${inputClass}`
    inputProps.id = id
    if (!inputProps.placeholder) inputProps.placeholder = label

    return (
      <div className={`form-group ${className}`}>
        <label htmlFor={id} className="sr-only">
          {label}
        </label>
        {this.renderInner(inputProps)}
      </div>
    )
  }
}

export default FormField
