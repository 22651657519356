import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AddressInput from './fields/Address'
import CityInput from './fields/City'
import ZipInput from './fields/Zip'

class FullAddressInput extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    onChangeText: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      addressValid: false,
      cityValid: false,
      zipValid: false,
    }
  }

  formIsValid() {
    return (
      this.state.addressValid &&
      this.state.cityValid &&
      this.state.zipValid
    )
  }

  updateFormValidState = (updatedText) => {
    this.props.onChangeText(this.formIsValid(), updatedText)
  }

  handleAddressHange = (text, valid) =>
    this.setState({ addressValid: valid }, () =>
      this.updateFormValidState({ address: text }),
    )

  handleAddressRef = (ref) => (this.addressInput = ref)

  handleZipChange = (text, valid) =>
    this.setState({ zipValid: valid }, () =>
      this.updateFormValidState({ zip: text }),
    )

  handleCityChange = (text, valid) =>
    this.setState({ cityValid: valid }, () =>
      this.updateFormValidState({ city: text }),
    )

  render() {
    return (
      <>
        <AddressInput
          onChangeText={this.handleAddressHange}
          initialValue={this.props.booking.address}
          isValid={this.state.addressValid}
          showError={this.state.showError}
          onRef={this.handleAddressRef}
          placeholder="Gateadresse"
        />

        <ZipInput
          onChangeText={this.handleZipChange}
          initialValue={this.props.booking.zip}
          isValid={this.state.zipValid}
          showError={this.state.showError}
        />

        <CityInput
          onChangeText={this.handleCityChange}
          initialValue={this.props.booking.city}
          isValid={this.state.cityValid}
          showError={this.state.showError}
        />
      </>
    )
  }
}

export default FullAddressInput
