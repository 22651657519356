import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '../forms/fields/Button'
import ClinicPatientListItem from './ClinicPatientListItem'
import { patientShape } from '../appointments/propShapes'
import ClinicContext from './ClinicContext'

const EMPTY_PATIENT = {
  id: 'new',
  name: '',
  phoneNumber: '',
  nationalId: '',
  appearedAt: '',
  readyAt: '',
  sysvacRegisteredAt: '',
  options: {},
}

const ClinicPatientList = (props) => {
  const {
    clinic,
    hackConsentOptions,
    isMassVaccineBooking,
  } = useContext(ClinicContext)

  const [patients, setPatients] = useState([])
  const [patientToSave, setPatientToSave] = useState({})
  const [editing, setEditing] = useState(false)

  const emptyPatient = !!clinic
    ? EMPTY_PATIENT
    : {
        ...EMPTY_PATIENT,
        options: hackConsentOptions,
        whoIsPaying: 'user',
      }

  useEffect(() => {
    const checkIfOptionsAreSet = (patient) => {
      if (!patient.options) {
        patient.options = hackConsentOptions
      }
      return patient
    }
    const newPatients = props.patients.map((p) =>
      checkIfOptionsAreSet(p),
    )
    setPatients(newPatients)
  }, [props.patients])

  const addPatient = () => {
    setPatients((prev) => [...prev, emptyPatient])
    setEditing(true)
  }

  const handlePatientDelete = (deletedPatient) => {
    const newPatientList = patients.filter(
      (p) => p.id != deletedPatient.id,
    )
    setPatients(newPatientList)
    setPatientToSave(deletedPatient)
    setEditing(false)
  }

  const handlePatientSave = (patient, wasCancelled = false) => {
    if (wasCancelled) {
      setPatients((prev) =>
        prev.filter((p) => p.id != emptyPatient.id),
      )
    } else {
      const patientsWithoutEmpty = patients.filter(
        (p) => p.id != emptyPatient.id,
      )
      const updatedPatients = patientsWithoutEmpty.map((p) =>
        p.id == patient.id ? patient : p,
      )
      const newPatientList =
        patient.id == emptyPatient.id
          ? [...patientsWithoutEmpty, patient]
          : updatedPatients
      setPatients(newPatientList)
      setPatientToSave(patient)
    }
    setEditing(false)
  }

  useEffect(() => {
    if (Object.keys(patientToSave).length == 0) return

    props.onListModification(patientToSave, patients)
    setPatientToSave({})
  }, [patientToSave])

  return (
    <>
      <h2 className="h5">Pasienter</h2>
      <div className="clinic-list mb-5">
        <div className="row header">
          <div className="col-1 table-heading" title="Ankommet">
            Ank.
          </div>
          <div className="col-2 table-heading">Navn</div>
          <div className="col-2 table-heading">Telefonnummer</div>
          <div className="col-2 table-heading">Personnummer</div>
          <div
            className="col-2 small table-heading"
            title="Status for samtykkeskjema"
          >
            Samtykke
          </div>
          <div
            className="col-1 small table-heading"
            title="Vaksinert. Hjemmelegene skal fakturere for denne pasienten"
          >
            Vaksinert
          </div>
          <div
            className="col-1 small table-heading"
            title="Vaksinen er journalført og registrert i Sysvak"
          >
            Journalført
          </div>
          <div className="col-1"></div>
        </div>
        {patients.map((patient, i) => (
          <ClinicPatientListItem
            key={i}
            patient={patient}
            onSave={handlePatientSave}
            onDelete={handlePatientDelete}
            editable={props.editable}
            className={i == patients.length - 1 ? 'last' : ''}
            isLastItem={patients.length == 1}
            appointment={props.appointment}
            onCirclesClick={props.onCirclesClick}
          />
        ))}
        <div className="row last"></div>
        {!editing && props.editable && !isMassVaccineBooking && (
          <Button
            className="btn btn-outline-primary btn-intext m-0 mt-4"
            onClick={addPatient}
            label="Legg til pasient"
          />
        )}
      </div>
    </>
  )
}

ClinicPatientList.propTypes = {
  patients: PropTypes.arrayOf(patientShape),
  editable: PropTypes.bool,
  onListModification: PropTypes.func,
  appointment: PropTypes.object,
  onCirclesClick: PropTypes.func,
}

export default ClinicPatientList
