import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import moment from 'moment-timezone'

const SUPPORTED_LOCALES = {
  nb: { engName: 'Norwegian', locName: 'Norsk' },
  en: { engName: 'English', locName: 'English' },
  pl: { engName: 'Polish', locName: 'Polski' },
}

const LanguageSwitcher = ({
  className,
  defaultLocale,
  onChange,
  flags,
}) => {
  const [locale, setLocale] = useState(defaultLocale)
  const [open, setOpen] = useState(false)
  const locales = SUPPORTED_LOCALES

  moment.locale(locale)

  const toggleOpen = () => setOpen(!open)

  const handleLocaleSelect = (e) => {
    e.preventDefault()
    const locale = e.target.getAttribute('data-locale')
    setLocale(locale)
    onChange(locale)
    toggleOpen()
  }

  const renderMenuItem = (loc) => (
    <li key={loc}>
      <a href="#" data-locale={loc} onClick={handleLocaleSelect}>
        <span className="mx-2">
          <img
            src={flags[loc]}
            alt={`${SUPPORTED_LOCALES[loc].engName} flag`}
          />
        </span>
        {SUPPORTED_LOCALES[loc].engName}
      </a>
    </li>
  )

  return (
    <div className={className}>
      <div className="language-switcher">
        Language:
        <Button className="btn-link m-0" onClick={toggleOpen}>
          <span className="mx-2 my-0">
            <img
              src={flags[locale]}
              alt={`${SUPPORTED_LOCALES[locale].engName} flag`}
            />
          </span>
          {locales[locale].locName}
        </Button>
        {open && (
          <nav className="select-locale ishadow">
            <ul>
              {Object.keys(SUPPORTED_LOCALES).map((loc) =>
                renderMenuItem(loc),
              )}
            </ul>
          </nav>
        )}
      </div>
    </div>
  )
}

LanguageSwitcher.SUPPORTED_LOCALES = SUPPORTED_LOCALES

LanguageSwitcher.defaultProps = {
  defaultLocale: 'nb',
  className: '',
}

LanguageSwitcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultLocale: PropTypes.string,
  className: PropTypes.string,
  flags: PropTypes.object.isRequired,
}

export default LanguageSwitcher
