import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import GooglePlacesAutocompleteInput from './GooglePlacesAutocompleteInput'
import EmailInput from './forms/fields/EmailInput'
import NameInput from './forms/fields/NameInput'
import PhoneInput from './forms/fields/PhoneInput'
import ZipInput from './forms/fields/ZipInput'
import CityInput from './forms/fields/CityInput'
import PasswordInput from './user_registration_form/PasswordInput'
import PasswordConfirmationInput from './user_registration_form/PasswordConfirmationInput'
import RegistrationButton from './user_registration_form/RegistrationButton'
import PhoneValidationModal from './PhoneValidationModal'
import ExistingUserWarningModal from './ExistingUserWarningModal'
import RailsForm from './RailsForm'
import AddressDirectionsTextarea from './user_registration_form/AddressDirectionsTextarea'
import {
  v_city,
  v_phone,
  v_streetAddress,
  v_email,
  v_name,
  v_zip,
  ValidateZip,
} from '../lib/validations'
class UserRegistrationForm extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    action: PropTypes.string.isRequired,
    signInPath: PropTypes.any,
    recoverPasswordPath: PropTypes.any,
    googleMapsApiKey: PropTypes.string,
    authenticityToken: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      nameInputValid: v_name(this.props.user.name || ''),
      emailInputValid: v_email(this.props.user.email || ''),
      cityInputValid: v_city(this.props.user.city || ''),
      addressInputValid: v_streetAddress(
        this.props.user.address || '',
      ),
      phoneInputValid: v_phone(this.props.user.phone_number),
      zipInputValid: v_zip(this.props.user.zip),
      passwordInputValid: false,
      password: '',
      passwordConfirmationInputValid: false,
      locationInputValid: false,
      phoneNumber: '',
      email: '',
      showError: false,
      infoNotFound: false,
      errorClass: '',
      showExistingUserWarningModal: false,
      errorMessage: '',
      zipNonExistant: false,
      googleAddress: '',
    }
  }

  submitForm = () => {
    this.form.submit()
  }

  formIsValid = () => {
    if (this.props.action === 'post') {
      const inputsValid = _.every([
        this.state.nameInputValid,
        this.state.addressInputValid,
        this.state.phoneInputValid,
        this.state.emailInputValid,
        this.state.zipInputValid,
        this.state.cityInputValid,
        this.state.passwordInputValid,
        this.state.passwordConfirmationInputValid,
      ])
      return inputsValid
    } else if (this.props.action === 'patch') {
      const inputsValid = _.every([
        this.state.nameInputValid,
        this.state.addressInputValid,
        this.state.phoneInputValid,
        this.state.emailInputValid,
        this.state.zipInputValid,
        this.state.cityInputValid,
      ])
      return inputsValid
    }
  }

  validateZipExist = (zip) => {
    if (ValidateZip(zip)) {
      this.setState({ zipInputValid: true })
      this.setState({ zipNonExistant: false })
    } else {
      this.setState({ zipInputValid: false })
      this.setState({ zipNonExistant: true })
    }
  }

  validateCity = (city) => {
    if (city && v_city(city)) {
      this.setState({ cityInputValid: true })
    } else {
      this.setState({ cityInputValid: false })
    }
  }

  directSubmitOrOtp = () => {
    const signedInPhoneNumber = this.props.user['phone_number']
    if (
      this.props.isSignedIn &&
      signedInPhoneNumber == this.state.phoneNumber
    ) {
      this.submitForm()
    } else {
      this.setState({ showModal: true })
    }
  }

  showErrorDescription() {
    if (this.state.showError && !this.formIsValid()) {
      return 'Obs: Fyll ut alle nødvendige felter først!'
    }
  }

  handlePhoneChange = (value, valid) => {
    this.setState({
      phoneNumber: value,
      phoneInputValid: valid,
    })
  }

  renderExistingUserModal = () => {
    this.setState({ showExistingUserWarningModal: true })
  }

  handleExistenceCheck = () => {
    this.existenceVerificationCall()
  }

  existenceVerificationCall = () => {
    $.post('existence_verifications', {
      phone_number: this.state.phoneNumber,
      email: this.state.email,
      action_type: this.props.action,
    }).done((resp) => {
      if (resp.existence_status === 'existing_user') {
        this.setState({ errorMessage: resp.error_message })
        this.renderExistingUserModal()
        if (resp.input == 'phone_number_and_email') {
          this.setState({
            phoneInputValid: false,
            emailInputValid: false,
          })
        } else if (resp.input == 'phone_number') {
          this.setState({
            phoneInputValid: false,
          })
        } else if (resp.input == 'email') {
          this.setState({
            emailInputValid: false,
          })
        }
      } else if (
        resp.existence_status === 'new_user' ||
        'new_input'
      ) {
        if (resp.input == 'phone_number_and_email') {
          this.setState({
            phoneInputValid: true,
            emailInputValid: true,
          })
        } else if (resp.input == 'phone_number') {
          this.setState({
            phoneInputValid: true,
          })
        } else if (resp.input == 'email') {
          this.setState({
            emailInputValid: true,
          })
        }
      }
    })
  }

  handleNameChange = (text, valid) =>
    this.setState({ nameInputValid: valid })

  handleEmailChange = (text, valid) =>
    this.setState({
      email: text,
      emailInputValid: valid,
    })

  handlePasswordChange = (text, valid) =>
    this.setState({
      password: text,
      passwordInputValid: valid,
    })

  handlePasswordRef = (ref) => (this.PasswordInput = ref)

  handlePasswordConfirmationChange = (text, valid) =>
    this.setState({ passwordConfirmationInputValid: valid })

  handlePasswordconfirmationRef = (ref) =>
    (this.PasswordConfirmationInput = ref)

  handleAddressDirectionsChange = (text) =>
    this.setState({ addressDirections: text })

  // getPatientInfo = (attr) =>
  //   this.props.patient ? this.props.patient[`${attr}`] : ''

  disabledOnSubmit = () => this.setState({ showError: true })

  renderPassword = () => {
    if (this.props.action === 'post') {
      return (
        <div>
          <div className="booking-form__input-group-heading mb-0 pb-0">
            Passord
          </div>
          <div className="booking-form__input-group">
            <PasswordInput
              onChangeText={this.handlePasswordChange}
              initialValue={''}
              isValid={this.state.passwordInputValid}
              showError={this.state.showError}
              onRef={this.handlePasswordRef}
            />
          </div>
          <div className="booking-form__input-group">
            <PasswordConfirmationInput
              onChangeText={this.handlePasswordConfirmationChange}
              initialValue={''}
              password={this.state.password}
              isValid={this.state.passwordConfirmationInputValid}
              showError={this.state.showError}
              onRef={this.handlePasswordConfirmationRef}
            />
          </div>
        </div>
      )
    }
  }

  closeModal = () => this.setState({ showModal: false })

  closeExistingUserWarningModal = () =>
    this.setState({ showExistingUserWarningModal: false })

  getAddress = (value, valid, _name) => {
    this.setState({ addressInputValid: valid }, () => {
      this.setState({ zipInputValid: valid })
      this.setState({ cityInputValid: valid })
      this.setState({ googleAddress: value })
    })
  }

  render() {
    return (
      <RailsForm
        ref={(ref) => {
          this.form = ref
        }}
        onSubmit={this.submitForm}
        action={this.props.path}
        method={this.props.action}
        className="hlform"
        name="minside_user"
      >
        <div className="row">
          <div className="col-12">
            <div className="booking-form__input-group-heading">
              Mobilnummer
            </div>
            <div className="row">
              <div className="col-12">
                <div className="booking-form__input-group booking-form__phone-wrapper">
                  <PhoneInput
                    onChange={this.handlePhoneChange}
                    onBlur={this.handleExistenceCheck}
                    value={this.props.user.phone_number || ''}
                    name="user[phone_number]"
                    shadow={false}
                    isRequired
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex hlform">
              <NameInput
                onChange={this.handleNameChange}
                value={this.props.user.name || ''}
                valid={this.state.nameInputValid}
                highlightErrors={this.state.showError}
                className="flex-grow-1 mr-1"
                name="user[name]"
              />
              <EmailInput
                onChange={this.handleEmailChange}
                onBlur={this.handleExistenceCheck}
                value={this.props.user.email || ''}
                valid={this.state.emailInputValid}
                highlightErrors={this.state.showError}
                name="user[email]"
              />
            </div>
            <div className="hlform">
              <GooglePlacesAutocompleteInput
                googleMapsApiKey={this.props.googleMapsApiKey}
                onComplete={this.getAddress}
                initialValue={this.props.user.address || ''}
                name="user[patient_attributes][address]"
              />
              <div className="d-flex mt-2">
                <ZipInput
                  initialValue={this.props.user.zip || ''}
                  value={
                    this.state.googleAddress?.zip ||
                    this.props.user.zip
                  }
                  valid={this.state.zipInputValid}
                  className="flex-grow-1 mr-2"
                  showValidation={false}
                  readOnly
                  name="user[patient_attributes][zip]"
                />
                <CityInput
                  initialValue={this.props.user.city || ''}
                  value={
                    this.state.googleAddress?.city ||
                    this.props.user.city
                  }
                  valid={this.state.cityInputValid}
                  showValidation={false}
                  readOnly
                  name="user[patient_attributes][city]"
                />
              </div>
            </div>
            <div className="booking-form__input-group-heading">
              Veibeskrivelse og parkering (for behandleren)
            </div>
            <AddressDirectionsTextarea
              initialValue={this.props.user.address_directions || ''}
              className="form-control"
              placeholder='Gjør det lettere for oss å finne fram til deg og finne et bra sted å parkere, f.eks. "Rødt hus i enden av veien. Gateparkering er greit".'
              onChangeText={this.handleAddressDirectionsChange}
            />
            {this.renderPassword()}
          </div>
          <div className="col-12">
            <p className="booking-form__input-description booking-form__input-description--error">
              {this.showErrorDescription()}
            </p>
          </div>
        </div>

        <div className="row mt-1 mb-3">
          <div className="col-12 text-sm-center">
            <label className="booking-form__label">
              <div className="booking-form__checkbox-wrapper">
                <input
                  type="checkbox"
                  name="user[marketing_consent]"
                  defaultChecked={this.props.user.marketing_consent}
                />
              </div>
              <div className="booking-form__checkbox-text">
                Send meg gjerne E-post når Hjemmelegene har relevante
                nyheter for meg. Du kan når som helst melde deg av.
              </div>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-md-center">
            <RegistrationButton
              isDisabled={!this.formIsValid()}
              onSubmit={this.directSubmitOrOtp}
              disabledOnSubmit={this.disabledOnSubmit}
            />
            <PhoneValidationModal
              isOpen={this.state.showModal}
              close={this.closeModal}
              submit={this.submitForm}
              phoneNumber={this.state.phoneNumber}
              loginMode={false}
              authenticityToken={this.props.authenticityToken}
              buttonLabel="Bekreft"
            />
            <ExistingUserWarningModal
              isOpen={this.state.showExistingUserWarningModal}
              close={this.closeExistingUserWarningModal}
              signInPath={this.props.signInPath}
              recoverPasswordPath={this.props.recoverPasswordPath}
              errorMessage={this.state.errorMessage}
              action={this.props.action}
            />
          </div>
        </div>
      </RailsForm>
    )
  }
}

export default UserRegistrationForm
