import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FormProgress extends Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { currentStep: this.props.currentStep }
  }

  render() {
    const current = this.state.currentStep
    return (
      <ul className="form-progress">
        {this.props.steps.map((step, i) => (
          <li key={i} className={i == current ? 'current' : ''}>
            {step}
          </li>
        ))}
      </ul>
    )
  }
}

export default FormProgress
