import { Loader } from '@googlemaps/js-api-loader'

export async function loadGoogleMap(googleMapsApiKey) {
  const loader = new Loader({
    apiKey: googleMapsApiKey,
    version: 'weekly',
    mapIds: ['a8a3a2d31870ba87'],
  })
  try {
    await loader.load()
  } catch (error) {
    Sentry.captureException(error)
  }
}
