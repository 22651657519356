import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, post } from '../lib/backend-client'
import Textarea from './forms/fields/Textarea'
import Button from './Button'
import Comment from './Comment'

const CommentList = (props) => {
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [busy, setBusy] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  React.useEffect(() => {
    fetchComments()
  }, [])

  const fetchComments = async () => {
    try {
      const response = await get(props.commentsListPath)
      if (response.success) {
        setComments(response.comments)
        setLoaded(true)
      } else {
        setErrorMessage(response.errors.join(', '))
      }
    } catch (e) {
      if (e.responseJSON) {
        setErrorMessage(e.responseJSON.errors.join(', '))
      } else {
        setErrorMessage(e.statusText)
      }
    }
  }

  const submit = async (path, params) => {
    setIsTyping(false)
    setBusy(true)
    try {
      const response = await post(path, params)
      if (response.success) {
        fetchComments()
        setNewComment(null)
      } else {
        setErrorMessage(response.errors.join(', '))
      }
    } catch (e) {
      if (e.responseJSON) {
        setErrorMessage(e.responseJSON.errors.join(', '))
      } else {
        setErrorMessage(e.statusText)
      }
    }
    setBusy(false)
  }

  const handleChangeCommentText = (value, valid) => {
    value.length < 1 ? setIsTyping(false) : setIsTyping(true)
    setIsValid(valid)
    setNewComment(value)
  }

  const submitComment = async () => {
    submit(props.commentsListPath, {
      comment: {
        text: newComment,
      },
    })
    setNewComment(null)
  }

  return (
    <div className="comment-box my-3">
      <h2 className="h6">{`${comments.length} kommentarer`}</h2>

      {props.currentUserType == 'ServiceProvider' && (
        <p className="text-danger">
          HUSK: Pasientopplysninger og annen sensitiv informasjon skal
          i journal, IKKE her. Beskjeder som trenger oppfølging av
          andre må du også varsle om på Slack. Alle i HL kan se hva du
          skriver her, men ingen blir varslet om nye kommentarer.
        </p>
      )}

      {loaded && (
        <div>
          {comments && (
            <div className="comment-list px-0 mb-0">
              {comments.map((comment, i) => (
                <Comment
                  key={comment.id}
                  index={i}
                  comment={comment}
                  currentUser={props.currentUser}
                  currentUserType={props.currentUserType}
                  commentPath={props.commentsListPath}
                  onChange={fetchComments}
                />
              ))}
            </div>
          )}
          <Textarea
            minLength={2}
            maxLength={500}
            showError={true}
            value={newComment || ''}
            isValid={isValid || !isTyping}
            onChangeText={handleChangeCommentText}
          />
          <Button
            label="Legg til kommentar"
            onClick={submitComment}
            className="btn btn-compact btn-primary mt-2"
            disabled={busy || !isValid}
          ></Button>
          {errorMessage && (
            <p className="text-danger">{errorMessage}</p>
          )}
        </div>
      )}
    </div>
  )
}

CommentList.propTypes = {
  commentsListPath: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentUserType: PropTypes.string.isRequired,
}

export default CommentList
