import _ from 'lodash'

const polygonStringToHash = (polygonString) => {
  if (polygonString == null) return null
  let polygon = polygonString.replace('POLYGON ((', '')
  polygon = polygon.replace('))', '')
  polygon = polygon.split(', ')

  return _.map(polygon, (latlng) => {
    const latlngArray = latlng.split(' ')
    return {
      lat: parseFloat(latlngArray[1]),
      lng: parseFloat(latlngArray[0]),
    }
  })
}

const buildPolygon = (props) => {
  const polygon = new google.maps.Polygon({
    paths: polygonStringToHash(props.data),
    editable: false,
    strokeColor: props.color,
    strokeOpacity: 0.5,
    fillColor: props.color,
    fillOpacity: 0.3,
  })

  return polygon
}

export default buildPolygon
