import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CityInput from '../forms/fields/CityInput'
import ZipInput from '../forms/fields/ZipInput'
import GooglePlacesAutocompleteInput from '../GooglePlacesAutocompleteInput'
import { v_streetAddress, v_city, v_zip } from '../../lib/validations'

class FullAddressInput extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    bookingClass: PropTypes.string.isRequired,
    transferValid: PropTypes.func,
    googleMapsApiKey: PropTypes.string.isRequired,
  }

  static defaultProps = {
    bookingClass: 'booking',
  }

  constructor(props) {
    super(props)

    this.state = {
      addressValid:
        v_streetAddress(props.booking.address) ||
        v_streetAddress(props.booking?.user?.patient?.address),
      cityValid:
        v_city(props.booking.city) ||
        v_city(props.booking?.user?.patient?.city),
      zipValid:
        v_zip(props.booking.zip) ||
        v_zip(props.booking?.user?.patient?.zip),
      address: {
        street:
          props.booking.address ||
          props.booking?.user?.patient?.address,
        zip: props.booking.zip || props.booking?.user?.patient?.zip,
        city:
          props.booking?.city || props.booking?.user?.patient?.city,
      },
    }
  }

  formIsValid() {
    return (
      this.state.addressValid &&
      this.state.cityValid &&
      this.state.zipValid
    )
  }

  getAddress = (value, valid, _name) => {
    this.setState(
      { addressValid: valid, zipValid: valid, cityValid: valid },
      () => {
        this.setState({ address: value })
        if (this.props.transferValid) this.props.transferValid(valid)
      },
    )
  }

  render() {
    return (
      <div className="hlform">
        <GooglePlacesAutocompleteInput
          googleMapsApiKey={this.props.googleMapsApiKey}
          onComplete={this.getAddress}
          initialValue={this.state.address?.street || ''}
        />
        <input
          type="hidden"
          value={`${this.state.address.street}${
            this.state.address.number
              ? ' ' + this.state.address.number
              : ''
          }`}
          name={`${this.props.bookingClass}[address]`}
          id="booking[address]"
        />

        <div className="d-flex space-between justify-content-between mt-2">
          <ZipInput
            onChange={() => {}}
            value={this.state.address?.zip || ''}
            showValidation={false}
            className="flex-grow-1 mr-2"
            name={`${this.props.bookingClass}[zip]`}
            readOnly
          />

          <CityInput
            onChange={() => {}}
            value={this.state.address?.city || ''}
            name={`${this.props.bookingClass}[city]`}
            showValidation={false}
            readOnly
          />
        </div>
      </div>
    )
  }
}

export default FullAddressInput
