import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { idType } from '../../appointments/propShapes'
import _ from 'lodash'

const TypeaheadInput = (props) => {
  const [candidate, setCandidate] = useState(-1)
  const [results, setResults] = useState([])
  const [displayValue, setDisplayValue] = useState('')

  useEffect(() => {
    setDisplayValue(props.value.name)
  }, [props.value])

  const handleChange = (e) => {
    const newVal = e.target.value
    setResults(props.dataProvider(newVal))
    setDisplayValue(newVal)
  }

  const onResultClick = (e) => {
    const id = e.target.getAttribute('data-id')
    const name = e.target.innerText
    setSelected({ id, name })
  }

  const setSelected = (newVal) => {
    setResults([])
    setCandidate(0)
    setDisplayValue('')
    const newValid = props.validate(newVal)
    if (props.onChange) props.onChange(newVal, newValid)
  }

  const onKeyNav = (e) => {
    if (results?.length < 1) return
    switch (e.keyCode) {
      case 38: // up
        setCandidate(
          candidate > 0 ? candidate - 1 : results.length - 1,
        )
        break
      case 40: // down
        setCandidate(
          candidate < results.length - 1 ? candidate + 1 : 0,
        )
        break
      case 13: // return
        const newVal = results[candidate]
        setSelected(newVal)
        break
    }
  }

  const handleInput = (e) => {
    setDisplayValue(props.sanitize(props.filter(e.target.value)))
  }

  const inputProps = _.pick(props, [
    'autoComplete',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'inputMode',
    'readOnly',
    'id',
  ])

  return (
    <div className="typeahead-input" onKeyUp={onKeyNav}>
      <input type="hidden" name={props.name} value={props.value.id} />
      <input
        type="text"
        value={displayValue}
        onChange={handleChange}
        onInput={handleInput}
        className={`input-toggle text ${
          props.editable ? 'editable' : ''
        }`}
        placeholder={props.label || props.name}
        disabled={!props.editable}
        {...inputProps}
      />
      {results.length > 0 && (
        <div className="typeahead-results">
          <ul>
            {results.map((res, i) => (
              <li
                key={res.id}
                data-id={res.id}
                onClick={onResultClick}
                className={i == candidate ? 'current' : ''}
              >
                {res.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

TypeaheadInput.defaultProps = {
  value: '',
  placeholder: '',
  autoComplete: 'off',
  highlightErrors: false,
  isRequired: false,
  disabled: false,
  showValidation: true,
  inputMode: 'text',
  editable: false,
  name: 'Text',
  validate: (value) => value?.length > 1,
  filter: (value) => value,
  sanitize: (value) => value,
}

TypeaheadInput.propTypes = {
  value: PropTypes.shape({
    id: idType.isRequired,
    name: PropTypes.string.isRequired,
  }),
  dataProvider: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  sanitize: PropTypes.func,
  filter: PropTypes.func,
  validate: PropTypes.func,
  maxLength: PropTypes.number,
  highlightErrors: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPlaceholder: PropTypes.string,
  attentionMessage: PropTypes.string,
  filter: PropTypes.func,
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  inputMode: PropTypes.string,
  onChange: PropTypes.func,
}

export default TypeaheadInput
