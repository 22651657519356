import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import ErrorMessage from './ErrorMessage'
import ReactModal from 'react-modal'
import UploadFileForm from './UploadFileForm'

const UploadFilePicker = ({
  recordId,
  recordType,
  fieldName,
  targetImageContainer,
  outputWidth,
  outputHeight,
}) => {
  ReactModal.setAppElement('body')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [error, setError] = useState(null)

  const modalStyle = {
    content: {
      width: '80%',
      WebkitOverflowScrolling: 'touch',
    },
  }

  const openModal = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const closeModal = (imgUrl) => {
    if (imgUrl) {
      $(targetImageContainer).html(renderOutputImage())
      window.location.reload()
    } else {
      setIsModalOpen(false)
    }
  }

  const openFilepickerDialog = (event) => {
    event.preventDefault()

    openModal(event)
  }

  const clearAvatar = (event) => {
    event.preventDefault()

    axios({
      method: 'post',
      url: '/discard_upload',
      data: JSON.stringify({
        field_name: fieldName,
        record_type: recordType,
        record_id: recordId,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.status == 200) {
          $(targetImageContainer).html(
            "<i class='icon icon-profile profile-picture__placeholder'></i>",
          )
          setError(null)
        } else {
          setError('Noe gikk galt med oppdatering av bildet')
        }
      })
      .catch((error) => {
        setError('Noe gikk galt med oppdatering av bildet')
      })
  }

  const imageNewUrl = () => ''
  // todo
  const renderOutputImage = () => {
    return (
      <img
        alt="Profilbilde"
        width={outputWidth}
        height={outputHeight}
        src={imageNewUrl()}
        className="image-circle"
      />
    )
  }

  return (
    <div>
      <div className="button-group profile-picture__action-wrapper">
        <a
          onClick={openFilepickerDialog}
          href="upload"
          className="button-group-item profile-picture__action"
        >
          Last opp bilde
        </a>
      </div>

      <div className="button-group">
        <a
          onClick={clearAvatar}
          href="clear"
          className="button-group-item profile-picture__action"
        >
          Fjern bilde
        </a>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Add new image"
        style={modalStyle}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <UploadFileForm
          recordId={recordId}
          recordType={recordType}
          fieldName={fieldName}
          submitCallback={closeModal}
        />
      </ReactModal>

      <ErrorMessage errorMessage={error} />
    </div>
  )
}

UploadFilePicker.propTypes = {
  recordId: PropTypes.string.isRequired,
  recordType: PropTypes.string,
  fieldName: PropTypes.string,
  targetImageContainer: PropTypes.string.isRequired,
  outputWidth: PropTypes.number,
  outputHeight: PropTypes.number,
}

UploadFilePicker.defaultProps = {
  outputWidth: 100,
  outputHeight: 100,
  fieldName: 'avatar_image',
  recordType: 'ServiceProvider',
}

export default UploadFilePicker
