import React from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import { useState } from 'react'

const InfoToggle = ({ buttonLabel, text, children }) => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }
  return (
    <>
      <p>
        <Button
          className="btn-link"
          label={buttonLabel}
          onClick={toggle}
        />
      </p>
      {open && text && <p>{text}</p>}
      {open && !text && <>{children}</>}
    </>
  )
}
InfoToggle.defaultProps = {
  buttonLabel: 'Show more',
}

InfoToggle.propTypes = {
  buttonLabel: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
}

export default InfoToggle
