import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

const modalStyle = {
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

class BookingExpiredModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    restartPath: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    ReactModal.setAppElement('.page__content')
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        contentLabel="Timen din er ikke lenger tilgjengelig"
        style={modalStyle}
        shouldCloseOnEsc={false}
      >
        <h1 className="ReactModal__heading">
          😢 Timener ikke lenger tilgjengelig
        </h1>
        <p>
          Forsvant du? Timen du valgte er tatt av noen andre fordi det
          var så stille her en stund.
        </p>
        <p>Håper du fortsatt finner en time som passer!</p>
        <a
          href={this.props.restartPath}
          className="receipt__link receipt__link--block"
          style={{ marginBottom: 0 }}
          data-turbolinks="false"
        >
          Velg ny time
        </a>
      </ReactModal>
    )
  }
}

export default BookingExpiredModal
