import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const CircleItem = (props) => {
  const defineCircleClass = (status) => {
    switch (status) {
      case 'done':
        return 'done'
      case 'notDone':
        return 'not-done'
      case 'invalid':
        return 'invalid'
    }
  }
  const inputProps = _.pick(props, [
    'name',
    'autoComplete',
    'maxLength',
    'pattern',
    'onKeyUp',
    'autoFocus',
    'onFocus',
    'onBlur',
    'inputMode',
    'readOnly',
    'id',
    'title',
  ])

  return (
    <div className={props.className} id={props.id} {...inputProps}>
      <div className={`${defineCircleClass(props.status)}`}></div>
    </div>
  )
}

CircleItem.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CircleItem
