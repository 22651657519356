import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { purchaseEvent } from '../../lib/tracking'
import Button from '../forms/fields/Button'
import StaticMap from '../StaticMap'
import {
  cancelAppointment,
  getPriceFromOrder,
  confirmAppointment,
} from './queries'

const Confirmation = ({
  appointment,
  patients,
  autoRestart,
  onUpdate,
  onError,
  comingFromSmsLink,
  reset,
  t,
}) => {
  const [countdown, setCountdown] = useState(15)
  const [cancelled, setCancelled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [totalNetPrice, setTotalNetPrice] = useState(0)
  const calendarLink = `/appointments/${appointment.code}.ics`

  const closeModal = () => {
    setIsModalOpen(false)
  }

  Modal.setAppElement('body')

  useEffect(() => {
    getTotalPrice()
    trackEcommerce()
  }, [])

  useEffect(() => {
    if (autoRestart) {
      const interval = setInterval(() => {
        const newVal = countdown - 1
        if (newVal >= 0) {
          setCountdown(countdown - 1)
        } else {
          clearInterval(interval)
          reset()
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [autoRestart, countdown, reset])

  const trackEcommerce = () => {
    if (!!appointment.booking) return

    const order = appointment.order
    if (!order || !order.lineItems || order.lineItems.length === 0)
      return errorEcommerceEvent

    purchaseEvent(order)
  }

  const getTotalPrice = async () => {
    const lineItems = appointment.order.lineItems
    if (!lineItems || lineItems.length == 0) return

    const prices = []
    for (const li of lineItems) {
      const response = await getPriceFromOrder(
        li.product.id,
        li.quantity,
      )
      prices.push(response.data.totalNetPrice)
    }
    const priceTotal = prices.reduce(
      (prev, next) => prev + next / 100,
      0,
    )
    setTotalNetPrice(priceTotal)
  }

  const renderDateString = (date) => {
    const dateString = moment(date).format('dddd Do MMMM')
    return `${
      dateString.charAt(0).toUpperCase() + dateString.slice(1)
    } ${moment(date).calendar(null, {
      sameDay: `[(${t('today')})]`,
      nextDay: `[(${t('tomorrow')})]`,
      nextWeek: `[]`,
      lastDay: `[]`,
      lastWeek: `[]`,
      sameElse: `[]`,
    })}`
  }

  const cancel = async () => {
    const response = await cancelAppointment(appointment.id, true)
    if (response.netError) return onError(response.errors)
    setIsModalOpen(false)
    setCancelled(true)
  }

  const appointmentContainsExtraServices = () =>
    appointment.order.lineItems.length > 1 &&
    appointment.order.lineItems[0].product.slug === 'loc-tst-c19-pu'

  const appointmentAddress = appointment.serviceCenter
    ? `${appointment.serviceCenter.street}, ${appointment.serviceCenter.zip} ${appointment.serviceCenter.city}`
    : `${appointment.booking.address}, ${appointment.booking.zip} ${appointment.booking.city}`

  return (
    <section>
      <h1>
        {cancelled ? t('headingCancelled', s) : t('heading', s)}
      </h1>
      {!cancelled && (
        <>
          <p className="h5">{t('preamble', s)}</p>
          {appointmentContainsExtraServices() && (
            <div className="card bg-danger p-2 my-3">
              <p className="text-white m-0">{t('callWarning', s)}</p>
            </div>
          )}
          <div className="row my-4">
            <div className="col-md-7">
              <dl className="dl-table">
                <dt>{t('time')}:</dt>
                <dd>
                  {`
              ${renderDateString(appointment.date)}
              ${t('atTime')}
              ${appointment.hour}
            `}
                </dd>
                {appointment.serviceCenter && (
                  <>
                    <dt>{t('location')}:</dt>
                    <dd>{appointment.serviceCenter.title}</dd>
                  </>
                )}

                {appointment.serviceCenter?.operationType !==
                  'mobile' && (
                  <>
                    <dt>{t('address', null, 's')}:</dt>
                    <dd>{appointmentAddress}</dd>
                  </>
                )}
                {appointment.appointmentsPatients ? (
                  <>
                    <dt>{t('persons', null, 's')}:</dt>
                    <dd>{patients.length || 1}</dd>
                  </>
                ) : null}

                {!appointment.booking ? (
                  <>
                    <dt>{t('products', s, 's')}:</dt>
                    <dd>
                      {appointment.order.lineItems
                        .map((li) => li.product.publicName)
                        .join(', ')}
                    </dd>
                    <dt>{t('totalPrice', s, 's')}:</dt>
                    <dd>{`kr ${totalNetPrice}`}</dd>
                  </>
                ) : null}
              </dl>
            </div>

            <div className="col-md-5 mt-4 mt-md-0">
              <StaticMap
                lat={
                  appointment.serviceCenter?.lat ||
                  appointment.booking.lat
                }
                lng={
                  appointment.serviceCenter?.lng ||
                  appointment.booking.lng
                }
                styles={{ height: '200px' }}
              />
            </div>
          </div>
        </>
      )}
      {appointment.productSlugs?.some((s) => s.includes('vac')) ? (
        <p>{t('personalVaccineConfirmation', s)}</p>
      ) : !cancelled && autoRestart ? (
        <p>{t('kioskConfirmation', s)}</p>
      ) : (
        !cancelled &&
        !autoRestart && <p>{t('personalConfirmation', s)}</p>
      )}

      {!cancelled && (
        <>
          <h2 className="h6">{`${t('directions', s)}`}</h2>
          <p> {t('clickForGoogleMaps', s)}</p>
          {appointment.serviceCenter?.directions && (
            <>
              <p>{appointment.serviceCenter.directions}</p>
            </>
          )}
          {!comingFromSmsLink ? (
            <>
              <Button className="btn-link mb-3" onClick={onUpdate}>
                {t('updateAppointment', s)}
              </Button>
              <Button
                className="btn-link text-danger mb-3"
                onClick={() => setIsModalOpen(true)}
              >
                {t('cancelAppointment', s)}
              </Button>
            </>
          ) : null}
        </>
      )}
      <div className="mb-3">
        <a className="btn-link" href={calendarLink} download>
          {t('calendar', s)}
        </a>
      </div>
      {cancelled && (
        <>
          <p>{t('cancelledConfirmation', s)}</p>
          <Button
            className="btn-primary mt-2 mb-4 ishadow w-100"
            onClick={reset}
          >
            {t('newAppointment', s)}
          </Button>
        </>
      )}
      <p>
        {`${t('website', s)} `}
        <a
          href={t('websiteUrl')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('website')}
        </a>
      </p>
      {!cancelled && (
        <>
          <Button className="btn-link mt-4" onClick={reset}>
            {autoRestart
              ? `${t('restarting', s)} ${countdown}...`
              : t('restart')}
          </Button>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        contentLabel="Confirm cancel appointment"
        onRequestClose={closeModal}
      >
        <div
          onClick={() => closeModal()}
          className="text-right"
          style={{
            cursor: 'pointer',
          }}
        >
          ⓧ
        </div>
        <section className="d-flex flex-column justify-content-center align-items-center">
          <h4 className="text-center">{`${t(
            'isSureCancellation',
            s,
          )}`}</h4>
          <Button
            onClick={cancel}
            label={`${t('confirm', s)}`}
            className="btn-danger w-75 mt-4"
          />
        </section>
      </Modal>
    </section>
  )
}

import { appointmentShape, patientShape } from './propShapes'

Confirmation.propTypes = {
  autoRestart: PropTypes.bool,
  appointment: appointmentShape.isRequired,
  comingFromSmsLink: PropTypes.bool,
  patients: PropTypes.arrayOf(
    PropTypes.oneOfType([patientShape, PropTypes.string]),
  ).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    heading: 'Timen er bekreftet',
    preamble:
      'Dette vinduet kan lukkes. Du vil motta all informasjon du trenger på SMS og E-post.',
    headingCancelled: 'Timen er avlyst',
    restarting: 'Starter på nytt om',
    newAppointment: 'Sett opp ny time',
    cancelAppointment:
      'Trenger du å avlyse timen, kan du klikke her.',
    cancelledConfirmation:
      'Alt i orden. Denne timen er avlyst og du trenger ikke gjøre mer. Hvis du likevel trenger time, er det bare å lage en ny.',
    kioskConfirmation:
      'Vi roper deg opp når det er din tur. Hold deg i nærheten så lenge er du snill!',
    personalConfirmation:
      'Vi er klare for deg på tidspunktet du har valgt. Møt opp presis for å unngå kødannelse.',
    personalVaccineConfirmation:
      'Vi er klare for deg på tidspunktet du har valgt. Møt opp presis for å unngå kødannelse.',
    callWarning:
      'Merk at du kan bli oppringt av oss før timen dersom vi trenger mer informasjon fra deg. Vi ringer da fra skjult nummer.',
    website:
      'Nysgjerrig på hva annet vi kan hjelpe deg med? Hjemmelegene har leger som kommer hjem til deg og dine. Se hva annet vi kan gjøre for deg og dine på nettsiden vår:',
    directions: 'Veibeskrivelse',
    updateAppointment: 'Ønsker du å endre timen, kan du klikke her.',
    calendar: 'Legg til kalender',
    clickForGoogleMaps:
      'Klikk på adressen over for å få nøyaktig veibeskrivelse med bil eller kollektivt fra Google Maps.',
    products: 'Tjenester',
    isSureCancellation: 'Er du sikker på at du vil avbestille timen?',
    confirm: 'Avbestill time',
    totalPrice: 'Sum',
  },
  en: {
    heading: 'Your appointment is confirmed',
    preamble:
      'You can close this window. All information you need will be sent on SMS og E-post.',
    headingCancelled: 'Your appointment has been cancelled',
    restarting: 'Starting over in',
    newAppointment: 'Create new appointment',
    cancelAppointment:
      'If you need to cancel the appointment, click here.',
    cancelledConfirmation:
      'Got it. This appointment has been cancelled. Go ahead and create a new appointment if you would like.',
    kioskConfirmation:
      "We will call your name when it's your turn. Please don't leave the area in the mean time",
    personalConfirmation:
      'We are ready for you at your assigned time. Please show up on time to prevent long waiting lines.',
    personalVaccineConfirmation:
      'We are ready for you at your assigned time. Please show up on time to prevent long waiting lines.',
    website:
      'Curious about what else we can help you with? Hjemmelegene are doctors and nurses who treat patients at home. Have a look at what else we can do for you and your family on our website:',
    directions: 'Directions',
    updateAppointment:
      'If you need to change the appointment, click here.',
    calendar: 'Add to calendar',
    clickForGoogleMaps:
      'Click the address above for precise directions by car or public transport from Google Maps.',
    products: 'Services',
    callWarning:
      'Note that we may need to call you before the appointment, in which case we will be calling from a hidden number.',
    isSureCancellation:
      'Are you sure you want to cancel the appointment?',
    confirm: 'Cancel appointment',
    totalPrice: 'Total price',
  },
  pl: {
    heading: 'Dokonano pomyślnej rezerwacji',
    preamble:
      'Możesz zamknąć to okno. Wszystkie informacje, których możesz potrzebować wyślemy Ci przez SMS i E-mail.',
    headingCancelled: 'Twój umówiony termin został anulowany',
    restarting: 'Rozpocznij od nowa',
    newAppointment: 'Rezerwuj nowy termin',
    cancelAppointment:
      'Jeśli potrzebujesz anulować zarezerwowaną wizytę, kliknij tutaj.',
    cancelledConfirmation:
      'Umówiona wizyta została właśnie anulowana. Jeśli chcesz, możesz umówić nową wizytę.',
    kioskConfirmation:
      'Gdy przyjdzie Twoja kolej, wyczytamy Twoje nazwisko. Prosimy o nie oddalanie się od punktu świadczenia usług.',
    personalConfirmation:
      'Będziemy na ciebie czekać w wybranym przez Ciebie czasie. Aby uniknąć długiej kolejki, prosimy o punktualne przybycie.',
    personalVaccineConfirmation:
      'Będziemy na ciebie czekać w wybranym przez Ciebie czasie. Aby uniknąć długiej kolejki, prosimy o punktualne przybycie.',
    website:
      'Zastanawiasz się, z czym jeszcze moglibyśmy Ci pomóc? Hjemmelegene to lekarze i pielęgniarki, którzy leczą swoich pacjentów dojeżdżając do ich domów. Przekonaj się co możemy jeszcze zrobić dla Ciebie i Twojej rodziny, wchodząc na stronę:',
    directions: 'Wskazówki dojazdu',
    calendar: 'Dodaj do kalendarza',
    clickForGoogleMaps:
      'Kliknij powyższy adres, aby uzyskać dokładne wskazówki dojazdu samochodem lub komunikacją miejską z Google Maps.',
    products: 'Usługi',
    callWarning:
      'Pamiętaj, że być może będziemy musieli zadzwonić przed wizytą, w takim przypadku będziemy dzwonić z ukrytego numeru.',
    isSureCancellation: 'Czy na pewno chcesz odwołać wizytę?',
    confirm: 'Potwierdzam odwołanie wizyty',
    totalPrice: 'Całkowita cena',
  },
}

export default Confirmation
