import React, { Component } from 'react'
import PropTypes from 'prop-types'

const NO_TEXT_STATUS = ['unavailable', 'selected', 'booked']

class Hour extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hourCount: PropTypes.number,
    hourLimit: PropTypes.number,
    isSubmitting: PropTypes.bool.isRequired,
    coverageMap: PropTypes.object,
    assetRootUrl: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      status: props.status,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isSubmitting && nextProps.isSubmitting) {
      return
    }

    this.setState({ status: nextProps.status })
  }

  handleClick = () => {
    if (!this.props.isSubmitting) {
      const status = this.state.status
      const time = this.props.time

      if (status == 'open') {
        this.props.onChange({ time: time, newStatus: 'selected' })
        this.setState({ status: 'selected' })
      } else if (status == 'selected') {
        this.props.onChange({ time: time, newStatus: 'open' })
        this.setState({ status: 'open' })
      }
    }
  }

  statusClass() {
    if (this.state.status == 'open') {
      return ''
    } else if (this.state.status == 'booked') {
      return 'hour-select--locked'
    } else if (this.state.status == 'selected') {
      return 'hour-select--selected'
    } else {
      return 'hour-select--unavailable'
    }
  }

  statusIcon() {
    if (this.state.status == 'open') {
      return <img src={this.props.assetRootUrl.circleIcon} />
    } else if (this.state.status == 'booked') {
      return <img src={this.props.assetRootUrl.lockIcon} />
    } else if (this.state.status == 'selected') {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: this.props.avatar }}
        />
      )
    } else {
      return <img src={this.props.assetRootUrl.timesIcon} />
    }
  }

  hourStatusClass() {
    if (NO_TEXT_STATUS.includes(this.state.status)) return null

    const coverage = this.props.hourCount / this.props.hourLimit

    if (coverage > 0.67) return null

    let modifier = ''
    if (coverage == 0) {
      modifier = 'medium'
    } else {
      return null
      modifier = 'medium'
    }

    return `hour-status--${modifier}`
  }

  coverageMapName() {
    const mapName = this.props.coverageMap?.name
    if (!mapName) return ''
    return mapName.length > 13
      ? mapName.substring(0, 13) + '...'
      : mapName
  }

  render() {
    return (
      <div
        className={`hour-select ${this.statusClass()} ${this.hourStatusClass()}`}
        onClick={this.handleClick}
      >
        <div className="hour-select__inner">
          {this.props.time}
          <div className="status-icon">{this.statusIcon()}</div>
          {this.state.status == 'selected' && (
            <span className="small">{this.coverageMapName()}</span>
          )}
        </div>
      </div>
    )
  }
}

export default Hour
