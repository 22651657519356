import React from 'react'
import PropTypes from 'prop-types'

const Submit = (props) => {
  const handleClick = (e) => {
    e.preventDefault()

    if (!props.disabled) {
      props.onSubmit()
    }
  }

  return (
    <button
      className={`input btn btn-secondary ${
        props.disabled ? 'disabled' : ''
      }`}
      onClick={handleClick}
      id="booking_button"
      disabled={props.disabled}
    >
      {props.label}
    </button>
  )
}

Submit.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}

Submit.defaultProps = {
  label: 'Neste',
}

export default Submit
