import React from 'react'
import moment from 'moment-timezone'
import ServiceProviderListItemDetailed from './ServiceProviderListItemDetailed'
import { useSelector } from 'react-redux'

const BookingTab = () => {
  const bookings = JSON.parse(
    JSON.stringify(useSelector((state) => state.dataset.bookings)),
  )
  const serviceProviders = JSON.parse(
    JSON.stringify(
      useSelector((state) => state.dataset.serviceProviders),
    ),
  )
  const dateSelected = useSelector((state) => state.dateSelected)

  const buildSpsBookingsData = () => {
    const spChecked = serviceProviders
      .filter((sp) => sp.checked === true)
      .map((sp) => sp.id)

    const spsBookings = serviceProviders.reduce((accum, sp) => {
      const bookingsToDisplayIds = bookings
        .filter(
          (booking) =>
            booking.service_provider_id === sp.id &&
            dateSelected ===
              moment(booking.begins_at)
                .tz('Europe/Oslo')
                .startOf('day')
                .format('yyyy-MM-DD'),
        )
        .map((booking) => booking.id)

      if (
        spChecked.includes(sp.id) &&
        bookingsToDisplayIds.length > 0
      ) {
        sp['bookingsList'] = bookings
          .filter(
            (booking) =>
              booking.service_provider_id === sp.id &&
              bookingsToDisplayIds.includes(booking.id),
          )
          .map((booking) => booking)
        const {
          lat,
          lng,
          first_name,
          last_name,
          address,
          color,
          id,
          bookingsList,
          avatar_url,
        } = sp

        return [
          ...accum,
          {
            lat,
            lng,
            first_name,
            last_name,
            address,
            color,
            id,
            bookingsList,
            avatar_url,
          },
        ]
      } else {
        return accum
      }
    }, [])
    return spsBookings
  }

  const displaySpsBookings = () => {
    const SpsBookingsToDisplay = buildSpsBookingsData().map((sp) => (
      <ServiceProviderListItemDetailed
        key={sp.id}
        serviceProvider={sp}
      />
    ))

    return SpsBookingsToDisplay
  }

  const handleCloseBookings = (e) => {
    const filter = document.querySelector(
      '.item-form__multiselect.for-booking',
    )
    filter.classList.toggle('expended')
    const nav = document.querySelector(
      '.route-dashboard__nav-item.for-booking',
    )
    nav.classList.toggle('selected')
  }

  return (
    <div className="item-form__multiselect for-booking">
      <div className="item-form__multiselect__clos-btn">
        <i
          className="fas fa-arrow-alt-circle-right"
          onClick={handleCloseBookings}
        ></i>
      </div>
      <ul className="list-group list-group--full-width">
        {displaySpsBookings()}
      </ul>
    </div>
  )
}

export default BookingTab
