import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../forms/fields/Button'
import StaticMap from '../../StaticMap'
import { serviceCenterProducts } from './queries'
import InfoToggle from '../../InfoToggle'

export function ServiceCenterSelector({
  productSlugs,
  onComplete,
  onError,
  showReset,
  reset,
  renderAppointmentSummary,
  t,
}) {
  const [
    availableServiceCenters,
    setAvailableServiceCenters,
  ] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  function onServiceCenterClick(serviceCenterId) {
    const selectedServiceCenter = availableServiceCenters.find(
      (serviceCenter) => serviceCenter.id === serviceCenterId,
    )
    onComplete(selectedServiceCenter)
  }

  useEffect(() => {
    async function fetchAvailableServiceCenters() {
      try {
        const { serviceCenters } = (
          await serviceCenterProducts({ productSlugs })
        ).data.serviceCenterProducts
        setAvailableServiceCenters(serviceCenters)
      } catch (error) {
        onError(error)
      }
    }
    if (isLoading) {
      fetchAvailableServiceCenters()
      setIsLoading(false)
    }
  }, [isLoading, productSlugs, onError])

  return (
    <section>
      {renderAppointmentSummary()}
      <h1 className="mb-4">{t('header', s)}</h1>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <span className="spinner-border text-primary ml-2" />
        </div>
      ) : (
        availableServiceCenters.map((serviceCenter) => (
          <div key={serviceCenter.id}>
            <ServiceCenter
              serviceCenter={serviceCenter}
              onSelect={() => onServiceCenterClick(serviceCenter.id)}
              t={t}
            />
          </div>
        ))
      )}
      {showReset && (
        <div className="mt-5">
          <Button
            className="btn-link"
            label={t('restart')}
            onClick={reset}
          />
        </div>
      )}
    </section>
  )
}

ServiceCenterSelector.propTypes = {
  productSlugs: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderAppointmentSummary: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  showReset: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

function ServiceCenter({ serviceCenter, onSelect, t }) {
  return (
    <div className="my-4">
      <div className="row my-5">
        <div className="col-md-7">
          <h2 className="h5">{`${serviceCenter.title}`}</h2>
          <p>{`${serviceCenter.street}, ${serviceCenter.zip} ${serviceCenter.city}`}</p>
          {/* <dl className="dl-table my-3">
            <dt>{t('wheelchair_access', s)}:</dt>
            <dd>
              {serviceCenter.wheelchair_accessible
                ? t('yes', s)
                : t('no', s)}
            </dd>
          </dl> */}
          {serviceCenter.openingHours && (
            <>
              <h3 className="d-none">{t('opening_hours', s)}:</h3>
              <InfoToggle
                buttonLabel={`${t('show')} ${t('opening_hours', s)}`}
              >
                <dl className="dl-table mb-2">
                  {serviceCenter.openingHours
                    .split('\n')
                    .map((line) => (
                      <Fragment key={line}>
                        <dt>{line.split(' ')[0]}</dt>
                        <dd>
                          {line.match(
                            /\d{2}[.:]\d{2}\s?.{1}\s?\d{2}[.:]\d{2}/,
                          )}
                        </dd>
                      </Fragment>
                    ))}
                </dl>
              </InfoToggle>
            </>
          )}
          <Button
            className="btn-primary btn-intext m-0 mt-2 ishadow"
            onClick={onSelect}
            label={`${t('select', s)} ${serviceCenter.title}`}
          />
        </div>
        <div
          className="col-md-5 mt-4 mt-md-0"
          style={{ height: '200px' }}
        >
          <StaticMap
            lat={serviceCenter.lat}
            lng={serviceCenter.lng}
          />
        </div>
      </div>
    </div>
  )
}

ServiceCenter.propTypes = {
  serviceCenter: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const s = {
  nb: {
    header: 'Velg din klinikk',
    select: 'Velg',
    wheelchair_access: 'Tilgjengelig med rullestol',
    yes: 'Ja',
    no: 'Nei',
    opening_hours: 'Åpningstider',
  },
  en: {
    header: 'Select your clinic',
    select: 'Select',
    wheelchair_access: 'Wheel chair access',
    yes: 'Ja',
    no: 'Nei',
    opening_hours: 'Opening hours',
  },
  pl: {
    header: 'Wybierz centrum',
    select: 'Wybierz',
    wheelchair_access: 'Dostęp dla wózków inwalidzkich',
    yes: 'Tak',
    no: 'Nie',
    opening_hours: 'Godziny otwarcia',
  },
}
