import React from 'react'
import PropTypes from 'prop-types'
import Shield from './Shield'

const ShieldedSpinner = ({ show = false, message = null }) => {
  return (
    <Shield show={show}>
      <span className="shield-spinner" />
      {message && <p>{message}</p>}
    </Shield>
  )
}

ShieldedSpinner.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
}

export default ShieldedSpinner
