import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HLForm from '../forms/HLForm'
import PhoneInput from '../forms/fields/PhoneInput'
import Button from '../forms/fields/Button'
import RadioButtons from '../forms/fields/RadioButtons.jsx'
import GreenCheckMark from '../../assets/GreenCheckMark'
import { sendLinkWithSms } from './queries'

const ConsentOverviewPatientItem = ({
  patient,
  actionTaken,
  updateDetails,
  t,
}) => {
  const [consentFormIsOpen, setConsentFormIsOpen] = useState(
    patient.consentFormIsOpen,
  )
  const [consentGiven, setConsentGiven] = useState(
    patient.consentGiven,
  )
  const [openSmsField, setOpenSmsField] = useState(false)
  const [smsSent, setSmsSent] = useState(patient.smsSent)
  const [smsError, setSmsError] = useState(false)
  const [willNotVaccinate, setWillNotVaccinate] = useState(
    patient.willNotVaccinate,
  )
  const [phoneNumber, setPhoneNumber] = useState(patient.phoneNumber)

  const toggleChoice = (value) => {
    switch (value) {
      case 'Open consent form':
        setOpenSmsField(false)
        setWillNotVaccinate(false)
        setConsentFormIsOpen(true)
        updateDetails({
          ...patient,
          openConsentForm: true,
          willNotVaccinate: false,
          smsSent: false,
        })
        actionTaken()
        break
      case 'Send sms':
        setConsentFormIsOpen(false)
        setWillNotVaccinate(false)
        setOpenSmsField(!openSmsField)
        actionTaken()
        break
      case 'Skal ikke vaksineres':
        setOpenSmsField(false)
        setConsentFormIsOpen(false)
        setWillNotVaccinate(!willNotVaccinate)
        updateDetails({
          ...patient,
          willNotVaccinate: true,
          openConsentForm: false,
          smsSent: false,
        })
        actionTaken()
        break
      default:
        return `The switch is not working for ${patient.id}`
    }
  }

  const handleSendSms = async () => {
    const data = await sendLinkWithSms(patient.id, phoneNumber)
    if (data) {
      setSmsSent(true)
      updateDetails({
        ...patient,
        smsSent: true,
        phoneNumber: phoneNumber,
        willNotVaccinate: false,
        openConsentForm: false,
      })
      actionTaken()
    } else {
      setSmsError(true)
      setOpenSmsField(true)
    }
  }

  const handleToggleWillNotVaccinate = () => {
    setWillNotVaccinate(false)
    updateDetails({
      ...patient,
      willNotVaccinate: false,
      openConsentForm: false,
      smsSent: false,
    })
    actionTaken()
  }

  const renderSendSmsForm = () => {
    return (
      <section className="mt-3">
        <HLForm
          name="Phone form"
          method="post"
          preventSubmit={true}
          className=""
        >
          <div>
            {smsError ? t('linkSmsError', s) : t('linkSms', s)}
          </div>
          <PhoneInput
            autoComplete="off"
            onChange={(e) => setPhoneNumber(e)}
            showValidation={false}
          />
        </HLForm>
        <section className="text-right">
          <Button
            label={t('sendSmsButton', s)}
            className="btn-primary w-50"
            onClick={handleSendSms}
          />
        </section>
      </section>
    )
  }

  const ConfirmActionTaken = () => {
    return (
      <section className="mb-4">
        <article className="d-flex flex-column w-100">
          <div className="d-flex flex-column justify-content-center align-items-start">
            <h5 className="mb-1">{patient.name}</h5>
          </div>

          <article className="text-muted d-flex justify-content-between align-items-center flex-fill">
            {consentGiven ? (
              <section className="d-flex">
                <GreenCheckMark />
                <div className="ml-2">
                  {t('consentFormSubmitted', s)}
                </div>
              </section>
            ) : smsSent ? (
              <section className="d-flex">
                <GreenCheckMark />
                <div className="ml-2">
                  {t('smsSent', s)} {phoneNumber}
                </div>
              </section>
            ) : willNotVaccinate ? (
              <section className="d-flex">
                <div className="mr-2">{t('willNotVaccinate', s)}</div>
                <article
                  role="button"
                  style={{ cursor: 'pointer' }}
                  className="text-muted d-flex justify-content-between align-items-center "
                  onClick={handleToggleWillNotVaccinate}
                >
                  <div
                    className="ml-1"
                    style={{
                      color: '#7517d6',
                      textDecoration: 'underline',
                    }}
                  >
                    {t('change', s)}
                  </div>
                </article>
              </section>
            ) : (
              'error'
            )}
          </article>
        </article>
      </section>
    )
  }

  return consentGiven || smsSent || willNotVaccinate ? (
    <ConfirmActionTaken />
  ) : (
    <>
      <h5 className="mb-2">{patient.name}</h5>
      <section className="mb-4 d-flex justify-content-between align-items-center">
        <section className="d-flex flex-column w-100">
          <RadioButtons
            onChange={toggleChoice}
            name={`Buttons for ${patient.name}`}
            shadow={false}
            variant="primary"
            options={[
              {
                value: 'Open consent form',
                label1: `${t('fillOutNow', s)}`,
              },
              { value: 'Send sms', label1: `${t('sendSms', s)}` },
              {
                value: 'Skal ikke vaksineres',
                label1: `${t('willNotGetAVaccine', s)}`,
              },
            ]}
          />
          {openSmsField ? renderSendSmsForm() : null}
        </section>
      </section>
    </>
  )
}

ConsentOverviewPatientItem.propTypes = {
  patient: PropTypes.object,
  actionTaken: PropTypes.func,
  updateDetails: PropTypes.func,
  t: PropTypes.func,
}

const s = {
  nb: {
    linkSms: 'Link til skjemaet vil bli send til dette nummeret.',
    linkSmsError: 'Noe gikk galt. Prøv igjen.',
    sendSmsButton: 'Send',
    consentFormSubmitted: 'Samtykkeskjema er fylt inn',
    smsSent: 'Link sendt til',
    willNotVaccinate: 'Skal ikke ha vaksine',
    change: 'Endre',
    fillOutNow: 'Fyll ut nå',
    sendSms: 'Send link på SMS',
    willNotGetAVaccine: 'Skal ikke vaksineres',
  },
  en: {
    linkSms: ' A link to the form will be sent to this number.',
    linkSmsError: 'Something went wrong. Please try again.',
    sendSmsButton: 'Send',
    consentFormSubmitted: 'Consent form is submitted.',
    smsSent: 'SMS sent to',
    willNotVaccinate: 'Will not get a vaccine',
    change: 'Change',
    fillOutNow: 'Fill now',
    sendSms: 'Send SMS',
    willNotGetAVaccine: 'Will not vaccinate',
  },
  pl: {
    linkSms: 'Link do formularza zostanie wysłany na ten numer',
    linkSmsError: 'Something went wrong. Please try again.',
    sendSmsButton: 'Wyślij SMS',
    consentFormSubmitted: 'Formularz zgód wysłany',
    smsSent: 'SMS wysłany na:',
    willNotVaccinate: 'Nie zostanie zaszczepiony',
    change: 'Zmień',
    fillOutNow: 'Wypełnij teraz',
    sendSms: 'Wyślij SMS',
    willNotGetAVaccine: 'Nie zostanie zaszczepiony',
  },
}

export default ConsentOverviewPatientItem
