import React from 'react'

const MessageField = () => {
  const templateMobileVerification = () => {
    setMessageText(
      'Din engangskode er ' + Math.floor(1000 + Math.random() * 9000),
    )
  }

  const setMessageText = (text) => {
    document.getElementById('sms-message').value = text
  }

  return (
    <div className="sms-message-field">
      <div className="sms-message">
        <label className="form-control-label" htmlFor="sms-message">
          Melding
        </label>
        <textarea
          className="form-control review__textarea"
          id="sms-message"
        ></textarea>
      </div>
      <div className="sms-verfication">
        <h2 className="h6">Maler</h2>
        <button
          className="btn btn-link"
          onClick={templateMobileVerification}
        >
          Mobilverifisering
        </button>
      </div>
    </div>
  )
}

export default MessageField
