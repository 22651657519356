import React, { useEffect, useContext, useState } from 'react'
import ClinicContext from './ClinicContext'
import PropTypes from 'prop-types'
import loadGoogle from '../../lib/loadGoogle'
import moment from 'moment-timezone'

const time = (stamp) => moment(stamp).format('HH:mm')

const NextBooking = ({
  currentBookingLatitude,
  currentBookingLongitude,
}) => {
  const { nextBooking, googleMapsApiKey, addError } = useContext(
    ClinicContext,
  )
  const [distanceToNextBooking, setDistanceToNextBooking] = useState(
    null,
  )
  const [timeToNextBooking, setTimeToNextBooking] = useState(null)

  const navigateNextBooking = (e) => {
    if (!nextBooking) return
    if (!confirm('Er du sikker på at du vil gå til neste?')) {
      e.preventDefault()
    } else {
      e.preventDefault()
      window.location.href = `/backoffice/bookings/${nextBooking.id}/vaccinations`
    }
  }

  useEffect(() => {
    if (!nextBooking) {
      return
    } else {
      const fetchDistanceAndTimeToNextBooking = async () => {
        const origin = {
          lat: currentBookingLatitude,
          lng: currentBookingLongitude,
        }
        const destination = {
          lat: nextBooking.lat,
          lng: nextBooking.lng,
        }
        const getGoogle = async () => {
          if (window && window.google) {
            return window.google
          } else {
            return await loadGoogle(googleMapsApiKey)
          }
        }
        const google = await getGoogle()
        const service = new google.maps.DistanceMatrixService()

        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: 'DRIVING',
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              addError(
                `Error fetching distance and time to next booking: ${status}: ${response}`,
              )
            } else {
              setDistanceToNextBooking(
                response.rows[0].elements[0].distance.text,
              )
              setTimeToNextBooking(
                response.rows[0].elements[0].duration.text,
              )
            }
          },
        )
      }
      try {
        fetchDistanceAndTimeToNextBooking()
      } catch (e) {
        addError(
          `Error fetching distance and time to next booking: ${e.message}`,
        )
      }
    }
  }, [
    currentBookingLatitude,
    currentBookingLongitude,
    googleMapsApiKey,
    nextBooking,
    addError,
  ])

  return (
    <div className="col-4">
      <div className="next-booking" onClick={navigateNextBooking}>
        {!!nextBooking ? (
          <>
            <span className="strong">Neste:</span>{' '}
            {nextBooking.address}, {nextBooking.city}{' '}
            {!!distanceToNextBooking && !!timeToNextBooking && (
              <span>
                {' '}
                ({distanceToNextBooking} / {timeToNextBooking})
              </span>
            )}
            <div>kl.{time(nextBooking.begins_at)}</div>
          </>
        ) : (
          <p className="no-content">Ingen flere oppdrag</p>
        )}
      </div>
    </div>
  )
}

NextBooking.propTypes = {
  currentBookingLatitude: PropTypes.number.isRequired,
  currentBookingLongitude: PropTypes.number.isRequired,
}

export default React.memo(NextBooking)
