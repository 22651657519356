import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import ShieldedSpinner from '../ShieldedSpinner'
import { cancelConsultation } from './queries'

const RefundModal = ({ consultationId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const toggleModal = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  const submitForm = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    try {
      await cancelConsultation(consultationId)
      window.location.reload()
    } catch (e) {
      setError(e)
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    setIsOpen(false)
  }

  return (
    <div>
      <button
        className="btn btn-outline-primary"
        onClick={toggleModal}
      >
        Cancel consultation
      </button>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <ShieldedSpinner show={loading} />
        <div
          className="ReactModal__exit-button-new"
          onClick={toggleModal}
        />

        <h1 className="backoffice__header">
          Confirm cancelling the consultation
        </h1>
        <p>
          Are you sure you want to cancel this consultation? This is
          not reversible
        </p>
        <div
          className="text-center d-flex"
          name="cancel-consultation-modal"
        >
          <button
            type="button"
            className="btn btn-outline-primary mr-2"
            onClick={toggleModal}
          >
            Abort
          </button>
          <button
            type="button"
            className="btn btn-outline-primary mr-2"
            onClick={submitForm}
          >
            Confirm
          </button>
        </div>
        {!!error && <p className="text-danger">{error.message}</p>}
      </ReactModal>
    </div>
  )
}

RefundModal.propTypes = {
  consultationId: PropTypes.string.isRequired,
}

export default RefundModal
