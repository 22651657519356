import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import HLForm from './forms/HLForm'
import ConfirmPatientState from './ConfirmPatientState'
import PatientIndepencenceRow from './PatientIndependenceRow'
import Button from './forms/fields/Button'

const modalStyle = {
  content: {
    width: '80%',
    WebkitOverflowScrolling: 'touch',
  },
}

const EditPatientBySpModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [busy, setBusy] = useState(false)
  const [isReadyForDelete, setIsReadyForDelete] = useState(false)
  const [patient, setPatient] = useState(props.patient)

  useEffect(() => {
    if (isReadyForDelete) {
      document.forms['edit-patient-form'].submit()
    }
  }, [isReadyForDelete])

  const openModal = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setPatient(props.patient)
    setIsModalOpen(false)
  }

  const submitChanges = async (patient = props.patient) => {
    setBusy(true)
    document.forms['edit-patient-form'].submit()
    setBusy(false)
  }

  const cancelChanges = (e) => {
    closeModal()
  }

  const deletePatient = () => {
    if (
      window.confirm('Er du sikker på at du vil slette pasienten?')
    ) {
      setBusy(true)
      setIsReadyForDelete(true)
      setBusy(false)
    }
  }

  const toggleIndependence = (updatedPatient) => {
    setPatient({
      ...patient,
      independent: updatedPatient.independent,
    })
  }

  ReactModal.setAppElement('body')

  return (
    <>
      <Button
        className="btn btn-link"
        id={`edit-patient-${props.patient.id}`}
        onClick={openModal}
      >
        <i className="far fa-edit"></i>
      </Button>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Edit patient information"
        style={modalStyle}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        {props.isDeletablePatient && (
          <Button
            className="btn-link text-danger mb-3"
            id={`delete-patient-${props.patient.id}`}
            onClick={deletePatient}
            label="Slett pasient"
          />
        )}

        <h2 className="h4 mb-4">Rediger pasient</h2>

        <HLForm
          preventSubmit={true}
          action={props.path}
          method="patch"
          name="edit-patient-form"
        >
          <ConfirmPatientState
            hasDuplicatePatients={props.hasDuplicatePatients}
            patient={patient}
            submit={submitChanges}
            cancel={cancelChanges}
            buttonCancelLabel="Avbryt"
            buttonConfirmLabel="Bekreft"
            nameInputName="booking[patients_attributes][][name]"
            phoneInputName="booking[patients_attributes][][phone_number]"
            busy={busy}
          >
            <input
              value={props.patient.id}
              type="hidden"
              name="booking[patients_attributes][][id]"
            />
            {isReadyForDelete && (
              <input
                value={true}
                type="hidden"
                name="booking[patients_attributes][][_destroy]"
              />
            )}
            <h3 className="groupheading mt-4 mb-3">
              Hvem skal kontaktes?
            </h3>
            <div className="mb-4">
              <PatientIndepencenceRow
                patient={{
                  ...patient,
                  contact_person: {
                    id: null,
                    legal_guardian: false,
                  },
                }}
                patients={props.patients}
                mainContact={props.mainContact}
                lastContact={props.lastContact}
                onChange={toggleIndependence}
                hasHeading={false}
                forManyPatients={false}
              />
            </div>
          </ConfirmPatientState>
        </HLForm>
      </ReactModal>
    </>
  )
}

EditPatientBySpModal.propTypes = {
  hasDuplicatePatients: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  isDeletablePatient: PropTypes.bool,
  patients: PropTypes.arrayOf(PropTypes.object),
  mainContact: PropTypes.object,
  lastContact: PropTypes.object,
}

export default EditPatientBySpModal
