import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

class SupplierTypeFilteringModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    zip: PropTypes.string,
    imgPath: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: this.props.isOpen || false,
      zip: this.props.zip,
    }
    ReactModal.setAppElement('body')
  }

  toggleModal = (e) => {
    e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  displayCoronaResult = () => {
    window.location =
      '/booking_wizard/start' +
      `?zip=${this.props.zip}` +
      '&products=hcl-tst-c19-pr'
  }

  render() {
    return (
      <div>
        <ReactModal isOpen={this.state.isOpen}>
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <div>
              <h1 className="backoffice__header">
                Skal du bestille coronatest?
              </h1>
              <p>
                Velger du time for coronatesting viser vi timer for
                leger og sykepleiere, slik at du kan få time raskere.
                Har du andre behov, eller vil sikre at du får besøk av
                lege, bør du velge vanlig legetime.
              </p>
            </div>
            <img src={this.props.imgPath} />
          </div>
          <div style={{ display: 'flex' }}>
            <button
              className="btn btn-outline-primary mr-2"
              onClick={this.displayCoronaResult}
            >
              Vis timer for coronatesting
            </button>
            <button
              className="btn btn-outline-primary mr-2"
              onClick={this.toggleModal}
            >
              Vis vanlige legetimer
            </button>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default SupplierTypeFilteringModal
