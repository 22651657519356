export const errorEcommerceEvent = {
  event: 'GAEvent',
  eventCategory: 'ecommerce',
  eventAction: 'error',
  eventLabel: 'Model without order',
  eventValue: 1,
  nonInteraction: true,
}

export const purchaseEvent = (order) => {
  if (!order?.id) {
    return errorEcommerceEvent
  }

  const itemsElement = order.lineItems.map((li) => {
    return {
      item_id: li.id,
      item_name: li.product?.name,
      coupon: li.coupon?.code,
      discount: (li.defaultPrice - li.netPrice) / 100,
      item_category: li.product?.productCategory?.name,
      item_category2: li.product?.publicName,
      item_category3: li.product?.slug,
      price: li.netPrice / 100,
      quantity: li.quantity,
    }
  })

  const purchaseData = {
    transaction_id: order.id,
    value: order.totalPrice / 100,
    currency: 'NOK',
    items: itemsElement,
  }

  window.dataLayer.push({
    event: 'purchase',
    ...purchaseData,
  })
}
