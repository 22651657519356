import React, { Component } from 'react'
import PropTypes from 'prop-types'

import formatCurrency from '../../lib/formatCurrency'

class PriceCalcTable extends Component {
  static propTypes = {
    price: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    baseTwoInOnePrice: PropTypes.number.isRequired,
    discountedPrice: PropTypes.number,
    booking: PropTypes.object.isRequired,
    discountAmount: PropTypes.any,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }

  showPatient() {
    if (this.props.booking.two_in_one) {
      return (
        <tr>
          <td>{this.props.user.name}</td>
          <td>{formatCurrency(this.props.basePrice)}</td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td>Konsultasjon</td>
          <td>{formatCurrency(this.props.basePrice)}</td>
        </tr>
      )
    }
  }

  showSecondaryPatient() {
    if (this.props.booking.two_in_one) {
      return (
        <tr>
          <td>{this.props.booking.secondary_patient_name}</td>
          <td>{formatCurrency(this.props.baseTwoInOnePrice)}</td>
        </tr>
      )
    }
  }

  showDiscount() {
    if (
      this.props.discountAmount != null &&
      this.props.discountAmount != 0
    ) {
      const discount = this.props.discountAmount
      return (
        <tr>
          <td>Rabattkode</td>
          <td>-{formatCurrency(discount)}</td>
        </tr>
      )
    }
  }

  totalPrice() {
    if (this.props.discountedPrice != null)
      return formatCurrency(this.props.discountedPrice)
    else return formatCurrency(this.props.price)
  }

  render() {
    if (
      !(
        this.props.discountedPrice != null ||
        this.props.booking.two_in_one
      )
    ) {
      return <div></div>
    }

    return (
      <div className="row">
        <div className="col-12">
          <table className="table price-calc-table">
            <tbody>
              {this.showPatient()}
              {this.showSecondaryPatient()}
              {this.showDiscount()}
              <tr>
                <td>Totalt</td>
                <td>{this.totalPrice()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default PriceCalcTable
