const PHONE_EXP = /^(\+|0{2})[0-9][0-9\d\-\(\)]{5,15}$/
const V_CARRIER_PATH = '/phone_validation/lookup_phone_number'
const V_CODE_PATH = '/phone_validation'
const SEND_CODE_PATH = '/phone_validation/new'
const OTP_SESSION_PATH = '/otp_session/new'
const OTP_SESSION_VERIFY_PATH = '/otp_session'

export const verifyMobileCarrier = async (phone_number) => {
  if (!PHONE_EXP.test(phone_number))
    return buildErrorResponse({
      status: 400,
      message: 'Telefonnummer har ugyldig format',
    })

  try {
    const response = await $.post(V_CARRIER_PATH, { phone_number })
    return buildSuccessResponse(response)
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const validateOTP = async (phone_number, code) => {
  try {
    const response = await $.post(V_CODE_PATH, { phone_number, code })
    return buildSuccessResponse(response)
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const createOtpSession = async (
  phone_number,
  authenticityToken,
) => {
  try {
    const response = await $.post(OTP_SESSION_PATH, {
      phone_number,
      authenticityToken,
    })
    return buildSuccessResponse(response)
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const verifyOtpSession = async (phone_number, code) => {
  try {
    const response = await $.post(OTP_SESSION_VERIFY_PATH, {
      phone_number,
      code,
    })
    return buildSuccessResponse(response)
  } catch (err) {
    return buildErrorResponse(err)
  }
}

export const sendCode = async (phone_number) => {
  try {
    const response = await $.post(SEND_CODE_PATH, { phone_number })
    return buildSuccessResponse(response)
  } catch (err) {
    return buildErrorResponse(err)
  }
}

const buildSuccessResponse = (resp) => {
  const response = {
    success: true,
    data: resp,
  }
  // Only returned in Rails.env.development? for easier testing
  if (resp && resp.otp_code) response.code = resp.otp_code
  return response
}

const buildErrorResponse = (err) => ({
  success: false,
  err: {
    status: err.status,
    message: err.message || err.responseJSON?.error || err.toString(),
  },
})
