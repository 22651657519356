import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { purchaseEvent } from '../lib/tracking'

const TrackingPurchases = ({ booking }) => {
  useEffect(() => {
    purchaseEvent(booking.order)
  }, [])

  return <></>
}

TrackingPurchases.propTypes = {
  booking: PropTypes.object.isRequired,
}

export default TrackingPurchases
