import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import AddressInput from '../../forms/fields/AddressInput.jsx'
import CityInput from '../../forms/fields/CityInput.jsx'
import CompanyNameInput from '../../forms/fields/CompanyNameInput.jsx'
import EmailInput from '../../forms/fields/EmailInput.jsx'
import InvoiceReference from '../../forms/fields/InvoiceReference.jsx'
import NameInput from '../../forms/fields/NameInput.jsx'
import NumericInput from '../../forms/fields/NumericInput.jsx'
import OrganizationNumberInput from '../../forms/fields/OrganizationNumberInput.jsx'
import PhoneInput from '../../forms/fields/PhoneInput.jsx'
import RadioButtons from '../../forms/fields/RadioButtons.jsx'
import ZipInput from '../../forms/fields/ZipInput.jsx'
import ShieldedSpinner from '../../ShieldedSpinner.jsx'
import GooglePlacesAutocompleteInput from '../../GooglePlacesAutocompleteInput.jsx'
import Price from './Price.jsx'

import gqlCall from '../../../api/graphql'
import { parseQueryResponse } from '../../../api/graphqlExtended'

const EditOrderDetailsList = ({
  order,
  organization,
  organizationAddress,
  organizationOrgNumber,
  products,
  quantity,
  price,
  booking,
  bookingContactPerson,
  orderContactPerson,
  toggleIsEditing,
  googleMapsApiKey,
}) => {
  const [editableSection, setEditableSection] = useState('')
  const [loadingAddressCheck, setLoadingAddressCheck] = useState(
    false,
  )
  const [errorNotCovered, setErrorNotCovered] = useState(false)
  const [numberOfVaccines, setNumberOfVaccines] = useState(quantity)
  const [totalPrice, setTotalPrice] = useState(price)
  const [companyInfo, setCompanyInfo] = useState({
    organization: { ...organization },
  })

  const [bookingInfo, setBookingInfo] = useState({
    address: booking.address,
    city: booking.city,
    zip: booking.zip,
    name: bookingContactPerson.name,
    phone_number: bookingContactPerson.phone_number,
    // email: bookingContactPerson.email,
  })

  const [
    bookingContactPersonData,
    setBookingContactPerson,
  ] = useState({
    name: bookingContactPerson.name,
    phone_number: bookingContactPerson.phone_number,
  })

  const [orderContactPersonData, setOrderContactPerson] = useState({
    name: orderContactPerson.name,
    phone_number: orderContactPerson.phone_number,
  })

  const [organizationAddressData, setOrganizationAddress] = useState({
    address: organizationAddress.address,
    zip: organizationAddress.zip,
    city: organizationAddress.city,
  })

  const [companyInfoValid, setCompanyInfoValid] = useState({
    name: true,
    org_nr: true,
    mailing_address: true,
    mailing_zip: true,
    billing_method: true,
    billing_email: true,
    billing_reference: true,
  })

  const [bookingInfoValid, setBookingInfoValid] = useState({
    address: true,
    city: true,
    zip: true,
    name: true,
    phone_number: true,
    email: true,
  })

  // const [contactPersonValid, setContactPersonValid] = useState({})

  const handleEdit = (section) => {
    //switch sections and redirect to different routes

    switch (section) {
      case 'oppdragsinformasjon':
        window.location.href = `/bedriftsvaksine/booking_details`
        break
      case 'bedriftsinformasjon':
        window.location.href = `/bedriftsvaksine/order_details`
        break
      case 'fakturainformasjon':
        window.location.href = `/bedriftsvaksine/booking_details`
        break
      default:
        window.location.href = `/bedriftsvaksine/search`
        break
    }
    // const validationChecks = {
    //   oppdragsinformasjon: () => {
    //     const valid =
    //       Object.values(bookingInfoValid).every((val) => !!val) &&
    //       !errorNotCovered
    //     return valid ? '' : section
    //   },
    //   bedriftsinformasjon: () => {
    //     const valid =
    //       Object.values(companyInfoValid).every((val) => !!val) &&
    //       Object.values(bookingInfoValid).every((val) => !!val)
    //     return valid ? '' : section
    //   },
    //   fakturainformasjon: () => {
    //     const valid = Object.values(bookingInfoValid).every(
    //       (val) => val === true,
    //     )
    //     return valid ? '' : section
    //   },
    //   // kontaktperson: () => {
    //   //   const valid = Object.values(contactPersonValid).every(
    //   //     (val) => val === true,
    //   //   )
    //   //   return valid ? '' : section
    //   // },
    //   default: () => '',
    // }

    // const currentValidationCheck =
    //   validationChecks[section] || validationChecks.default

    // if (editableSection === section) {
    //   setEditableSection(currentValidationCheck())
    // } else {
    //   const valid =
    //     Object.values(companyInfoValid).every((val) => !!val) &&
    //     Object.values(bookingInfoValid).every((val) => !!val) &&
    //     // Object.values(contactPersonValid).every((val) => !!val) &&
    //     !errorNotCovered

    //   if (valid) {
    //     setEditableSection(section)
    //   }
    // }
  }

  useEffect(() => {
    if (editableSection === '') {
      toggleIsEditing(false)
    } else {
      toggleIsEditing(true)
    }
  }, [editableSection, toggleIsEditing])

  const renderEditButton = (section) => {
    return (
      <div
        style={{
          textDecoration: 'underline',
          color: '#cba6fb',
          cursor: 'pointer',
        }}
        onClick={() => handleEdit(section)}
      >
        {editableSection === section ? 'Lagre' : 'Endre'}
      </div>
    )
  }

  const renderSectionHeader = (text, section) => {
    return (
      <div
        className="mb-2"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <b style={{ opacity: 0.3 }}>{text}</b>
        <div>{renderEditButton(section)}</div>
      </div>
    )
  }

  const handleCompanyInfoChanges = (value, valid, name) => {
    const updateNestedState = (prevState, keys, newValue) => {
      const [currentKey, ...remainingKeys] = keys
      if (remainingKeys.length === 0) {
        return { ...prevState, [currentKey]: newValue }
      }

      return {
        ...prevState,
        [currentKey]: updateNestedState(
          prevState[currentKey] || {},
          remainingKeys,
          newValue,
        ),
      }
    }

    const isNestedKey = name.includes('[') && name.includes(']')
    if (isNestedKey) {
      const keys = name.match(/\w+/g)
      if (keys) {
        setCompanyInfo((prevCompanyInfo) => ({
          ...prevCompanyInfo,
          organization: updateNestedState(
            prevCompanyInfo.organization || {},
            keys.slice(1),
            value,
          ),
        }))
        setCompanyInfoValid((prevCompanyInfoValid) => ({
          ...prevCompanyInfoValid,
          [name]: valid,
        }))
      }
    } else {
      setCompanyInfo((prevCompanyInfo) => ({
        ...prevCompanyInfo,
        [name]: value,
      }))
      setCompanyInfoValid((prevCompanyInfoValid) => ({
        ...prevCompanyInfoValid,
        [name]: valid,
      }))
    }
  }

  const validateAddressWithCoverage = async (lat, lng, zip) => {
    setLoadingAddressCheck(true)

    const query = `query {
      inVaccineReferralCoverage(lat: ${lat}, lng: ${lng}, zip: "${zip}")
    }`

    try {
      const response = await gqlCall(query)
      const parsedResponse = parseQueryResponse({
        response: response,
        resourceName: 'inVaccineReferralCoverage',
        multiple: false,
      })
      setLoadingAddressCheck(false)
      return parsedResponse
    } catch (e) {
      setLoadingAddressCheck(false)
      Sentry.captureException(e, {
        extra: {
          location:
            'VaccineReferralConfirm.validateAddressWithCoverage',
        },
      })
      // eslint-disable-next-line no-console
      console.log('error', e)
    }
  }

  const handleBookingInfoChanges = async (value, valid, name) => {
    if (!valid) return
    setBookingInfo({
      address: `${value.street} ${value.number}`,
      city: value.city,
      zip: value.zip,
    })

    const isAddressCovered = await validateAddressWithCoverage(
      value.lat,
      value.lng,
      value.zip,
    )

    if (!isAddressCovered) {
      setErrorNotCovered(true)
      setBookingInfoValid({
        address: false,
        city: false,
        zip: false,
      })
      return
    }

    setErrorNotCovered(false)

    setBookingInfoValid({
      address: true,
      city: true,
      zip: true,
    })
  }

  // const handleContactPersonChanges = (value, valid, name) => {
  //   const stateName = name
  //     .replace('organization[contact_person_attributes][', '')
  //     .replace(']', '')

  //   setContactPersonValid((prevContactPersonValid) => ({
  //     ...prevContactPersonValid,
  //     [stateName]: valid,
  //   }))
  //   setContactPerson((prevContactPerson) => ({
  //     ...prevContactPerson,
  //     [stateName]: value,
  //   }))
  // }

  const onNewPrice = (newPrice) => {
    setTotalPrice(newPrice)
  }

  const sanitizeSpecialValues = (val) => {
    switch (val) {
      case 'email':
        return 'E-post'
      case 'ehf':
        return 'EHF'
      default:
        return val
    }
  }

  const renderEditableSectionField = (label, value, section) => {
    switch (label) {
      case 'Antall vaksiner':
        return (
          <NumericInput
            min={6}
            max={30}
            label="Antall vaksiner"
            name="referral[epax_count]"
            value={numberOfVaccines}
            onChange={(val) => setNumberOfVaccines(val)}
            showValidation={true}
            isRequired={true}
          />
        )
      case 'Pris':
        return (
          <Price
            label="Pris"
            value={totalPrice}
            onChange={onNewPrice}
            quant
            ity={numberOfVaccines}
            products={products}
          />
        )
      case 'Bedrift':
        return (
          <CompanyNameInput
            name="referral[related_organization][name]]"
            onChange={handleCompanyInfoChanges}
            highlightErrors={true}
            isRequired
            value={booking.organization}
            // value={companyInfo.organization.name}
            className="b-bottom"
          />
        )
      case 'Organisasjonsnummer':
        return (
          <div>
            <OrganizationNumberInput
              name="referral[related_organization][org_nr]]"
              highlightErrors={true}
              onChange={handleCompanyInfoChanges}
              isRequired
              value={companyInfo.organization.org_nr}
              className="b-bottom"
            />
          </div>
        )
      case 'Postadresse':
        return (
          <div className="formrow align-items-end">
            <AddressInput
              highlightErrors={true}
              name="referral[organization_address][address]"
              onChange={handleCompanyInfoChanges}
              label="Postadresse"
              isRequired
              value={organizationAddress.address}
              className="mr-sm-2"
            />
            <CityInput
              name="referral[organization_address][city]"
              onChange={handleCompanyInfoChanges}
              isRequired
              value={organizationAddress.city}
              className="mr-sm-2"
            />
            <div className="col-sm-4">
              <ZipInput
                highlightErrors={true}
                name="referral[organization_address][zip]"
                onChange={handleCompanyInfoChanges}
                label="Postnummer"
                isRequired
                value={organizationAddress.zip}
                className="mr-sm-2"
              />
            </div>
          </div>
        )
      case 'Besøksadresse':
        return (
          <div className="mb-2">
            <GooglePlacesAutocompleteInput
              googleMapsApiKey={googleMapsApiKey}
              isSingleLine={true}
              showValidation={false}
              onComplete={handleBookingInfoChanges}
              label="Besøksadresse"
              initialValue={`${bookingInfo.address}`}
              name="referral[address]"
            />
            {errorNotCovered && (
              <aside role="alert" className="attention-message">
                Vi tilbyr dessverre ikke bedriftsvaksinering på din
                adresse gjennom selvbestilling. Kontakt kundesupport
                for å se om vi likevel kan hjelpe deg.
              </aside>
            )}
          </div>
        )
      case 'Faktureringsmetode':
        return (
          <>
            <RadioButtons
              highlightErrors={true}
              className="mb-3"
              name="referrel[billing_method]"
              defaultChecked={booking.billing_method}
              onChange={handleCompanyInfoChanges}
              options={[
                { value: 'email', label1: 'E-post' },
                { value: 'ehf', label1: 'EHF' },
              ]}
            />

            {companyInfo.organization.billing_method == 'email' && (
              <EmailInput
                highlightErrors={true}
                name="referral[billing_email]"
                onChange={handleCompanyInfoChanges}
                label="E-post fakturakontakt"
                autoComplete="off"
                isRequired
                value={booking.billing_email}
                className="b-bottom"
              />
            )}
            <InvoiceReference
              highlightErrors={true}
              onChange={handleCompanyInfoChanges}
              name="referral[billing_reference]"
              isRequired={false}
              value={booking.billing_reference}
            />
          </>
        )
      case 'Navn på kontaktperson':
        return (
          <NameInput
            highlightErrors={true}
            name="referral[contact_person_booking][name]"
            onChange={handleBookingInfoChanges}
            label="Navn på kontaktperson"
            isRequired
            value={bookingInfo.name}
            className="b-bottom"
          />
        )
      case 'E-postadresse':
        return (
          <EmailInput
            highlightErrors={true}
            name="referral[contact_person_booking][email]"
            onChange={handleBookingInfoChanges}
            label="E-postadresse"
            autoComplete="off"
            isRequired
            value={bookingInfo.email}
            className="b-bottom"
          />
        )
      case 'Telefonnummer':
        return (
          <PhoneInput
            highlightErrors={true}
            name="referal[contact_person_booking][phone_number]"
            onChange={handleBookingInfoChanges}
            label="Telefonnummer"
            isRequired
            value={bookingInfo.phone_number}
            className="b-bottom"
          />
        )
      default:
        return null
    }
  }

  const renderSectionField = (
    label,
    value,
    section,
    name,
    onlyHidden = false,
    isEditable = true,
  ) => {
    if (editableSection === section && isEditable) {
      return renderEditableSectionField(label, value, section)
    } else {
      if (onlyHidden) {
        return <input hidden name={name} defaultValue={value} />
      }

      return (
        <div>
          <label style={{ fontWeight: 'bold', color: 'black' }}>
            {label}:
          </label>{' '}
          <span>{sanitizeSpecialValues(value)}</span>
          <input hidden name={name} defaultValue={value} />
        </div>
      )
    }
  }

  return (
    <>
      <ShieldedSpinner show={loadingAddressCheck} />
      <div className="mb-2">
        <b style={{ opacity: 0.3 }}>Registrert informasjon</b>
      </div>

      <section
        className="list-unstyled mt-2 p-3"
        style={{
          border: '1px solid #cba6fb',
        }}
      >
        <div className="mb-4">
          {/* {renderSectionHeader('Vaksiner', 'vaksiner')} */}
          <div
            className="mb-2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          ></div>
          {renderSectionHeader(
            'Oppdragsinformasjon',
            'oppdragsinformasjon',
          )}
          {renderSectionField(
            'Antall vaksiner',
            numberOfVaccines,
            'oppdragsinformasjon',
            'amount',
            false,
            false,
          )}
          {renderSectionField(
            'Pris',
            totalPrice,
            'oppdragsinformasjon',
            'price',
            false,
            false,
          )}
          {renderSectionField(
            'Besøksadresse',
            `${bookingInfo.address}`,
            'oppdragsinformasjon',
            'referral[address]',
          )}
          {/* {renderSectionField(
            'Besøksadresse zip',
            `${bookingInfo.zip}`,
            'oppdragsinformasjon',
            'organization[visiting_address][zip]',
            true,
          )}
          {renderSectionField(
            'Besøksadresse by',
            `${bookingInfo.city}`,
            'oppdragsinformasjon',
            'organization[visiting_address][city]',
            true,
          )} */}
          <div className="mb-4">
            {renderSectionField(
              'Navn på kontaktperson',
              bookingInfo.name,
              'oppdragsinformasjon',
              'referral[contact_person_booking][name]',
            )}
            {renderSectionField(
              'Telefonnummer',
              bookingInfo.phone_number,
              'oppdragsinformasjon',
              'referal[contact_person_booking][phone_number]',
            )}
          </div>
        </div>

        <div className="mb-4">
          {renderSectionHeader(
            'Bedriftsinformasjon',
            'bedriftsinformasjon',
          )}
          {renderSectionField(
            'Bedrift',
            booking.organization,
            // companyInfo.organization.name,
            'bedriftsinformasjon',
            'referral[related_organization][name]',
          )}
          {renderSectionField(
            'Organisasjonsnummer',
            organizationOrgNumber,
            'bedriftsinformasjon',
            'referral[related_organization][org_nr]',
          )}
          {renderSectionField(
            'Postadresse',
            organizationAddress.address,
            // `${companyInfo.organization.mailing_address}`,
            'bedriftsinformasjon',
            'referal[organization_address][address]',
          )}
          {renderSectionField(
            'Postadresse zip',
            `${organizationAddress.zip}`,
            'bedriftsinformasjon',
            'referal[organization_address][zip]',
            true,
          )}
          {renderSectionField(
            'Postadresse by',
            `${organizationAddress.city}`,
            'bedriftsinformasjon',
            'referal[organization_address][city]',
            true,
          )}
        </div>

        <div className="mb-4">
          {renderSectionHeader(
            'Fakturainformasjon',
            'fakturainformasjon',
          )}
          {renderSectionField(
            'Faktureringsmetode',
            booking.billing_method,
            // companyInfo.organization.billing_method,
            'fakturainformasjon',
            'referral[billing_method]',
          )}
          {booking.billing_method === 'email'
            ? renderSectionField(
                'E-postadresse for faktura',
                booking.billing_email,
                // companyInfo.organization.billing_email,
                'fakturainformasjon',
                'referral[billing_email]',
              )
            : null}
          {/* {companyInfo.organization.billing_method === 'email'
            ? renderSectionField(
                'E-postadresse for faktura',
                companyInfo.organization.billing_email,
                'fakturainformasjon',
                'referal[billing_email]',
              )
            : null} */}
          {renderSectionField(
            'Fakturareferanse',
            booking.billing_reference,
            // companyInfo.organization.billing_reference,
            'fakturainformasjon',
            'referal[billing_reference]',
          )}
        </div>
      </section>
    </>
  )
}

EditOrderDetailsList.propTypes = {
  order: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  organizationAddress: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  bookingContactPerson: PropTypes.object.isRequired,
  orderContactPerson: PropTypes.object.isRequired,
  products: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  toggleIsEditing: PropTypes.func.isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
  organizationOrgNumber: PropTypes.string || PropTypes.number,
}

EditOrderDetailsList.defaultProps = {
  order: {},
  organization: {},
  products: '',
  booking: {},
  bookingContactPerson: {},
  payment: {},
  price: 0,
  quantity: 0,
  organizationOrgNumber: '',
}

export default EditOrderDetailsList
