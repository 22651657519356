import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LineItemPickerModal from './active_admin/LineItemPickerModal'
import _ from 'lodash'
import { useFormik } from 'formik'
import DatePicker from 'react-datepicker'
import gqlCall from '../api/graphql'

const CompensationItemForm = (props) => {
  const [
    isLineItemPickerVisible,
    setLineItemPickerVisibility,
  ] = useState(false)

  const createGql = (obj) => {
    return `
      mutation {
        createCompensationItem(
          serviceProviderId: ${obj.service_provider_id},
          lineItemId: ${obj.line_item_id || 'null'},
          compensationSchemeId: ${
            obj.compensation_scheme_id || 'null'
          },
          quantity: ${obj.quantity},
          unitCompensation: ${obj.unit_compensation * 100},
          appliesForPayout: ${obj.applies_for_payout},
          effectiveAt: "${obj.effective_at}",
          onHold: ${obj.on_hold},
          description: "${obj.description}") {
          id
        }
      }
      `
  }

  const submitBtnCaption = props.compensationItem.id
    ? 'Update compensation item'
    : 'Create Compensation item'

  const updateGql = (obj) => {
    return `
      mutation {
        updateCompensationItem(
          id: ${obj.id},
          serviceProviderId: ${obj.service_provider_id},
          lineItemId: ${obj.line_item_id || 'null'},
          compensationSchemeId: ${
            obj.compensation_scheme_id || 'null'
          },
          quantity: ${obj.quantity},
          unitCompensation: ${obj.unit_compensation * 100},
          appliesForPayout: ${obj.applies_for_payout},
          effectiveAt: "${obj.effective_at}",
          onHold: ${obj.on_hold},
          description: "${obj.description}") {
          id
        }
      }
      `
  }

  const gqlQuery = (compItem) => {
    return compItem.id ? updateGql(compItem) : createGql(compItem)
  }

  const formik = useFormik({
    initialValues: {
      creatorId: props.creatorId,
      ...props.compensationItem,
      unit_compensation:
        props.compensationItem.unit_compensation / 100,
    },
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2))
      const response = await gqlCall(gqlQuery(values)) //todo extend
      if (response.status == 200 && !response.data.data.errors) {
        window.location.replace(props.returnPath)
      } else {
        alert(JSON.stringify(esponse.data.data.errors, null, 2))
      }
    },
  })

  const [lineItemId, setLineItemId] = useState(null)

  const toggleLineItemPicker = (e) => {
    if (e) {
      e.preventDefault()
    }
    setLineItemPickerVisibility(!isLineItemPickerVisible)
  }

  const renderServiceProviders = () => {
    return props.serviceProviders.map((el) => (
      <option key={el[0]} value={el[0]}>
        {el[1]} {el[2]}
      </option>
    ))
  }

  const renderCompensationSchemes = () => {
    return props.compensationSchemes.map((el) => (
      <option key={el[0]} value={el[0]}>
        {el[1]}
      </option>
    ))
  }

  const lineItemPickerHandler = (vals) => {
    toggleLineItemPicker()
    setLineItemId(vals.lineItemId)
    formik.setFieldValue('line_item_id', vals.lineItemId, false)
  }

  return (
    <div id="main_content_wrapper">
      <div id="main_content">
        <form onSubmit={formik.handleSubmit}>
          <input
            type="hidden"
            name="authenticity_token"
            value={props.authenticityToken}
          />
          <p>This is a react-rendered form :-)</p>
          <fieldset className="inputs">
            <ol>
              <li
                className="select input required"
                id="v2_compensation_item_service_provider_input"
              >
                <label
                  htmlFor="v2_compensation_item_service_provider_id"
                  className="label"
                >
                  Service provider <abbr title="required">*</abbr>
                </label>

                <select
                  name="service_provider_id"
                  className="default-select custom-select"
                  id="v2_compensation_item_service_provider_id"
                  onChange={formik.handleChange}
                  value={formik.values.service_provider_id}
                >
                  <option value="" label=" "></option>
                  {renderServiceProviders()}
                </select>
              </li>
              <li
                className="select input required"
                id="v2_compensation_item_line_item_id_input"
              >
                <label
                  htmlFor="v2_compensation_item_line_item_id"
                  className="label"
                >
                  Line item
                </label>

                <input
                  type="text"
                  name="line_item_id"
                  onChange={formik.handleChange}
                  style={{ maxWidth: '200px', marginRight: 20 }}
                  value={formik.values.line_item_id}
                />

                <input
                  type="button"
                  name="pick_line_item"
                  value="Pick associated line item / booking"
                  onClick={toggleLineItemPicker}
                />
              </li>
              <li
                className="select input optional"
                id="v2_compensation_item_compensation_scheme_id_input"
              >
                <label
                  htmlFor="v2_compensation_item_compensation_scheme_id"
                  className="label"
                >
                  Compensation scheme
                </label>
                <select
                  name="compensation_scheme_id"
                  className="default-select custom-select"
                  id="v2_compensation_item_compensation_scheme_id"
                  onChange={formik.handleChange}
                  value={formik.values.compensation_scheme_id || ''}
                >
                  <option
                    value=""
                    label=" "
                    data-select2-id="6"
                  ></option>
                  {renderCompensationSchemes()}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="5"
                  //style="width: 80%;"
                ></span>
              </li>
              <li
                className="number input required numeric stringish"
                id="v2_compensation_item_quantity_input"
              >
                <label
                  htmlFor="v2_compensation_item_quantity"
                  className="label"
                >
                  Quantity<abbr title="required">*</abbr>
                </label>
                <input
                  id="v2_compensation_item_quantity"
                  style={{ maxWidth: '300px' }}
                  min="0.1"
                  step="any"
                  type="number"
                  name="quantity"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                />
              </li>
              <li
                className="number input required numeric stringish"
                id="v2_compensation_item_unit_compensation_input"
              >
                <label
                  htmlFor="v2_compensation_item_unit_compensation"
                  className="label"
                >
                  Unit compensation<abbr title="required">*</abbr>
                </label>
                <input
                  id="v2_compensation_item_unit_compensation"
                  style={{ maxWidth: '300px' }}
                  step="any"
                  type="number"
                  name="unit_compensation"
                  onChange={formik.handleChange}
                  value={formik.values.unit_compensation}
                />
              </li>
              <li
                className="boolean input required"
                id="v2_compensation_item_applies_for_payout_input"
              >
                <input
                  type="hidden"
                  name="v2_compensation_item[applies_for_payout]"
                  value={formik.values.applies_for_payout}
                />
                <label
                  htmlFor="v2_compensation_item_applies_for_payout"
                  className=""
                >
                  <input
                    type="checkbox"
                    name="applies_for_payout"
                    onChange={formik.handleChange}
                    checked={formik.values.applies_for_payout}
                    id="v2_compensation_item_applies_for_payout"
                  />
                  Applies for payout<abbr title="required">*</abbr>
                </label>
              </li>
              <li
                className="date_time_picker input required stringish"
                id="v2_compensation_item_effective_at_input"
              >
                <label
                  htmlFor="v2_compensation_item_effective_at"
                  className="label"
                >
                  Effective at<abbr title="required">*</abbr>
                </label>

                <div className="date-picker-container">
                  <DatePicker
                    selected={new Date(formik.values.effective_at)}
                    onChange={(date) => {
                      formik.setFieldValue(
                        'effective_at',
                        date.toISOString(),
                        false,
                      )
                    }}
                  />
                </div>
              </li>
              <li
                className="boolean input optional"
                id="v2_compensation_item_on_hold_input"
              >
                <input type="hidden" name="on_hold" value="0" />
                <label
                  htmlFor="v2_compensation_item_on_hold"
                  className=""
                >
                  <input
                    type="checkbox"
                    name="on_hold"
                    onChange={formik.handleChange}
                    checked={formik.values.on_hold}
                    id="v2_compensation_item_on_hold"
                  />
                  On hold
                </label>
              </li>
              <li
                className="string input optional stringish"
                id="v2_compensation_item_description_input"
              >
                <label
                  htmlFor="v2_compensation_item_description"
                  className="label"
                >
                  Description
                </label>
                <input
                  id="v2_compensation_item_description"
                  style={{ maxWidth: '500px' }}
                  type="text"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
              </li>
            </ol>
          </fieldset>
          <fieldset className="actions">
            <ol>
              <li
                className="action input_action "
                id="v2_compensation_item_submit_action"
              >
                <input
                  type="submit"
                  name="commit"
                  value={submitBtnCaption}
                />
              </li>
              <li className="cancel">
                <a href={props.returnPath}>Cancel</a>
              </li>
            </ol>
          </fieldset>
        </form>
        <LineItemPickerModal
          isOpen={isLineItemPickerVisible}
          onSubmit={lineItemPickerHandler}
          onClose={toggleLineItemPicker}
        ></LineItemPickerModal>
      </div>
    </div>
  )
}

export default CompensationItemForm
