import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import NameInput from '../forms/fields/NameInput'
import NationalIdInput from '../forms/fields/NationalIdInput'
import EmailInput from '../forms/fields/EmailInput'
import PhoneInput from '../forms/fields/PhoneInput'
import Checkbox from '../forms/fields/CheckboxHL'
import SelectHL from '../forms/fields/SelectHL'
import HLForm from '../forms/HLForm'
import { fetchUser } from './queries'
import {
  v_name,
  v_email,
  v_nationalId,
  v_phone,
} from '../../lib/validations'
import ClinicContext from './ClinicContext'

const LOCALES = [
  { value: 'NB', label: 'Norsk' },
  { value: 'EN', label: 'Engelsk' },
]

export const ClinicUserForm = ({
  user,
  setUser,
  onChange,
  onValid,
}) => {
  const { t, addError } = useContext(ClinicContext)
  const [fieldValid, setFieldValid] = useState({
    isPatient: true,
    phoneNumber: v_phone(user?.phoneNumber),
    name: user.name !== undefined && v_name(user?.name),
    nationalId:
      user.nationalId === undefined || v_nationalId(user.nationalId),
    email: user.email === undefined || v_email(user?.email),
  })
  const [missingPhone, setMissingPhone] = useState(true)
  const [isFetchingUser, setIsFetchingUser] = useState(false)
  const [isNewUser, setIsNewUser] = useState(null)

  async function queryUser(phoneNumber) {
    try {
      setMissingPhone(true)
      setIsFetchingUser(true)
      const response = await fetchUser(phoneNumber)

      if (response && !response.errors) {
        const { name, nationalId, email } = response
        const validName = name && v_name(name)
        const validNationalId =
          !nationalId || v_nationalId(nationalId)
        const validEmail = !email || v_email(email)
        setFieldValid((prev) => {
          return {
            ...prev,
            name: validName,
            nationalId: validNationalId,
            email: validEmail,
          }
        })
        setUser((prev) => {
          return {
            ...prev,
            name,
            nationalId: nationalId === null ? '' : nationalId,
            email: email === null ? '' : email,
          }
        })
        setIsNewUser(false)
        onValid(true)
      } else {
        setUser((prev) => {
          return {
            ...prev,
            name: '',
            nationalId: '',
            email: '',
          }
        })
        setFieldValid((prev) => {
          return {
            ...prev,
            name: false,
            nationalId: true,
            email: true,
          }
        })
        setIsNewUser(true)
        onValid(false)
      }
      setIsFetchingUser(false)
      setMissingPhone(false)
    } catch (error) {
      addError(`Error in queryUser: ${error}`)
    }
  }

  const validate = (value, valid, name) => {
    switch (name) {
      case 'nationalId':
      case 'email':
        const isValid = !value || valid
        setFieldValid((prev) => {
          return { ...prev, [name]: isValid }
        })
        break
      default:
        setFieldValid((prev) => {
          return { ...prev, [name]: valid }
        })
        break
    }
    onChange(value, name)
  }

  useEffect(() => {
    const isValidForm = Object.values(fieldValid).every((val) => val)
    onValid(isValidForm)
  }, [fieldValid, onValid])

  useEffect(() => {
    if (fieldValid.phoneNumber) {
      queryUser(user.phoneNumber)
    } else {
      setMissingPhone(true)
      setIsNewUser(null)
    }
  }, [user.phoneNumber, fieldValid.phoneNumber])

  return (
    <section className="mb-3">
      <h4 className="mt-5 mb-4">
        {`${t('who_to_contact', s)} `}
        <span className="text-danger">{`${t('ask_id', s)}`}</span>
      </h4>
      <HLForm preventSubmit={true} omitCsrf={true}>
        <Checkbox
          name="isPatient"
          onChange={validate}
          className="naked"
          showValidation={false}
          value={user?.isPatient}
        >
          {t('user_also_patient', s)}
        </Checkbox>
        <PhoneInput
          name="phoneNumber"
          onChange={validate}
          label={t('phoneNumber')}
          autoComplete="off"
          autoFocus={true}
          isRequired={true}
          value={user?.phoneNumber}
        />
        {!missingPhone && (
          <>
            <p className={`${isNewUser ? '' : 'text-danger'}`}>
              {t('user_description', s)(isNewUser)}
            </p>
            <NameInput
              autoComplete="off"
              label={t('fullName')}
              onChange={validate}
              isRequired={true}
              value={user?.name}
            />
            {user.isPatient && (
              <NationalIdInput
                autoComplete="off"
                onChange={validate}
                label={t('national_id', s)}
                isRequired={user.nationalId ? true : false}
                value={user?.nationalId}
                name="nationalId"
              />
            )}
            <EmailInput
              autoComplete="off"
              label={`${t('email', s)}`}
              onChange={validate}
              isRequired={user.email ? true : false}
              value={user?.email}
            />
            <SelectHL
              name="locale"
              placeholder="select language"
              options={LOCALES}
              onChange={validate}
              value={user.locale}
            />
          </>
        )}
        {!fieldValid.nationalId && (
          <p className="small text-danger">
            Ugyldig personnummer/D-nummer.
          </p>
        )}
        {!fieldValid.email && (
          <p className="small text-danger">Ugyldig e-post.</p>
        )}
      </HLForm>
      {isFetchingUser && (
        <div className="d-flex justify-content-center .align-items-center">
          <span className="spinner-border text-primary ml-2" />
        </div>
      )}
    </section>
  )
}

ClinicUserForm.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}

const s = {
  nb: {
    who_to_contact: 'Hvem skal vi kontakte?',
    ask_id: 'Sjekk ID!',
    heading: 'Bruker',
    national_id: 'Personnummer/D-nummer fra ID',
    email: 'E-post',
    user_also_patient: 'Kontaktperson er pasient',
    user_description: (newUser) =>
      newUser
        ? 'Dette er en ny bruker. Be om følgende opplysninger:'
        : 'Brukeren eksisterer allerede. Spør om følgende opplysninger stemmer:',
  },
}
