import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { patch } from '../../lib/backend-client'
import RadioListElement from '../RadioListElement'

const CoverageMapList = (props) => {
  const [mainCoverageMap, setMainCoverageMap] = useState(
    props.coverageMaps.find((map) => map.category == 'main'),
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      const data = {
        id: props.serviceProvider.id,
        coverage_map: {
          id: mainCoverageMap.id,
          category: 'main',
          name: mainCoverageMap.name,
        },
      }
      const updatePath = `${props.path}/coverage_maps/${mainCoverageMap.id}`
      patch(updatePath, JSON.stringify(data), true)
      setIsLoading(false)
    }
  }, [mainCoverageMap])

  const isMain = (map) => {
    return map == mainCoverageMap
  }

  const changeMainMap = (e) => {
    setIsLoading(true)
    const newMainMap = props.coverageMaps.find(
      (map) => map.id == parseInt(e.target.id),
    )
    setMainCoverageMap(newMainMap)
  }

  return (
    <>
      <div className="row">
        <div className="col-2">Hovedkart</div>
        <div className="col-8">Navn</div>
      </div>
      {props.coverageMaps.map((map) => (
        <div className="row" key={map.id}>
          <div className="col-2">
            <RadioListElement
              name="main-coverage-map"
              id={map.id}
              checked={isMain(map)}
              onChange={changeMainMap}
            />
          </div>
          <div className="col-8">
            <a href={`${props.path}/coverage_maps/${map.id}/edit`}>
              {map.name}
            </a>
          </div>
        </div>
      ))}
    </>
  )
}

CoverageMapList.propTypes = {
  path: PropTypes.string.isRequired,
  coverageMaps: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceProvider: PropTypes.object.isRequired,
}

export default CoverageMapList
