import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ValidatedInput from '../inputs/ValidatedInput'

class CityInput extends Component {
  static propTypes = {
    onChangeText: PropTypes.func,
    isValid: PropTypes.bool,
    initialValue: PropTypes.string,
    className: PropTypes.string,
  }

  validate(text) {
    return text.length > 1
  }

  wrapperClass = () => {
    if (this.props.isValid) {
      return 'form-group booking-form__valid-icon'
    } else {
      return 'form-group booking-form__valid-icon booking-form__valid-icon--invalid'
    }
  }

  render() {
    const { ...prevProps } = this.props

    const props = {
      ...prevProps,
      validate: this.validate,
      name: 'user[patient_attributes][city]',
      placeholder: 'Poststed',
    }

    return (
      <div
        className={`${this.wrapperClass()} ${this.props.className}`}
      >
        <label className="sr-only">{props.placeholder}</label>
        <ValidatedInput {...props} />
      </div>
    )
  }
}

export default CityInput

