import React, { useState } from 'react'
import RadioButtons from '../forms/fields/RadioButtons'
import HLForm from '../forms/HLForm'
import Button from '../Button'
import PropTypes from 'prop-types'

const ClinicPaymentMethod = ({
  booking,
  handleSelectedOrg,
  toggleWhoIsPaying,
  whoIsPaying,
  setPaymentIsConfirmed,
}) => {
  const [transitoryWhoIsPaying, setTransitoryWhoIsPaying] = useState(
    whoIsPaying,
  )
  const [
    transitoryEmployerInfo,
    setTransitoryEmployerInfo,
  ] = useState('')

  const [confirmed, setConfirmed] = useState(false)

  const handleTransitoryWhoIsPaying = (e) => {
    if (confirmed) return
    setTransitoryWhoIsPaying(e)
  }

  const handleTransitoryEmployerInfo = (e) => {
    if (confirmed) return
    const selectedValue = e.target.value
    const employerInfoObject = JSON.parse(selectedValue)
    setTransitoryEmployerInfo(employerInfoObject)
  }

  const handleConfirm = () => {
    toggleWhoIsPaying(transitoryWhoIsPaying)
    handleSelectedOrg(transitoryEmployerInfo)
    setConfirmed(true)
    setPaymentIsConfirmed(true)
  }

  return (
    <div id="clinic-payment-method">
      <RadioButtons
        disabled={confirmed}
        onChange={(e) => handleTransitoryWhoIsPaying(e)}
        defaultChecked={transitoryWhoIsPaying}
        name="whoIsPaying"
        value={transitoryWhoIsPaying}
        options={[
          {
            value: 'user',
            label1: 'Pasienten betaler selv',
          },
          {
            value: 'company',
            label1: 'Arbeidsgiver betaler',
          },
        ]}
      />
      {transitoryWhoIsPaying == 'company' && (
        <HLForm preventSubmit={true} className="mt-1">
          <select
            className="select select-company-name"
            onChange={(e) => handleTransitoryEmployerInfo(e)}
            name="choose-billable-organization"
            disabled={confirmed}
          >
            <option defaultChecked value="">
              Velg arbeidsgiver
            </option>
            {booking.billable_organizations.map((org) => {
              return (
                <option
                  key={org.id}
                  value={JSON.stringify({
                    name: org.name,
                    id: org.id,
                  })}
                >
                  {org.name}
                </option>
              )
            })}
          </select>
        </HLForm>
      )}
      {confirmed ? (
        <div id="payment-is-confirmed">Betalingsmåte bekreftet</div>
      ) : (
        <Button
          className="btn btn-primary btn-block btn-lg mt-2"
          label={'Bekreft'}
          onClick={handleConfirm}
          disabled={
            !transitoryWhoIsPaying ||
            (transitoryWhoIsPaying === 'company' &&
              !transitoryEmployerInfo)
          }
        />
      )}
    </div>
  )
}

ClinicPaymentMethod.propTypes = {
  booking: PropTypes.object.isRequired,
  handleSelectedOrg: PropTypes.func.isRequired,
  toggleWhoIsPaying: PropTypes.func.isRequired,
  whoIsPaying: PropTypes.string.isRequired,
  setPaymentIsConfirmed: PropTypes.func.isRequired,
}

export default ClinicPaymentMethod
