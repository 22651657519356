export const filterEmpty = (stringArr) =>
  stringArr.filter((val) => val != '').join(' ')

export const capitalizeFirstLetter = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1)

export const getInputValue = (el) =>
  el.type != 'checkbox' ? el.value : el.checked

export const serializeForm = (form) => {
  const values = {}
  Array.prototype.slice.call(form.elements).forEach((el) => {
    if (el.name !== '_method' && !/button|submit/.test(el.type))
      values[el.name] = getInputValue(el)
  })
  return values
}

export const attributeFilter = (collection, query = null) => {
  if (!query) return collection
  const parts = query.toLowerCase().split(':')
  const attr = parts.length == 2 ? parts[0] : 'name'
  const val = parts.length == 2 ? parts[1] : query
  return collection.filter((p) => p[attr].toLowerCase().includes(val))
}

export const toCamelCase = (string) => {
  return string
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}
