import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, translate } from '../lib/locale/'
import gqlCall from '../api/graphql'
import {
  buildGraphQLQuery,
  parseQueryResponse,
  defaultGraphQLSerializer,
} from '../api/graphqlExtended'

import HLForm from './forms/HLForm'
import OrganizationInputs from './OrganizationInputs'
import Submit from './forms/fields/SubmitButton'

const OrganizationForm = ({
  redirectPath,
  organization = {},
  contactPerson = {},
  showIntroText = true,
  narrowPage = true,
}) => {
  const [organizationUpdated, setOrganizationUpdated] = useState({})
  const [contactPersonUpdated, setConctactPersonUpdated] = useState(
    contactPerson,
  )
  const [error, setError] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  const isNewOrganization = !organizationUpdated?.id

  const isNewContactPerson = !contactPerson?.id

  useEffect(() => {
    if (!organization?.billingMethod || !organization?.billingDueBy) {
      setOrganizationUpdated({
        ...organization,
        billingMethod: 'email',
        billingDueBy: 30,
      })
    } else {
      setOrganizationUpdated(organization)
    }
  }, [])

  const locale = DEFAULT_LOCALE
  const t = (prop, source) => translate(`${locale}.${prop}`, source)
  const isSelfCreated = !redirectPath.includes('admin')

  const createOrUpdateOrganization = async (contactPersonId) => {
    const params = [
      `contactPersonId: ${contactPersonId}`,
      `name: "${organizationUpdated.name}"`,
      `mailingAddress: {
        address: "${organizationUpdated.mailingAddress?.address}",
        zip: "${organizationUpdated.mailingAddress?.zip}",
        city: "${organizationUpdated.mailingAddress?.city}"
      }`,
      `billingEmail: "${
        organizationUpdated.billingEmail ||
        organizationUpdated.billing_email ||
        ''
      }"`,
      `billingReference: "${
        organizationUpdated.billingReference ||
        organizationUpdated.billing_reference ||
        ''
      }"`,
      `billingMethod: "${
        organizationUpdated.billingMethod ||
        organizationUpdated.billing_method
      }"`,
      `billingDueBy: ${
        organizationUpdated.billingDueBy ||
        organizationUpdated.billing_due_by
      }`,
      `orgNr: "${
        organizationUpdated.orgNr || organizationUpdated.org_nr
      }"`,
    ]

    if (isNewOrganization) {
      params.push(`selfCreated: ${isSelfCreated}`)
    } else {
      params.push(`id: ${organizationUpdated.id}`)
    }

    if (
      organizationUpdated.visitingAddress &&
      Object.keys(organizationUpdated.visitingAddress).length > 0
    ) {
      params.push(`visitingAddress: {
        address: "${organizationUpdated.visitingAddress?.address}",
        zip: "${organizationUpdated.visitingAddress?.zip}",
        city: "${organizationUpdated.visitingAddress?.city}"
      }`)
    }

    const resourceName = isNewOrganization
      ? 'createOrganization'
      : 'updateOrganization'

    const organizationQuery = buildGraphQLQuery({
      params: params.join(','),
      resourceName: resourceName,
      type: 'mutation',
    })

    const organizationResponse = await gqlCall(organizationQuery)

    return parseQueryResponse({
      response: organizationResponse,
      resourceName: resourceName,
      multiple: false,
    })
  }

  const createOrUpdateContactPerson = async () => {
    const resourceName = isNewContactPerson
      ? 'createContactPerson'
      : 'updateContactPerson'

    const params = defaultGraphQLSerializer(
      contactPersonUpdated,
      resourceName,
    )
    const contactPersonQuery = buildGraphQLQuery({
      params: params,
      resourceName: resourceName,
      type: 'mutation',
    })

    const contactPersonResponse = await gqlCall(contactPersonQuery)

    return parseQueryResponse({
      response: contactPersonResponse,
      resourceName: resourceName,
      multiple: false,
    })
  }

  const handleOrganizationInputs = (
    element,
    elementType,
    isFormValid,
  ) => {
    setIsFormValid(isFormValid)
    switch (elementType) {
      case 'organization':
        setOrganizationUpdated(element)
        break
      case 'contactPerson':
        setConctactPersonUpdated(element)
        break
      default:
        break
    }
  }

  const onSubmit = () => {
    organizationSubmit()
  }

  const organizationSubmit = async () => {
    try {
      const contactPersonPersisted = await createOrUpdateContactPerson()

      await createOrUpdateOrganization(contactPersonPersisted.id)

      redirectToConfirmation()
    } catch (e) {
      setError(`Cannot create organization : ${e}`)
    }
  }

  const redirectToConfirmation = () => {
    window.location.href = redirectPath + '?success=true'
  }

  return (
    <section
      className={`${
        narrowPage && ' page__content page__content--narrow'
      }`}
    >
      {showIntroText && (
        <>
          <h1 className="mb-4">Registrer bedriftskunde</h1>
          <p>
            Velkommen som bedrifts-/organisasjonskunde hos
            Hjemmelegene! Takk for at du fyller ut dette skjemaet. Det
            sikrer at vi har riktig informasjon for fremtiden.
          </p>
        </>
      )}
      <HLForm preventSubmit={true} omitCsrf={true}>
        <OrganizationInputs
          onChange={handleOrganizationInputs}
          organization={organization}
          contactPerson={contactPerson}
          organizationAutocomplete={isNewOrganization}
          askVisitingAddress={true}
        />
        {error && <p className="text-danger small">{error}</p>}
        <Submit
          disabled={!isFormValid}
          onClick={onSubmit}
          label={t('confirm')}
          className="btn-100 mt-3"
        />
      </HLForm>
    </section>
  )
}

OrganizationForm.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  organization: PropTypes.object,
  contactPerson: PropTypes.object,
  showIntroText: PropTypes.bool,
  narrowPage: PropTypes.bool,
}

export default OrganizationForm
